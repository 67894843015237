import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";
import ClubCommDashboardContext from "../../contexts/ClubCommDashboardContext";

const ClubCommFilterManager = withRouter(({ location }) => {
  const clubCommDbContext = useContext(ClubCommDashboardContext);
  const { state, dispatch } = clubCommDbContext;
  const { filtersDirty } = state;

  useEffect(() => {
    if (location.pathname.substring(0, 9) !== RouteConstants.CLUB_COMM && filtersDirty) {
      dispatch({ type: "resetFilters" });
    }
  }, [location, dispatch, filtersDirty]);

  return <></>;
});

export default ClubCommFilterManager;
