import { createSelector } from "reselect";
import { flatten, isEmpty, isEqual, mapValues, values } from "lodash";

const getPitchCountsByProfileIdAndDate = state => state.pitchCountsByProfileIdAndDate;
const getPersistedPitchCountsByProfileIdAndDate = state => state.persistedPitchCountsByProfileIdAndDate;

const getPitchCounts = createSelector(getPitchCountsByProfileIdAndDate, pitchCountsByProfileIdAndDate =>
  flatten(values(pitchCountsByProfileIdAndDate).map(values))
);

const getPitchCountsByProfileId = createSelector(getPitchCountsByProfileIdAndDate, pitchCountsByProfileIdAndDate =>
  mapValues(pitchCountsByProfileIdAndDate, values)
);

const getIsDirty = createSelector(
  getPitchCountsByProfileIdAndDate,
  getPersistedPitchCountsByProfileIdAndDate,
  (pitchCountsByProfileIdAndDate, persistedPitchCountsByProfileIdAndDate) =>
    !isEqual(pitchCountsByProfileIdAndDate, persistedPitchCountsByProfileIdAndDate)
);

const getIsValid = createSelector(getPitchCounts, pitchCounts => pitchCounts.every(pitchCount => pitchCount.valid));

const getHasPitchers = createSelector(
  getPitchCountsByProfileIdAndDate,
  pitchCountsByProfileIdAndDate => !isEmpty(pitchCountsByProfileIdAndDate)
);

export { getPitchCounts, getPitchCountsByProfileId, getIsDirty, getIsValid, getHasPitchers };
