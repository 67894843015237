import React from "react";
import SharedRosterForm from "./SharedRosterForm";
import { MAIN } from "../../constants/TournamentTypeConstants";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";

const MainTournamentRosterForm = () => (
  <TournamentTypeContext.Provider value={MAIN}>
    <SharedRosterForm />
  </TournamentTypeContext.Provider>
);

export default MainTournamentRosterForm;
