import BaseApi from "./BaseApi";

export default class RosterApi extends BaseApi {
  constructor() {
    if (new.target === RosterApi) {
      throw new TypeError("Cannot construct RosterApi instances directly");
    }
  }

  static getAvailableRoster = (prefix, fedTeamId) => this.get(`team/${fedTeamId}/${prefix}/available`);
  static getProvisionalRoster = (prefix, fedTeamId) => this.get(`team/${fedTeamId}/${prefix}/provisional`);
  static getFinalRoster = (prefix, fedTeamId) => this.get(`team/${fedTeamId}/${prefix}/final`);

  static updateTournamentRoster = (prefix, fedTeamId, rosterUpdates) =>
    this.put(`roster/${prefix}/team/${fedTeamId}`, rosterUpdates);
  static updateUniforms = (fedTeamId, uniformUpdates) => this.patch(`roster/uniform/${fedTeamId}`, uniformUpdates);
  static updatePosition = (fedTeamId, profileId, positionId) =>
    this.patch(`roster/${fedTeamId}/${profileId}/${positionId}/position`);
}
