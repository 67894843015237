const SERVICE_URL = window.MLBBest.envVariables.REACT_APP_SERVICES_URL;

const currentYearURL = `${SERVICE_URL}/currentYear`;

// unauthenticated endpoint to get current year

export const getCurrentYear = async () => {
  const response = await fetch(currentYearURL);
  return response.json();
};
