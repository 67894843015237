import React, { useState, useContext } from "react";
import { BestSelect } from "best-common-react";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import CountryContext from "../../../contexts/CountryContext";
import WBCAccessControl from "../../protected/WBCAccessControl";

const DropdownTitleStyle = styled.h6`
  text-align: left;
  font-size: 17px;
  font-weight: bold;
`;

const Form = styled.form`
  text-align: left;
`;

const AddModalBodyContent = ({ setNewTeams, onSubmit }) => {
  const { selectedYear } = useContext(WBCYearsContext);
  const countryContext = useContext(CountryContext);
  const [teamsList, setTeamsList] = useState([]);

  let manageList = values => {
    if (values) {
      const formattedValues = values.map(t => {
        return {
          countryId: t.value,
          name: t.label,
          countryCode: t.code,
          year: selectedYear
        };
      });
      setNewTeams(formattedValues);
    } else {
      setNewTeams([]);
    }
    setTeamsList(values);
  };

  return (
    <Form
      onSubmit={event => {
        event.preventDefault();
        onSubmit();
      }}
    >
      <DropdownTitleStyle>
        Federations <span style={{ color: "red" }}>*</span>
      </DropdownTitleStyle>
      <WBCAccessControl
        input={isDisabled => (
          <BestSelect
            isDisabled={isDisabled}
            isMulti
            id={"federation-teams"}
            options={countryContext.state.countryDropdown}
            value={teamsList}
            values={teamsList}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={value => {
              manageList(value);
            }}
          />
        )}
      />
    </Form>
  );
};

AddModalBodyContent.propTypes = {
  addFedTeamModalStatus: PropTypes.func,
  setNewTeams: PropTypes.func,
  onSubmit: PropTypes.func
};

AddModalBodyContent.defaultProps = {
  onSubmit: () => {}
};

const AddModalFooterContent = ({ formStatus, onSave, onCancel }) => {
  return (
    <div className={"d-flex"}>
      <WBCPrimaryButton
        disabled={formStatus}
        onClick={event => {
          event.stopPropagation();
          onSave();
        }}
      >
        Add Teams
      </WBCPrimaryButton>
      <div className={"pl-2"}>
        <WBCPrimaryOutlinedButton
          onClick={event => {
            event.stopPropagation();
            onCancel();
          }}
        >
          Cancel
        </WBCPrimaryOutlinedButton>
      </div>
    </div>
  );
};

AddModalFooterContent.propTypes = {
  formStatus: PropTypes.bool,
  onSave: PropTypes.func,
  onCancel: PropTypes.func
};

AddModalFooterContent.defaultProps = {
  formStatus: true,
  onSave: () => {},
  onCancel: () => {}
};

AddModalFooterContent.displayName = "FooterContent";
AddModalBodyContent.displayName = "BodyContent";

export { AddModalFooterContent, AddModalBodyContent };
