import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import styled from "styled-components";

export const ApproveButton = styled(WBCPrimaryButton)`
  &:focus {
    outline: none;
  }
`;

export const OutlinedApproveButton = styled(WBCPrimaryOutlinedButton)`
  &:focus {
    outline: none;
  }
`;

export const DenyButton = styled(WBCPrimaryOutlinedButton)`
  &:focus {
    outline: none;
  }
`;

export const BackToPendingButton = styled(WBCPrimaryOutlinedButton)`
  &:focus {
    outline: none;
  }
`;
