import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";
import Login from "../components/login/Login";
import RouteConstants from "../constants/RouteConstants";
import LoginContextProvider from "../providers/LoginContextProvider";

const LoginContainer = () => {
  const authContext = useContext(AuthContext);

  return (
    <LoginContextProvider>
      <div>
        {!authContext.state.loggedIn ? (
          <div>
            <Login />
          </div>
        ) : (
          <Redirect to={RouteConstants.BASE} />
        )}
      </div>
    </LoginContextProvider>
  );
};

LoginContainer.propTypes = {};

export default LoginContainer;
