import React, { useContext } from "react";
// import { Collapse } from "best-common-react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import Collapse from "../temp/Collapse";

const CollapseTitle = styled.div`
  height: 19px;
  font-family: Helvetica;
  font-size: 16px;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
`;

const WBCCollapse = ({ title, styles, openCloseCallback, children, ...rest }) => {
  const Theme = useContext(ThemeContext);

  const titleElement = <CollapseTitle>{title}</CollapseTitle>;

  const DefaultStyle = {
    titleColor: Theme["wbcBlue"],
    borderColor: "#d4e0ec"
  };
  const CollapseStyle = { ...DefaultStyle, ...styles };

  return (
    <Collapse title={titleElement} styles={CollapseStyle} openCloseCallback={openCloseCallback} {...rest}>
      {children}
    </Collapse>
  );
};

WBCCollapse.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.any.isRequired,
  styles: PropTypes.object,
  openCloseCallback: PropTypes.func
};

export default WBCCollapse;
