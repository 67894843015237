import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import { AlertConstants, Icon } from "best-common-react";
import { ContextMenu, MenuButton, MenuIcon, MenuItem } from "../elements/ContextMenu";
import CoachingStaffContext from "../../contexts/CoachingStaffContext";
import MLBPlayerRequestApi from "../../httpClients/MLBPlayerRequestApi";
import { useAlert } from "../hooks/useAlert";
import ProfileRequestConstants from "../../constants/ProfileRequestConstants";
import AuthContext from "../../contexts/AuthContext";
import {
  isStatusCancelled,
  isStatusRequestRequired,
  isStatusSubmittedToBOC,
  isStatusSubmittedToClub
} from "../../utils/MLBProfileRequestUtil";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import styled from "styled-components";
import openDocumentsModal from "../../effects/documents/openDocumentsModal";
import LabelConstants from "../../constants/LabelConstants";
import StaffApi from "../../httpClients/StaffApi";

const CoachingStaffActionIcon = styled(Icon)`
  color: ${props => (props.disabled ? props.theme.grey : props.theme.icon.wbcBlue)};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  &:hover {
    color: ${props => (props.disabled ? props.theme.grey : props.theme.icon.hover)};
    cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

const CoachingStaffActionsFormatter = ({ row: profile }) => {
  const showAlert = useAlert();
  const targetRef = useRef(null);

  const authContext = useContext(AuthContext);
  const coachingStaffContext = useContext(CoachingStaffContext);

  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { dispatch: coachingStaffDispatch } = coachingStaffContext;
  const { dispatch: globalModalDispatch } = useContext(GlobalModalContext);

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const canEdit = isBOCadmin || isFedAdmin || isTeamCoordinator;
  const canCancelRequest =
    isStatusSubmittedToBOC(profile?.requestStatus) || isStatusSubmittedToClub(profile?.requestStatus);
  const canSubmitRequestToBoc = isStatusRequestRequired(profile?.requestStatus);

  const onCancelRequestClicked = () => {
    toggleMenuIsOpen();
    globalModalDispatch({
      type: "openConfirmSubmitModal",
      headerText: ProfileRequestConstants.MODAL_TEXT.CANCEL.headerText,
      content: ProfileRequestConstants.MODAL_TEXT.CANCEL.content,
      updateStatusButtonText: "Cancel Request",
      onConfirmRequestFn: () => updateRequestStatus(ProfileRequestConstants.STATUS.CANCELLED, "Cancelled Request"),
      profileId: profile?.profileId,
      submitProfile: profile
    });
  };

  const onRemoveStaffClicked = () => {
    toggleMenuIsOpen();
    globalModalDispatch({
      type: "openConfirmSubmitModal",
      headerText: "Remove Staff",
      content: "Are you sure you want to remove this staff member?",
      onConfirmRequestFn: () => removeStaff(),
      submitProfile: profile,
      updateStatusButtonText: "Remove Staff"
    });
  };

  const onSubmitToBocClicked = () => {
    toggleMenuIsOpen();
    globalModalDispatch({
      type: "openConfirmSubmitModal",
      headerText: ProfileRequestConstants.MODAL_TEXT.SUBMIT.headerText,
      content: ProfileRequestConstants.MODAL_TEXT.SUBMIT.content.replace("person", "staff"),
      updateStatusButtonText: isBOCadmin
        ? ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC
        : ProfileRequestConstants.ACTIONS.SUBMIT_TO_WBCI,
      onConfirmRequestFn: () => updateRequestStatus(ProfileRequestConstants.STATUS.SUBMITTED_TO_BOC, "Submitted"),
      profileId: profile?.profileId,
      submitProfile: profile
    });
  };

  const onUploadDocsClicked = () => {
    toggleMenuIsOpen();
    openDocumentsModal({
      profile,
      labelTypeId: LabelConstants.LABEL_TYPE.STAFF,
      globalModalDispatch,
      onSaveSuccess: () => reloadStaff()
    });
  };

  const toggleMenuIsOpen = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  const removeStaff = () => {
    StaffApi.removeStaff(profile.profileId)
      .then(() => {
        reloadStaff();
        showAlert("Removed");
      })
      .catch(() => {
        showAlert("Failed to remove staff", AlertConstants.TYPES.DANGER);
      });
  };

  const updateRequestStatus = (requestStatus, alert) => {
    MLBPlayerRequestApi.updatePlayerRequestStatus(profile.profileId, requestStatus, alert)
      .then(() => {
        reloadStaff();
        showAlert(alert);
      })
      .catch(() => {
        showAlert("Failed to submit request", AlertConstants.TYPES.DANGER);
      });
  };

  const reloadStaff = () => {
    coachingStaffDispatch({ type: "setShouldLoadStaff", shouldLoadStaff: true });
  };

  return (
    canEdit && (
      <>
        <MenuButton ref={targetRef} onClick={toggleMenuIsOpen}>
          <MenuIcon iconName="fa-ellipsis-v" />
        </MenuButton>
        <ContextMenu targetRef={targetRef} isOpen={menuIsOpen} toggleMenu={toggleMenuIsOpen}>
          <MenuItem
            disabled={!canSubmitRequestToBoc}
            onClick={() =>
              canSubmitRequestToBoc ? onSubmitToBocClicked(ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC) : null
            }
          >
            <CoachingStaffActionIcon disabled={!canSubmitRequestToBoc} iconName="fa-check-circle" className="fa-md" />
            Submit Request
          </MenuItem>
          <MenuItem onClick={onUploadDocsClicked}>
            <CoachingStaffActionIcon iconName="fa-file-upload" className="fa-md" />
            Upload Docs
          </MenuItem>
          <MenuItem onClick={onRemoveStaffClicked}>
            <CoachingStaffActionIcon iconName="fa-trash-alt" className="fa-md" />
            Remove Staff
          </MenuItem>
          <MenuItem disabled={!canCancelRequest} onClick={() => (canCancelRequest ? onCancelRequestClicked() : null)}>
            <CoachingStaffActionIcon disabled={!canCancelRequest} iconName="fa-times-circle" className="fa-md" />
            Cancel Request
          </MenuItem>
        </ContextMenu>
      </>
    )
  );
};

CoachingStaffActionsFormatter.propTypes = {
  row: PropTypes.object
};

export default CoachingStaffActionsFormatter;
