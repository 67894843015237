import React from "react";
import PropTypes from "prop-types";
import { Input } from "best-common-react";

const allowNull = propTypes => {
  return (props, propName, ...rest) => {
    if (props[propName] === null) {
      return null;
    }
    return propTypes(props, propName, ...rest);
  };
};

const WBCNumberInput = ({ name, value, onChange, disabled, className, step, positiveOnly, max }) => {
  const validateChange = event => {
    if (event.target.validity.valid) {
      onChange(event.target.value);
    }
  };

  return (
    <div className={`input-group ${className}`}>
      <Input
        type="number"
        step={step}
        id={name}
        onChange={validateChange}
        value={value === null ? "" : value}
        disabled={disabled}
        max={max}
        {...(positiveOnly ? { min: "0.00" } : {})}
      />
    </div>
  );
};

WBCNumberInput.propTypes = {
  name: PropTypes.string,
  value: allowNull(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  positiveOnly: PropTypes.bool,
  max: allowNull(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  step: allowNull(PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired)
};

WBCNumberInput.defaultProps = {
  name: "best-number-input",
  disabled: false,
  positiveOnly: false,
  max: "999.99",
  step: "1"
};

export default WBCNumberInput;
