import React from "react";
import PropTypes from "prop-types";
import SelectedTeamContextInitilizer from "../initializer/SelectedTeamContextInitilizer";
import SelectedTeamContextProvider from "../SelectedTeamContextProvider";

const SelectedTeamContextProviderWrapper = ({ children }) => {
  return (
    <SelectedTeamContextProvider>
      <SelectedTeamContextInitilizer>{children}</SelectedTeamContextInitilizer>
    </SelectedTeamContextProvider>
  );
};

SelectedTeamContextProviderWrapper.propTypes = {
  children: PropTypes.object
};

export default SelectedTeamContextProviderWrapper;
