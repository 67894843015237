import PropTypes from "prop-types";
import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import RouteConstants from "../../../constants/RouteConstants";
import AuthContext from "../../../contexts/AuthContext";
import FinalizedStatusContext from "../../../contexts/FinalizedStatusContext";
import RosterContext from "../../../contexts/RosterContext";
import TournamentFormContext from "../../../contexts/TournamentFormContext";
import { removeFromList } from "../../../utils/DragUtils";
import { isEmpty } from "../../../utils/StringUtils";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import LinkText from "../components/LinkText";
import { DragIcon } from "../provisional/PlayerRowHelper";
import { ListItem } from "./PositionHelpers";

const PlayerName = styled(LinkText)`
  display: block;
  max-width: calc(100% - 40px);
`;

const Trash = styled.i.attrs(() => ({
  className: "fas fa-trash-alt"
}))`
  display: block;
  float: right;
  font-size: 1.16em;
  color: ${props => props.theme.wbcBlue};
  cursor: pointer;

  &&& {
    line-height: inherit;
  }
`;

const PlayerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
  padding-left: 25px;
`;

const getName = row => {
  const { firstName, lastName } = row;
  if (isEmpty(firstName) || isEmpty(lastName)) {
    const { firstName: first, lastName: last } = row.fullName || {};
    return isEmpty(first) || isEmpty(last) ? null : `${last}, ${first}`;
  }

  return `${lastName}, ${firstName}`;
};

const PositionRow = ({ droppableId, row, index, isDragDisabled = false }) => {
  // context(s) && state
  const authContext = useContext(AuthContext);
  const rosterContext = useContext(RosterContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);

  // variable(s)
  const dragId = `${droppableId}-${row.profileId}`;
  const { isBOCadmin } = authContext.state;
  const { onFormDirty } = tournamentFormContext.state;
  const { idToDataMap, final } = rosterContext.state;
  const { finalized } = finalizedStatusContext.state;

  // function(s)
  const removePlayer = () => {
    const source = idToDataMap[droppableId];
    const { profileId } = final[source][index];
    rosterContext.dispatch({
      source,
      profileId,
      type: "removeFromFinalRoster",
      list: removeFromList(final[source], index)
    });
    onFormDirty();
  };

  return (
    <Draggable draggableId={dragId} index={index} isDragDisabled={isDragDisabled}>
      {provided => {
        const { innerRef, draggableProps, dragHandleProps } = provided;
        return (
          <ListItem ref={innerRef} {...draggableProps} {...dragHandleProps}>
            <PlayerWrapper>
              {!isDragDisabled && <DragIcon />}
              <PlayerName row={row} profileType={RouteConstants.PLAYER}>
                {getName(row)}
              </PlayerName>
              {!finalized || isBOCadmin ? (
                <WBCAdminAccess>
                  <Trash onClick={removePlayer} />
                </WBCAdminAccess>
              ) : null}
            </PlayerWrapper>
          </ListItem>
        );
      }}
    </Draggable>
  );
};

PositionRow.propTypes = {
  droppableId: PropTypes.string,
  row: PropTypes.object,
  index: PropTypes.number,
  isDragDisabled: PropTypes.bool
};

export default PositionRow;
