import _ from "lodash";
import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import DnDContext from "../contexts/DnDContext";

const initialState = {};

const DragDropContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "mount":
        return { ...state, [action.droppableId]: { onDragEndFn: action.onDragEndFn, isMounted: true } };
      case "listMetadata":
        const droppable = state[action.droppableId];
        if (!_.isEqual(droppable?.listMetadata, action.listMetadata)) {
          droppable.listMetadata = action.listMetadata;
          return { ...state, droppable };
        }
        return state;
      default:
        return state;
    }
  };

  const onBeforeCapture = ({ draggableId }) => {
    /**
     * Monkey patch: this sets the max width of the dragged DOM element
     * to something arbitrarily small (353px). This is useful
     * when dragging to a different list that is significantly
     * smaller than the source list
     */
    const el = document.getElementById(draggableId);
    if (!el) return;

    if (draggableId.startsWith("player-table")) {
      el.style.maxWidth = "353px";
    }
  };

  const onDragEnd = result => {
    // variables
    const { source, destination, draggableId } = result;
    const el = document.getElementById(draggableId);
    const onDragEndFn = state[source.droppableId].onDragEndFn;

    // update size of elem
    if (el) el.style.maxWidth = "initial";

    // call onDragEndFn if there is a destination
    if (destination && onDragEndFn) {
      if (source.droppableId === destination.droppableId) {
        result.action = "reorder";
      } else if (
        (source?.droppableId === "player-table" && state[destination.droppableId]?.listMetadata?.remaining) ||
        source?.droppableId === "staff-list"
      ) {
        result.action = "addProvisionalToFinal";
      }
      onDragEndFn(result);
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DnDContext.Provider value={{ state, dispatch }}>
      <DragDropContext onDragEnd={onDragEnd} onBeforeCapture={onBeforeCapture}>
        {children}
      </DragDropContext>
    </DnDContext.Provider>
  );
};

DragDropContextProvider.propTypes = {
  children: PropTypes.object
};

export default DragDropContextProvider;
