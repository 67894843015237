import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { FormColumn2Wide, FormInput } from "best-common-react";
import _ from "lodash";
import RouteConstants from "../../../constants/RouteConstants";
import AuthContext from "../../../contexts/AuthContext";
import CountryContext from "../../../contexts/CountryContext";
import ProfileContext from "../../../contexts/ProfileContext";
import { useProfileFedTeams } from "../../hooks/useProfileFedTeams";
import ProfileHistoryTable from "./ProfileHistoryTable";

const StyledForm = styled.div.attrs(() => ({
  className: "container-fluid"
}))`
  text-align: left;
  height: 81vh;
  overflow: scroll;

  &&& {
    padding: 24px;
  }
`;

const HistoryTableWrapper = styled.div`
  width: 100%;
`;

const allTeamsOption = {
  fedTeamId: -1,
  profileId: -1,
  countryId: -1,
  label: "All Federations",
  value: "All Federations"
};

const ProfileHistoryForm = withRouter(({ history, match: { params } }) => {
  // hooks
  const authContext = useContext(AuthContext);
  const countryContext = useContext(CountryContext);
  const profileContext = useContext(ProfileContext);

  // variables
  const { dispatch } = profileContext;
  const { countries } = countryContext.state;
  const { isBOC, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { playerId: profileId } = params;

  // teams
  const { currentlySelectedTeam: selectedTeam, teams: teamsOptions } = profileContext.state;
  const options = [allTeamsOption, ...teamsOptions];
  const value = _.isEmpty(selectedTeam) ? allTeamsOption : selectedTeam;

  // functions
  const findCountryForTeam = team =>
    countries.find(country => country.countryId === team.countryId && team.fedTeamId !== selectedTeam.fedTeamId);

  const onTeamChange = selection => {
    // selected all federations
    if (selection.fedTeamId === -1) {
      dispatch({ type: "setSelectedTeam", currentlySelectedTeam: allTeamsOption });
    } else {
      // find the country that matches the team
      const country = findCountryForTeam(selection);
      if (country) {
        const newSelectedTeam = {
          value: country.countryName,
          label: country.countryName,
          fedTeamId: selection.fedTeamId,
          countryId: selection.countryId
        };
        dispatch({ type: "setSelectedTeam", currentlySelectedTeam: newSelectedTeam });

        history.replace(
          RouteConstants.PROFILE.replace(":playerId", selection.profileId)
            .replace(":fedTeamId", selection.fedTeamId)
            .replace(":profileType", RouteConstants.PLAYER) + "?tab=1"
        );
      }
    }
  };

  // effects
  useProfileFedTeams(profileId);

  return (
    <StyledForm>
      <div className="row">
        {isBOC || isFedAdmin || isTeamCoordinator ? (
          <div className="col-xs-12 col-md-6">
            <div className="row">
              <FormColumn2Wide>
                <FormInput
                  editable
                  isRequired={false}
                  isDisabled={false}
                  name="teamDropdown"
                  label="Federation"
                  type="select"
                  options={options}
                  value={value}
                  onChangeFn={onTeamChange}
                />
              </FormColumn2Wide>
            </div>
          </div>
        ) : null}
      </div>
      <HistoryTableWrapper className="row no-gutters">
        <HistoryTableWrapper>
          <ProfileHistoryTable profileId={profileId} fedTeams={options} />
        </HistoryTableWrapper>
      </HistoryTableWrapper>
    </StyledForm>
  );
});

export default ProfileHistoryForm;
