import React, { useContext } from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthContext";
import { isStatusRequestRequired } from "../../utils/MLBProfileRequestUtil";

const RequestStatusFormatter = ({ row }) => {
  const { isBOC } = useContext(AuthContext).state;
  const requestRequired = isStatusRequestRequired(row?.requestStatus);

  if (requestRequired && !isBOC) {
    // make text red
    return (
      // align div to center
      <div style={{ color: "red" }}>Required</div>
    );
  }

  if (isBOC) {
    if (requestRequired) {
      // make text red
      return <div style={{ color: "red" }}>{row.requestStatus}</div>;
    }
    return <div>{row?.requestStatus}</div>;
  }

  return <div>Not Required</div>;
};

RequestStatusFormatter.propTypes = {
  row: PropTypes.object
};

export default RequestStatusFormatter;
