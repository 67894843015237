import React from "react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import NavigationLink from "./NavigationLink";

const IconNavigationLink = ({ to, icon, tooltip, toggleMobileNav, ...rest }) => {
  return (
    <NavigationLink to={to} tooltip={tooltip} toggleMobileNav={toggleMobileNav} {...rest}>
      <Icon iconName={icon} />
    </NavigationLink>
  );
};

IconNavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  toggleMobileNav: PropTypes.bool.isRequired,
  tooltip: PropTypes.string
};

IconNavigationLink.defaultProps = {
  tooltip: null
};

export default IconNavigationLink;
