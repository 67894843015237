import React from "react";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

const DesktopTabletView = props => <Responsive {...props} minWidth={props.minWidth} />;

DesktopTabletView.propTypes = {
  minWidth: PropTypes.number
};

DesktopTabletView.defaultProps = {
  minWidth: 768
};

export default DesktopTabletView;
