import React from "react";
import PropTypes from "prop-types";
import PositionList from "./PositionList";
import { PositionContainer } from "./PositionHelpers";

const Catchers = ({ settings }) => (
  <PositionContainer>
    <PositionList
      droppableId="catcher-list"
      header="Catchers"
      maximum={settings.catchers.catchersMax}
      minimum={settings.catchers.catchersMin}
    />
  </PositionContainer>
);

Catchers.propTypes = {
  settings: PropTypes.object
};

export default Catchers;
