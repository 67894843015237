import BaseApi from "./BaseApi";

export default class EmailNotificationsApi extends BaseApi {
  constructor() {
    if (new.target === EmailNotificationsApi) {
      throw new TypeError("Cannot construct EmailNotificationsApi instances directly");
    }
  }

  static getNotificationsByYear = year => this.get(`notifications?wbcYear=${year}`);
  static sendNotifications = emailIds => this.post("notifications/send", { notificationIds: emailIds });
  static getNotificationTypes = () => this.get("notifications/types");
  static syncClubRecipients = () => this.get("notifications/syncClubRecipients");
}
