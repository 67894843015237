import BaseApi from "./BaseApi";

export default class InterestListApi extends BaseApi {
  static addPlayerToInterestList = (year, teamId, profileId, override = false) =>
    this.put(`interestlist/${year}?profileId=${profileId}&fedTeamId=${teamId}&override=${override}`);

  static getInterestListPlayers = (year, fedTeamId) => this.get(`interestlist/${year}/${fedTeamId}`);

  static updateInterestListPlayers = (fedTeamId, updates, override = false) =>
    this.patch(`interestlist/${fedTeamId}/update?override=${override}`, updates);

  static isOnInterestList = (year, fedTeamId, profileId) => this.get(`interestlist/${year}/${fedTeamId}/${profileId}`);
}
