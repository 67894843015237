import { FormColumn4Wide, FormInput } from "best-common-react";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import EligibilityStatusConstants from "../../constants/EligibilityStatusConstants";
import RouteConstants from "../../constants/RouteConstants";
import AuthContext from "../../contexts/AuthContext";
import ProfileContext from "../../contexts/ProfileContext";
import { properNoun } from "../../utils/StringUtils";
import DisabledInputStyle from "../elements/DisabledInputStyle";
import WBCCheckbox from "../elements/WBCCheckbox";
import useHasAdminAccess from "../hooks/useHasAdminAccess";
import { useProfileFedTeams } from "../hooks/useProfileFedTeams";
import useQuery from "../hooks/useQuery";
import NonCurrentYearAccess from "../protected/NonCurrentYearAccess";
import WBCAccessControl from "../protected/WBCAccessControl";
import ProfileBaseballInfoForm from "./baseball-info/ProfileBaseballInfoForm";
import ProfileAddressInfoForm from "./ProfileAddressInfoForm";
import ProfileBioInfoForm from "./ProfileBioInfoForm";
import ProfileContactInfoForm from "./ProfileContactInfoForm";
import ProfileDocumentsSection from "./ProfileDocumentsSection";
import ProfileNotesForm from "./ProfileNotesForm";

const FormContainer = styled.div.attrs(() => ({
  className: "container-fluid"
}))`
  overflow: scroll;
  /* NOTE: figure out nicer way to do this */
  height: 81vh;

  &&& {
    padding: 24px;
  }
`;

const FormLabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  color: black;
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0;
`;

const eligibilityOptions = [
  { label: "Approved", value: EligibilityStatusConstants.STATUS.APPROVED },
  { label: "Denied", value: EligibilityStatusConstants.STATUS.DENIED }
];

const ProfileInfoForm = withRouter(({ history, match: { params } }) => {
  // hooks
  const authContext = useContext(AuthContext);
  const profileContext = useContext(ProfileContext);

  // variables
  const { playerId: profileId, profileType, fedTeamId } = params;
  const { isBOC, isBOCviewer, isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { teams, eligibilityStatusId, pastWbcFlag } = profileContext.state;

  // team
  const currentTeam = teams.find(team => team.fedTeamId === parseInt(fedTeamId)) || {};

  // eligibility
  const eligibilityLabel = EligibilityStatusConstants.ID[eligibilityStatusId];

  // route constants
  const isNew = profileId === RouteConstants.NEW_PROFILE_SUFFIX;
  const isStaff = profileType === RouteConstants.STAFF;
  const routeProps = { isNew, isStaff };
  const query = useQuery();

  // functions
  const onFederationChange = selection => {
    const { profileId, fedTeamId, wbcYear } = selection;
    query.set("year", wbcYear);
    history.push({
      pathname: RouteConstants.PROFILE.replace(":playerId", profileId)
        .replace(":fedTeamId", fedTeamId)
        .replace(":profileType", profileType),
      search: query.toString()
    });
  };

  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  // effects
  /**
   * grab all teams that the person is on. this updates `teams` in the
   * profile context
   */
  useProfileFedTeams(profileId, isNew);

  return (
    <FormContainer>
      <div className="row">
        <div className="col-xs-12 col-md-12">
          <div className="row">
            {(isBOC || isFedAdmin || isTeamCoordinator) && !isNew ? (
              <WBCAccessControl
                noDiv
                bocOnly={false}
                includesTc
                input={isDisabled => (
                  <>
                    <FormColumn4Wide>
                      <FormInput
                        editable
                        isRequired={false}
                        isDisabled={isStaff}
                        label="Federation"
                        name={`teamDropdown${isStaff ? "Staff" : ""}`}
                        type={isStaff ? undefined : "select"}
                        options={isStaff ? undefined : teams}
                        onChangeFn={isStaff ? undefined : onFederationChange}
                        inputStyle={isStaff ? DisabledInputStyle : undefined}
                        value={isStaff ? currentTeam.label : currentTeam}
                      />
                    </FormColumn4Wide>
                    <FormColumn4Wide>
                      {isStaff ? null : (
                        <FormInput
                          name="eligibilityDropdown"
                          label="Eligibility"
                          type="select"
                          isDisabled={isDisabled}
                          options={eligibilityOptions}
                          value={{
                            value: eligibilityStatusId || "",
                            label: eligibilityLabel && eligibilityStatusId !== 0 ? properNoun(eligibilityLabel) : ""
                          }}
                          onChangeFn={value => onProfileChange(value.value, "eligibilityStatusId")}
                        />
                      )}
                    </FormColumn4Wide>
                  </>
                )}
              />
            ) : null}
            {isStaff ? null : (
              <FormColumn4Wide>
                <FormLabelWrapper>
                  <div className="mt-1">
                    <WBCCheckbox
                      checked={pastWbcFlag}
                      isDisabled={!isBOCadmin}
                      onClick={() => onProfileChange(!pastWbcFlag, "pastWbcFlag")}
                    />
                  </div>
                  <div className="ml-2">Past WBC Player</div>
                </FormLabelWrapper>
              </FormColumn4Wide>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <ProfileBioInfoForm {...routeProps} />
        </div>
        <div className="col-xs-12 col-md-6">
          <ProfileBaseballInfoForm {...routeProps} />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <ProfileContactInfoForm />
        </div>
        <div className="col-xs-12 col-md-6">
          <ProfileAddressInfoForm />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-6 pl-4 pb-5">
          <NonCurrentYearAccess>
            <ProfileDocumentsSection isStaff={isStaff} />
          </NonCurrentYearAccess>
        </div>
        {isBOCviewer || isBOCadmin || isFedAdmin ? (
          <div className="col-xs-12 col-md-6">
            <ProfileNotesForm disabled={!useHasAdminAccess(false)} />
          </div>
        ) : null}
      </div>
    </FormContainer>
  );
});

export default ProfileInfoForm;
