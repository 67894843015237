import React, { useReducer } from "react";
import AuthContext from "../../contexts/AuthContext";
import PropTypes from "prop-types";
import UserRoleConstants from "../../constants/UserRoleConstants";
import { datadogRum } from "@datadog/browser-rum";

const initialState = {
  loggedIn: false,
  email: "",
  authToken: "",
  refreshToken: "",
  userData: {},
  isBOCadmin: false,
  isBOCviewer: false,
  isBOC: false,
  isFedAdmin: false,
  isFedUser: false,
  isTeamCoordinator: false,
  features: {},
  userInfoFailed: false
};

if (localStorage.getItem("okta-token-storage")) {
  const { accessToken, idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
  if (accessToken && idToken) {
    initialState.authToken = accessToken.accessToken;
    initialState.email = idToken.claims.email;
    initialState.loggedIn = true;
    initialState.userData = JSON.parse(localStorage.getItem("userData"));
    if (initialState.userData != null) {
      if (initialState.userData.roles.filter(role => role.role === UserRoleConstants.BOC_ADMIN).length > 0) {
        initialState.isBOCadmin = true;
        initialState.isBOC = true;
      } else if (initialState.userData.roles.filter(role => role.role === UserRoleConstants.BOC_VIEWER).length > 0) {
        initialState.isBOCviewer = true;
        initialState.isBOC = true;
      } else if (
        initialState.userData.roles.filter(role => role.role === UserRoleConstants.FED_TEAM_ADMIN).length > 0
      ) {
        initialState.isFedAdmin = true;
      } else if (initialState.userData.roles.filter(role => role.role === UserRoleConstants.FED_TEAM_USER).length > 0) {
        initialState.isFedUser = true;
      } else if (
        initialState.userData.roles.filter(role => role.role === UserRoleConstants.TEAM_COORDINATOR).length > 0
      ) {
        initialState.isTeamCoordinator = true;
      }

      if (initialState.userData.wbcFeatures) {
        initialState.features = initialState.userData.wbcFeatures.reduce((map, f) => {
          map[f.featureName] = f.enabled;
          return map;
        }, {});
      }
    }
  }
}

const Authentication = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "login":
        const { accessToken, idToken } = JSON.parse(localStorage.getItem("okta-token-storage"));
        const emailAddress = idToken?.claims?.email;
        datadogRum.setUser({
          id: emailAddress,
          email: emailAddress,
          name: idToken?.claims?.name
        });
        return { ...state, authToken: accessToken.accessToken, email: emailAddress, loggedIn: false, userData: {} };
      case "logout":
        datadogRum.removeUser();
        localStorage.clear();
        return {
          loggedIn: false,
          email: "",
          authToken: "",
          refreshToken: "",
          userData: {}
        };
      case "setUserInfo":
        const userInfo = action.userInfo;
        localStorage.setItem("userData", JSON.stringify(userInfo));
        const features = userInfo.wbcFeatures.reduce((map, f) => {
          map[f.featureName] = f.enabled;
          return map;
        }, {});
        if (userInfo.roles.filter(role => role.role === UserRoleConstants.BOC_ADMIN).length > 0) {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isBOCadmin: true,
            isBOCviewer: false,
            isBOC: true,
            isFedAdmin: false,
            isFedUser: false,
            features,
            userInfoFailed: false
          };
        } else if (userInfo.roles.filter(role => role.role === UserRoleConstants.FED_TEAM_ADMIN).length > 0) {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isFedAdmin: true,
            isBOCadmin: false,
            isBOCviewer: false,
            isBOC: false,
            isFedUser: false,
            features,
            userInfoFailed: false
          };
        } else if (userInfo.roles.filter(role => role.role === UserRoleConstants.FED_TEAM_USER).length > 0) {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isFedUser: true,
            isBOCadmin: false,
            isBOCviewer: false,
            isBOC: false,
            isFedAdmin: false,
            features,
            userInfoFailed: false
          };
        } else if (userInfo.roles.filter(role => role.role === UserRoleConstants.BOC_VIEWER).length > 0) {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isFedUser: false,
            isBOCadmin: false,
            isBOCviewer: true,
            isBOC: true,
            isFedAdmin: false,
            features,
            userInfoFailed: false
          };
        } else if (userInfo.roles.filter(role => role.role === UserRoleConstants.TEAM_COORDINATOR).length > 0) {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isFedUser: false,
            isBOCadmin: false,
            isBOCviewer: true,
            isBOC: false,
            isFedAdmin: false,
            isTeamCoordinator: true,
            features,
            userInfoFailed: false
          };
        } else {
          return {
            ...state,
            loggedIn: true,
            userData: userInfo,
            isFedUser: false,
            isBOCadmin: false,
            isBOCviewer: false,
            isBOC: false,
            isFedAdmin: false,
            features,
            userInfoFailed: false
          };
        }
      case "setUserInfoFailed":
        return {
          ...state,
          userInfoFailed: action.userInfoFailed
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

Authentication.propTypes = {
  children: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Authentication;
