const sortList = (col, dir, rows, useTeamName) => {
  if (rows.length > 0) {
    if (col === "event") {
      col = useTeamName ? "teamName" : "opponent";
    } else if (col === "playerInfo") {
      col = "completedPlayerCount";
    }
    switch (dir) {
      case "ASC":
        if (!isNaN(rows[0][col])) return numberSort(rows, col);
        return rows.sort((first, second) =>
          first[col]
            .toString()
            .toLowerCase()
            .localeCompare(second[col].toString().toLowerCase())
        );
      case "DESC":
        if (!isNaN(rows[0][col])) return numberSort(rows, col).reverse();
        return rows
          .sort((first, second) =>
            first[col]
              .toString()
              .toLowerCase()
              .localeCompare(second[col].toString().toLowerCase())
          )
          .reverse();
      default:
        return sortByDateAsc(rows);
    }
  }
  return [];
};

const sortByDateAsc = data => {
  return data.sort((val1, val2) => {
    if (val1.eventDate < val2.eventDate.toLowerCase()) return -1;
    else if (val1.eventDate.toLowerCase() > val2.eventDate.toLowerCase()) return 1;

    if (val1.eventDate.toLowerCase() < val2.eventDate.toLowerCase()) return -1;
    else if (val1.eventDate.toLowerCase() > val2.eventDate.toLowerCase()) return 1;
    else return 0;
  });
};

const numberSort = (data, prop) => {
  return data.sort((val1, val2) => {
    if (val1[prop] < val2[prop]) return -1;
    if (val1[prop] > val2[prop]) return 1;
    else return 0;
  });
};

export { sortList };
