import React, { useReducer } from "react";
import PropTypes from "prop-types";
import BuildContext from "../contexts/BuildContext";

const initialState = {
  buildInfo: {}
};

if (localStorage.getItem("buildInfo")) {
  initialState.buildInfo = JSON.parse(localStorage.getItem("buildInfo"));
}

const BuildContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setBuildInfo":
        localStorage.setItem("buildInfo", JSON.stringify(action.buildInfo));
        return { ...state, buildInfo: action.buildInfo };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <BuildContext.Provider value={{ state, dispatch }}>{children}</BuildContext.Provider>;
};

BuildContextProvider.propTypes = {
  children: PropTypes.object
};

export default BuildContextProvider;
