import React, { useContext, useCallback, useEffect } from "react";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import RosterContext from "../../../contexts/RosterContext";
import PositionsContext from "../../../contexts/PositionsContext";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import RosterApi from "../../../httpClients/RosterApi";
import { useAlert } from "../../hooks/useAlert";
import { removeFromList } from "../../../utils/DragUtils";

const InfielderModalFooter = () => {
  // contexts && hooks
  const globalModalContext = useContext(GlobalModalContext);
  const rosterContext = useContext(RosterContext);
  const positionsContext = useContext(PositionsContext);
  const showAlert = useAlert();

  // variables
  const { teamId } = globalModalContext.state.content.team;
  const { profileId } = globalModalContext.state.content.player;
  const { selectedPosition } = globalModalContext.state;
  const { posAbbrevToId } = positionsContext.state;
  const { final } = rosterContext.state;
  const rosterDispatch = rosterContext.dispatch;
  const globalModalDispatch = globalModalContext.dispatch;
  const index = final.infield.findIndex(p => p.profileId === profileId);

  // function(s)
  const removeFromFinalRoster = useCallback(() => {
    rosterDispatch({
      source: "infield",
      profileId,
      type: "removeFromFinalRoster",
      list: removeFromList(final["infield"], index)
    });
  }, [final, index, profileId, rosterDispatch]);

  // effect(s)
  useEffect(() => {
    globalModalDispatch({
      type: "setCloseCallback",
      closeCallback: removeFromFinalRoster
    });
  }, [final, index, removeFromFinalRoster, globalModalDispatch]);

  return (
    <div>
      <div className="d-flex">
        <WBCPrimaryButton
          width={154}
          disabled={selectedPosition === ""}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            RosterApi.updatePosition(teamId, profileId, posAbbrevToId[selectedPosition]).then(() => {
              showAlert("Position updated");
            });
            globalModalContext.dispatch({
              type: "closeModal"
            });
            rosterDispatch({
              type: "updatePosition",
              profileId: profileId,
              positionId: posAbbrevToId[selectedPosition],
              position: selectedPosition
            });
          }}
        >
          Update Position
        </WBCPrimaryButton>

        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
              removeFromFinalRoster();
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

InfielderModalFooter.displayName = "FooterContent";

export default InfielderModalFooter;
