import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import PersonMatchModalAddButton from "./PersonMatchModalAddButton";
import { VerticalTable } from "best-common-react";
import { formatDate } from "../../../../utils/DateUtils";
import RouteConstants from "../../../../constants/RouteConstants";

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
`;

const TableWrapper = styled.div`
  padding-top: 15px;
  overflow-x: auto;

  table {
    table-layout: fixed;
  }
`;

const headers = [{ name: "" }, { name: "You Entered:" }, { name: "System Matches" }];

const PersonMatchModalBodyContent = ({ matchData, matchMetaData, toggleModal, personType }) => {
  const { hasEbisId, hasFederations } = matchMetaData;
  const Theme = useContext(ThemeContext);
  const rows = [
    {
      name: "",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: (row, _, colIndex) =>
        !colIndex ? null : <PersonMatchModalAddButton data={row} toggleModal={toggleModal} personType={personType} />
    },
    {
      name: "Last, First",
      key: "personFullName"
    },
    {
      name: "Birth Country",
      key: "birthCountryName"
    },
    {
      name: "Birth Date",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: row => formatDate(row.birthDate)
    }
  ];

  if (hasFederations) {
    rows.push({
      name: "Federations",
      key: "",
      // eslint-disable-next-line react/display-name
      cellOverride: row => (row.federations ? row.federations.join(", ") : "")
    });
  }

  if (hasEbisId) {
    rows.push({
      name: "EBIS ID",
      key: "ebisId"
    });
  }

  const TableStyle = {
    td: {
      backgroundColor: `${Theme.white} !important`,
      fontWeight: "normal !important"
    }
  };

  return (
    <div>
      <ModalText>
        Is the {personType} you&apos;re entering one of the{" "}
        {personType === RouteConstants.PLAYER ? "players" : "coaching staff members"} below?
      </ModalText>
      <TableWrapper>
        <VerticalTable rows={rows} headers={headers} data={matchData} styles={TableStyle} />
      </TableWrapper>
    </div>
  );
};

PersonMatchModalBodyContent.propTypes = {
  matchData: PropTypes.array.isRequired,
  matchMetaData: PropTypes.object.isRequired,
  toggleModal: PropTypes.func,
  personType: PropTypes.string.isRequired
};

PersonMatchModalBodyContent.displayName = "BodyContent";

export default PersonMatchModalBodyContent;
