import React, { useReducer } from "react";
import PropTypes from "prop-types";
import InterestListContext from "../contexts/InterestListContext";

const initialState = {
  players: [],
  displayedPlayers: [],
  positionUpdates: {},
  removedPlayers: [],
  pastWbcFlagUpdates: {},
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  },
  rosterSaved: false
};

const InterestListProvider = ({ children }) => {
  const filterPlayers = (players, removedPlayers = []) => {
    return players.filter(player => !removedPlayers.includes(player.profileId));
  };

  const updateHasDocuments = (players, profileId, hasDocuments) =>
    players.map(player =>
      player.profileId === profileId
        ? {
            ...player,
            hasDocuments
          }
        : player
    );

  const updatePlayerRequestStatus = (players, profileId, requestStatus) =>
    players.map(player =>
      player.profileId === profileId
        ? {
            ...player,
            requestStatus: requestStatus
          }
        : player
    );

  const reducer = (state, action) => {
    switch (action.type) {
      case "setPlayers":
        return {
          ...state,
          players: action.players,
          displayedPlayers: filterPlayers(action.players),
          positionUpdates: {},
          removedPlayers: [],
          pastWbcFlagUpdates: {}
        };
      case "sortPlayers":
        return {
          ...state,
          sortFilters: {
            col: action.col,
            dir: action.dir
          },
          players: action.players,
          displayedPlayers: filterPlayers(action.players, state.removedPlayers)
        };
      case "updatePosition": {
        const idx = state.players.findIndex(p => p.profileId === action.profileId);
        const updatedPlayer = {};
        updatedPlayer[idx] = { ...state.players[idx], pos: action.pos };
        const players = Object.assign([], state.players, updatedPlayer);
        const positionUpdate = {};
        positionUpdate[action.profileId] = action.pos;
        return {
          ...state,
          players,
          displayedPlayers: filterPlayers(players, state.removedPlayers),
          positionUpdates: { ...state.positionUpdates, ...positionUpdate }
        };
      }
      case "updatePastWbcFlag": {
        const idx = state.players.findIndex(p => p.profileId === action.profileId);
        const updatedPlayer = {};
        updatedPlayer[idx] = { ...state.players[idx], pastWbcFlag: action.pastWbcFlag };
        const players = Object.assign([], state.players, updatedPlayer);

        const pastWbcFlagUpdate = {};
        pastWbcFlagUpdate[action.profileId] = action.pastWbcFlag;
        const alreadyUpdated = state.pastWbcFlagUpdates[action.profileId] !== undefined;
        const pastWbcFlagUpdates = alreadyUpdated
          ? { ...state.pastWbcFlagUpdates }
          : { ...state.pastWbcFlagUpdates, ...pastWbcFlagUpdate };
        if (alreadyUpdated) {
          delete pastWbcFlagUpdates[action.profileId];
        }
        return {
          ...state,
          players,
          displayedPlayers: filterPlayers(players, state.removedPlayers),
          pastWbcFlagUpdates: pastWbcFlagUpdates
        };
      }
      case "removePlayer":
        const removedPlayers = [...state.removedPlayers, action.removedPlayer.profileId];
        return {
          ...state,
          removedPlayers: removedPlayers,
          displayedPlayers: filterPlayers(state.players, removedPlayers)
        };
      case "resetAfterError":
        return {
          ...state,
          removedPlayers: [],
          displayedPlayers: filterPlayers(state.players)
        };
      case "setRosterSaved":
        return {
          ...state,
          rosterSaved: action.rosterSaved
        };
      case "setPlayerDocFlags": {
        const { profileId, hasDocuments } = action;

        return {
          ...state,
          players: updateHasDocuments(state.players, profileId, hasDocuments),
          displayedPlayers: updateHasDocuments(state.displayedPlayers, profileId, hasDocuments)
        };
      }
      case "setPlayerRequestStatus": {
        const { profileId, newStatus } = action;
        return {
          ...state,
          players: updatePlayerRequestStatus(state.players, profileId, newStatus),
          displayedPlayers: updatePlayerRequestStatus(state.displayedPlayers, profileId, newStatus)
        };
      }
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <InterestListContext.Provider value={{ state, dispatch }}>{children}</InterestListContext.Provider>;
};

InterestListProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default InterestListProvider;
