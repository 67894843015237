import styled from "styled-components";

const FlagHolder = styled.img.attrs(() => ({
  className: "mr-2"
}))`
  &&& {
    height: ${props => `${props.height}px`};
    width: ${props => `${props.width}px`};
    background-image: ${props => props.src};
  }
`;

FlagHolder.defaultProps = {
  height: 40,
  width: 50
};

export default FlagHolder;
