import React, { useContext } from "react";
import InterestListContext from "../../contexts/InterestListContext";
import DocsFormatter from "./DocsFormatter";
import InterestListActionsFormatter from "./InterestListActionsFormatter";
import PlayerLinkFormatter from "../table/PlayerLinkFormatter";
import PropTypes from "prop-types";
import EligibleFormatter from "./EligibleFormatter";
import InterestListPositionsFormatter from "./InterestListPositionsFormatter";
import { sortPlayers } from "../roster/RosterSortHelper";
import PastWbcFormatter from "./PastWbcFormatter";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import CheckMarkFormatter from "../elements/CheckMarkFormatter";
import ClubFormatter from "../table/ClubFormatter";
import WBCDataTable from "../data-table/WBCDataTable";
import OrgNameFormatter from "../elements/OrgNameFormatter";
import PositionKeyIcon from "../elements/PositionKeyIcon";
import useHasNonCurrentYearAccess from "../hooks/useHasNonCurrentYearAccess";
import RequestStatusFormatter from "../table/RequestStatusFormatter";
import BirthDateFormatter from "../table/BirthDateFormatter";
import { BatsThrowsFormatter } from "../prospective-players/ProspectivePlayersTable";
import BirthCountryFormatter from "../table/BirthCountryFormatter";
import RosterStatusFormatter from "../mlb-players/mlb-player-summary/MLBPlayerRosterStatusFormatter";
import AuthContext from "../../contexts/AuthContext";
import ChronicConditionsCheckMarkFormatter from "../elements/CheckMarkFromatterChronicConditions";

const columns = [
  {
    key: "actions",
    name: " ",
    sortable: false,
    width: 25,
    formatter: InterestListActionsFormatter
  },
  {
    key: "lastName",
    name: "Last",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter
  },
  {
    key: "firstName",
    name: "First",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter
  },
  {
    key: "pos",
    name: (
      <>
        Pos <PositionKeyIcon tableHeader />
      </>
    ),
    sortable: true,
    width: 125,
    formatter: InterestListPositionsFormatter
  },
  {
    key: "bats",
    name: "B/T",
    sortable: true,
    width: 50,
    formatter: BatsThrowsFormatter,
    getRowMetaData: row => row
  },
  {
    key: "birthDate",
    name: "DOB",
    sortable: true,
    width: 90,
    formatter: BirthDateFormatter
  },
  {
    key: "birthCountryId",
    name: "Birth Country",
    sortable: true,
    width: 140,
    formatter: BirthCountryFormatter,
    getRowMetaData: row => row
  },
  {
    key: "pastWbcFlag",
    name: "Past WBC",
    sortable: true,
    width: 90,
    formatter: PastWbcFormatter
  },
  {
    key: "requestStatus",
    name: "MLB Request Status",
    sortable: true,
    width: 200,
    formatter: RequestStatusFormatter
  },
  {
    key: "hasDocuments",
    name: "Docs",
    sortable: true,
    formatter: DocsFormatter,
    width: 60
  },
  {
    key: "eligible",
    name: "Eligibility",
    sortable: true,
    formatter: EligibleFormatter,
    width: 90
  },
  {
    key: "ebisId",
    name: "EBIS ID",
    sortable: true,
    width: 90,
    getRowMetaData: row => row
  },
  {
    key: "org",
    name: "Org",
    sortable: true,
    width: 65,
    formatter: OrgNameFormatter
  },
  {
    key: "club",
    name: "Club",
    sortable: true,
    width: 150,
    formatter: ClubFormatter
  },
  {
    key: "fortyMan",
    name: "40-Man",
    width: 80,
    sortable: true,
    formatter: CheckMarkFormatter
  },
  {
    key: "ilFlag",
    name: "IL",
    sortable: true,
    width: 60,
    formatter: CheckMarkFormatter
  },
  {
    key: "ebisMlbRosterStatus",
    name: "MLB Roster Status",
    sortable: true,
    width: 180,
    formatter: RosterStatusFormatter
  },
  {
    key: "insuranceStatus",
    name: "Chronic Conditions",
    sortable: true,
    width: 160,
    formatter: ChronicConditionsCheckMarkFormatter
  }
];

const InterestListTable = ({ data }) => {
  // context(s) and state
  const interestListContext = useContext(InterestListContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const hasNonCurrentYearAccess = useHasNonCurrentYearAccess();
  const { isBOC, isTeamCoordinator } = useContext(AuthContext).state;

  // variable(s)
  const { players, sortFilters } = interestListContext.state;

  const getColumns = () => {
    if (isBOC) {
      return columns;
    }

    // TC only non BOC that can see ebis id
    if (hasNonCurrentYearAccess) {
      if (isTeamCoordinator) {
        return columns
          .filter(({ key }) => key !== "none")
          .map(column => (column.key === "requestStatus" ? { ...column, name: "MLB Request" } : column));
      } else {
        return columns
          .filter(({ key }) => key !== "ebisId")
          .map(column => (column.key === "requestStatus" ? { ...column, name: "MLB Request" } : column));
      }
    } else {
      if (isTeamCoordinator) {
        return columns
          .filter(({ key }) => key !== "none")
          .map(column => (column.key === "requestStatus" ? { ...column, name: "MLB Request" } : column));
      } else {
        return columns
          .filter(({ key }) => key !== "ebisId")
          .map(column => (column.key === "requestStatus" ? { ...column, name: "MLB Request" } : column));
      }
    }
  };

  return (
    <div className="row">
      <div className="col-12 full-page-table dropdown-table">
        <WBCDataTable
          useBCR
          enableRowSelect={null}
          rowScrollTimeout={null}
          id="interest-list-table"
          data={data}
          columns={getColumns()}
          minColumnWidth={100}
          sortFunc={(col, dir) => {
            interestListContext.dispatch({
              col,
              dir,
              type: "sortPlayers",
              players: sortPlayers(col, dir, players)
            });
            tableSortFilterContext.dispatch({
              type: "setSortFilters",
              sortFilters: { col, dir }
            });
          }}
          sortColumn={sortFilters.col}
          sortDirection={sortFilters.dir}
        />
      </div>
    </div>
  );
};

InterestListTable.propTypes = {
  data: PropTypes.array
};

export default InterestListTable;
