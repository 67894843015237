import React, { useContext } from "react";
import PropTypes from "prop-types";
import ProspectivePlayerContext from "../../contexts/ProspectivePlayerContext";
import { Input } from "best-common-react";

const ProspectivePlayerSearch = () => {
  const prospectivePlayerContext = useContext(ProspectivePlayerContext);
  return (
    <div>
      <Input
        placeholder="begin typing to search players"
        onChange={e => {
          prospectivePlayerContext.dispatch({ type: "setPlayerFilter", playerFilter: e.target.value });
        }}
        type="text"
      ></Input>
    </div>
  );
};

ProspectivePlayerSearch.propTypes = {
  rootStore: PropTypes.object
};

export default ProspectivePlayerSearch;
