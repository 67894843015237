import { useCallback, useEffect, useMemo, useReducer } from "react";
import invariant from "invariant";

const enhanceReducer = reducer => {
  let _middlewares = null;
  let _unmounted = false;

  return {
    reducer: (state, action) => {
      invariant(!!_middlewares, "Dispatch called before middlewares were registered!");
      const nextState = reducer(state, action);
      _middlewares.forEach(middleware => middleware(state, nextState, action));
      return nextState;
    },
    registerMiddlewares: (dispatch, middlewares = []) => {
      if (!_middlewares) {
        _middlewares = middlewares.map(middleware => middleware(dispatch));
      }
    },
    setUnmounted: () => {
      _unmounted = true;
    },
    isUnmounted: () => _unmounted
  };
};

/**
 * An enhanced reducer that takes middleware functions that will process the actions
 * either before or after the reducer has updated the state.
 *
 * Middlewares should have the following signature:
 * const middleware = dispatch => (prevState, nextState, action) => void;
 */
const useEnhancedReducer = (reducer, initialState, middlewares = []) => {
  const { reducer: enhancedReducer, registerMiddlewares, setUnmounted, isUnmounted } = useMemo(
    () => enhanceReducer(reducer),
    [reducer]
  );
  const [state, dispatch] = useReducer(enhancedReducer, initialState);
  const enhancedDispatch = useCallback(
    (...args) => {
      if (!isUnmounted()) {
        dispatch(...args);
      }
    },
    [dispatch, isUnmounted]
  );
  registerMiddlewares(enhancedDispatch, middlewares);

  useEffect(() => setUnmounted, [setUnmounted]);

  return [state, enhancedDispatch];
};

export default useEnhancedReducer;
