import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCModal from "../../elements/WBCModal";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import Select from "react-select";
import Theme from "../../../Theme";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import InterestListApi from "../../../httpClients/InterestListApi";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import ChronicConditionsApi from "../../../httpClients/ChronicConditionsApi";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import { useAlert } from "../../hooks/useAlert";

const BodyContent = styled.div``;

BodyContent.displayName = "BodyContent";

const FooterContent = styled.div`
  display: flex;
  flex-direction: row;
  & button {
    margin-left: 0.5rem;
  }
`;

FooterContent.displayName = "FooterContent";

const PlayersToAddContainer = styled.div.attrs(() => ({
  className: "d-flex flex-column"
}))`
  margin: 1.375rem 0;
`;

const PlayerToAdd = styled.div.attrs(() => ({
  className: "d-flex flex-row col-12 align-items-center"
}))`
  background-color: ${Theme.white};
  border: solid 1 ${Theme["grey-three"]};
  border-radius: 0.3rem;
  padding: 0;

  & .remove-player {
    display: flex;
    margin: 0 0.625rem;
    color: ${props => props.theme.wbcBlue};
    cursor: pointer;
    text-align: center;
  }

  & .player-info {
    display: flex;
    padding: 0.625rem 0;
  }
`;

const INITIAL_STATE = {
  allPlayers: [],
  filteredPlayerOptions: [],
  isLoading: false,
  selectedPlayerOption: null,
  selectedPlayers: []
};

const AddChronicConditionPlayerModal = ({ isOpen, setIsOpen, loadChronicConditionsByYearFn }) => {
  const showAlert = useAlert();

  const { teams } = useContext(SelectedTeamContext).state;
  const { selectedYear } = useContext(WBCYearsContext);
  const { playerConditions } = useContext(ChronicConditionsContext);

  const [allPlayers, setAllPlayers] = useState(INITIAL_STATE.allPlayers);
  const [filteredPlayerOptions, setFilteredPlayerOptions] = useState(INITIAL_STATE.filteredPlayerOptions);
  const [isLoading, setIsLoading] = useState(INITIAL_STATE.isLoading);
  const [selectedPlayerOption, setSelectedPlayerOption] = useState(INITIAL_STATE.selectedPlayerOption);
  const [selectedPlayers, setSelectedPlayers] = useState(INITIAL_STATE.selectedPlayers);

  const playerProfileIdsWithChronicConditions = useMemo(() => playerConditions.map(player => player.profileId), [
    playerConditions
  ]);

  const handleAddToListBtnClick = () => {
    ChronicConditionsApi.addChronicConditionForPlayers(selectedPlayers.map(player => player.profileId))
      .then(response => {
        loadChronicConditionsByYearFn();
        showAlert(`${response?.length > 1 ? "Players" : "Player"} added`, "success");
      })
      .finally(resetState());
  };

  const handleCancelBtnClick = () => {
    resetState();
  };

  const handleRemovePlayerFromSelectedPlayers = player => {
    setSelectedPlayers(selectedPlayers.filter(p => p !== player));
    setAndSortPlayerOptions([...filteredPlayerOptions, { value: player, label: playerLabel(player) }]);
  };

  const handleSearchPlayers = inputValue => {
    const matchingPlayers = allPlayers.filter(player => {
      return (
        !selectedPlayers.includes(player) &&
        playerDisplayName(player)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
      );
    });
    setAndSortPlayerOptions(playerListToSelectOptions(matchingPlayers));
  };

  const handleSelectPlayerOption = option => {
    setAndSortPlayerOptions(filteredPlayerOptions.filter(player => player.value !== option.value));
    setSelectedPlayers([...selectedPlayers, option.value]);
    setSelectedPlayerOption(null);
  };

  const setAndSortPlayerOptions = playerOptions => {
    setFilteredPlayerOptions(playerOptions.sort((a, b) => a?.label.localeCompare(b.label)));
  };

  const playerDisplayName = player => `${player.lastName}, ${player.firstName}`;

  const playerLabel = player =>
    `${playerDisplayName(player)} - ${player.org && player.org !== "BOC" ? player.org : "N/A"}, ${player.fedCode}`;

  const playerListToSelectOptions = playerList => {
    return playerList
      .map(player => ({
        value: player,
        label: playerLabel(player)
      }))
      .filter(player => !playerProfileIdsWithChronicConditions.includes(player.profileId));
  };

  const resetState = () => {
    setAllPlayers(INITIAL_STATE.allPlayers);
    setFilteredPlayerOptions(INITIAL_STATE.filteredPlayerOptions);
    setIsLoading(INITIAL_STATE.isLoading);
    setSelectedPlayerOption(INITIAL_STATE.selectedPlayerOption);
    setSelectedPlayers(INITIAL_STATE.selectedPlayers);
    setIsOpen(false);
  };

  // need to filter out players that are not affiliated as this is required for chronic conditions to be created
  const playerIsAllifiated = player => {
    return player?.org && !["BOC", "MSB", "NONE", null, ""].includes(player?.org.toUpperCase());
  };

  useEffect(() => {
    const loadPlayerList = () => {
      setIsLoading(true);

      const teamInterestListPromises = teams.map(team =>
        InterestListApi.getInterestListPlayers(selectedYear, team.teamId).then(response =>
          response.map(player => ({
            profileId: player.profileId,
            lastName: player.lastName,
            firstName: player.firstName,
            org: player.org,
            fedTeamId: player.fedTeamId,
            fedCode: team.code.toUpperCase(),
            wbcYear: player.wbcYear,
            pos: player.pos
          }))
        )
      );

      Promise.all(teamInterestListPromises)
        .then(interestListPlayers => {
          const interestPlayersWithoutChronicConditions = interestListPlayers
            .flat()
            .filter(player => !playerProfileIdsWithChronicConditions.includes(player.profileId))
            .filter(player => playerIsAllifiated(player));
          setAllPlayers(interestPlayersWithoutChronicConditions);
        })
        .catch(error => {
          console.error("Error fetching players: ", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    if (isOpen) {
      loadPlayerList();
    }
  }, [isOpen, playerProfileIdsWithChronicConditions]);

  useEffect(() => {
    const selectedPlayerProfileIds = selectedPlayers.map(player => player.profileId);
    const nonSelectedPlayers = allPlayers.filter(player => !selectedPlayerProfileIds.includes(player.profileId));
    setAndSortPlayerOptions(playerListToSelectOptions(nonSelectedPlayers));
  }, [allPlayers, selectedPlayers, playerConditions]);

  return (
    <WBCModal isOpen={isOpen} headerText="Add Chronic Condition Player" toggleModal={() => handleCancelBtnClick()}>
      <BodyContent>
        <Select
          id="add-player-select"
          placeholder="type to search for a player"
          autoFocus
          value={selectedPlayerOption}
          options={isLoading ? [] : filteredPlayerOptions}
          onChange={handleSelectPlayerOption}
          onInputChange={handleSearchPlayers}
          noOptionsMessage={() => "no matching players found"}
          isLoading={isLoading}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: state.isFocused ? "#fff7d4" : "#ffffff",
              borderColor: state.isFocused ? "#4c8cee" : baseStyles.borderColor
            }),
            option: baseStyles => ({
              ...baseStyles,
              color: Theme.black,
              backgroundColor: Theme.white,
              ":hover": {
                backgroundColor: "#fff7d4"
              }
            }),
            placeholder: baseStyles => ({
              ...baseStyles,
              color: Theme.grey,
              fontStyle: "italic"
            })
          }}
        />
        <PlayersToAddContainer>
          {selectedPlayers.map(player => (
            <PlayerToAdd key={player.profileId}>
              <div className="remove-player" onClick={() => handleRemovePlayerFromSelectedPlayers(player)}>
                <i className="fa fa-trash fa-lg" />
              </div>
              <div className="player-info">{playerLabel(player)}</div>
            </PlayerToAdd>
          ))}
        </PlayersToAddContainer>
      </BodyContent>
      <FooterContent>
        <WBCPrimaryOutlinedButton onClick={handleAddToListBtnClick} disabled={!(selectedPlayers?.length > 0)}>
          Add to List
        </WBCPrimaryOutlinedButton>
        <WBCPrimaryOutlinedButton onClick={handleCancelBtnClick}>Cancel</WBCPrimaryOutlinedButton>
      </FooterContent>
    </WBCModal>
  );
};

AddChronicConditionPlayerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  loadChronicConditionsByYearFn: PropTypes.func.isRequired
};

export default AddChronicConditionPlayerModal;
