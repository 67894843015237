import React from "react";
import HorizontalNav from "../navigation/HorizontalNav";
import PlayerEligibilityForm from "./PlayerEligibilityForm";
import { useClearSectionsOpen } from "../hooks/useClearSectionsOpen";

const PlayerEligibility = () => {
  // NOTE: need this to assure any time you leave a profile the selectedPlayer in docs is reset.
  useClearSectionsOpen();

  return (
    <div>
      <HorizontalNav title={`Player Eligibility`} />
      <PlayerEligibilityForm />
    </div>
  );
};

export default PlayerEligibility;
