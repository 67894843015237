import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import background from "../../assets/background.jpg";
import map from "../../assets/map.png";
import logo from "../../assets/logo.png";
import LoginForm from "./LoginForm";
import ResetForm from "./ResetForm";
import LoginContext from "../../contexts/LoginContext";
import AlertContext from "../../contexts/AlertContext";
import { getCurrentYear } from "../../httpClients/LoginApi";

const LoginGradients = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  background: linear-gradient(to bottom, rgba(27, 59, 97, 0.7) 0%, rgba(0, 131, 192, 0.2) 100%),
    /*top to bottom blue gradient*/
      linear-gradient(135deg, rgba(238, 51, 66, 0) 0%, rgba(238, 51, 66, 0) 65%, rgba(238, 51, 66, 0.65) 100%); /*bottom red gradient*/
  background-blend-mode: multiply;
  z-index: 10;
`;

const LoginBG = styled.div.attrs(() => ({
  style: {
    background: `linear-gradient(
      to right,
      rgba(254, 209, 5, 0.9) 0%,
      rgba(62, 176, 73, 0.9) 33%,
      rgba(0, 131, 192, 0.9) 100%
    ),
    url(${background})`
  }
}))`
  background-blend-mode: color;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
`;

const LoginWrapper = styled.div.attrs(() => ({
  className: "d-flex align-items-start justify-content-start"
}))`
  height: calc(100vh - 30px);
  padding: 40px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  @media (max-width: 425px) {
    width: 100%;
  }
`;

const LoginMapWrapper = styled.div`
  position: absolute;
  z-index: 11;
  bottom: 0;
  height: calc(60vh);
  width: 100%;
`;

const LoginMap = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LoginHolder = styled.div`
  display: flex;
  @media (max-width: 425px) {
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const LoginLogoHolder = styled.div.attrs(() => ({
  style: {
    backgroundImage: `url(${logo})`
  }
}))`
  width: 165px;
  height: 165px;
  margin-right: 12px;
  background-size: cover;
  background-position: center;
  @media (max-width: 425px) {
    width: 100px;
    height: 101px;
    margin-bottom: 1rem;
  }
`;

const LoginYear = styled.div.attrs(() => ({
  className: "d-flex flex-row mb-3"
}))`
  font-family: "Open Sans", sans-serif;
  color: ${props => props.theme.wbcYellow};
  font-size: 24px;
  font-weight: bold;
  margin-top: -0.75rem;
`;

const LoginTitle = styled.div.attrs(() => ({
  className: "d-flex flex-row"
}))`
  font-family: "Open Sans", sans-serif;
  word-spacing: 90px;
  font-weight: 600;
  font-size: 32px;
  line-height: 36px;
  text-align: left;
  width: 200px;
  color: ${props => props.theme.white};
  & span {
    font-size: 0.75rem;
    font-weight: lighter;
    padding-top: 0.5rem;
  }
`;

const LoginFormHolder = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))``;

const Login = () => {
  const alertContext = useContext(AlertContext);
  const loginContext = useContext(LoginContext);
  const [currYear, setCurrYear] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { dispatch } = alertContext;
  const { alerts } = alertContext.state;

  useEffect(() => {
    getCurrentYear().then(response => {
      setCurrYear(response);
    });
  }, []);

  useEffect(() => {
    if (alerts && alerts.length > 0) {
      dispatch({ type: "clearAllAlerts" });
    }
  }, [dispatch, alerts]);

  return (
    <div>
      <LoginWrapper>
        <LoginHolder>
          <LoginLogoHolder className={"d-flex flex-column"} />
          <LoginFormHolder className={"d-flex flex-column align-items-start"}>
            <div>
              <div className="justify-content-start">
                <LoginTitle>WORLD</LoginTitle>
                <LoginTitle>BASEBALL</LoginTitle>
                <LoginTitle>
                  CLASSIC<span>&trade;</span>
                </LoginTitle>
                <LoginYear>{!!currYear ? currYear : ""}</LoginYear>
              </div>
            </div>
            {!loginContext.state.showResetPassword ? (
              <LoginForm submitted={submitted} setSubmitted={setSubmitted} />
            ) : (
              <ResetForm />
            )}
          </LoginFormHolder>
        </LoginHolder>
      </LoginWrapper>
      <LoginGradients />
      <LoginBG />
      <LoginMapWrapper>
        <LoginMap src={map} />
      </LoginMapWrapper>
    </div>
  );
};

Login.propTypes = {};

export default Login;
