export default {
  STATUS: {
    NONE: 0,
    PENDING: 1,
    APPROVED: 2,
    DENIED: 3
  },
  ID: {
    0: "NONE",
    1: "PENDING",
    2: "APPROVED",
    3: "DENIED"
  }
};
