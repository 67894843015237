import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import AuthContext from "../../contexts/AuthContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";

const BodyContent = styled.div`
  text-align: left;
`;

const ErrorText = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const ErrorList = styled.ol`
  padding-left: 0;
  list-style: none;
  counter-reset: error-counter;
`;

const ErrorMessage = styled.li`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: normal;
  color: ${props => props.theme.wbcRed};
  counter-increment: error-counter;

  ::before {
    content: counter(error-counter) ".) ";
  }
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled(WBCPrimaryOutlinedButton)`
  width: auto !important;
`;

const Override = styled(Button)`
  margin-right: 8px;
`;

const ErrorModalBody = ({ errors }) => (
  <BodyContent>
    <ErrorText>{`${errors.length} Error${errors.length === 1 ? " has" : "s have"} been found`}</ErrorText>
    <ErrorList>
      {errors.map((message, i) => (
        <ErrorMessage key={i}>{message}</ErrorMessage>
      ))}
    </ErrorList>
  </BodyContent>
);

const ErrorModalFooter = ({ overrideFn }) => {
  // context(s)
  const authContext = useContext(AuthContext);
  const globalModalContext = useContext(GlobalModalContext);

  // variable(s)
  const { isBOCadmin } = authContext.state;

  // function(s)
  const onClose = () => {
    if (globalModalContext.state.closeCallback) {
      globalModalContext.state.closeCallback();
    }

    globalModalContext.dispatch({ type: "closeModal" });
  };

  const onOverride = () => {
    if (overrideFn) {
      overrideFn();
      onClose();
    }
  };

  // component
  return (
    <ButtonContainer>
      {!isBOCadmin || !overrideFn ? null : <Override onClick={onOverride}>Override and Continue</Override>}
      <Button onClick={onClose}>Back</Button>
    </ButtonContainer>
  );
};

ErrorModalBody.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string)
};

ErrorModalBody.defaultProps = {
  errors: []
};

ErrorModalFooter.propTypes = {
  overrideFn: PropTypes.func
};

ErrorModalFooter.defaultProps = {
  overrideFn: () => {}
};

ErrorModalBody.displayName = "BodyContent";
ErrorModalFooter.displayName = "FooterContent";

export { ErrorModalBody, ErrorModalFooter };
