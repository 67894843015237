import React, { useContext, useReducer } from "react";
import styled from "styled-components";
import { PrimaryButton, LightButton } from "best-common-react";
import LoginContext from "../../contexts/LoginContext";
import ResetPasswordApi from "../../httpClients/ResetPasswordApi";

const ResetButton = styled(PrimaryButton)`
  &&& {
    width: 90px;
    margin-right: 10px;
    background-color: ${props => props.theme.wbcBlue};
    border-color: ${props => props.theme.wbcBlue};
  }
`;
const ResetInput = styled.input`
  width: 200px;
`;

const CancelButton = styled(LightButton)`
  &&& {
    width: 90px;
  }
`;

const QuestionWrapper = styled.div`
  width: 200px;
  text-align: left;
  color: ${props => props.theme.white};
`;

const ErrorWrapper = styled.div`
  width: 200px;
  text-align: left;

  color: ${props => props.theme.wbcRed};
`;

const ResetFormWrapper = styled.div`
  padding-left: 15px;
`;

const ContactWrapper = styled.div`
  width: 200px;
  text-align: left;
  color: ${props => props.theme.white};
`;

const initialState = {
  userName: "",
  correctAnswer: "",
  answer: "",
  question: "",
  message: "Enter your email address to reset your password",
  error: "",
  showContact: false,
  password: "",
  confirmPassword: ""
};

const ResetForm = () => {
  const loginContext = useContext(LoginContext);

  const reducer = (state, action) => {
    switch (action.type) {
      case "setUserName":
        return { ...state, userName: action.userName };
      case "setCorrectAnswer":
        return { ...state, correctAnswer: action.correctAnswer };
      case "setAnswer":
        return { ...state, answer: action.answer };
      case "setQuestion":
        return { ...state, question: action.question, message: action.question, error: "" };
      case "setMessage":
        return { ...state, message: action.message };
      case "setError":
        return { ...state, error: action.error };
      case "setShowContact":
        return { ...state, showContact: action.showContact, error: action.error };
      case "setPassword":
        return { ...state, password: action.password };
      case "setConfirmPassword":
        return { ...state, confirmPassword: action.confirmPassword };
      case "reset":
        return { ...action.state };
      default:
        return state;
    }
  };

  let [state, dispatch] = useReducer(reducer, initialState);

  const resetState = () => {
    dispatch({ type: "reset", state: initialState });
  };

  const checkUser = () => {
    ResetPasswordApi.checkUser(state.userName).then(resp => {
      if (resp.status !== "") {
        dispatch({ type: "setError", error: resp.status });
      } else {
        dispatch({ type: "setQuestion", question: `Security Question: ${resp.question}` });
      }
    });
  };

  const checkAnswer = () => {
    ResetPasswordApi.checkAnswer(state.userName, state.answer).then(resp => {
      if (resp.status === "OK") {
        dispatch({ type: "setMessage", message: "Please enter a new password" });
        dispatch({ type: "setCorrectAnswer", correctAnswer: true });
        dispatch({ type: "setShowContact", showContact: false, error: "" });
      } else {
        dispatch({ type: "setShowContact", showContact: true, error: resp.status });
      }
    });
  };

  const resetPassword = () => {
    if (state.password !== state.confirmPassword) {
      dispatch({ type: "setError", error: "Passwords do not match" });
    } else {
      ResetPasswordApi.resetPassword(state.userName, state.password, state.answer).then(resp => {
        if (resp.status !== "success") {
          dispatch({ type: "setError", error: resp.status });
        } else {
          resetState();
          loginContext.dispatch({
            type: "setResetMessage",
            resetMessage: "Your password has been reset, please login"
          });
          loginContext.dispatch({ type: "toggleResetPassword" });
        }
      });
    }
  };

  return (
    <ResetFormWrapper>
      <div>
        {state.error !== "" ? (
          <div className={"row"}>
            <div className="form-group">
              <ErrorWrapper>{state.error}</ErrorWrapper>
              {state.showContact ? (
                <ContactWrapper>
                  For assistance with your logon credentials if you cannot remember your password secret answer, please
                  contact <a href="ebissupport@mlb.com">ebissupport@mlb.com</a>
                </ContactWrapper>
              ) : (
                ""
              )}
            </div>
          </div>
        ) : (
          ""
        )}

        {state.message !== "" ? (
          <div className={"row"}>
            <div className="form-group">
              <QuestionWrapper>{state.message}</QuestionWrapper>
            </div>
          </div>
        ) : (
          ""
        )}
        {!state.question && !state.answer ? (
          <form
            onSubmit={e => {
              e.preventDefault();
              checkUser();
            }}
          >
            <div className="row">
              <div className="form-group">
                <ResetInput
                  className={"form-control"}
                  type={"text"}
                  name={"username"}
                  placeholder={"email"}
                  value={state.userName}
                  onChange={e => {
                    dispatch({ type: "setUserName", userName: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <ResetButton disabled={!state.userName}>Submit</ResetButton>
                <CancelButton
                  onClick={() => {
                    resetState();
                    loginContext.dispatch({ type: "toggleResetPassword" });
                  }}
                >
                  Cancel
                </CancelButton>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
        {state.question && !state.correctAnswer ? (
          <form
            onSubmit={e => {
              e.preventDefault();
              checkAnswer();
            }}
          >
            <div className="row">
              <div className="form-group">
                <ResetInput
                  className={"form-control"}
                  type={"password"}
                  name={"answer"}
                  placeholder={"answer"}
                  value={state.answer}
                  onChange={e => {
                    dispatch({ type: "setAnswer", answer: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <ResetButton disabled={state.answer === ""}>Submit</ResetButton>
                <CancelButton
                  onClick={() => {
                    resetState();
                  }}
                >
                  Cancel
                </CancelButton>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
        {state.correctAnswer ? (
          <form
            onSubmit={e => {
              e.preventDefault();
              resetPassword();
            }}
          >
            <div className="row">
              <div className="form-group">
                <ResetInput
                  className={"form-control"}
                  type={"password"}
                  name={"password"}
                  placeholder={"password"}
                  value={state.password}
                  onChange={e => {
                    dispatch({ type: "setPassword", password: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <ResetInput
                  className={"form-control"}
                  type={"password"}
                  name={"repassword"}
                  placeholder={"confirm password"}
                  value={state.confirmPassword}
                  onChange={e => {
                    dispatch({ type: "setConfirmPassword", confirmPassword: e.target.value });
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <ResetButton disabled={state.password === "" || state.confirmPassword === ""}>Submit</ResetButton>
                <CancelButton
                  onClick={() => {
                    dispatch({ type: "setAnswer", answer: "" });
                    dispatch({ type: "setMessage", message: state.question });
                    dispatch({ type: "setCorrectAnswer", correctAnswer: false, error: "" });
                  }}
                >
                  Cancel
                </CancelButton>
              </div>
            </div>
          </form>
        ) : (
          ""
        )}
      </div>
    </ResetFormWrapper>
  );
};

export default ResetForm;
