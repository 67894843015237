import React from "react";
import Moment from "moment";
import PropTypes from "prop-types";

const formatDate = dateInput => {
  if (!!dateInput) {
    const date = Moment(dateInput)
      .utc()
      .format("MM/DD/YYYY");
    if (date !== "Invalid date") {
      return date;
    }
  }
  return "";
};

const BirthDateFormatter = ({ value }) => {
  return <>{formatDate(value)}</>;
};

BirthDateFormatter.propTypes = {
  value: PropTypes.any
};

export default BirthDateFormatter;
