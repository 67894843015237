import React, { useReducer } from "react";
import PropTypes from "prop-types";
import GlobalFedDropdownContext from "../contexts/GlobalFedDropdownContext";

const initialState = {
  showingState: false,
  dropdownState: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setShowingState":
      return {
        ...state,
        showingState: action.showingState
      };
    case "setDropdownState":
      return {
        ...state,
        dropdownState: action.dropdownState
      };
    case "setState":
      return {
        ...state,
        showingState: action.showingState,
        dropdownState: action.dropdownState
      };
    default:
      return state;
  }
};

const GlobalFedDropdownContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <GlobalFedDropdownContext.Provider value={{ state, dispatch }}>{children}</GlobalFedDropdownContext.Provider>;
};

GlobalFedDropdownContextProvider.propTypes = {
  children: PropTypes.object
};

export default GlobalFedDropdownContextProvider;
