import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import MobileView from "./MobileView";
import DesktopTabletView from "./DesktopTabletView";

const ENV = window.MLBBest.envVariables.REACT_APP_ENV;

const Holder = styled.div`
  &&& {
    margin-top: 22px;
  }
`;

const NavBarHidden = styled.nav.attrs(({ expandStyleHidden }) => ({
  className: `navbar fixed-top navbar-expand-${expandStyleHidden} navbar-light bg-faded align-items-start justify-content-start`,
  id: "best-navbar"
}))`
  padding: 0 !important;
`;

const NavBarStyled = styled.nav.attrs(({ expandStyleStyled }) => ({
  className: `navbar fixed-top navbar-expand-${expandStyleStyled} navbar-light bg-faded align-items-start justify-content-start`,
  id: "best-navbar"
}))`
  &&& {
    width: ${props => props.styles.width};
    height: 100vh;
    background-color: ${props => props.styles.backgroundColor};
    z-index: 1050;
    margin-top: 58px;
    padding: 10px 0 5px 0;
    box-shadow: ${props => props.styles.boxShadow};
    border-top: 1px solid #041e42;
    border-bottom: 1px solid #041e42;
  }
`;

const DefaultStyle = {
  width: "100px",
  backgroundColor: "#1f3780",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)"
};

const NavigationVertical = ({
  children,
  loggedIn,
  isLoginTab,
  logo,
  tabletSupport,
  styles,
  mobileMax,
  desktopMin,
  expandStyleHidden,
  expandStyleStyled
}) => {
  const env = !!ENV ? ENV.toLowerCase() : "";
  const isProd = env === "prod" || env === "production";

  const CompStyles = { ...DefaultStyle, ...styles };

  if (tabletSupport) {
    NavBarStyled.attrs[0].className = NavBarStyled.attrs[0].className.replace("md", "lg");
  }
  return (
    <Holder>
      {loggedIn && logo && (
        <React.Fragment>
          <MobileView maxWidth={mobileMax}>
            <NavBarHidden>{loggedIn ? children : null}</NavBarHidden>
          </MobileView>
          <DesktopTabletView minWidth={desktopMin}>
            <NavBarStyled
              styles={CompStyles}
              loggedIn={loggedIn}
              envIndicator={!isLoginTab}
              paddMobile={loggedIn && !isProd}
              expandStyleHidden={expandStyleHidden}
              expandStyleStyled={expandStyleStyled}
            >
              {loggedIn ? children : null}
            </NavBarStyled>
          </DesktopTabletView>
        </React.Fragment>
      )}
    </Holder>
  );
};

NavigationVertical.propTypes = {
  children: PropTypes.any.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  isLoginTab: PropTypes.bool,
  logo: PropTypes.any,
  builds: PropTypes.array,
  tabletSupport: PropTypes.bool,
  styles: PropTypes.object,
  mobileMax: PropTypes.number,
  desktopMin: PropTypes.number,
  expandStyleHidden: PropTypes.string,
  expandStyleStyled: PropTypes.string
};

NavigationVertical.defaultProps = {
  displayName: "navigation-vertical",
  isProd: false,
  isLoginTab: false,
  builds: [],
  tabletSupport: false,
  styles: {},
  mobileMax: 767,
  desktopMin: 768,
  expandStyleHidden: "md",
  expandStyleStyled: "md"
};

export default NavigationVertical;
