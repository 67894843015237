const sortList = (col, dir, rows, isComputedClub = true) => {
  if (rows.length > 0) {
    switch (dir) {
      case "ASC":
        if (!isNaN(rows[0][col])) return numberSort(rows, col);
        return rows.sort((first, second) =>
          first[col]
            ?.toString()
            .toLowerCase()
            .localeCompare(second[col]?.toString().toLowerCase())
        );
      case "DESC":
        if (!isNaN(rows[0][col])) return numberSort(rows, col).reverse();
        return rows
          .sort((first, second) =>
            first[col]
              ?.toString()
              .toLowerCase()
              .localeCompare(second[col]?.toString().toLowerCase())
          )
          .reverse();
      default:
        return sortByNameAsc(rows);
    }
  }
  return [];
};

const sortByNameAsc = data => {
  return data.sort((val1, val2) => {
    if (val1.lastName.toLowerCase() < val2.lastName.toLowerCase()) return -1;
    else if (val1.lastName.toLowerCase() > val2.lastName.toLowerCase()) return 1;

    if (val1.firstName.toLowerCase() < val2.firstName.toLowerCase()) return -1;
    else if (val1.firstName.toLowerCase() > val2.firstName.toLowerCase()) return 1;
    else return 0;
  });
};

const sortByNameDesc = data => {
  return data.sort((val1, val2) => {
    if (val1.lastName.toLowerCase() < val2.lastName.toLowerCase()) return 1;
    else if (val1.lastName.toLowerCase() > val2.lastName.toLowerCase()) return -1;

    if (val1.firstName.toLowerCase() < val2.firstName.toLowerCase()) return 1;
    else if (val1.firstName.toLowerCase() > val2.firstName.toLowerCase()) return -1;
    else return 0;
  });
};

const numberSort = (data, prop) => {
  return data.sort((val1, val2) => {
    if (val1[prop] < val2[prop]) return -1;
    if (val1[prop] > val2[prop]) return 1;
    else return 0;
  });
};

const sortAndHandleNulls = (dir, col, rows, isComputedClub) => {
  if (rows && rows.length > 0) {
    if ((col === "clubId" || col === "club") && isComputedClub) {
      col = "computedClub";
    }

    if (col === "fortyManStatus" || col === "fortyMan" || col === "ilFlag") {
      const nullList = sortByNameAsc(rows.filter(row => row[col] === null || row[col] === ""));
      const falseList = sortByNameAsc(rows.filter(row => row[col] !== null && row[col] === false));
      const trueList = sortByNameAsc(rows.filter(row => row[col] === true));
      let sortedList = [];

      if (dir === "ASC") {
        sortedList = sortList(col, dir, trueList);
        if (falseList.length > 0) sortedList.unshift(...falseList);
        if (nullList.length > 0) sortedList.push(...nullList);
        return sortedList;
      } else if (dir === "DESC") {
        sortedList = sortList(col, dir, trueList).reverse();
        if (falseList.length > 0) sortedList.push(...falseList);
        if (nullList.length > 0) sortedList.unshift(...nullList);
        return sortedList;
      }
    } else {
      if (col === "eligible") {
        if (dir === "ASC") dir = "DESC";
        else if (dir === "DESC") dir = "ASC";
      }
      if (dir === "ASC") {
        const nullList = sortByNameAsc(rows.filter(row => row[col] === null || row[col] === ""));
        const valueList = sortByNameAsc(rows.filter(row => row[col] !== null && row[col] !== ""));
        const sortedList = sortList(col, dir, valueList);
        if (nullList.length > 0) sortedList.push(...nullList);
        return sortedList;
      } else if (dir === "DESC") {
        const nullList = sortByNameDesc(rows.filter(row => row[col] === null || row[col] === ""));
        const valueList = sortByNameDesc(rows.filter(row => row[col] !== null && row[col] !== ""));
        const sortedList = sortList(col, dir, valueList);
        if (nullList.length > 0) sortedList.unshift(...nullList);
        return sortedList;
      }
    }

    return sortByNameAsc(rows);
  }
  return rows;
};

const sortPlayers = (col, dir, rows, isComputedClub = true) => sortAndHandleNulls(dir, col, rows, isComputedClub);
export { sortPlayers };
