import React from "react";
import { Modal } from "best-common-react";
import PropTypes from "prop-types";
import WBCFooter from "../navigation/WBCFooter";
import styled from "styled-components";

const WrappedFooter = styled(WBCFooter)`
  &&& {
    left: -4px;
    height: 2px;
    z-index: 0;
  }
`;

const HeaderStyle = styled.div.attrs(() => ({
  className: "d-flex justify-content-between"
}))`
  width: 100%;
`;

const HeaderTextStyle = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const WBCModal = ({ isOpen, toggleModal, headerText, children, onClose, canClose, onCloseDisabled, headerChild }) => {
  return (
    <Modal size={"lg"} show={isOpen}>
      {headerChild ? (
        <Modal.Header
          styles={{
            backgroundColor: "#ffffff",
            borderBottomColor: "#1e376d",
            color: "#1e376d"
          }}
        >
          <HeaderStyle>
            <HeaderTextStyle>{headerText}</HeaderTextStyle>
            {headerChild}
          </HeaderStyle>
        </Modal.Header>
      ) : (
        <Modal.HeaderWithTitleAndClose
          title={headerText || ""}
          close={event => {
            if (canClose && canClose() === false) {
              if (onCloseDisabled) {
                onCloseDisabled();
              }
              return;
            }

            event.stopPropagation();
            toggleModal(false);
            if (onClose) {
              onClose();
            }
          }}
          styles={{
            backgroundColor: "#ffffff",
            borderBottomColor: "#1e376d",
            color: "#1e376d"
          }}
        />
      )}

      {React.Children.map(children, child => {
        const type = !child || !child.type ? null : child.type.displayName;
        switch (type) {
          case "BodyContent":
            return (
              <Modal.Body
                styles={{
                  backgroundColor: "#f2f3f4"
                }}
              >
                {React.cloneElement(child, {})}
              </Modal.Body>
            );
          case "FooterContent":
            return (
              <Modal.Footer
                styles={{
                  backgroundColor: "#f2f3f4",
                  borderTop: "none"
                }}
              >
                <React.Fragment>
                  {React.cloneElement(child, {})}
                  <WrappedFooter />
                </React.Fragment>
              </Modal.Footer>
            );
          default:
            return null;
        }
      })}
    </Modal>
  );
};

WBCModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  headerText: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.array,
  canClose: PropTypes.func,
  onCloseDisabled: PropTypes.func,
  headerChild: PropTypes.object
};

export default WBCModal;
