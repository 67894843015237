import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import ProfileInfoForm from "./ProfileInfoForm";
import ProfileHistoryForm from "./history/ProfileHistoryForm";
import ControlledTabSwitcher from "../elements/ControlledTabSwitcher";

const TabList = [
  {
    key: "info",
    name: "Info",
    component: ProfileInfoForm
  },
  {
    key: "history",
    name: "History",
    component: ProfileHistoryForm
  }
];

const getCurrentTab = location => {
  const parsed = queryString.parse(location);
  return parsed.tab && !isNaN(parsed.tab) ? parseInt(parsed.tab) : parsed.tab;
};

const ProfileTabManager = withRouter(({ history, location, count }) => {
  // variables
  const activeTab = getCurrentTab(location.search);

  // functions
  const onSwitch = (index, tabKey) => {
    count.current = count.current + 1;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("tab", tabKey || index);
    history.push(`?${searchParams.toString()}`);
  };

  return <ControlledTabSwitcher activeTab={activeTab} onSwitch={onSwitch} tabs={TabList} />;
});

ProfileTabManager.propTypes = {
  count: PropTypes.exact({
    current: PropTypes.number
  }).isRequired
};

export default ProfileTabManager;
