import styled from "styled-components";

export const ContainerStyle = styled.div.attrs(() => ({
  className: "mb-2"
}))``;

export const FormTitleWrapper = styled.div`
  &&& {
    color: #666666;
  }
`;

export const Title = styled.div.attrs(() => ({
  className: "mt-0 mb-1 "
}))`
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #b9d5e3;
  font-size: 16px;
`;

export const NumberInputWrapper = styled.div`
  .form-group {
    margin-bottom: 0px;
  }
`;

export const BreakoutTableData = styled.td`
  padding-left: 2.5px;
  padding-right: 2.5px;
`;

export const BreakoutTableText = styled.div`
  width: 115px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  color: #000000;
`;
