import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import CommentsContent from "../elements/CommentsContent";
import { MenuButton, MenuIcon, ContextMenu } from "../elements/ContextMenu";

const EligibleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusText = styled.span`
  margin-right: 8px;
`;

const FlyoutWrapper = styled.span`
  margin-top: 4px;
`;

const EligibilityPopover = styled(ContextMenu)`
  padding: 8px 0px;
  background-color: ${props => props.theme.white};
`;

const EligibleFormatter = ({ value, row }) => {
  // hooks && variable(s)
  const targetRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { eligible, eligibilityComment } = row;

  // function(s)
  const toggleMenu = value => setIsOpen(value !== undefined ? value : !isOpen);

  return (
    <EligibleWrapper>
      <StatusText>
        {eligible === "Submitted" || value === "Submitted"
          ? "Pending"
          : eligible === "Yes" || value === "Yes"
          ? "Approved"
          : eligible === "No" || value === "No"
          ? "Denied"
          : ""}
      </StatusText>
      {eligible === "No" || value === "No" ? (
        <FlyoutWrapper>
          <MenuButton ref={targetRef} onClick={() => setIsOpen(!isOpen)}>
            <MenuIcon iconName="fa-comment" />
          </MenuButton>
          <EligibilityPopover targetRef={targetRef} isOpen={isOpen} toggleMenu={toggleMenu}>
            <CommentsContent
              title="Denial Reason"
              content={eligibilityComment ? eligibilityComment : row.eligibilityComment ? row.eligibilityComment : ""}
            />
          </EligibilityPopover>
        </FlyoutWrapper>
      ) : null}
    </EligibleWrapper>
  );
};

EligibleFormatter.propTypes = {
  row: PropTypes.object,
  value: PropTypes.string
};

export default EligibleFormatter;
