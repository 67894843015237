import React, { useContext } from "react";
import PropTypes from "prop-types";
import PositionContext from "../../contexts/PositionsContext";

const PositionFormatter = ({ value }) => {
  const positionContext = useContext(PositionContext);
  return <div>{value ? positionContext.state.posIdToAbbrev[value] : null}</div>;
};

PositionFormatter.propTypes = {
  value: PropTypes.number
};

export default PositionFormatter;
