import React from "react";
import { BackToPendingButton, OutlinedApproveButton } from "./EligibilityButtons.js";
import PropTypes from "prop-types";

function DeniedButtonFormatter({ onPendingClick, onApproveClick }) {
  return (
    <div className="d-flex">
      <BackToPendingButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onPendingClick();
        }}
        style={{ width: "140px" }}
      >
        Return To Pending
      </BackToPendingButton>
      <OutlinedApproveButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onApproveClick();
        }}
      >
        Approve
      </OutlinedApproveButton>
    </div>
  );
}

DeniedButtonFormatter.propTypes = {
  onApproveClick: PropTypes.func.isRequired,
  onPendingClick: PropTypes.func.isRequired
};
export default DeniedButtonFormatter;
