import React from "react";
import InterestListForm from "../components/interest-list/InterestListForm";
import InterestListProvider from "../providers/InterestListProvider";

const InterestListContainer = () => (
  <div>
    <InterestListProvider>
      <InterestListForm />
    </InterestListProvider>
  </div>
);

export default InterestListContainer;
