import { useContext, useEffect } from "react";
import RosterContext from "../../contexts/RosterContext";

export const useClearSectionsOpen = () => {
  const rosterContext = useContext(RosterContext);
  const { dispatch } = rosterContext;

  useEffect(() => {
    dispatch({
      type: "closeSections"
    });
  }, [dispatch]);
};
