import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { BestSelect } from "best-common-react";
import EligibilityListApi from "../../httpClients/EligibilityListApi";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../utils/WBCYearPredicates";

const EligibilityYearSelect = () => {
  const {
    state: {
      team: { teamId }
    },
    dispatch: playerEligibilityDispatch
  } = useContext(PlayerEligibilityContext);
  const { wbcYears, selectedWbcYear, changeSelectedYear, selectedYear } = useContext(WBCYearsContext);
  const filteredOptions = useMemo(() => wbcYears.filter(isCurrentOrPastTournamentYear), [wbcYears]);

  useEffect(() => {
    if (selectedYear && teamId != null) {
      EligibilityListApi.getPlayerEligibilityList(selectedYear, teamId).then(players => {
        playerEligibilityDispatch({ type: "setPlayers", players });
      });
    }
  }, [selectedYear, teamId, playerEligibilityDispatch]);

  return (
    <div className="form-group">
      <BestSelect
        name="yearsDropdown"
        value={selectedWbcYear}
        options={filteredOptions}
        onChange={useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear])}
      />
    </div>
  );
};

export default EligibilityYearSelect;
