import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { EnvIndicator } from "best-common-react";
import DesktopTabletView from "./DesktopTabletView";

const NavBarSignoutStyle = styled.div.attrs(() => ({
  className: "d-flex flex-row info-bar fixed-top align-items-center",
  id: "best-navbar-signout"
}))`
  &&& {
    background-color: ${props => (props.color ? props.color : "#ffffff")};
    z-index: 1052;
    height: 22px;
  }
`;

const EnvHolder = styled.div`
  width: 65px;
  height: 22px;
`;

const NavbarSignout = ({ loggedIn, children, color, displayEnvIndicator, desktopMin }) => (
  <div>
    <DesktopTabletView minWidth={desktopMin}>
      {loggedIn ? (
        <NavBarSignoutStyle color={color}>
          {displayEnvIndicator ? (
            <EnvHolder>
              <EnvIndicator />
            </EnvHolder>
          ) : (
            ""
          )}
          <div className="ml-auto">{children}</div>
        </NavBarSignoutStyle>
      ) : (
        ""
      )}
    </DesktopTabletView>
  </div>
);

NavbarSignout.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  displayEnvIndicator: PropTypes.bool,
  color: PropTypes.string,
  desktopMin: PropTypes.number
};

NavbarSignout.defaultProps = {
  displayName: "nav-bar-signout",
  displayEnvIndicator: true,
  desktopMin: 768
};

export default NavbarSignout;
