import React from "react";
import PropTypes from "prop-types";
import PositionList from "./PositionList";
import { PositionContainer } from "./PositionHelpers";

const Infield = ({ settings }) => (
  <PositionContainer>
    <PositionList
      droppableId="infield-list"
      header="Infield"
      maximum={settings.infield.infieldMax}
      minimum={settings.infield.infieldMin}
    />
  </PositionContainer>
);

Infield.propTypes = {
  settings: PropTypes.object
};

export default Infield;
