import { useContext, useCallback } from "react";
import AlertContext from "../../contexts/AlertContext";
import { AlertConstants } from "best-common-react";

const nextId = (() => {
  let _nextId = 1;
  return () => `${_nextId++}`;
})();

/**
 * Hook to send alerts
 */
export const useAlert = () => {
  const alertContext = useContext(AlertContext);
  const { dispatch } = alertContext;

  return useCallback(
    (message, messageType = AlertConstants.TYPES.SUCCESS) => {
      const id = nextId();

      dispatch({
        type: "addAlert",
        alert: {
          id,
          type: messageType,
          text: message
        }
      });

      return () => {
        dispatch({
          type: "removeAlert",
          id
        });
      };
    },
    [dispatch]
  );
};
