import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormColumn2Wide, FormInput, BestLabel } from "best-common-react";
import WBCAccessControl from "../../protected/WBCAccessControl";
import MobileView from "../../temp/MobileView";
import DesktopTabletView from "../../temp/DesktopTabletView";
import OrgsContext from "../../../contexts/OrgsContext";
import ProfileContext from "../../../contexts/ProfileContext";
import ClubsContext from "../../../contexts/ClubsContext";
import UncontrolledFormInput from "../../elements/UncontrolledFormInput";
import PositionKeyIcon from "../../elements/PositionKeyIcon";
import PositionsContext from "../../../contexts/PositionsContext";
import { Required } from "../shared/ProfileComponents";
import DisabledInputStyle from "../../elements/DisabledInputStyle";
import AuthContext from "../../../contexts/AuthContext";

const SuspendedRow = styled.div.attrs(() => ({
  className: "row"
}))`
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
`;

const handedOptions = [
  { label: "R", value: "R" },
  { label: "L", value: "L" },
  { label: "S", value: "S" }
];

const boolToString = val => (val === undefined || val === null ? "" : val ? "Yes" : "No");

const BaseballLeftForm = ({ isStaff }) => {
  // hooks
  const orgsContext = useContext(OrgsContext);
  const authContext = useContext(AuthContext);
  const clubsContext = useContext(ClubsContext);
  const positionsContext = useContext(PositionsContext);
  const profileContext = useContext(ProfileContext);
  const [clubOptions, setClubOptions] = useState([]);

  // variables
  const { orgIdToCode, orgsDropdown } = orgsContext.state;
  const {
    club,
    orgId,
    clubId,
    ebisId,
    bats,
    throws,
    positionId,
    fortyManRosterFlag,
    injuredListFlag,
    suspendedListFlag
  } = profileContext.state;
  const { clubs, clubIdToName } = clubsContext.state;
  const { posIdToAbbrev, profilePositionsDropdown, staffPositionsDropdown } = positionsContext.state;
  const { isTeamCoordinator, isBOC } = authContext.state;

  const canSeeEbisId = isBOC || isTeamCoordinator;

  // position
  const positionOptions = _.sortBy(isStaff ? staffPositionsDropdown : profilePositionsDropdown, ["orderNum"]);

  // functions
  const findClubsForOrg = useCallback(
    orgId => {
      const clubMap = {};
      clubs.forEach(club => {
        if (club.orgId === orgId) {
          clubMap[club.sortOrder] = {
            value: club.clubId,
            label: club.clubName,
            clubId: club.clubId
          };
        }
      });
      return Object.values(clubMap);
    },
    [clubs]
  );

  const onOrgChange = value => {
    const orgId = value.value;
    const clubsForOrg = findClubsForOrg(orgId);
    const clubId = orgId !== 1 && clubsForOrg.length > 0 ? clubsForOrg[0].clubId : null;

    profileContext.dispatch({
      type: "setProfileOrganization",
      orgId,
      clubId
    });
  };

  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  // effects
  // update clubOptions for the selected org
  useEffect(() => {
    setClubOptions(findClubsForOrg(orgId));
  }, [orgId, findClubsForOrg]);

  return (
    <div className="col-xl-6 col-lg-12">
      <WBCAccessControl
        input={isDisabled => (
          <>
            <div>
              <MobileView maxWidth={1199}>
                <div className="row">
                  <div className="col-4 pr-1">
                    <FormInput
                      editable
                      name="orgDropdown"
                      label="Org"
                      type="select"
                      isRequired={false}
                      options={orgsDropdown}
                      isDisabled={isDisabled}
                      value={{
                        value: orgId,
                        label: orgIdToCode[orgId] || ""
                      }}
                      onChangeFn={onOrgChange}
                    />
                  </div>
                  <div className="col pl-0">
                    {orgId === 1 || orgId === 5 ? (
                      <UncontrolledFormInput
                        id="clubTextField"
                        label="Club"
                        disabled={isDisabled}
                        value={club}
                        postChange={value => onProfileChange(value, "club")}
                      />
                    ) : (
                      <FormInput
                        editable
                        name="clubDropdown"
                        label="Club"
                        type="select"
                        isRequired={false}
                        options={clubOptions}
                        isDisabled={isDisabled}
                        inputStyle={DisabledInputStyle}
                        value={{
                          value: clubId,
                          label: clubIdToName[clubId] || ""
                        }}
                        onChangeFn={value => onProfileChange(value.clubId, "clubId")}
                      />
                    )}
                  </div>
                </div>
              </MobileView>
              <DesktopTabletView minWidth={1200}>
                <div className="row">
                  <div className="col pr-1" disabled={isDisabled}>
                    <BestLabel>
                      Position{isDisabled ? null : <Required>*</Required>}
                      <PositionKeyIcon />
                    </BestLabel>
                    <FormInput
                      editable
                      label=""
                      type="select"
                      name="positionDropdown"
                      isDisabled={isDisabled}
                      options={positionOptions}
                      isRequired={!isDisabled}
                      value={{
                        value: positionId,
                        label: posIdToAbbrev[positionId] || ""
                      }}
                      onChangeFn={value => onProfileChange(value.value, "positionId")}
                    />
                  </div>
                  <div className="col pl-0">
                    <FormInput
                      editable
                      name="orgDropdown"
                      label="Org"
                      type="select"
                      isRequired={false}
                      options={orgsDropdown}
                      isDisabled={isDisabled}
                      value={{
                        value: orgId,
                        label: orgIdToCode[orgId] || ""
                      }}
                      onChangeFn={onOrgChange}
                    />
                  </div>
                </div>
              </DesktopTabletView>
            </div>
            {!isStaff && (
              <div className="row">
                <div className="col pr-1 pb-0">
                  <FormInput
                    editable
                    name="batsDropdown"
                    label="Bats"
                    type="select"
                    options={handedOptions}
                    isDisabled={isDisabled}
                    inputStyle={DisabledInputStyle}
                    isRequired={!isDisabled}
                    value={{
                      value: bats || "",
                      label: bats || ""
                    }}
                    onChangeFn={value => onProfileChange(value.value, "bats")}
                  />
                </div>
                <div className="col pl-0">
                  <FormInput
                    editable
                    name="throwsDropdown"
                    label="Throws"
                    type="select"
                    options={handedOptions}
                    isDisabled={isDisabled}
                    inputStyle={DisabledInputStyle}
                    isRequired={!isDisabled}
                    value={{
                      value: throws || "",
                      label: throws || ""
                    }}
                    onChangeFn={value => onProfileChange(value.value, "throws")}
                  />
                </div>
              </div>
            )}
          </>
        )}
      />
      {ebisId && canSeeEbisId ? (
        <>
          <div className="row">
            <FormColumn2Wide>
              <FormInput
                editable
                isDisabled
                type="text"
                name="disabledInputWithText"
                label="EBIS ID"
                value={ebisId}
                inputStyle={DisabledInputStyle}
              />
            </FormColumn2Wide>
            {!isStaff && (
              <FormColumn2Wide>
                <FormInput
                  editable
                  isDisabled
                  type="text"
                  name="disabledInputWithText"
                  label="40 Man"
                  inputStyle={DisabledInputStyle}
                  value={boolToString(fortyManRosterFlag)}
                />
              </FormColumn2Wide>
            )}
          </div>
          {!isStaff && (
            <SuspendedRow>
              <FormColumn2Wide>
                <FormInput
                  editable
                  isDisabled
                  type="text"
                  name="disabledInputWithText"
                  label="IL"
                  value={boolToString(injuredListFlag)}
                  inputStyle={DisabledInputStyle}
                />
              </FormColumn2Wide>
              <FormColumn2Wide>
                <FormInput
                  editable
                  isDisabled
                  type="text"
                  name="disabledInputWithText"
                  label="Suspended List"
                  value={boolToString(suspendedListFlag)}
                  inputStyle={DisabledInputStyle}
                />
              </FormColumn2Wide>
            </SuspendedRow>
          )}
        </>
      ) : null}
    </div>
  );
};

BaseballLeftForm.propTypes = {
  isStaff: PropTypes.bool
};

export default BaseballLeftForm;
