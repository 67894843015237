import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import StatusSelect from "./StatusSelect";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";

// leaving for future styling
const SelectsContainer = styled.div``;

const MLBRequestSelects = () => {
  const { playerStatuses, requestStatusFilter, setRequestStatusFilter, playerRequests } = useContext(
    MLBPlayerRequestContext
  );

  const [statusMap, setStatusMap] = useState({});

  const generateStatusMap = (playerRequestList, requestStatusList) => {
    const statusMap = {};
    requestStatusList.forEach(status => {
      statusMap[status.label] = 0;
    });
    statusMap["All"] = playerRequestList?.length;

    playerRequestList.forEach(request => {
      const status = requestStatusList.find(status => status.label === request.requestStatus);
      if (status) {
        statusMap[status.label]++;
      }
    });

    return statusMap;
  };

  const handleOnClick = status => {
    setRequestStatusFilter(status);
  };

  useEffect(() => {
    setStatusMap(generateStatusMap(playerRequests, playerStatuses));
  }, [playerRequests, playerStatuses]);

  return (
    <SelectsContainer>
      {playerStatuses.map(status => (
        <StatusSelect
          key={status?.value}
          statusName={status?.label}
          number={statusMap[status?.label]}
          onClick={handleOnClick.bind(this, status)}
          isSelected={requestStatusFilter.value === status?.value}
        />
      ))}
    </SelectsContainer>
  );
};

export default MLBRequestSelects;
