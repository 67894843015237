import React, { useContext, useEffect } from "react";
import { MLBPlayersDashboardTab } from "../MLBPlayersDashboardStyles";
import MLBPlayerSummaryApi from "../../../httpClients/MLBPlayerSummaryApi";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";
import MLBPlayersByFederation from "./MLBPlayersByFederation";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import MLBPlayersByFilters from "./MLBPlayersByFilters";
import MLBPlayersByOrg from "./MLBPlayersByOrg";
import MLBPlayersDetails from "./MLBPlayerDetails";
import AuthContext from "../../../contexts/AuthContext";

const MLBPlayerSummary = () => {
  const { setPlayerSummaries, setPlayerStatuses } = useContext(MLBPlayerSummaryContext);
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const authContext = useContext(AuthContext);
  const { isBOC } = authContext.state;
  // pull country assignments from local storage inside of userData
  const userData = JSON.parse(localStorage.getItem("userData"));

  useEffect(() => {
    if (selectedWbcYear?.year) {
      const fetchPlayerSummaries = async () => {
        if (isBOC) {
          const res = await MLBPlayerSummaryApi.getMLBPlayerSummariesByYear(selectedWbcYear.year);
          setPlayerSummaries(res?.playerSummaries?.length ? res.playerSummaries : []);
        } else if (userData?.assignments?.length) {
          const res = await MLBPlayerSummaryApi.getMLBPlayerSummariesByYearAndCountry(
            selectedWbcYear.year,
            userData.assignments
          );
          setPlayerSummaries(res?.length ? res : []);
        }
      };

      fetchPlayerSummaries();
    }

    const fetchPlayerStatuses = async () => {
      const statuses = await MLBPlayerSummaryApi.getStatuses();
      statuses?.length ? setPlayerStatuses(statuses) : setPlayerStatuses([]);
    };

    fetchPlayerStatuses();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWbcYear]);

  return (
    <MLBPlayersDashboardTab>
      <div id="mlb-players-by-container">
        <MLBPlayersByFilters />
        <MLBPlayersByFederation />
        <MLBPlayersByOrg />
      </div>
      <MLBPlayersDetails />
    </MLBPlayersDashboardTab>
  );
};

export default MLBPlayerSummary;
