import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import styled from "styled-components";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";

const DropArea = styled.div`
  flex: 1;
  margin-right: 5px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #ececec;
  text-align: center;
  height: 36px;
`;

const DropAreaWrapper = styled.div`
  height: 36px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
`;

const DropAreaText = styled.div`
  font-size: 14px;
  font-weight: 300px;
  font-style: oblique;
  color: ${props => props.theme.grey};
  margin-top: 7px;
`;

const BrowseButton = styled(WBCPrimaryOutlinedButton)`
  float: right;
`;

const BrowseFiles = ({ fileTypes, onFileAdd }) => {
  const getFileTypes = () => {
    if (Array.isArray(fileTypes)) {
      // Given an array of file types, join them with commas
      return fileTypes.join(", ");
    } else if (typeof fileTypes === "string") {
      // Given a string, just use that as the file type
      return fileTypes;
    } else {
      // By passing null, it will allow all file types
      return null;
    }
  };

  return (
    <div className="dropzone">
      <Dropzone
        accept={getFileTypes()}
        onDrop={(accepted, rejected) => {
          // Call the provided onChange function with the new file(s) added
          onFileAdd(accepted);
        }}
        disableClick
      >
        {({ getRootProps, getInputProps, open }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />

            <DropAreaWrapper>
              <div style={{ width: "100%" }} className={"d-flex align-items-center"}>
                <DropArea>
                  <DropAreaText>drop files here (or click browse)</DropAreaText>
                </DropArea>
                <BrowseButton
                  onClick={e => {
                    e.stopPropagation();
                    open();
                  }}
                >
                  Browse
                </BrowseButton>
              </div>
            </DropAreaWrapper>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

BrowseFiles.propTypes = {
  fileTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onFileAdd: PropTypes.func.isRequired
};

export default BrowseFiles;
