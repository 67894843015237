import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import { isEmpty } from "../../utils/StringUtils";

const Input = styled.input.attrs(() => ({
  className: "form-control"
}))`
  &&& {
    color: black;

    :disabled {
      background-color: ${props => props.theme["light-grey"]};
    }

    :focus {
      background-color: #fbf8cb;
    }
  }
`;

/**
 * An input component that is uncontrolled. This component allows for
 * an action to be performed after the text in the box has been updated
 * after a specified customizable timeout.
 *
 */
const UncontrolledInput = ({ timeout, postChange, required, className, value: initValue, ...rest }) => {
  // hooks
  const timeoutRef = useRef(null);
  const [value, setValue] = useState(initValue);

  // variables
  const inputClassName = classNames(className, { "is-invalid": required && isEmpty(value) });

  // functions
  const onFormChange = e => {
    setValue(e.target.value);
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(
      value => {
        if (postChange) {
          postChange(value);
        }
      },
      timeout,
      e.target.value
    );
  };

  // effects
  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  // render
  return <Input className={inputClassName} value={value} onChange={onFormChange} {...rest} />;
};

UncontrolledInput.propTypes = {
  required: PropTypes.bool,
  timeout: PropTypes.number,
  postChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

UncontrolledInput.defaultProps = {
  timeout: 150,
  required: false
};

export default UncontrolledInput;
