import styled from "styled-components";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";

export const FinalizeWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 16px;
`;

export const FinalizeButton = styled(WBCPrimaryButton)`
  &&&& {
    width: auto;
  }
`;
