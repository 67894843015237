import React from "react";
import PropTypes from "prop-types";

const adaptToBCRTableCell = WrappedComponent => {
  class AdaptedComponent extends React.Component {
    render() {
      return <WrappedComponent {...this.props.row} />;
    }
  }

  AdaptedComponent.propTypes = {
    row: PropTypes.any
  };

  return AdaptedComponent;
};

export default adaptToBCRTableCell;
