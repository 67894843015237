import React, { useReducer } from "react";
import PropTypes from "prop-types";
import TableSortFilterContext from "../contexts/TableSortFilterContext";

const initialState = {
  sortFilters: { col: "last_name", dir: "ASC" }
};

const TableSortFilterContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    if (action.type === "setSortFilters") {
      switch (action.sortFilters.col) {
        case "lastName":
          action.sortFilters.col = "last_name";
          break;
        case "firstName":
          action.sortFilters.col = "first_name";
          break;
        case "ebisId":
          action.sortFilters.col = "ebis_id";
          break;
        case "ilDays":
          action.sortFilters.col = "il_days";
          break;
        case "fortyManStatus":
          action.sortFilters.col = "forty_man_status";
          break;
        case "fortyMan":
          action.sortFilters.col = "forty_man";
          break;
        case "birthDate":
          action.sortFilters.col = "birth_date";
          break;
        case "birthCountry":
          action.sortFilters.col = "birth_country";
          break;
        case "clubId":
          action.sortFilters.col = "computed_club";
          break;
        case "club":
          action.sortFilters.col = "computed_club";
          break;
        case "otherDocs":
          action.sortFilters.col = "has_other_docs";
          break;
        case "positionId":
          action.sortFilters.col = "position_id";
          break;
        case "orgId":
          action.sortFilters.col = "org_id";
          break;
        case "birthCountryId":
          action.sortFilters.col = "birth_country_id";
          break;
        case "pastWbcFlag":
          action.sortFilters.col = "past_wbc_flag";
          break;
        case "hasPPA":
          action.sortFilters.col = "has_ppa";
          break;
        case "hasPpa":
          action.sortFilters.col = "has_ppa";
          break;
        case "uniform":
          action.sortFilters.col = "uniform_number";
          break;
        case "ilFlag":
          action.sortFilters.col = "il_flag";
          break;
        case "hasDocuments":
          action.sortFilters.col = "has_documents";
          break;
        default:
          break;
      }
      if (action.sortFilters.dir === "NONE") return initialState;
      return { ...state, sortFilters: action.sortFilters };
    } else {
      return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <TableSortFilterContext.Provider value={{ state, dispatch }}>{children}</TableSortFilterContext.Provider>;
};

TableSortFilterContextProvider.propTypes = {
  children: PropTypes.object
};

export default TableSortFilterContextProvider;
