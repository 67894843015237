import PropTypes from "prop-types";
import useHasNonCurrentYearAccess from "../hooks/useHasNonCurrentYearAccess";

const NonCurrentYearAccess = ({ orElse, children }) => (useHasNonCurrentYearAccess() ? children : orElse);

NonCurrentYearAccess.propTypes = {
  bocOnly: PropTypes.bool,
  orElse: PropTypes.node,
  children: PropTypes.node
};

NonCurrentYearAccess.defaultProps = {
  bocOnly: false,
  orElse: null
};

export default NonCurrentYearAccess;
