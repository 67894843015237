import React, { useReducer } from "react";
import PropTypes from "prop-types";
import FinalizedStatusContext from "../contexts/FinalizedStatusContext";

const initialState = {
  finalized: false
};

const FinalizedStatusContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setFinalizedStatus":
        return { ...state, finalized: action.finalized || false };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <FinalizedStatusContext.Provider value={{ state, dispatch }}>{children}</FinalizedStatusContext.Provider>;
};

FinalizedStatusContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default FinalizedStatusContextProvider;
