import React, { useContext } from "react";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import WBCTeamApi from "../../../httpClients/WBCTeamApi";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import { useAlert } from "../../hooks/useAlert";
import { useError } from "../../hooks/useError";
import TournamentFormContext from "../../../contexts/TournamentFormContext";
import InterestListContext from "../../../contexts/InterestListContext";
import { INTEREST_LIST, PROVISIONAL_LIST } from "../../../constants/RosterListConstants";
import RosterContext from "../../../contexts/RosterContext";
import WBCYearsContext from "../../../contexts/WBCYearsContext";

const FinalizeRosterModalFooter = () => {
  // hook(s)
  const globalModalContext = useContext(GlobalModalContext);
  const selectedWbcYear = useContext(WBCYearsContext).selectedWbcYear;
  const interestListForm = useContext(InterestListContext);
  const rosterConstext = useContext(RosterContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const tournamentFormContext = useContext(TournamentFormContext);

  const showAlert = useAlert();
  const showErrors = useError();

  // variable(s)
  const { team, prefix, rosterType } = globalModalContext.state.content;
  const { teams } = selectedTeamContext.state;

  // function(s)
  const onSuccess = finalizedStatus => {
    const index = teams.findIndex(t => t.teamId === team.teamId);
    const updatedTeam = { ...teams[index], finalizedStatus };
    selectedTeamContext.dispatch({
      type: "setTeams",
      teams: Object.assign([...teams], { [index]: updatedTeam }),
      team: updatedTeam
    });

    showAlert("Finalized");
    globalModalContext.dispatch({ type: "closeModal" });
  };

  const finalizeRosterOverride = () => {
    WBCTeamApi.finalizeRoster(team.teamId, prefix, true)
      .then(onSuccess)
      .catch(({ response }) => showAlert(response.data.message.split("\n"), "danger"));
  };

  const finalize = () => {
    WBCTeamApi.finalizeRoster(team.teamId, prefix)
      .then(onSuccess)
      .catch(({ response }) => showErrors(response.data.message, finalizeRosterOverride));
  };

  const finalizeInterestList = () => {
    if (interestListForm.state.formDirtyBool) {
      interestListForm.state.onSave(() => {
        interestListForm.state.cleanForm();
      });
    }
    WBCTeamApi.finalizeInterestList(team.teamId)
      .then(onSuccess)
      .catch(({ response }) => showAlert(response.data.message, "danger"));
  };

  const finalizeProvisionalList = () => {
    WBCTeamApi.finalizeProvisionalList(team.teamId, selectedWbcYear?.generalRosterSettings?.tournamentTypeId)
      .then(onSuccess)
      .catch(({ response }) => showAlert(response.data.message, "danger"));
  };

  const finalizeRoster = () => {
    if (rosterType === INTEREST_LIST) {
      finalizeInterestList();
    } else if (tournamentFormContext.state.formDirtyBool) {
      tournamentFormContext.state.onSave(() => {
        tournamentFormContext.state.cleanForm();
        if (rosterType === PROVISIONAL_LIST) {
          finalizeProvisionalList();
        } else {
          finalize();
        }
      });
    } else {
      if (rosterType === PROVISIONAL_LIST) {
        finalizeProvisionalList();
      } else {
        finalize();
      }
    }
  };

  return (
    <div>
      <div className={"d-flex"}>
        <WBCPrimaryButton
          width="auto"
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            finalizeRoster();
          }}
        >
          {`Finalize ${rosterType}`}
        </WBCPrimaryButton>

        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

FinalizeRosterModalFooter.displayName = "FooterContent";

export default FinalizeRosterModalFooter;
