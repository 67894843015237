import React from "react";
import styled from "styled-components";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 16px;
`;

const MailToLink = styled.a`
  color: ${props => props.theme.wbcBlue};
  font-weight: bold;
`;

const HelpAndSupportModalBody = () => {
  return (
    <BodyContent>
      <ModalText>
        If you need assistance please contact us at{" "}
        <MailToLink
          href="mailto:wbcsupport@MLB.com?Subject=WBC%20Portal%20Help&body=%0D%0A%0D%0A%0D%0ASent%20from%20WBC%20Portal"
          target="_top"
        >
          wbcsupport@MLB.com
        </MailToLink>
      </ModalText>
    </BodyContent>
  );
};

HelpAndSupportModalBody.displayName = "BodyContent";

export default HelpAndSupportModalBody;
