import { BestTitle, TextArea } from "best-common-react";
import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import ProfileContext from "../../contexts/ProfileContext";

const NotesTextArea = styled(TextArea)`
  &.form-control:disabled {
    color: black;
  }
`;

const NotesTextAreaLabel = styled.label`
  font-weight: lighter;
  font-style: italic;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const ProfileNotesForm = ({ disabled }) => {
  const profileContext = useContext(ProfileContext);

  const { notes } = profileContext.state;

  const maxLength = 500;

  return (
    <>
      <BestTitle>Notes</BestTitle>
      <NotesTextAreaLabel>
        {notes?.length ? notes.length : 0} of {maxLength} characters
      </NotesTextAreaLabel>
      <NotesTextArea
        disabled={disabled}
        value={notes}
        onChange={change => {
          profileContext.dispatch({
            key: "notes",
            value: change.target.value,
            type: "setProfileInfo"
          });
        }}
        maxLength={maxLength}
        rows={6}
      />
    </>
  );
};

ProfileNotesForm.propTypes = {
  disabled: PropTypes.bool
};

export default ProfileNotesForm;
