import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import RouteConstants from "../../constants/RouteConstants";

const StyledLink = styled(Link)`
  color: ${props => props.theme.wbcBlue};
  display: ${props => props.display || "block"};

  &:hover {
    color: ${props => props.theme.wbcBlue} !important;
    text-decoration: none !important;
  }
`;

const PlayerProfileLink = ({ profileType, profileId, fedTeamId, ...rest }) => (
  <StyledLink
    data-testid={`player-profile-link-${profileId}`}
    to={RouteConstants.PROFILE.replace(":playerId", profileId)
      .replace(":fedTeamId", fedTeamId)
      .replace(":profileType", profileType)}
    {...rest}
  />
);

PlayerProfileLink.propTypes = {
  profileType: PropTypes.oneOf([RouteConstants.PLAYER, RouteConstants.STAFF]),
  profileId: PropTypes.number.isRequired,
  fedTeamId: PropTypes.number.isRequired
};

PlayerProfileLink.defaultProps = {
  profileType: RouteConstants.PLAYER
};

export default PlayerProfileLink;
