import React from "react";
import PropTypes from "prop-types";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import EligibilityButtonFormatter from "./EligibilityButtonFormatter";

const EligibilityFormatter = ({ value, row }) => {
  return (
    <div className="d-flex justify-content-between">
      <div>{value === "Submitted" ? "Pending" : value === "Yes" ? "Approved" : value === "No" ? "Denied" : ""}</div>
      <WBCAdminAccess>
        <div>
          <EligibilityButtonFormatter row={row}></EligibilityButtonFormatter>
        </div>
      </WBCAdminAccess>
    </div>
  );
};

EligibilityFormatter.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object
};

export default EligibilityFormatter;
