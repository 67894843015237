import React from "react";
import ProfileContextProvider from "../providers/ProfileContextProvider";
import StateAndProvinceContextProvider from "../providers/StateAndProvinceContextProvider";
import StateAndProvinceProviderInitializer from "../providers/initializer/StateAndProvinceProviderInitializer";
import ProfileComponent from "../components/profile/ProfileComponent";

const ProfileContainer = () => (
  <StateAndProvinceContextProvider>
    <StateAndProvinceProviderInitializer>
      <ProfileContextProvider>
        <ProfileComponent />
      </ProfileContextProvider>
    </StateAndProvinceProviderInitializer>
  </StateAndProvinceContextProvider>
);

export default ProfileContainer;
