import { groupBy, mapValues, memoize } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import _ from "lodash";

import useEnhancedReducer from "../components/hooks/useEnhancedReducer";
import { QUALIFYING } from "../constants/TournamentTypeConstants";
import PitchTrackerContext from "../contexts/PitchTrackerContext";
import MainRosterApi from "../httpClients/MainRosterApi";
import PitchTrackerApi from "../httpClients/PitchTrackerApi";
import QualifyingRosterApi from "../httpClients/QualifyingRosterApi";
import { getPitchCounts, getPitchCountsByProfileId } from "../selectors/PitchTrackerSelectors";

const ActionTypes = {
  PITCH_COUNTS_REQUESTED: "PITCH_COUNTS_REQUESTED",
  PITCH_COUNTS_REQUEST_FULFILLED: "PITCH_COUNTS_REQUEST_FULFILLED",
  PITCH_COUNTS_REQUEST_FAILED: "PITCH_COUNTS_REQUEST_FAILED",
  PITCH_COUNT_UPDATED: "PITCH_COUNT_UPDATED",
  PITCH_COUNT_UPDATES_DISCARDED: "PITCH_COUNT_UPDATES_DISCARDED",
  PITCH_COUNTS_SAVE_REQUESTED: "PITCH_COUNTS_SAVE_REQUESTED",
  PITCH_COUNTS_SAVE_FULFILLED: "PITCH_COUNTS_SAVE_FULFILLED",
  PITCH_COUNTS_SAVE_FAILED: "PITCH_COUNTS_SAVE_FAILED",
  PITCH_COUNTS_PREVIEW_REQUESTED: "PITCH_COUNTS_PREVIEW_REQUESTED",
  PITCH_COUNTS_PREVIEW_FULFILLED: "PITCH_COUNTS_PREVIEW_FULFILLED",
  PITCH_COUNTS_PREVIEW_FAILED: "PITCH_COUNTS_PREVIEW_FAILED",
  PITCH_COUNTS_ADD_PITCHER_MODE_UPDATED: "PITCH_COUNTS_ADD_PITCHER_MODE_UPDATED",
  PITCH_COUNTS_PROVISIONAL_ROSTER_REQUESTED: "PITCH_COUNTS_PROVISIONAL_ROSTER_REQUESTED",
  PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FULFILLED: "PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FULFILLED",
  PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FAILED: "PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FAILED",
  PITCH_COUNTS_ADD_PITCHER_OPTIONS_UPDATED: "PITCH_COUNTS_ADD_PITCHER_OPTIONS_UPDATED",
  PITCH_COUNTS_PITCHER_ADDED: "PITCH_COUNTS_PITCHER_ADDED",
  PITCH_COUNTS_PITCHER_DELETED: "PITCH_COUNTS_PITCHER_DELETED",
  PITCH_COUNTS_EDIT_PLAYER_MODE_UPDATED: "PITCH_COUNTS_EDIT_PLAYER_MODE_UPDATED",
  PITCH_COUNTS_EDIT_PLAYER_PROFILE_ID_UPDATED: "PITCH_COUNTS_EDIT_PLAYER_PROFILE_ID_UPDATED",
  PITCH_COUNTS_EDIT_PLAYER_NEW_PROFILE_UPDATED: "PITCH_COUNTS_EDIT_PLAYER_NEW_PROFILE_UPDATED"
};

const createActions = memoize(dispatch => ({
  requestPitchCounts({ fedTeamId, tournamentType }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_REQUESTED,
      payload: { fedTeamId, tournamentType }
    });
  },
  fulfillPitchCountsRequest({ pitchCountData }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_REQUEST_FULFILLED,
      payload: { pitchCountData }
    });
  },
  failPitchCountsRequest({ error }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_REQUEST_FAILED,
      payload: { error }
    });
  },
  updatePitchCount({ pitchCount }) {
    dispatch({
      type: ActionTypes.PITCH_COUNT_UPDATED,
      payload: { pitchCount }
    });
  },
  discardPitchCountUpdates() {
    dispatch({
      type: ActionTypes.PITCH_COUNT_UPDATES_DISCARDED
    });
  },
  savePitchCounts({ fedTeamId, tournamentType }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_SAVE_REQUESTED,
      payload: { fedTeamId, tournamentType }
    });
  },
  fulfillPitchCountsSave({ pitchCounts }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_SAVE_FULFILLED,
      payload: { pitchCounts }
    });
  },
  failPitchCountsSave({ error }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_SAVE_FAILED,
      payload: { error }
    });
  },
  requestPitchCountPreview({ profileId, tournamentType }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PREVIEW_REQUESTED,
      payload: { profileId, tournamentType }
    });
  },
  fulfillPitchCountsPreview({ pitchCounts }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PREVIEW_FULFILLED,
      payload: { pitchCounts }
    });
  },
  failPitchCountsPreview({ error }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PREVIEW_FAILED,
      payload: { error }
    });
  },
  pitchCountsAddPlayerMode(addPlayerMode) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_ADD_PITCHER_MODE_UPDATED,
      payload: { addPlayerMode }
    });
  },
  requestProvisionalRoster({ tournamentType, fedTeamId }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUESTED,
      payload: { tournamentType, fedTeamId }
    });
  },
  fulfillProvisionalRosterRequest({ provisionalRoster }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FULFILLED,
      payload: { provisionalRoster }
    });
  },
  failProvisionalRosterRequest({ error }) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FAILED,
      payload: { error }
    });
  },
  updatePitchCountAddPitcherOptions(pitcherOptions) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_ADD_PITCHER_OPTIONS_UPDATED,
      payload: { pitcherOptions }
    });
  },
  addPitcherToPitchCounts(pitcher) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PITCHER_ADDED,
      payload: { pitcher }
    });
  },
  deletePitcherFromPitchCounts(profileId) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_PITCHER_DELETED,
      payload: { profileId }
    });
  },
  pitchCountsEditPlayerMode(editPlayerMode) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_EDIT_PITCHER_MODE_UPDATED,
      payload: { editPlayerMode }
    });
  },
  pitchCountsEditPlayerProfileId(editPlayerProfileId) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_EDIT_PLAYER_PROFILE_ID_UPDATED,
      payload: { editPlayerProfileId }
    });
  },
  pitchCountsEditPlayerNewProfile(editPlayerNewProfile) {
    dispatch({
      type: ActionTypes.PITCH_COUNTS_EDIT_PLAYER_NEW_PROFILE_UPDATED,
      payload: { editPlayerNewProfile }
    });
  }
}));

const indexByProfileIdAndDate = pitchCounts =>
  mapValues(
    groupBy(pitchCounts, ({ profileId }) => profileId),
    pitchCountsForProfile =>
      pitchCountsForProfile.reduce(
        (acc, pitchCount) => ({
          ...acc,
          [pitchCount.date]: pitchCount
        }),
        {}
      )
  );

const initialState = {
  tournamentType: null,
  tournamentDates: [],
  pitchers: [],
  persistedPitchers: [],
  pitchCountsByProfileIdAndDate: {},
  persistedPitchCountsByProfileIdAndDate: {},
  provisionalRoster: [],
  loaded: false,
  loading: false,
  saving: false,
  loadingError: null,
  previewLoadingError: null,
  savingError: null,
  addPlayerMode: false,
  pitcherSelectOptions: [],
  editPlayerMode: false,
  editPlayerProfileId: null
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ActionTypes.PITCH_COUNTS_REQUESTED:
      return {
        ...initialState,
        tournamentType: payload.tournamentType,
        loading: true
      };
    case ActionTypes.PITCH_COUNTS_REQUEST_FULFILLED: {
      const {
        pitchCountData: { tournamentDates, pitchers, pitchCounts }
      } = payload;
      const pitchCountsByProfileIdAndDate = indexByProfileIdAndDate(pitchCounts);

      return {
        ...state,
        tournamentDates,
        pitchers,
        persistedPitchers: pitchers,
        pitchCountsByProfileIdAndDate,
        persistedPitchCountsByProfileIdAndDate: pitchCountsByProfileIdAndDate,
        loading: false,
        loaded: true
      };
    }
    case ActionTypes.PITCH_COUNTS_REQUEST_FAILED: {
      const { error } = payload;

      return {
        ...state,
        loading: false,
        loadingError: error
      };
    }
    case ActionTypes.PITCH_COUNT_UPDATED: {
      const {
        pitchCount,
        pitchCount: { profileId, date }
      } = payload;
      const { pitchCountsByProfileIdAndDate } = state;
      const pitchCountsByDate = pitchCountsByProfileIdAndDate[profileId];

      return {
        ...state,
        pitchCountsByProfileIdAndDate: {
          ...pitchCountsByProfileIdAndDate,
          [profileId]: {
            ...pitchCountsByDate,
            [date]: pitchCount
          }
        }
      };
    }
    case ActionTypes.PITCH_COUNTS_PREVIEW_REQUESTED:
      return {
        ...state,
        previewLoadingError: null
      };
    case ActionTypes.PITCH_COUNTS_PREVIEW_FULFILLED: {
      const { pitchCounts } = payload;

      return {
        ...state,
        previewLoadingError: null,
        pitchCountsByProfileIdAndDate: {
          ...state.pitchCountsByProfileIdAndDate,
          ...indexByProfileIdAndDate(pitchCounts)
        }
      };
    }
    case ActionTypes.PITCH_COUNTS_PREVIEW_FAILED: {
      const { error } = payload;

      return {
        ...state,
        previewLoadingError: error
      };
    }
    case ActionTypes.PITCH_COUNT_UPDATES_DISCARDED:
      return {
        ...state,
        pitchers: state.persistedPitchers,
        pitchCountsByProfileIdAndDate: state.persistedPitchCountsByProfileIdAndDate,
        addPlayerMode: false
      };
    case ActionTypes.PITCH_COUNTS_SAVE_REQUESTED:
      return {
        ...state,
        saving: true
      };
    case ActionTypes.PITCH_COUNTS_SAVE_FULFILLED: {
      const { pitchCounts } = payload;
      const pitchCountsByProfileIdAndDate = indexByProfileIdAndDate(pitchCounts);

      return {
        ...state,
        pitchCountsByProfileIdAndDate,
        persistedPitchCountsByProfileIdAndDate: pitchCountsByProfileIdAndDate,
        saving: false
      };
    }
    case ActionTypes.PITCH_COUNTS_SAVE_FAILED: {
      const { error } = payload;

      return {
        ...state,
        saving: false,
        savingError: error
      };
    }
    case ActionTypes.PITCH_COUNTS_ADD_PITCHER_MODE_UPDATED: {
      const { addPlayerMode } = payload;
      return { ...state, addPlayerMode: addPlayerMode };
    }
    case ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUESTED:
      return {
        ...state,
        provisionalRoster: []
      };
    case ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FULFILLED: {
      const { provisionalRoster } = payload;

      return {
        ...state,
        provisionalRoster: provisionalRoster
      };
    }
    case ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUEST_FAILED: {
      const { error } = payload;

      return {
        ...state,
        loadingError: error
      };
    }
    case ActionTypes.PITCH_COUNTS_ADD_PITCHER_OPTIONS_UPDATED: {
      const { pitcherOptions } = payload;

      return { ...state, pitcherSelectOptions: pitcherOptions };
    }
    case ActionTypes.PITCH_COUNTS_PITCHER_ADDED: {
      const { pitcher } = payload;

      const updatedPitchers = [...state.pitchers, pitcher];
      const updatedPitcherSelectOptions = state.pitcherSelectOptions.filter(
        option => option.value.profileId !== pitcher.profileId
      );

      const updatedPitchCountsByProfileIdAndDate = {
        ...state.pitchCountsByProfileIdAndDate,
        [pitcher.profileId]: state.tournamentDates.reduce(
          (acc, date) => ({
            ...acc,
            [date]: { profileId: pitcher.profileId, date: date, pitches: 0, requiredToRest: false, valid: true }
          }),
          {}
        )
      };

      return {
        ...state,
        addPlayerMode: false,
        pitcherSelectOptions: updatedPitcherSelectOptions,
        pitchers: updatedPitchers,
        pitchCountsByProfileIdAndDate: updatedPitchCountsByProfileIdAndDate
      };
    }
    case ActionTypes.PITCH_COUNTS_PITCHER_DELETED: {
      const { profileId } = payload;

      const pitcherIdx = state.pitchers.findIndex(p => p.profileId === profileId);
      const pitcher = state.pitchers[pitcherIdx];
      // Remove pitcher from pitch count
      const updatedPitchers = [...state.pitchers];
      updatedPitchers.splice(pitcherIdx, 1);
      // If player is a provisional pitcher add back as a player option
      const updatedAddPlayerOpts = pitcher.isFinalRosterPitcher
        ? [...state.pitcherSelectOptions]
        : [...state.pitcherSelectOptions, { label: pitcher.formattedName, value: pitcher }];

      const updatedPitchCountsByProfileIdAndDate = { ...state.pitchCountsByProfileIdAndDate };
      delete updatedPitchCountsByProfileIdAndDate[profileId];

      return {
        ...state,
        pitcherSelectOptions: _.sortBy(updatedAddPlayerOpts, "label"),
        pitchCountsByProfileIdAndDate: updatedPitchCountsByProfileIdAndDate,
        pitchers: updatedPitchers
      };
    }
    case ActionTypes.PITCH_COUNTS_EDIT_PITCHER_MODE_UPDATED: {
      const { editPlayerMode } = payload;
      return { ...state, editPlayerMode: editPlayerMode };
    }
    case ActionTypes.PITCH_COUNTS_EDIT_PLAYER_PROFILE_ID_UPDATED: {
      const { editPlayerProfileId } = payload;
      return { ...state, editPlayerProfileId: editPlayerProfileId };
    }
    case ActionTypes.PITCH_COUNTS_EDIT_PLAYER_NEW_PROFILE_UPDATED: {
      const { editPlayerNewProfile } = payload;

      const pitchCountsForNewPitcher = { ...state.pitchCountsByProfileIdAndDate[state.editPlayerProfileId] };
      Object.values(pitchCountsForNewPitcher).forEach(pitchCount => {
        pitchCount.profileId = editPlayerNewProfile.profileId;
      });

      const updatedPitchCount = {
        ...state.pitchCountsByProfileIdAndDate,
        [editPlayerNewProfile.profileId]: pitchCountsForNewPitcher
      };
      delete updatedPitchCount.editPlayerProfileId;

      const updatedPitchers = [...state.pitchers, editPlayerNewProfile].filter(
        p => p.profileId !== state.editPlayerProfileId
      );

      const updatedAddPlayerOpts = editPlayerNewProfile?.isFinalRosterPitcher
        ? [...state.pitcherSelectOptions]
        : [...state.pitcherSelectOptions, { label: editPlayerNewProfile.formattedName, value: editPlayerNewProfile }];

      return {
        ...state,
        editPlayerMode: false,
        editPlayerProfileId: null,
        pitchCountsByProfileIdAndDate: updatedPitchCount,
        pitcherSelectOptions: _.sortBy(updatedAddPlayerOpts, "label"),
        pitchers: updatedPitchers
      };
    }
    default:
      return state;
  }
};

// Visible for testing
export const VALIDATE_PITCH_COUNT_UPDATE_DEBOUNCE_MILLIS = 500;

const middleware = dispatch => {
  const {
    fulfillPitchCountsRequest,
    failPitchCountsRequest,
    fulfillPitchCountsSave,
    failPitchCountsSave,
    requestPitchCountPreview,
    fulfillPitchCountsPreview,
    failPitchCountsPreview,
    fulfillProvisionalRosterRequest,
    failProvisionalRosterRequest
  } = createActions(dispatch);

  let _mostRecentPitchCountsRequestRef = null;
  const runIfMostRecentPitchCountsRequest = (ref, callback) => {
    _mostRecentPitchCountsRequestRef = ref;
    return (...args) => {
      if (ref === _mostRecentPitchCountsRequestRef) {
        callback(...args);
      }
    };
  };

  const _mostRecentPitchCountPreviewRequestByProfileId = {};
  const runIfMostRecentPitchCountPreviewRequest = (profileId, ref, callback) => {
    _mostRecentPitchCountPreviewRequestByProfileId[profileId] = ref;
    return (...args) => {
      if (ref === _mostRecentPitchCountPreviewRequestByProfileId[profileId]) {
        callback(...args);
      }
    };
  };

  let _mostRecentProvisionalRosterRequestRef = null;
  const runIfMostRecentProvisionalRosterRequestRef = (ref, callback) => {
    _mostRecentProvisionalRosterRequestRef = ref;
    return (...args) => {
      if (ref === _mostRecentProvisionalRosterRequestRef) {
        callback(...args);
      }
    };
  };

  // TODO: only run if most recent request
  const onPitchCountsRequestFulfilled = pitchCountData => {
    fulfillPitchCountsRequest({ pitchCountData });
  };
  const onPitchCountsRequestFailed = error => {
    failPitchCountsRequest({ error });
  };

  const onProvisionalRosterRequestFulfilled = provisionalRoster => {
    fulfillProvisionalRosterRequest({ provisionalRoster });
  };
  const onProvisionalRosterRequestFailed = error => {
    failProvisionalRosterRequest({ error });
  };

  const _previewRequestTimeoutByProfileId = {};
  const debouncePreviewRequest = ({ profileId, tournamentType }) => {
    const previousTimeout = _previewRequestTimeoutByProfileId[profileId];

    if (previousTimeout) {
      clearTimeout(previousTimeout);
    }

    _previewRequestTimeoutByProfileId[profileId] = setTimeout(
      () => requestPitchCountPreview({ profileId, tournamentType }),
      VALIDATE_PITCH_COUNT_UPDATE_DEBOUNCE_MILLIS
    );
  };
  const onPitchCountsPreviewFulfilled = ({ pitchCounts }) => {
    fulfillPitchCountsPreview({ pitchCounts });
  };
  const onPitchCountsPreviewFailed = error => {
    failPitchCountsPreview({ error });
  };

  const onPitchCountsSaveFulfilled = ({ pitchCounts }) => {
    fulfillPitchCountsSave({ pitchCounts });
  };
  const onPitchCountsSaveFailed = error => {
    failPitchCountsSave({ error });
  };

  return (prevState, nextState, { type, payload }) => {
    switch (type) {
      case ActionTypes.PITCH_COUNTS_REQUESTED: {
        const { fedTeamId, tournamentType } = payload;

        PitchTrackerApi.getPitchCounts({ fedTeamId, tournamentType }).then(
          runIfMostRecentPitchCountsRequest(payload, onPitchCountsRequestFulfilled),
          runIfMostRecentPitchCountsRequest(payload, onPitchCountsRequestFailed)
        );
        break;
      }
      case ActionTypes.PITCH_COUNT_UPDATED: {
        const { profileId } = payload.pitchCount;
        const { tournamentType } = nextState;

        debouncePreviewRequest({ profileId, tournamentType });
        break;
      }
      case ActionTypes.PITCH_COUNTS_PREVIEW_REQUESTED: {
        const { profileId, tournamentType } = payload;
        const pitchCounts = getPitchCountsByProfileId(nextState)[profileId];

        PitchTrackerApi.previewPitchCounts({ profileId, tournamentType, pitchCounts }).then(
          runIfMostRecentPitchCountPreviewRequest(profileId, payload, onPitchCountsPreviewFulfilled),
          runIfMostRecentPitchCountPreviewRequest(profileId, payload, onPitchCountsPreviewFailed)
        );
        break;
      }
      case ActionTypes.PITCH_COUNTS_SAVE_REQUESTED: {
        const { fedTeamId, tournamentType } = payload;
        const pitchCounts = getPitchCounts(prevState);

        PitchTrackerApi.savePitchCounts({ fedTeamId, tournamentType, pitchCounts }).then(
          onPitchCountsSaveFulfilled,
          onPitchCountsSaveFailed
        );
        break;
      }
      case ActionTypes.PITCH_COUNTS_PROVISIONAL_ROSTER_REQUESTED: {
        const { tournamentType, fedTeamId } = payload;

        const api = tournamentType === QUALIFYING ? QualifyingRosterApi : MainRosterApi;
        api
          .getProvisionalRoster(fedTeamId)
          .then(
            runIfMostRecentProvisionalRosterRequestRef(payload, onProvisionalRosterRequestFulfilled),
            runIfMostRecentProvisionalRosterRequestRef(payload, onProvisionalRosterRequestFailed)
          );
        break;
      }
      default:
        break;
    }
  };
};

const middlewares = [middleware];

const PitchTrackerContextProvider = ({ children }) => {
  const [state, dispatch] = useEnhancedReducer(reducer, initialState, middlewares);
  const actions = createActions(dispatch);

  return <PitchTrackerContext.Provider value={{ state, actions }}>{children}</PitchTrackerContext.Provider>;
};

PitchTrackerContextProvider.propTypes = {
  children: PropTypes.node
};

export default PitchTrackerContextProvider;
