import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled, { ThemeContext } from "styled-components";
import { Icon } from "best-common-react";

const Header = styled.div.attrs(() => ({
  className: "d-flex mb-2"
}))`
  &&& {
    border-bottom: 1px solid ${props => props.styles.borderColor};
  }
`;

const Title = styled.div.attrs(() => ({
  className: "ml-2"
}))`
  &&& {
    color: ${props => props.styles.titleColor};
  }
`;

const SubTitle = styled.div.attrs(() => ({
  className: "wbc-collapse-subtitle"
}))`
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 11px;
  align-self: center;
  color: ${props => props.theme["dark-grey"]};
`;

const Export = styled.i.attrs(() => ({
  className: "fas fa-download"
}))`
  cursor: pointer;
  float: right;
  color: ${props => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const Collapse = ({
  startsOpen,
  title,
  subTitle,
  children,
  styles,
  className,
  exportable,
  exportFn,
  openCloseCallback
}) => {
  const [isOpen, setIsOpen] = useState(startsOpen);
  const Theme = useContext(ThemeContext);
  const DefaultStyle = {
    borderColor: Theme["primary-blue"],
    titleColor: Theme["primary-blue"]
  };
  const CompStyles = { ...DefaultStyle, ...styles };

  const clickedCollapse = () => {
    const newState = !isOpen; // need this because updating state in react is not a guaranteed synchronous call

    setIsOpen(!isOpen);
    if (openCloseCallback) {
      openCloseCallback(newState);
    }
  };

  return (
    <div className={className}>
      <Header className="d-flex mb-2 flex-fill" styles={CompStyles}>
        <Icon iconName={`fa-${isOpen ? "minus" : "plus"}-circle`} onClick={clickedCollapse} />
        <Title onClick={clickedCollapse} styles={CompStyles}>
          {title}
        </Title>
        {!subTitle ? null : <SubTitle>{subTitle}</SubTitle>}
        <div className={"ml-auto"}>{exportable ? <Export onClick={exportFn} /> : null}</div>
      </Header>
      {isOpen && children}
    </div>
  );
};

Collapse.defaultProps = {
  startsOpen: true,
  styles: {},
  exportable: false,
  exportFn: () => {}
};

Collapse.propTypes = {
  /** title of the collapse */
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  /** subtitle of the collapse */
  subTitle: PropTypes.string,
  /** children of the collapse */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Should the collapse start open */
  startsOpen: PropTypes.bool,
  /** className */
  className: PropTypes.string,
  /** styles override */
  styles: PropTypes.object,
  exportable: PropTypes.bool,
  exportFn: PropTypes.func,
  openCloseCallback: PropTypes.func
};

export default Collapse;
