import React, { useCallback, useContext, useEffect } from "react";
import { BestSelect } from "best-common-react";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import { findSelectedTeamByCountry } from "../../utils/FedTeamUtils";

const EligibilityTeamSelect = () => {
  // context(s)
  const selectedTeamContext = useContext(SelectedTeamContext);
  const playerEligibilityContext = useContext(PlayerEligibilityContext);

  // variable(s)
  const { team, teams } = playerEligibilityContext.state;
  const { dispatch } = playerEligibilityContext;

  const setTeam = useCallback(
    ({ value, label, teamId, code }) => {
      dispatch({
        type: "setTeam",
        value,
        label,
        teamId,
        code
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: "setTeams",
      teams: selectedTeamContext.state.teams
    });
  }, [dispatch, selectedTeamContext.state.teams]);

  // since fed teams are different for each year, when the year changes, the teams change
  // so we select the team for the previously selected country
  useEffect(() => {
    const teamForCountry = findSelectedTeamByCountry(teams, team);
    if (teamForCountry && teamForCountry.teamId !== team.teamId) {
      setTeam(teamForCountry);
    }
  }, [setTeam, teams, team]);

  return (
    <div className="form-group">
      <BestSelect name="teamDropdown" value={team} options={teams} onChange={setTeam} />
    </div>
  );
};

export default EligibilityTeamSelect;
