import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestTitle } from "best-common-react";
import DocumentsSection from "../documents/DocumentsSection";
import { FormTitleWrapper } from "./shared/ProfileComponents";
import ProfileContext from "../../contexts/ProfileContext";
import DocumentsContext from "../../contexts/DocumentsContext";

const DownloadAll = styled.i.attrs(() => ({
  className: "fas fa-download"
}))`
  cursor: pointer;
  float: right;
  color: ${props => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const WarningText = styled.div.attrs(() => ({
  className: "mb-5"
}))`
  &&& {
    color: ${props => props.theme["dark-grey"]};
  }
`;

const ProfileDocumentsSection = ({ isStaff }) => {
  const profileContext = useContext(ProfileContext);
  const {
    actions: { downloadAllDocuments }
  } = useContext(DocumentsContext);

  useEffect(() => {
    // TODO: Reset on cancel
  }, [profileContext.state]);

  return (
    <div>
      <BestTitle>
        <div className="d-flex justify-content-between">
          <FormTitleWrapper>{isStaff ? "Staff" : "Player"} Documents</FormTitleWrapper>
          <DownloadAll onClick={downloadAllDocuments} />
        </div>
      </BestTitle>
      <div>
        <DocumentsSection />
      </div>
    </div>
  );
};

ProfileDocumentsSection.propTypes = {
  isStaff: PropTypes.bool.isRequired
};

export default ProfileDocumentsSection;
