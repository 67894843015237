import React, { useContext, useEffect, useState } from "react";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import EligibilityListApi from "../../httpClients/EligibilityListApi";
import { AlertConstants } from "best-common-react";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { useAlert } from "../hooks/useAlert";

const DenyEligibilityModalFooter = () => {
  // context(s) && state
  const playerEligibilityContext = useContext(PlayerEligibilityContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const globalModalContext = useContext(GlobalModalContext);
  const [denyDisabled, setDenyDisabled] = useState(true);
  const showAlert = useAlert();

  // variable(s)
  const { content } = globalModalContext.state;
  const { playerProfileToDenyReason, team } = playerEligibilityContext.state;

  // function(s)
  const denyPlayerEligibility = () => {
    EligibilityListApi.denyProfileEligibility(
      selectedYear,
      content.teamId,
      content.profileId,
      playerProfileToDenyReason
    )
      .then(() => {
        globalModalContext.dispatch({
          type: "closeModal"
        });
        playerEligibilityContext.dispatch({ type: "setPlayerProfileToDenyReason", reason: null });
        showAlert("Denied");
        EligibilityListApi.getPlayerEligibilityList(selectedYear, team.teamId).then(response => {
          playerEligibilityContext.dispatch({ type: "setPlayers", players: response });
        });
      })
      .catch(({ response }) => showAlert(response.data.message, AlertConstants.TYPES.DANGER));
  };

  // effect(s)
  useEffect(() => {
    setDenyDisabled(!playerProfileToDenyReason || playerProfileToDenyReason.length < 1);
  }, [setDenyDisabled, playerProfileToDenyReason]);

  return (
    <div>
      <div className={"d-flex"}>
        <WBCPrimaryButton
          width={84}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            denyPlayerEligibility();
          }}
          disabled={denyDisabled}
        >
          Deny
        </WBCPrimaryButton>

        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
              playerEligibilityContext.dispatch({ type: "setPlayerProfileToDenyReason", reason: null });
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

DenyEligibilityModalFooter.displayName = "FooterContent";

export default DenyEligibilityModalFooter;
