import React from "react";
import TournamentComponent from "../components/tournament/TournamentComponent";

const TournamentContainer = () => (
  <div>
    <TournamentComponent />
  </div>
);

export default TournamentContainer;
