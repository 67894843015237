import React, { useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HorizontalNav from "../navigation/HorizontalNav";
import MLBPlayerSummary from "./mlb-player-summary/MLBPlayerSummary";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import AuthContext from "../../contexts/AuthContext";
import { MLBPlayersDashboardTabSwitcher } from "./MLBPlayersDashboardStyles";
import MLBPlayerSummaryContext from "../../contexts/MLBPlayerSummaryContext";
import LoadingContext from "../../contexts/LoadingContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { addPageBreak, cloneHtmlElementForPdfExport, exportHtmlElementToPdf } from "../../utils/PdfExportUtil";
import ButtonHolder from "../elements/ButtonHolder";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";
import MLBPlayerRequest from "./mlb-player-requests/MLBPlayerRequest";
import ChronicConditions from "./chronic-conditions/ChronicConditions";

const MLBPlayersDashboard = withRouter(() => {
  const { isBOC, features } = useContext(AuthContext).state;
  const { dispatch: globalFedDropdownDispatch } = useContext(GlobalFedDropdownContext);
  const { isLoading, setLoading } = useContext(LoadingContext);
  const { positionFilter, resetState, rosterListFilter } = useContext(MLBPlayerSummaryContext);
  const { selectedYear } = useContext(WBCYearsContext);

  const chronicConditionsEnabled = features["Chronic Conditions"];

  const MLBPlayerSummaryTab = {
    name: "MLB Player Summary",
    component: MLBPlayerSummary,
    id: "summary"
  };

  const RequestsTab = {
    name: "Requests",
    component: MLBPlayerRequest,
    id: "requests"
  };

  const ChronicConditionsTab = {
    name: "Chronic Conditions",
    component: ChronicConditions,
    id: "chronic-conditions"
  };

  const exportMLBPlayersByContainer = async () => {
    const container = cloneHtmlElementForPdfExport("mlb-players-by-container");

    // When exporting to PDF these elements are covering up the toggle buttons. Set them to be transparent
    const sortByFilter = container.querySelector("#sort-player-summaries-by");
    if (sortByFilter) {
      sortByFilter.setAttribute("style", "background-color: transparent;");
    }
    const sortByToggleBtns = container.querySelector(".wbc-toggle-btns");
    if (sortByToggleBtns) {
      sortByToggleBtns.setAttribute("style", "background-color: transparent;");
    }

    addPageBreak(container, ".player-count-collapse", "page-break-after: always;");

    if (container) {
      const exportOpts = {
        footer: {
          includePageNumbers: true,
          includeExportedDateTime: true
        },
        html2pdf: {
          filename: `${selectedYear}_${rosterListFilter.label}_${positionFilter.label}_MLB_Players.pdf`.replaceAll(
            " ",
            "-"
          ),
          jsPDF: {
            orientation: "landscape"
          }
        }
      };
      exportHtmlElementToPdf(container, exportOpts).then(() => {
        setLoading(false);
      });
    }
  };

  const handleExportClick = () => {
    setLoading(true);
    // This is a workaround for isLoading not being set to true when generating the PDF. By calling the function
    // in a setTimeout our components will receive the updated isLoading state and re-render during the PDF generation.
    setTimeout(() => {
      exportMLBPlayersByContainer();
    }, 0);
  };

  useEffect(() => {
    return () => {
      resetState();
    }; // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Hide Global Fed Team dropdown
  useEffect(() => {
    globalFedDropdownDispatch({ type: "setShowingState", showingState: false });
  }, [globalFedDropdownDispatch]);

  return (
    <div>
      <HorizontalNav title="MLB Affiliated Players">
        <ButtonHolder>
          <WBCPrimaryOutlinedButton className="btn-md" disabled={isLoading} onClick={handleExportClick}>
            Export
          </WBCPrimaryOutlinedButton>
        </ButtonHolder>
      </HorizontalNav>
      <div>
        <MLBPlayersDashboardTabSwitcher
          hasPadding={false}
          hideOnMobile={false}
          tabs={
            isBOC && chronicConditionsEnabled
              ? [MLBPlayerSummaryTab, RequestsTab, ChronicConditionsTab]
              : isBOC
              ? [MLBPlayerSummaryTab, RequestsTab]
              : [RequestsTab]
          }
        />
      </div>
    </div>
  );
});

export default MLBPlayersDashboard;
