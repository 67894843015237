import React, { useContext, useEffect, useState, useCallback } from "react";
import HorizontalNav from "../navigation/HorizontalNav";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { useAlert } from "../hooks/useAlert";
import AuthContext from "../../contexts/AuthContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import ClubCommDashboardContext from "../../contexts/ClubCommDashboardContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import RouteConstants from "../../constants/RouteConstants";
import MobileFullWidth from "../elements/MobileFullWidth";
import ClubCommYearSelect from "./ClubCommSelect/ClubCommYearSelect";
import ClubCommStatusSelect from "./ClubCommSelect/ClubCommStatusSelect";
import ClubCommOrgSelect from "./ClubCommSelect/ClubCommOrgSelect";
import ClubCommTable from "./ClubCommTable";
import ClubCommOppSelect from "./ClubCommSelect/ClubCommOppSelect";
import ClubCommFedSelect from "./ClubCommSelect/ClubCommFedSelect";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import EventReportApi from "../../httpClients/EventReportApi";
import { useClearSectionsOpen } from "../hooks/useClearSectionsOpen";
import ButtonHolder from "../elements/ButtonHolder";
import FeatureToggler from "../elements/FeatureToggler";
import FeatureConstants from "../../constants/FeatureConstants";
import StatsBatchApi from "../../httpClients/StatsBatchApi";

const Content = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 80vh;
`;

const QuickSearchContainer = styled(MobileFullWidth).attrs(() => ({
  className: "mr-3"
}))`
  width: 286px;
`;

const ButtonContainer = styled.div`
  margin-top: 6px;
  display: flex;
  float: right;
`;

const ClubCommDashboard = withRouter(({ history }) => {
  // context(s)
  const authContext = useContext(AuthContext);
  const globalModalContext = useContext(GlobalModalContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const clubCommDbContext = useContext(ClubCommDashboardContext);
  const { selectedYear, currentYear } = useContext(WBCYearsContext);
  const [runningStatsBatch, setRunningStatsBatch] = useState(false);

  // variable(s)
  const { dispatch: globalModalDispatch } = globalModalContext;
  const { isBOC, isBOCadmin, isFedAdmin, isFedUser, isTeamCoordinator } = authContext.state;
  const { dispatch: clubCommDispatch } = clubCommDbContext;
  const { status, org } = clubCommDbContext.state;
  const {
    state: { team },
    dispatch: selectedTeamDispatch
  } = selectedTeamContext;
  const emptyEventReport = {
    eventDate: new Date(),
    eventType: {},
    eventTypeId: 0
  };
  const showAlert = useAlert();
  const year = isBOC ? selectedYear : currentYear;

  // effect hooks
  useClearSectionsOpen();

  // function(s)
  const addEventReport = eventReportToAdd => {
    // prepare the event report for post-ing
    eventReportToAdd.wbcYear = year;
    if (!eventReportToAdd.teamId) {
      eventReportToAdd.teamId = team.teamId;
    }

    EventReportApi.createEventReport(eventReportToAdd, eventReportToAdd.teamId)
      .then(data => {
        if (data && data.eventReportId && data.teamId) {
          setSelectedTeam(team, eventReportToAdd);
          const url = RouteConstants.EVENT_REPORT.replace(":eventReportId", data.eventReportId).replace(
            ":teamId",
            data.teamId
          );
          history.push(url);
        } else {
          showAlert(`Error while creating Non-Game Day report`, "danger");
        }
      })
      .catch(() => {
        showAlert(`Error while creating Non-Game Day report`, "danger");
      });
  };

  const setSelectedTeam = (currSelectedTeam, eventReport) => {
    if (eventReport && eventReport.selectedTeam && currSelectedTeam.teamId !== eventReport.selectedTeam.teamId) {
      selectedTeamDispatch({
        type: "setTeam",
        value: eventReport.selectedTeam.value,
        label: eventReport.selectedTeam.label,
        teamId: eventReport.selectedTeam.teamId,
        finalizedStatus: eventReport.selectedTeam.finalizedStatus,
        code: eventReport.selectedTeam.code
      });
    }
  };

  const loadEventReports = useCallback(() => {
    EventReportApi.getEventReports(year, team.teamId, status.value, org.value)
      .then(response => {
        if (response.length > 0) {
          const opponents = [];
          const allOpponents = { label: "All Opponents", value: 0 };
          response.forEach(report => {
            if (report.opponent) {
              const opp = { label: report.opponent, value: report.opponent, labelLower: report.opponent.toLowerCase() };
              if (!_.find(opponents, { labelLower: opp.label.toLowerCase() })) {
                opponents.push(opp);
              }
            }
          });
          const opponentsSorted = _.sortBy(opponents, [o => o.label.toLowerCase()]);
          opponentsSorted.unshift(allOpponents);
          clubCommDispatch({
            type: "setOpponents",
            opponents: opponentsSorted
          });
        }
      })
      .catch(() => {
        //error
      });
  }, [clubCommDispatch, org.value, status.value, team.teamId, year]);

  const runStatsBatch = () => {
    if (!runningStatsBatch) {
      setRunningStatsBatch(true);

      StatsBatchApi.runStatsBatch()
        .then(() => {
          showAlert(`Stats batch complete`);
          loadEventReports();

          setRunningStatsBatch(false);
        })
        .catch(() => {
          showAlert(`Error while running stats batch`, "danger");

          setRunningStatsBatch(false);
        });
    }
  };

  // Only load opponent options for Fed Team Admin
  useEffect(() => {
    const canLoadReports = isFedUser || isTeamCoordinator;
    const dataExists = year && team && team.teamId && status && org;
    if (canLoadReports && dataExists) {
      loadEventReports();
    }
  }, [year, team, clubCommDispatch, isFedUser, org, status, loadEventReports]);

  return (
    <div>
      <HorizontalNav title="Club Communication">
        <ButtonHolder>
          {isBOCadmin ? (
            <FeatureToggler feature={FeatureConstants.CLUB_COMM}>
              <WBCPrimaryOutlinedButton
                className={"btn-md"}
                onClick={() => {
                  globalModalDispatch({
                    type: "openAreYouSureModal",
                    discardChangesFn: runStatsBatch,
                    discardChangesButtonText: "Run",
                    content: "Are you sure you want to run the stats batch?"
                  });
                }}
              >
                Run Batch
              </WBCPrimaryOutlinedButton>
            </FeatureToggler>
          ) : null}
        </ButtonHolder>
      </HorizontalNav>
      <Content>
        <div className="d-flex flex-wrap">
          {isBOC && (
            <QuickSearchContainer>
              <ClubCommYearSelect />
            </QuickSearchContainer>
          )}
          <QuickSearchContainer>
            {isBOC || isFedAdmin ? (
              <ClubCommFedSelect />
            ) : isFedUser || isTeamCoordinator ? (
              <ClubCommOppSelect />
            ) : null}
          </QuickSearchContainer>
          <QuickSearchContainer>
            <ClubCommStatusSelect />
          </QuickSearchContainer>
          <QuickSearchContainer>
            <ClubCommOrgSelect />
          </QuickSearchContainer>
        </div>
        <ClubCommTable />
        <ButtonContainer>
          {isBOCadmin || isFedAdmin ? (
            <WBCPrimaryOutlinedButton
              width={150}
              onClick={event => {
                event.stopPropagation();
                globalModalDispatch({
                  type: "openAddNonGameDayModal",
                  content: {},
                  addEventReportFn: addEventReport,
                  eventReport: emptyEventReport
                });
              }}
            >
              Add Non Game-Day
            </WBCPrimaryOutlinedButton>
          ) : null}
        </ButtonContainer>
      </Content>
    </div>
  );
});

export default ClubCommDashboard;
