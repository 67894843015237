import React, { useReducer } from "react";
import PropTypes from "prop-types";
import MobileNavContext from "../contexts/MobileNavContext";

const initialState = {
  mobileNavOpen: false,
  toggleMobileNav: () => {}
};

const MobileNavContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_MOBILE_NAV":
        return { ...state, mobileNavOpen: action.mobileNavOpen };
      case "SET_MOBILE_NAV_FUNC":
        return { ...state, toggleMobileNav: action.toggleMobileNav };
      case "RESET_MOBILE_NAV":
        return initialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <MobileNavContext.Provider value={{ state, dispatch }}>{children}</MobileNavContext.Provider>;
};

MobileNavContextProvider.propTypes = {
  children: PropTypes.array
};

export default MobileNavContextProvider;
