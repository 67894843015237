export const reorder = (list, startIndex, endIndex) => {
  const result = [...list];
  const [removed] = result.splice(startIndex, 1);

  if (removed) {
    result.splice(endIndex, 0, removed);
  }

  return result;
};

export const addToList = (list, item, index = 0) => {
  const result = [...list];
  if (!index) {
    result.unshift(item);
  } else {
    result.splice(index, 0, item);
  }

  return result;
};

export const removeFromList = (list, index = 0) => {
  const result = [...list];
  result.splice(index, 1);
  return result;
};
