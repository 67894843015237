import React, { useContext, useCallback } from "react";
import styled from "styled-components";
import Select from "react-select";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import AuthContext from "../../contexts/AuthContext";
import { useBOCdropdown } from "../hooks/useBOCdropdown";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { openUnsavedChangesModal } from "./UnsavedChangesModal";
import FlagHolder from "../elements/WBCFlagHolder";
import MobileFullWidth from "../elements/MobileFullWidth";
import { isEmpty } from "../../utils/StringUtils";

const CountryNameDisplay = styled.div`
  &&& {
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    color: ${props => props.theme["dark-grey"]};
  }
`;

const StyledSelect = styled(Select).attrs(() => ({
  className: "mb-3 text-left"
}))`
  z-index: 1000;
`;

const FlagAndDropdownStyle = styled.div.attrs(() => ({
  className: "d-flex ml-3 mr-3 pt-3 pb-0 align-self-center"
}))``;

const SelectContainer = styled(MobileFullWidth)`
  min-width: 300px;
`;

const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

const WBCTeamSelect = () => {
  // context(s)
  const authContext = useContext(AuthContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const globalFedDropdownContext = useContext(GlobalFedDropdownContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const globalModalContext = useContext(GlobalModalContext);

  // variable(s)
  const { dropdownState } = globalFedDropdownContext.state;
  const { onSave, cleanForm } = tournamentFormContext.state;
  const { team: selectedTeam, teams } = selectedTeamContext.state;
  const { loggedIn, isBOC } = authContext.state;

  const { dispatch: selectedDispatch } = selectedTeamContext;

  const team =
    loggedIn && !isBOC
      ? selectedTeam && selectedTeam.label
        ? selectedTeam
        : teams[0]
      : teams.find(t => t.teamId === selectedTeam.teamId);

  // function(s)
  const setSelectedTeam = useCallback(
    selection => {
      selectedDispatch({
        type: "setTeam",
        value: selection.value,
        label: selection.label,
        teamId: selection.teamId,
        finalizedStatus: selection.finalizedStatus,
        code: selection.code
      });
    },
    [selectedDispatch]
  );

  const onSaveChanges = useCallback(
    selection => {
      onSave().then(() => {
        setSelectedTeam(selection);
        cleanForm();
      });
    },
    [onSave, cleanForm, setSelectedTeam]
  );

  const onDiscardChanges = useCallback(
    selection => {
      setSelectedTeam(selection);
      cleanForm();
    },
    [cleanForm, setSelectedTeam]
  );

  // effect(s)
  useBOCdropdown();

  return (
    <FlagAndDropdownStyle>
      {team && !isEmpty(team.code) ? <FlagHolder src={`${FLAG_URL}/${team.code}.svg`} /> : <></>}
      <SelectContainer>
        {dropdownState ? (
          <StyledSelect
            value={team}
            options={teams}
            onChange={selection => {
              if (tournamentFormContext.state.formDirtyBool) {
                openUnsavedChangesModal(globalModalContext, onSaveChanges, selection, onDiscardChanges, selection);
              } else {
                setSelectedTeam(selection);
              }
            }}
            editable
          />
        ) : (
          <div className="mb-2">{team ? <CountryNameDisplay>{team.label}</CountryNameDisplay> : null}</div>
        )}
      </SelectContainer>
    </FlagAndDropdownStyle>
  );
};

export default WBCTeamSelect;
