import React, { useReducer } from "react";
import PropTypes from "prop-types";
import PlayerReportModalContext from "../contexts/PlayerReportModalContext";
import { sortPlayers } from "../components/elements/SortPlayersHelper";

const initialState = {
  isOpen: false,
  content: null,
  currentIndex: null,
  prevIndex: null,
  nextIndex: null,
  currentEventPlayer: null,
  currentEventPlayerInfo: null,
  eventPlayers: [],
  eventPlayersInfo: [],
  children: null,
  fedTeamId: -1,
  formDirty: false,
  sortFilters: {
    col: "playerName",
    dir: "ASC"
  },
  reportById: null,
  reportByLabel: ""
};

const stateWithEventPlayers = (state, current) => {
  return {
    ...state,
    eventPlayers: Object.assign([], state.eventPlayers, { [state.currentIndex]: current })
  };
};

const stateWithEventPlayersDirtyForm = (state, current) => {
  return {
    ...state,
    eventPlayers: Object.assign([], state.eventPlayers, { [state.currentIndex]: current }),
    formDirty: true
  };
};

const stateWithSideWorkToggle = (state, sideWorkProperty, toggle) => {
  const current = state.eventPlayers[state.currentIndex];
  if (!current.sideWork) current.sideWork = {};
  current.sideWork = { ...current.sideWork, [sideWorkProperty]: toggle };

  return stateWithEventPlayers(state, current);
};

const stateWithPlayerComments = (state, commentsProperty, comments) => {
  const current = state.eventPlayers[state.currentIndex];
  if (!current.comments) current.comments = {};
  current.comments[commentsProperty] = comments;

  return stateWithEventPlayers(state, current);
};

const stateWithStolenBases = (state, stolenBasesProperty, value) => {
  const current = state.eventPlayers[state.currentIndex];
  if (!current.stolenBases) current.stolenBases = {};
  current.stolenBases = { ...current.stolenBases, [stolenBasesProperty]: value };

  return stateWithEventPlayers(state, current);
};

const initializeBreakout = player => {
  if (!player.breakout || !player.breakout.fastball) {
    player.breakout = getBreakoutTemplate();
  }
  return player;
};

const getBreakoutTemplate = () => ({
  fastball: {},
  curve: {},
  slider: {},
  change: {},
  other: {},
  calculated: {}
});

const reducer = (state, action) => {
  let current = null;
  switch (action.type) {
    case "openPlayerReportModal":
      return {
        ...state,
        isOpen: true,
        children: action.children,
        content: action.content
      };
    case "setEventPlayersInfo":
      return {
        ...state,
        eventPlayersInfo: sortPlayers(state.sortFilters.col, state.sortFilters.dir, action.eventPlayersInfo)
      };
    case "setEventPlayers":
      return {
        ...state,
        eventPlayers: action.eventPlayers,
        currentEventPlayerInfo: action.currentEventPlayerInfo,
        currentIndex: action.currentIndex,
        prevIndex: action.currentIndex - 1,
        nextIndex: action.currentIndex + 1,
        fedTeamId: action.fedTeamId
      };
    case "setNavPlayers":
      return {
        ...state,
        content: action.currentPlayer,
        currentIndex: action.currentIndex,
        prevIndex: action.currentIndex - 1,
        nextIndex: action.currentIndex + 1,
        children: action.children
      };
    case "setSortFilters":
      return {
        ...state,
        sortFilters: action.sortFilters,
        eventReport: {
          ...state.eventReport,
          eventPlayers: sortPlayers(action.sortFilters.col, action.sortFilters.dir, state.eventPlayers)
        }
      };
    case "resetSortFilters":
      return {
        ...state,
        sortFilters: initialState.sortFilters,
        eventReport: {
          ...state.eventReport,
          eventPlayers: sortPlayers(initialState.sortFilters.col, initialState.sortFilters.dir, state.eventPlayers)
        }
      };
    case "closeModal":
      return {
        ...state,
        isOpen: false,
        content: null
      };
    case "toggleWeightWork":
      return stateWithSideWorkToggle(state, "weightWork", action.toggle);
    case "toggleConditioning":
      return stateWithSideWorkToggle(state, "conditioning", action.toggle);
    case "toggleWarmUps":
      return stateWithSideWorkToggle(state, "warmUps", action.toggle);
    case "toggleOther":
      return stateWithSideWorkToggle(state, "other", action.toggle);
    case "setPosition":
      current = state.eventPlayers[state.currentIndex];
      current.positionId = action.positionId;
      if (current.playerType !== action.playerType) {
        // TODO: Other fields that need to be cleared
        current.comments = {};
        current.stolenBases = {};
        current.throwsToSecond = [];
        current.completed = false;
        current.breakout = getBreakoutTemplate();
      }
      current.playerType = action.playerType;

      return {
        ...state,
        eventPlayers: Object.assign([], state.eventPlayers, { [state.currentIndex]: current }),
        children: action.children
      };
    case "setOffComments":
      return stateWithPlayerComments(state, "offensive", action.offensive);
    case "setDefComments":
      return stateWithPlayerComments(state, "defensive", action.defensive);
    case "setSideWorkComments":
      current = state.eventPlayers[state.currentIndex];
      if (!current.sideWork) current.sideWork = {};
      current.sideWork = { ...current.sideWork, comments: action.comments };

      return stateWithEventPlayers(state, current);
    case "setInningsCaught":
      return stateWithStolenBases(state, "inningsCaught", action.inningsCaught);
    case "setStolenBaseAttempts":
      return stateWithStolenBases(state, "attempts", action.attempts);
    case "setStolenBasesAllowed":
      return stateWithStolenBases(state, "allowed", action.allowed);
    case "addThrowToSecondBase":
      current = state.eventPlayers[state.currentIndex];
      if (!current.throwsToSecond) current.throwsToSecond = [];
      current.throwsToSecond = Object.assign([], current.throwsToSecond, {
        [action.throwToSecond.number - 1]: action.throwToSecond
      });

      return stateWithEventPlayers(state, current);
    case "setThrowsToSecondSeconds":
      current = state.eventPlayers[state.currentIndex];
      current.throwsToSecond = Object.assign([], current.throwsToSecond, {
        [action.index]: { ...current.throwsToSecond[action.index], seconds: action.seconds }
      });

      return stateWithEventPlayers(state, current);
    case "setPlayerCompleted":
      current = state.eventPlayers[state.currentIndex];
      current.completed = action.playerCompleted;
      return stateWithEventPlayers(state, current);
    case "setFormDirty":
      return {
        ...state,
        formDirty: action.formDirty
      };
    case "setCount":
      current = state.eventPlayers[state.currentIndex];
      current = initializeBreakout(current);
      current.breakout[action.pitchType].count = action.value;
      const strikes = current.breakout[action.pitchType].strikes;
      current.breakout[action.pitchType].balls = action.value - strikes;
      return stateWithEventPlayersDirtyForm(state, current);
    case "setStrikes":
      current = state.eventPlayers[state.currentIndex];
      current = initializeBreakout(current);
      current.breakout[action.pitchType].strikes = action.value;
      const count = current.breakout[action.pitchType].count;
      current.breakout[action.pitchType].balls = count - action.value;
      return stateWithEventPlayersDirtyForm(state, current);
    case "setPitcherField":
      current = state.eventPlayers[state.currentIndex];
      current = initializeBreakout(current);
      if (action.pitchType && action.statKey) {
        current.breakout[action.pitchType][action.statKey] = action.value;
      } else if (action.pitchType === "totalPitches") {
        current.breakout[action.pitchType] = action.value;
      }
      return stateWithEventPlayersDirtyForm(state, current);
    case "reportByOnChange":
      current = state.eventPlayers[state.currentIndex];
      current.reportById = action.reportById;
      current.reportByLabel = action.reportByLabel;

      return {
        ...state,
        eventPlayers: Object.assign([], state.eventPlayers, { [state.currentIndex]: current })
      };

      return { ...state, reportById: action.reportById, reportByLabel: action.reportByLabel };
    default:
      return state;
  }
};

const PlayerReportModalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <PlayerReportModalContext.Provider value={{ state, dispatch }}>{children}</PlayerReportModalContext.Provider>;
};

PlayerReportModalContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default PlayerReportModalContextProvider;
