import React, { useContext } from "react";
import styled from "styled-components";
import { FormColumn2Wide, BestLabel, FormInput } from "best-common-react";
import EventReportContext from "../../contexts/clubcomm/EventReportContext";

const FormTitleWrapper = styled.div`
  &&& {
    color: #666666;
  }
`;

const Title = styled.div.attrs(() => ({
  className: "mt-0 mb-3"
}))`
  width: 100%;
  border-bottom: 1px solid #b9d5e3;
  font-size: 1rem;
`;

const StatusColorMapping = {
  Submitted: "green",
  Preliminary: "red",
  Published: "black"
};

const StatusInfoSection = () => {
  const eventReportContext = useContext(EventReportContext);
  const { eventReportStatus, eventPlayers } = eventReportContext.state.eventReport;
  const { dispatch } = eventReportContext;

  const completedCount = eventPlayers.filter(p => p.completed && p.orgCode !== null && p.orgCode !== "NO").length;
  const totalCount = eventPlayers.filter(p => p.completed !== null && p.orgCode !== null && p.orgCode !== "NO").length;

  return (
    <>
      <Title style={{ marginTop: "0px" }}>
        <FormTitleWrapper>Status Info</FormTitleWrapper>
      </Title>
      <div className={"row"}>
        <FormColumn2Wide>
          <div className="form-group">
            <BestLabel>Report Status</BestLabel>
            <br />
            <div style={{ color: StatusColorMapping[eventReportStatus] }}>{eventReportStatus}</div>
          </div>
        </FormColumn2Wide>
        <FormColumn2Wide>
          <div className="form-group">
            <BestLabel>MLB Player Info</BestLabel>
            <br />
            {completedCount} of {totalCount}
          </div>
        </FormColumn2Wide>
      </div>
    </>
  );
};

export default StatusInfoSection;
