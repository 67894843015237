import { DeleteIcon } from "best-common-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import styled from "styled-components";

import GlobalModalContext from "../../../contexts/GlobalModalContext";
import ProfileContext from "../../../contexts/ProfileContext";
import WBCProfileApi from "../../../httpClients/WBCProfileApi";
import WBCDataTable from "../../data-table/WBCDataTable";
import { useAlert } from "../../hooks/useAlert";
import WBCAdminAccess from "../../protected/WBCAdminAccess";
import ProfileHistoryDescriptionFormatter from "./ProfileHistoryDescriptionFormatter";

const DeleteButton = styled.button.attrs(() => ({
  type: "button",
  className: "btn btn-sm btn-link"
}))`
  cursor: pointer !important;
  &&& {
    width: ${props => (props.clickableWidth ? props.clickableWidth : "auto")};
    padding-top: 2px;
  }
`;

const DeleteHistoryTable = styled(WBCDataTable)`
  .react-grid-Viewport {
    height: ${props => `${props.viewportHeight}px`};
  }
`;

const ProfileHistoryDeleteFormatter = ({ row }) => {
  const globalModalContext = useContext(GlobalModalContext);
  const profileContext = useContext(ProfileContext);
  const alert = useAlert();

  const { playerHistoryId, profileId } = row;

  const deletePlayerHistory = async () => {
    const result = await WBCProfileApi.deletePlayerHistoryById(playerHistoryId);
    if (result.status === 200) {
      alert("Deleted");
      WBCProfileApi.getAllTeamsPlayerHistoryById(profileId).then(playerHistoryById => {
        profileContext.dispatch({
          type: "setProfileHistory",
          value: playerHistoryById
        });
      });
    } else {
      alert("Error Deleting History", "danger");
    }
  };

  const deletePlayerHistoryContent = () => {
    const columns = [
      {
        key: "actionDateAsString",
        name: "Date",
        width: 100
      },
      {
        key: "fedTeamName",
        name: "Fed",
        width: 230
      },
      {
        key: "description",
        name: "Description",
        width: 440,
        formatter: ProfileHistoryDescriptionFormatter
      }
    ];

    return (
      <>
        <p>Warning, this is a permanent action and cannot be undone. Do you wish to continue?</p>
        <DeleteHistoryTable
          useBCR
          columns={columns}
          headerRowHeight={34}
          minHeight={90}
          viewportHeight={55}
          data={[row]}
        />
      </>
    );
  };

  return (
    <WBCAdminAccess bocOnly>
      <DeleteButton
        onClick={() => {
          globalModalContext.dispatch({
            type: "openAreYouSureModal",
            headerText: "Delete History",
            discardChangesFn: deletePlayerHistory,
            discardChangesButtonText: "Delete",
            content: deletePlayerHistoryContent()
          });
        }}
      >
        <DeleteIcon />
      </DeleteButton>
    </WBCAdminAccess>
  );
};

ProfileHistoryDeleteFormatter.propTypes = {
  row: PropTypes.shape({
    playerHistoryId: PropTypes.number.isRequired,
    profileId: PropTypes.number.isRequired
  }).isRequired
};

export default ProfileHistoryDeleteFormatter;
