import React, { useContext } from "react";
import { BestSelect } from "best-common-react";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";

const EligibilityFilterSelect = () => {
  const playerEligibilityContext = useContext(PlayerEligibilityContext);

  return (
    <div className="form-group">
      <BestSelect
        name="eligibilitySelect"
        value={playerEligibilityContext.state.statusFilter}
        options={playerEligibilityContext.state.statuses}
        onChange={selection => {
          playerEligibilityContext.dispatch({
            type: "setStatusFilter",
            value: selection.value,
            label: selection.label
          });
        }}
      />
    </div>
  );
};

export default EligibilityFilterSelect;
