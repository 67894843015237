import _ from "lodash";

export const sortPlayers = (col, dir, rows) => {
  const filters =
    col === "completed"
      ? [r => (r.orgCode === "NO" || r.orgCode === null ? "aa" : _.toLower(r[col]))]
      : [r => _.toLower(r[col])];
  switch (dir) {
    case "ASC":
      return _.sortBy(rows, filters);
    case "DESC":
      return _.sortBy(rows, filters).reverse();
    default:
      return _.sortBy(rows, [r => _.toLower(r["playerName"])]);
  }
};
