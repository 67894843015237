class FileHelper {
  static downloadFile(url, headers) {
    let nameRegex = new RegExp("filename[^;=\\n]*=(?:(\\\\?['\"])(.*?)\\1|(?:[^\\s]+'.*?')?([^;\\n]*))");
    let matches = nameRegex.exec(headers["content-disposition"]);

    let fileName = "download";
    if (matches.length > 0) {
      fileName = matches[0].substring(9).replace(/"/g, "");
    }

    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    setTimeout(function() {
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }, 1000);
  }
}

export default FileHelper;
