import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const BodyContent = styled.div`
  text-align: left;
  height: 100px;
`;

const BoldContent = styled.div`
  font-weight: bold;
`;

const RemovePlayerModalBody = ({ content }) => {
  return (
    <BodyContent>
      <div className={"d-flex"}>
        Are you sure you want to delete&nbsp;<BoldContent>{content.playerInfo.playerName}</BoldContent>&nbsp;from this
        report?
      </div>
    </BodyContent>
  );
};

RemovePlayerModalBody.propTypes = {
  content: PropTypes.object
};

RemovePlayerModalBody.displayName = "BodyContent";

export default RemovePlayerModalBody;
