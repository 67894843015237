import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const CheckMarContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-center"
}))`
  &&& {
    color: ${props => props.theme["dark-grey"]};
  }
`;

const CheckMarkFormatter = ({ value }) => {
  return <CheckMarContainer>{!!value ? <i className="fas fa-check fa-lg"></i> : ""}</CheckMarContainer>;
};

CheckMarkFormatter.propTypes = {
  value: PropTypes.bool
};

export default CheckMarkFormatter;
