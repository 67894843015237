import React, { useReducer } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import PlayerEligibilityContext from "../contexts/PlayerEligibilityContext";

const initialState = {
  players: [],
  displayedPlayers: [],
  playerFilter: "",
  sortFilters: {
    direction: "ASC",
    key: "submittedBy"
  },
  statusFilter: { label: "Pending", value: 1 },
  statuses: [
    { label: "Pending", value: 1 },
    { label: "Approved", value: 2 },
    { label: "Denied", value: 3 }
  ],
  team: {},
  teams: [],
  playerProfileToDenyReason: ""
};

const allTeam = {
  label: "All Federations",
  value: 0,
  teamId: 0,
  countryCode: "",
  code: ""
};

const PlayerEligibilityProvider = ({ children }) => {
  const sort = (players, searchFilters) => {
    let direction = searchFilters.direction;
    if (direction === "NONE") {
      return _.sortBy(players, [
        player => _.toLower(player["submittedBy"]),
        player => _.toLower(player["lastName"]),
        player => _.toLower(player["firstName"])
      ]);
    } else if (direction === "ASC") {
      if (searchFilters.key === "lastName") {
        return _.sortBy(players, [player => _.toLower(player["lastName"]), player => _.toLower(player["firstName"])]);
      } else if (searchFilters.key === "submittedBy") {
        return _.sortBy(players, [
          player => _.toLower(player["submittedBy"]),
          player => _.toLower(player["lastName"]),
          player => _.toLower(player["firstName"])
        ]);
      }
      return _.sortBy(players, [player => _.toLower(player[searchFilters.key])]);
    } else {
      if (searchFilters.key === "lastName") {
        return _.sortBy(players, [
          player => _.toLower(player["lastName"]),
          player => _.toLower(player["firstName"])
        ]).reverse();
      } else if (searchFilters.key === "submittedBy") {
        return _.sortBy(players, [
          player => _.toLower(player["submittedBy"]),
          player => _.toLower(player["lastName"]),
          player => _.toLower(player["firstName"])
        ]).reverse();
      }
      return _.sortBy(players, [player => _.toLower(player[searchFilters.key])]).reverse();
    }
  };

  const formatForComparison = str => (!str ? "" : str.replace(/[^A-Za-z]+/g, "").toLowerCase());

  const filterPlayers = (players, sortFilters, playerFilter, statusFilter) => {
    const toDisplay = players
      .filter(player => {
        if (playerFilter === "") return true;
        return (
          _.startsWith(formatForComparison(player.lastName), formatForComparison(playerFilter)) ||
          _.startsWith(formatForComparison(player.firstName), formatForComparison(playerFilter)) ||
          _.startsWith(formatForComparison(player.lastName + player.firstName), formatForComparison(playerFilter))
        );
      })
      .filter(player => {
        return player.eligibilityStatusId === statusFilter.value;
      });
    return sort(toDisplay, sortFilters);
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setPlayers":
        return {
          ...state,
          players: action.players,
          displayedPlayers: filterPlayers(action.players, state.sortFilters, state.playerFilter, state.statusFilter)
        };
      case "setPlayerFilter":
        return {
          ...state,
          playerFilter: action.playerFilter,
          displayedPlayers: filterPlayers(state.players, state.sortFilters, action.playerFilter, state.statusFilter)
        };
      case "setStatusFilter":
        const statusFilter = { label: action.label, value: action.value };
        return {
          ...state,
          statusFilter: statusFilter,
          displayedPlayers: filterPlayers(state.players, state.sortFilters, state.playerFilter, statusFilter)
        };
      case "setSortFilters":
        return {
          ...state,
          sortFilters: action.sortFilters,
          displayedPlayers: filterPlayers(state.players, action.sortFilters, state.playerFilter, state.statusFilter)
        };
      case "setTeam":
        return {
          ...state,
          team: {
            value: action.value,
            label: action.label,
            teamId: action.teamId,
            countryCode: action.countryCode,
            code: action.code
          }
        };
      case "setTeams":
        let team = state.team || allTeam;
        return {
          ...state,
          team,
          teams: [allTeam, ...action.teams]
        };
      case "setPlayerProfileToDenyReason":
        return {
          ...state,
          playerProfileToDenyReason: action.reason
        };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <PlayerEligibilityContext.Provider value={{ state, dispatch }}>{children}</PlayerEligibilityContext.Provider>;
};

PlayerEligibilityProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default PlayerEligibilityProvider;
