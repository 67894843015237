import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ReportIconContainer = styled.div.attrs(() => ({
  className: "d-flex"
}))`
  height: 18px;
  width: 18px;
`;

const Circle = styled.i.attrs(() => ({
  className: "fas fa-check-circle fa-lg"
}))`
  &&& {
    height: 18px;
    width: 18px;
    color: ${props => (props.completed ? "#3eb049" : props.theme["grey"])};
    border-radius: 50%;
    position: relative;
  }
`;

const ReportCheckmark = ({ player }) => {
  return (
    <div className="d-flex justify-content-center mt-2">
      <ReportIconContainer>
        {player ? (
          <>
            <Circle completed={player.completed} />
          </>
        ) : null}
      </ReportIconContainer>
    </div>
  );
};

ReportCheckmark.propTypes = {
  player: PropTypes.object
};

export default ReportCheckmark;
