import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputWrapper = styled.div`
  float: left;
  width: 15%;
  &&&& {
    & * input:disabled {
      color: black;
    }
  }
`;

const RightLabelWrapper = styled.div`
  float: left;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  line-height: 3.5;
  padding-left: 5px;
  padding-right: ${props => (props.padding ? props.padding : "0px")};
  color: ${props => props.theme["dark-grey"]};
`;

const WBCHorizontalFieldInput = ({ label, children }) => {
  return (
    <div>
      <InputWrapper>{children}</InputWrapper>
      <RightLabelWrapper padding="10px">{label}</RightLabelWrapper>
    </div>
  );
};

WBCHorizontalFieldInput.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.object
};

export default WBCHorizontalFieldInput;
