import React, { useReducer } from "react";
import PropTypes from "prop-types";
import SelectedTeamContext from "../contexts/SelectedTeamContext";

const initialState = {
  teams: [],
  team: {},
  fedTeams: [],
  teamListChanged: false
};

const SelectedTeamContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setTeams":
        if (action.team) {
          return { ...state, teams: action.teams, team: action.team };
        } else {
          return { ...state, teams: action.teams };
        }
      case "setTeam":
        return {
          ...state,
          team: {
            value: action.value,
            label: action.label,
            teamId: action.teamId,
            finalizedStatus: action.finalizedStatus,
            countryCode: action.countryCode,
            code: action.code
          }
        };
      case "setFedTeams":
        return { ...state, fedTeams: action.fedTeams };
      case "setTeamListChanged":
        return { ...state, teamListChanged: action.teamListChanged };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <SelectedTeamContext.Provider value={{ state, dispatch }}>{children}</SelectedTeamContext.Provider>;
};

SelectedTeamContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default SelectedTeamContextProvider;
