import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestLabel } from "best-common-react";
import UncontrolledInput from "./UncontrolledInput";
import UncontrolledNumberInput from "./UncontrolledNumberInput";

const RightLabel = styled.span`
  color: ${props => props.theme["mlb-red"]};
  font-weight: 400;
  font-size: 0.875rem;
`;

/**
 * A wrapper arround an UncontrolledInput that adds some form properties,
 * such as labels and whether or not a form is required
 */
const UncontrolledFormInput = ({ type, label, required, rightLabel, className, number, numberLimit, ...props }) => (
  <div className={`form-group ${className || ""}`.trim()}>
    <div className="d-flex justify-content-between">
      {label ? <BestLabel required={required}>{label}</BestLabel> : null}
      {rightLabel ? <RightLabel>{rightLabel}</RightLabel> : null}
    </div>
    <div>
      {number ? (
        <UncontrolledNumberInput type={type} required={required} numberLimit={numberLimit} {...props} />
      ) : (
        <UncontrolledInput type={type} required={required} {...props} />
      )}
    </div>
  </div>
);

UncontrolledFormInput.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  rightLabel: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  number: PropTypes.bool,
  numberLimit: PropTypes.number
};

UncontrolledFormInput.defaultProps = {
  type: "text"
};

export default UncontrolledFormInput;
