import styled from "styled-components";
import TabSwitcher from "../temp/TabSwitcher";
import Theme from "../../Theme";

export const MLBPlayersDashboardTab = styled.div`
  padding: 1.5rem;
`;

export const MLBPlayersDashboardTabSwitcher = styled(TabSwitcher).attrs(() => ({
  styles: {
    tab: { defaultColor: Theme.wbcBlue, borderColor: Theme.grey },
    container: { borderColor: Theme.grey }
  }
}))`
  &&& .wbc-tab-component-container {
    height: calc(100vh - 80px - 2rem - 22px);
    overflow: auto;
  }
`;
