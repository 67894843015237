import React, { useCallback, useContext, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { AlertConstants, Icon } from "best-common-react";
import PropTypes from "prop-types";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import InterestListContext from "../../contexts/InterestListContext";
import EligibilityListApi from "../../httpClients/EligibilityListApi";
import InterestListApi from "../../httpClients/InterestListApi";
import LabelConstants from "../../constants/LabelConstants";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import AuthContext from "../../contexts/AuthContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { getHasDocuments } from "../../selectors/DocumentSelectors";
import openDocumentsModal from "../../effects/documents/openDocumentsModal";
import { useAlert } from "../hooks/useAlert";
import { MenuButton, MenuIcon, ContextMenu, MenuItem } from "../elements/ContextMenu";
import { sortPlayers } from "../roster/RosterSortHelper";
import MLBPlayerRequestApi from "../../httpClients/MLBPlayerRequestApi";
import ProfileRequestConstants from "../../constants/ProfileRequestConstants";

const RemoveIcon = styled(Icon)`
  color: ${props => props.theme[props.isOnProvisionalRoster ? "grey" : "wbcBlue"]};

  &:hover {
    cursor: ${props => (props.isOnProvisionalRoster ? "default" : "pointer")};
    color: ${props => props.theme[props.isOnProvisionalRoster ? "grey" : "wbcBlue"]};
    text-align: center;
  }
`;

const InterestListActionsFormatter = ({ row: profile }) => {
  // hook(s)
  const authContext = useContext(AuthContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const interestListContext = useContext(InterestListContext);
  const { dispatch: globalModalDispatch } = useContext(GlobalModalContext);
  const [isOpen, setIsOpen] = useState(false);
  const showAlert = useAlert();
  const targetRef = useRef(null);

  // variable(s)
  const { dispatch: interestDispatch } = interestListContext;
  const { profileId, hasDocuments, pastWbcFlag, eligible } = profile;
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const { team: selectedTeam } = selectedTeamContext.state;
  const { teamId: fedTeamId } = selectedTeamContext.state.team;
  const { sortFilters } = interestListContext.state;
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const canEdit = isBOCadmin || isFedAdmin || isTeamCoordinator;
  const canRemovePlayers = !selectedTeam.finalizedStatus?.finalizedInterestDate || isBOCadmin || isTeamCoordinator;
  // function(s)
  const filterPlayers = (players, removedPlayers = []) => {
    return players.filter(player => !removedPlayers.includes(player.profileId));
  };

  const toggleMenu = useCallback(value => setIsOpen(value !== undefined ? value : !isOpen), [isOpen, setIsOpen]);
  const submitForEligibility = () => {
    if ((isBOCadmin || hasDocuments || pastWbcFlag) && eligible !== "Submitted" && eligible !== "Yes") {
      EligibilityListApi.addPlayerToEligibilityList(selectedYear, fedTeamId, profileId)
        .then(() => {
          toggleMenu(false);
          showAlert("Submitted");
          InterestListApi.getInterestListPlayers(selectedYear, fedTeamId).then(response => {
            if (!!response) {
              interestDispatch({
                type: "sortPlayers",
                col: sortFilters.col,
                dir: sortFilters.dir,
                players: sortPlayers(sortFilters.col, sortFilters.dir, response)
              });
            }
          });
        })
        .catch(({ response }) => {
          toggleMenu(false);
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const onDocumentsSaveSuccess = useCallback(
    documentsState => {
      interestDispatch({
        type: "setPlayerDocFlags",
        profileId: documentsState.profile.profileId,
        hasDocuments: getHasDocuments(documentsState)
      });
    },
    [interestDispatch]
  );

  const onPlayerDocsClicked = useCallback(() => {
    openDocumentsModal({
      profile,
      labelTypeId: LabelConstants.LABEL_TYPE.PLAYER,
      globalModalDispatch,
      onSaveSuccess: onDocumentsSaveSuccess
    });
    toggleMenu(false);
  }, [profile, globalModalDispatch, toggleMenu, onDocumentsSaveSuccess]);

  const removePlayer = () => {
    if ((isBOCadmin || isFedAdmin || isTeamCoordinator) && !profile.onProvisionalRoster) {
      interestDispatch({
        type: "removePlayer",
        removedPlayer: profile
      });
      toggleMenu(false);
    }
  };

  const handleConfirmModal = () => {
    setShowConfirmModal(false);
    MLBPlayerRequestApi.updatePlayerRequestStatus(profileId, ProfileRequestConstants.STATUS.SUBMITTED_TO_BOC).then(
      res => {
        showAlert("Submitted");
        interestDispatch({
          type: "setPlayerRequestStatus",
          profileId: res.profileId,
          newStatus: res.requestStatus
        });
      }
    );
  };

  const handleCancelRequestModal = () => {
    setShowConfirmModal(false);
    const cancelledStatus = ProfileRequestConstants.STATUS.CANCELLED;
    if (isBOCadmin) {
      MLBPlayerRequestApi.updatePlayerRequestStatus(profileId, cancelledStatus).then(res => {
        showAlert("Cancelled Request");
      });
    }
    interestDispatch({
      type: "setPlayers",
      players: filterPlayers(interestListContext.state.players, [profileId])
    });
    interestDispatch({
      type: "setPlayerRequestStatus",
      profileId: profileId,
      newStatus: cancelledStatus
    });
  };

  const handleSubmitRequestClick = () => {
    setShowConfirmModal(true);
    toggleMenu(false);
  };

  const handleCancelRequest = () => {
    setShowCancelModal(true);
    toggleMenu(false);
  };

  useEffect(() => {
    if (showCancelModal) {
      globalModalDispatch({
        type: "openConfirmCancelModal",
        updateStatusButtonText: "Cancel Request",
        onConfirmUpdateFn: handleCancelRequestModal,
        profileId: profile.profileId
      });
      setShowCancelModal(false);
    }
  }, [showCancelModal]);

  useEffect(() => {
    if (showConfirmModal) {
      globalModalDispatch({
        type: "openConfirmSubmitModal",
        content: `Are you sure you want to submit a request for the following player?`,
        updateStatusButtonText: isBOCadmin
          ? ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC
          : ProfileRequestConstants.ACTIONS.SUBMIT_TO_WBCI,
        onConfirmRequestFn: handleConfirmModal,
        submitProfile: profile
      });
      setShowConfirmModal(false);
    }
  }, [showConfirmModal]);

  return (
    canEdit && (
      <>
        <MenuButton ref={targetRef} onClick={() => setIsOpen(!isOpen)}>
          <MenuIcon iconName="fa-ellipsis-v" />
        </MenuButton>
        <ContextMenu targetRef={targetRef} isOpen={isOpen} toggleMenu={toggleMenu}>
          {canRemovePlayers && (
            <MenuItem
              disabled={(!isBOCadmin && !isFedAdmin && !isTeamCoordinator) || profile.onProvisionalRoster}
              onClick={removePlayer}
            >
              <RemoveIcon
                iconName="fa-trash-alt"
                className="fa-md"
                isOnProvisionalRoster={profile.onProvisionalRoster}
              />{" "}
              remove player
            </MenuItem>
          )}
          <MenuItem onClick={onPlayerDocsClicked}>
            <Icon iconName="fa-file-upload" className="fa-md" /> player docs
          </MenuItem>
          {profile?.requestStatus === ProfileRequestConstants.STATUS.REQUEST_REQUIRED && (
            <MenuItem onClick={handleSubmitRequestClick}>
              <Icon iconName="fa-check-circle" className="fa-md" /> submit request
            </MenuItem>
          )}
          {profile?.requestStatus === ProfileRequestConstants.STATUS.CANCELLED && (
            <MenuItem disabled={!isBOCadmin} onClick={handleSubmitRequestClick}>
              <Icon iconName="fa-check-circle" className="fa-md" /> resubmit to BOC
            </MenuItem>
          )}
          {[ProfileRequestConstants.STATUS.SUBMITTED_TO_BOC, ProfileRequestConstants.STATUS.SUBMITTED_TO_CLUB].includes(
            profile?.requestStatus
          ) && (
            <MenuItem onClick={handleCancelRequest}>
              <Icon iconName="fa-check-circle" className="fa-md" /> cancel request
            </MenuItem>
          )}
          <MenuItem
            disabled={
              !(
                (isBOCadmin || (isTeamCoordinator && (hasDocuments || pastWbcFlag))) &&
                eligible !== "Submitted" &&
                eligible !== "Yes"
              )
            }
            onClick={submitForEligibility}
          >
            <Icon iconName="fa-check-circle" className="fa-md" /> submit for eligibility
          </MenuItem>
        </ContextMenu>
      </>
    )
  );
};

InterestListActionsFormatter.propTypes = {
  row: PropTypes.object
};

export default InterestListActionsFormatter;
