import React, { useReducer } from "react";
import WBCMobileNavContext from "../contexts/WBCMobileNavContext";
import PropTypes from "prop-types";

const initialState = {
  openSection: ""
};

const WBCMobileNavProvider = ({ openSection, children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setOpenSection":
        return { ...state, openSection: action.openSection };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, { ...initialState, openSection: openSection });

  return <WBCMobileNavContext.Provider value={{ state, dispatch }}>{children}</WBCMobileNavContext.Provider>;
};

WBCMobileNavProvider.propTypes = {
  openSection: PropTypes.any,
  children: PropTypes.any
};

WBCMobileNavProvider.defaultProps = {
  openSection: ""
};

export default WBCMobileNavProvider;
