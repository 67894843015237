import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestLabel } from "best-common-react";
import WBCDataTable from "../data-table/WBCDataTable";
import DocumentsTableFileNameCell from "./DocumentsTableFileNameCell";
import DocumentsTableLabelCell from "./DocumentsTableLabelCell";
import DocumentsTableDeleteCell from "./DocumentsTableDeleteCell";
import DocumentsContext from "../../contexts/DocumentsContext";
import { getDocuments, getLabels } from "../../selectors/DocumentSelectors";
import DocumentsTableUploadedCell from "./DocumentsTableUploadedCell";
import { sortList } from "./DocumentsSortHelper";

const DocumentsTableWrapper = styled.div`
  .react-grid-Main {
    outline: 1px solid #e7eaec;
  }

  .react-grid-Grid {
    border: 1px solid #dddddd;
  }

  .react-grid-HeaderCell {
    text-align: left;
    border-bottom: 1px solid #d2d2d2 !important;
  }

  .react-grid-Cell {
    text-align: left;
    font-weight: 400;
  }
`;

const createColumns = isReadOnly => [
  {
    key: "fileName",
    name: <BestLabel htmlFor="">File name</BestLabel>,
    sortable: false,
    width: 175,
    formatter: DocumentsTableFileNameCell
  },
  {
    key: "label",
    name: (
      <BestLabel htmlFor="" required={!isReadOnly}>
        Label
      </BestLabel>
    ),
    sortable: false,
    formatter: DocumentsTableLabelCell
  },
  {
    key: "createdTs",
    name: <BestLabel htmlFor="">Uploaded</BestLabel>,
    sortable: true,
    width: 110,
    formatter: DocumentsTableUploadedCell
  },
  {
    key: " ",
    name: " ",
    sortable: false,
    width: 50,
    formatter: DocumentsTableDeleteCell
  }
];

const readOnlyColumns = createColumns(true);
const editableColumns = createColumns(false);

const DocumentsTable = ({ isReadOnly }) => {
  const {
    actions: { sortDocuments },
    state
  } = useContext(DocumentsContext);
  const [mounted, setMounted] = useState(false);

  const documents = getDocuments(state);
  const labels = getLabels(state);
  const columns = useMemo(() => (isReadOnly ? readOnlyColumns : editableColumns), [isReadOnly]);

  const [sortFilter, setSortFilter] = useState({
    dir: "DESC",
    col: "createdTs"
  });

  // HACK: ReactDataGrid initial render does not size columns properly and
  // automatically scrolls into view if given non-empty data initially, so
  // we wait until it mounts with empty data before passing data.
  useEffect(() => {
    setMounted(true);
  }, [setMounted]);
  const data = useMemo(
    () =>
      mounted
        ? documents.map(document => ({
            document,
            labels,
            isReadOnly
          }))
        : [],
    [documents, labels, isReadOnly, mounted]
  );

  return (
    <DocumentsTableWrapper>
      <WBCDataTable
        useBCR
        columns={columns}
        data={data}
        minHeight={150}
        sortFunc={(col, dir) => {
          sortDocuments(col, dir, sortList);
          setSortFilter({ col, dir });
        }}
        sortColumn={sortFilter.col}
        sortDirection={sortFilter.dir}
      />
    </DocumentsTableWrapper>
  );
};

DocumentsTable.displayName = "DocumentsTable";

DocumentsTable.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default DocumentsTable;
