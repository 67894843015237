import { useCallback, useContext, useEffect } from "react";
import { reorder, addToList, removeFromList } from "../../utils/DragUtils";
import RosterContext from "../../contexts/RosterContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import DnDContext from "../../contexts/DnDContext";

const pitcherPositions = new Set(["LHR", "LHS", "RHR", "RHS"]);
const catcherPositions = new Set(["C", "UTL"]);
const infielderPositions = new Set(["1B", "2B", "3B", "SS", "IF", "DH", "PH", "UTL"]);
const outfielderPositions = new Set(["LF", "CF", "RF", "OF", "DH", "PH", "UTL"]);

/**
 * Tied closely to the rosterContext. For drag elements,
 * this makes the functionality to either re-order a list
 * or drag one list to another list
 * @param {string} personType - the category of person that is dragged
 */
const useDraggableEndFn = personType => {
  // context(s)
  const rosterContext = useContext(RosterContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const globalModalContext = useContext(GlobalModalContext);
  const selectedTeamContext = useContext(SelectedTeamContext);

  // variable(s)
  const { dispatch, state } = rosterContext;
  const { idToDataMap, [personType]: data } = state;
  const { team } = selectedTeamContext.state;
  const { onFormDirty } = tournamentFormContext.state;
  const { final } = personType === "provisional" ? state : {};
  const { dispatch: globalModalDispatch } = globalModalContext;

  return useCallback(
    result => {
      const { source, destination } = result;
      const sourceKey = idToDataMap[source.droppableId];

      if (result.action === "reorder") {
        dispatch({
          type: "updatePeople",
          key: personType,
          source: sourceKey,
          list: reorder(data[sourceKey], source.index, destination.index)
        });
        onFormDirty();
      } else if (result.action === "addProvisionalToFinal") {
        const destinationKey = idToDataMap[destination.droppableId];

        //Display modal if player is played into the pitcher/catcher section
        //if they aren't a pitcher/catcher.
        if (personType === "provisional") {
          const player = data[sourceKey][source.index];
          if (destinationKey === "pitchers" && !pitcherPositions.has(player.position)) {
            globalModalDispatch({
              type: "openPitcherModal",
              content: { player, team }
            });
          } else if (destinationKey === "catchers" && !catcherPositions.has(player.position)) {
            globalModalDispatch({
              type: "openCatcherModal",
              content: { player, team, droppableId: destination.droppableId }
            });
          } else if (destinationKey === "infield" && !infielderPositions.has(player.position)) {
            globalModalDispatch({
              type: "openInfielderModal",
              content: { player, team, droppableId: destination.droppableId }
            });
          } else if (destinationKey === "outfield" && !outfielderPositions.has(player.position)) {
            globalModalDispatch({
              type: "openOutfielderModal",
              content: { player, team, droppableId: destination.droppableId }
            });
          }
        }

        // add to other list, and remove from current list
        const listToAppend = personType === "provisional" ? final[destinationKey] : data[destinationKey];

        const sourceList =
          personType === "provisional"
            ? data[sourceKey].map((d, i) => ({ ...d, inFinalRoster: i === source.index || d.inFinalRoster }))
            : removeFromList(data[sourceKey], source.index);
        dispatch({
          type: "updateMultiple",
          key: personType,
          data: {
            [sourceKey]: sourceList,
            [destinationKey]: addToList(listToAppend, data[sourceKey][source.index], destination.index)
          }
        });
        onFormDirty();
      }
    },
    [idToDataMap, personType, final, data, dispatch, onFormDirty, globalModalDispatch, team]
  );
};

/**
 * Calls the drag and drop context. inherently creates the on drag end
 * function, which is coupled to the rosterContext.
 * @param {string} droppableId - the id of the droppable component
 * @param {string} personType - the category of person that is dragged
 */
export const useDraggable = (droppableId, personType) => {
  const { dispatch: dndDispatch } = useContext(DnDContext);
  const onDragEnd = useDraggableEndFn(personType);

  useEffect(() => {
    dndDispatch({
      type: "mount",
      droppableId,
      onDragEndFn: onDragEnd
    });
  }, [dndDispatch, droppableId, onDragEnd]);
};
