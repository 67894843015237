import React, { useContext } from "react";
import styled from "styled-components";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import { FormTitleWrapper, Title } from "./PlayerModalStyling";
import PitcherBreakoutRow from "./PitcherBreakoutRow";
import PitcherBreakoutBamTotalsRow from "./PitcherBreakoutBamTotalsRow";
import WBCAccessControl from "../../../protected/WBCAccessControl";
import EventReportStatusConstants from "../../../../constants/EventReportStatusConstants";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";

const BreakoutTable = styled.table`
  border-collapse: separate;
  border-spacing: 0 5px;
`;

const BreakoutTableRowHeader = styled.th`
  width: 115px;
  padding-right: 7px;
  font-family: Helvetica;
  font-size: 12px;
  font-weight: 300;
  text-align: right;
  color: #000000;
`;

const PitcherBreakout = () => {
  const playerReportContext = useContext(PlayerReportModalContext);
  const eventReportContext = useContext(EventReportContext);
  const { eventReport } = eventReportContext.state;
  const { eventPlayers, currentIndex } = playerReportContext.state;
  const eventPlayer = eventPlayers[currentIndex];

  const stats = eventPlayer.stats ? eventPlayer.stats : { pitching: {} };
  const pitching = stats.pitching;
  const breakout = eventPlayer.breakout
    ? eventPlayer.breakout
    : {
        fastball: {},
        curve: {},
        slider: {},
        change: {},
        other: {},
        calculated: {}
      };

  const parseNumber = (value, headerKey) => {
    if (typeof value === "string") {
      if (headerKey === "highSpeed" || headerKey === "avgSpeed") {
        return Number.parseFloat(value);
      }
      return Number.parseInt(value);
    }
    return value;
  };

  const getTotalValue = (value, headerKey) => {
    if (headerKey === "highSpeed" || headerKey === "avgSpeed") {
      const fixedValue = value.toFixed(2);
      return fixedValue === 0.0 ? 0 : fixedValue;
    }
    return value;
  };

  const calculateTotalsRow = () => {
    if (breakout) {
      const rowKeys = ["fastball", "curve", "slider", "change", "other"];
      const headerKeys = ["count", "strikes", "balls", "highSpeed", "avgSpeed"];
      if (!breakout.calculated) {
        breakout.calculated = {};
      }
      headerKeys.forEach(headerKey => {
        let totalValue = 0;
        rowKeys.forEach(rowKey => {
          let valueToAdd = 0;
          if (breakout[rowKey] && breakout[rowKey][headerKey]) {
            valueToAdd = breakout[rowKey][headerKey];
          }
          if (valueToAdd && !Number.isNaN(valueToAdd)) {
            totalValue += parseNumber(valueToAdd, headerKey);
          }
        });
        breakout.calculated[headerKey] = getTotalValue(totalValue, headerKey);
      });
    }
  };

  calculateTotalsRow();

  return (
    <>
      <Title>
        <FormTitleWrapper>Pitcher Breakout</FormTitleWrapper>
      </Title>
      <div>
        <BreakoutTable>
          <thead>
            <tr>
              <BreakoutTableRowHeader></BreakoutTableRowHeader>
              <BreakoutTableRowHeader>Count</BreakoutTableRowHeader>
              <BreakoutTableRowHeader>Strikes</BreakoutTableRowHeader>
              <BreakoutTableRowHeader>Balls</BreakoutTableRowHeader>
              <BreakoutTableRowHeader>High Speed</BreakoutTableRowHeader>
              <BreakoutTableRowHeader>Avg Speed</BreakoutTableRowHeader>
            </tr>
          </thead>
          <tbody>
            <WBCAccessControl
              bocOnly={
                eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
                eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
              }
              noDiv
              input={isDisabled => (
                <>
                  <PitcherBreakoutRow
                    disabled={isDisabled}
                    header="Fastball"
                    pitchType="fastball"
                    data={breakout.fastball}
                  />
                  <PitcherBreakoutRow disabled={isDisabled} header="Curve" pitchType="curve" data={breakout.curve} />
                  <PitcherBreakoutRow disabled={isDisabled} header="Slider" pitchType="slider" data={breakout.slider} />
                  <PitcherBreakoutRow disabled={isDisabled} header="Change" pitchType="change" data={breakout.change} />
                  <PitcherBreakoutRow disabled={isDisabled} header="Other" pitchType="other" data={breakout.other} />
                  <PitcherBreakoutRow
                    disabled={true}
                    header="Total"
                    pitchType="calculated"
                    data={breakout.calculated}
                    total
                  />
                  <PitcherBreakoutRow
                    required={false}
                    disabled={isDisabled}
                    header="Total Pitches"
                    pitchType="total"
                    data={breakout}
                    totalPitches
                  />
                  <PitcherBreakoutBamTotalsRow disabled={true} header="Bam Totals" pitchType="bam" data={pitching} />
                </>
              )}
            />
          </tbody>
        </BreakoutTable>
      </div>
    </>
  );
};

export default PitcherBreakout;
