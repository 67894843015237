import React, { useContext } from "react";
import BoxScoreTable from "./BoxScoreTable";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import PositionsContext from "../../../../contexts/PositionsContext";

const PositionAndCatcherBoxScore = () => {
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const positionsContext = useContext(PositionsContext);

  const { posIdToAbbrev } = positionsContext.state;
  const { eventPlayers, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];

  const mapLabelToStat = label => {
    switch (label) {
      case "POS":
        return posIdToAbbrev[currentEventPlayer.positionId];
      case "G":
        return currentEventPlayer.stats.batting.gamesPlayed;
      case "AB":
        return currentEventPlayer.stats.batting.atBats;
      case "R":
        return currentEventPlayer.stats.batting.runs;
      case "H":
        return currentEventPlayer.stats.batting.hits;
      case "2B":
        return currentEventPlayer.stats.batting.doubles;
      case "3B":
        return currentEventPlayer.stats.batting.triples;
      case "HR":
        return currentEventPlayer.stats.batting.homeRuns;
      case "RBI":
        return currentEventPlayer.stats.batting.rbis;
      case "TB":
        return currentEventPlayer.stats.batting.totalBases;
      case "BB":
        return currentEventPlayer.stats.batting.baseOnBalls;
      case "SO":
        return currentEventPlayer.stats.batting.strikeOuts;
      case "SB":
        return currentEventPlayer.stats.batting.stolenBases;
      case "CS":
        return currentEventPlayer.stats.batting.caughtStealing;
      default:
        return 0;
    }
  };

  return (
    <BoxScoreTable
      topRowHeaders={["POS", "G", "AB", "R", "H", "2B", "3B", "HR", "RBI"]}
      bottomRowHeaders={["TB", "BB", "SO", "SB", "CS"]}
      statsMap={mapLabelToStat}
    />
  );
};

export default PositionAndCatcherBoxScore;
