import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { memoize, omit, values } from "lodash";

import useEnhancedReducer from "../components/hooks/useEnhancedReducer";
import WBCYearsContext from "../contexts/WBCYearsContext";
import DocumentsContext from "../contexts/DocumentsContext";
import PlayerDocumentsApi from "../httpClients/PlayerDocumentsApi";
import FileHelper from "../httpClients/FileHelper";
import { getDocuments } from "../selectors/DocumentSelectors";
import readFile from "../utils/readFile";
import { sortList } from "../components/documents/DocumentsSortHelper";

const ActionTypes = {
  DOCUMENTS_REQUESTED: "DOCUMENTS_REQUESTED",
  DOCUMENTS_REQUEST_FULFILLED: "DOCUMENTS_REQUEST_FULFILLED",
  DOCUMENTS_RELOAD_REQUESTED: "DOCUMENTS_RELOAD_REQUESTED",
  DOCUMENTS_REQUEST_FAILED: "DOCUMENTS_REQUEST_FAILED",
  DOCUMENT_DOWNLOAD_REQUESTED: "DOCUMENT_DOWNLOAD_REQUESTED",
  DOCUMENT_DOWNLOAD_FAILED: "DOCUMENT_DOWNLOAD_FAILED",
  DOCUMENT_LABELS_REQUESTED: "DOCUMENT_LABELS_REQUESTED",
  DOCUMENT_LABELS_REQUEST_FULFILLED: "DOCUMENT_LABELS_REQUEST_FULFILLED",
  DOCUMENT_LABELS_REQUEST_FAILED: "DOCUMENT_LABELS_REQUEST_FAILED",
  LABEL_UPDATED: "LABEL_UPDATED",
  DOCUMENT_DELETED: "DOCUMENT_DELETED",
  FILES_UPLOADED: "FILES_UPLOADED",
  DOCUMENT_ADDED: "DOCUMENT_ADDED",
  ALL_DOCUMENTS_DOWNLOADED: "ALL_DOCUMENTS_DOWNLOADED",
  SORT_DOCUMENTS_REQUESTED: "SORT_DOCUMENTS_REQUESTED"
};

const createActions = memoize(dispatch => ({
  requestDocuments: () => {
    dispatch({
      type: ActionTypes.DOCUMENTS_REQUESTED
    });
  },
  fulfillDocumentsRequest: ({ documents }) => {
    dispatch({
      type: ActionTypes.DOCUMENTS_REQUEST_FULFILLED,
      payload: { documents }
    });
  },
  reloadDocuments: () => {
    dispatch({
      type: ActionTypes.DOCUMENTS_RELOAD_REQUESTED
    });
  },
  failDocumentsRequest: () => {
    dispatch({
      type: ActionTypes.DOCUMENTS_REQUEST_FAILED
    });
  },
  requestDocumentDownload: ({ documentId }) => {
    dispatch({
      type: ActionTypes.DOCUMENT_DOWNLOAD_REQUESTED,
      payload: { documentId }
    });
  },
  failDocumentDownload: () => {
    dispatch({
      type: ActionTypes.DOCUMENT_DOWNLOAD_FAILED
    });
  },
  requestDocumentLabels: () => {
    dispatch({
      type: ActionTypes.DOCUMENT_LABELS_REQUESTED
    });
  },
  fulfillDocumentLabelsRequest: ({ labels }) => {
    dispatch({
      type: ActionTypes.DOCUMENT_LABELS_REQUEST_FULFILLED,
      payload: { labels }
    });
  },
  failDocumentLabelsRequest: () => {
    dispatch({
      type: ActionTypes.DOCUMENT_LABELS_REQUEST_FAILED
    });
  },
  updateLabel: ({ documentId, label }) => {
    dispatch({
      type: ActionTypes.LABEL_UPDATED,
      payload: { documentId, label }
    });
  },
  deleteDocument: ({ documentId }) => {
    dispatch({
      type: ActionTypes.DOCUMENT_DELETED,
      payload: { documentId }
    });
  },
  uploadFiles: ({ files }) => {
    dispatch({
      type: ActionTypes.FILES_UPLOADED,
      payload: { files }
    });
  },
  addDocument: ({ document }) => {
    dispatch({
      type: ActionTypes.DOCUMENT_ADDED,
      payload: { document }
    });
  },
  downloadAllDocuments: () => {
    dispatch({
      type: ActionTypes.ALL_DOCUMENTS_DOWNLOADED
    });
  },
  sortDocuments: (col, dir, sortFn) => {
    dispatch({
      type: ActionTypes.SORT_DOCUMENTS_REQUESTED,
      payload: { col, dir, sortFn }
    });
  }
}));

const indexByDocumentId = documents =>
  documents.reduce(
    (acc, document) => ({
      ...acc,
      [document.documentId]: document
    }),
    {}
  );
const indexByLabelId = labels =>
  labels.reduce(
    (acc, label) => ({
      ...acc,
      [label.labelId]: label
    }),
    {}
  );

const initialDocumentsState = {
  loaded: false,
  loading: false,
  entities: {},
  entityIds: [],
  deletedEntities: {},
  error: null
};

const initialLabelsState = {
  loaded: false,
  entities: {},
  error: null
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "_resetProfileAndDocuments": {
      const { profile, documents } = payload;
      return {
        ...state,
        profile,
        documents
      };
    }
    case ActionTypes.DOCUMENTS_REQUESTED:
    case ActionTypes.DOCUMENTS_RELOAD_REQUESTED:
      return {
        ...state,
        documents: {
          ...initialDocumentsState,
          loading: true
        }
      };
    case ActionTypes.DOCUMENTS_REQUEST_FULFILLED:
      return {
        ...state,
        documents: {
          ...state.documents,
          loaded: true,
          loading: false,
          entities: indexByDocumentId(payload.documents),
          entityIds: payload.documents.map(d => d.documentId)
        }
      };
    case ActionTypes.DOCUMENTS_REQUEST_FAILED:
      return {
        ...state,
        documents: {
          ...state.documents,
          loading: false,
          error: {
            message: "Unexpected error fetching documents."
          }
        }
      };
    case ActionTypes.DOCUMENT_LABELS_REQUESTED:
      return {
        ...state,
        labels: initialLabelsState
      };
    case ActionTypes.DOCUMENT_LABELS_REQUEST_FULFILLED:
      return {
        ...state,
        labels: {
          ...state.labels,
          loaded: true,
          entities: indexByLabelId(payload.labels)
        }
      };
    case ActionTypes.DOCUMENT_LABELS_REQUEST_FAILED:
      return {
        ...state,
        labels: {
          ...state.labels,
          error: {
            message: "Unexpected error fetching document labels."
          }
        }
      };
    case ActionTypes.LABEL_UPDATED: {
      const { documentId, label } = payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          entities: {
            ...state.documents.entities,
            [documentId]: {
              ...state.documents.entities[documentId],
              label,
              isDirty: true
            }
          }
        }
      };
    }
    case ActionTypes.DOCUMENT_DELETED: {
      const { documentId } = payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          entities: omit(state.documents.entities, documentId),
          entityIds: state.documents.entityIds.filter(id => id !== documentId),
          deletedEntities: {
            ...state.documents.deletedEntities,
            [documentId]: state.documents.entities[documentId]
          }
        }
      };
    }
    case ActionTypes.DOCUMENT_ADDED: {
      const { document } = payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          entities: {
            ...state.documents.entities,
            [document.documentId]: document
          },
          entityIds: state.documents.entityIds.concat(document.documentId)
        }
      };
    }
    case ActionTypes.SORT_DOCUMENTS_REQUESTED:
      const { col, dir, sortFn } = payload;
      return {
        ...state,
        documents: {
          ...state.documents,
          entityIds: sortDocuments(col, dir, values(state.documents.entities), sortFn).map(d => d.documentId)
        }
      };
    default:
      return state;
  }
};

const transformDocuments = documents =>
  documents.map(({ playerDocsId: documentId, documentLabel: { labelId, text }, fileName, createdTs }) => ({
    documentId,
    label: { labelId, text },
    fileName,
    isNew: false,
    isDirty: false,
    createdTs
  }));

const sortDocuments = (col, dir, documents, sortFn) => {
  return sortFn ? sortFn(col, dir, documents) : sortList(col, dir, documents);
};

const middleware = dispatch => {
  const {
    fulfillDocumentsRequest,
    failDocumentsRequest,
    failDocumentDownload,
    fulfillDocumentLabelsRequest,
    failDocumentLabelsRequest,
    addDocument
  } = createActions(dispatch);

  const onDocumentsRequestFulfilled = documents => {
    fulfillDocumentsRequest({ documents });
  };

  const onDocumentsRequestFailed = () => {
    failDocumentsRequest();
  };

  const onDocumentDownloadFulfilled = ({ url, headers }) => {
    FileHelper.downloadFile(url, headers);
  };

  const onDocumentDownloadFailed = () => {
    failDocumentDownload();
  };

  const onDocumentLabelsRequestFulfilled = labels => {
    fulfillDocumentLabelsRequest({ labels });
  };

  const onDocumentLabelsRequestFailed = () => {
    failDocumentLabelsRequest();
  };

  const nextNewDocumentId = (() => {
    let newDocumentId = -1;
    return () => newDocumentId--;
  })();

  return (prevState, nextState, { type, payload }) => {
    switch (type) {
      case ActionTypes.DOCUMENTS_REQUESTED:
      case ActionTypes.DOCUMENTS_RELOAD_REQUESTED: {
        const { profileId, fedTeamId } = nextState.profile;

        PlayerDocumentsApi.getPlayerDocuments(profileId, fedTeamId)
          .then(transformDocuments)
          .then(documents => sortDocuments("createdTs", "DESC", documents))
          .then(onDocumentsRequestFulfilled, onDocumentsRequestFailed);
        break;
      }
      case ActionTypes.DOCUMENT_DOWNLOAD_REQUESTED: {
        const { documentId } = payload;
        const {
          profile: { profileId, fedTeamId }
        } = nextState;

        PlayerDocumentsApi.downloadDocument(fedTeamId, documentId, profileId, (url, headers) => ({
          url,
          headers
        })).then(onDocumentDownloadFulfilled, onDocumentDownloadFailed);
        break;
      }
      case ActionTypes.DOCUMENT_LABELS_REQUESTED: {
        const {
          profile: { labelTypeId }
        } = nextState;

        PlayerDocumentsApi.getDocumentLabels(labelTypeId).then(
          onDocumentLabelsRequestFulfilled,
          onDocumentLabelsRequestFailed
        );
        break;
      }
      case ActionTypes.FILES_UPLOADED: {
        const { files } = payload;

        files.forEach(async file =>
          addDocument({
            document: {
              documentId: nextNewDocumentId(),
              label: null,
              fileName: file.name,
              file: await readFile(file),
              isNew: true,
              isDirty: false
            }
          })
        );
        break;
      }
      case ActionTypes.ALL_DOCUMENTS_DOWNLOADED: {
        const {
          profile: { profileId, fedTeamId }
        } = nextState;
        const promises = getDocuments(nextState)
          .filter(({ isNew }) => !isNew)
          .map(({ documentId }) =>
            PlayerDocumentsApi.downloadDocument(fedTeamId, documentId, profileId, (url, headers) => ({
              url,
              headers
            }))
          );

        Promise.all(promises).then(function(dataList) {
          dataList.forEach(({ url, headers }, i) =>
            setTimeout(() => FileHelper.downloadFile(url, headers), 200 + i * 200)
          );
        });
        break;
      }
      default:
        break;
    }
  };
};

const middlewares = [middleware];

const DocumentsContextProvider = ({ profileId, fedTeamId, labelTypeId, children }) => {
  const { selectedYear } = useContext(WBCYearsContext);

  const profile = useMemo(
    () => ({
      profileId,
      fedTeamId,
      labelTypeId,
      wbcYear: selectedYear,
      isNew: !profileId
    }),
    [profileId, fedTeamId, labelTypeId, selectedYear]
  );
  const initialState = useMemo(
    () => ({
      profile,
      documents: initialDocumentsState,
      labels: initialLabelsState
    }),
    [profile]
  );

  const [state, dispatch] = useEnhancedReducer(reducer, initialState, middlewares);
  const actions = createActions(dispatch);

  useEffect(() => {
    dispatch({
      type: "_resetProfileAndDocuments",
      payload: {
        profile,
        documents: initialDocumentsState
      }
    });
  }, [dispatch, profile]);

  return <DocumentsContext.Provider value={{ state, actions }}>{children}</DocumentsContext.Provider>;
};

DocumentsContextProvider.propTypes = {
  profileId: PropTypes.number,
  fedTeamId: PropTypes.number,
  labelTypeId: PropTypes.number,
  children: PropTypes.node
};

export default DocumentsContextProvider;
