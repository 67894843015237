import { useCallback } from "react";
import FileHelper from "../../httpClients/FileHelper";

const useDownloadFile = () => {
  return useCallback(({ blob, headers }) => {
    const url = window.URL.createObjectURL(new Blob([blob], { type: headers["content-type"] }));
    FileHelper.downloadFile(url, headers);
  }, []);
};

export default useDownloadFile;
