import React, { useState } from "react";
import PropTypes from "prop-types";
import LoadingContext from "../contexts/LoadingContext";

const LoadingContextProvider = ({ children }) => {
  //Could not use useReducer as that was causing infinite re-renders from HttpInterceptor
  const [isLoading, setLoading] = useState(false);

  return <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>;
};

LoadingContextProvider.propTypes = {
  children: PropTypes.object
};

export default LoadingContextProvider;
