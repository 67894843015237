export default {
  EVENT_TYPES: {
    WORKOUT: {
      CODE: "Workout",
      ID: 1
    },
    EXHIBITION: {
      CODE: "Exhibition",
      ID: 2
    }
  },
  EVENT_TYPE_OPTIONS: [
    { value: 1, label: "Workout" },
    { value: 2, label: "Exhibition" }
  ]
};
