import React from "react";
import PropTypes from "prop-types";
import AlertContextProvider from "../AlertContextProvider";
import GlobalFedDropdownContextProvider from "../GlobalFedDropdownContextProvider";
import TournamentFormContextProvider from "../TournamentFormContextProvider";
import TableSortFilterContextProvider from "../TableSortFilterContextProvider";

const UIContextProviderWrapper = ({ children }) => {
  return (
    <AlertContextProvider>
      <GlobalFedDropdownContextProvider>
        <TournamentFormContextProvider>
          <TableSortFilterContextProvider>{children}</TableSortFilterContextProvider>
        </TournamentFormContextProvider>
      </GlobalFedDropdownContextProvider>
    </AlertContextProvider>
  );
};

UIContextProviderWrapper.propTypes = {
  children: PropTypes.object
};

export default UIContextProviderWrapper;
