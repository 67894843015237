import React, { useReducer } from "react";
import PropTypes from "prop-types";
import OrgsContext from "../contexts/OrgsContext";

const initialState = {
  orgs: [],
  orgsDropdown: [],
  orgIdToCode: {}
};

const OrgsContextProvider = ({ children }) => {
  const getOrgCode = orgId => {
    const orgCode = orgId ? state.orgIdToCode[orgId]?.toUpperCase() : null;
    return ["BOC", "MSB", "NONE", null, ""]?.includes(orgCode) ? null : orgCode;
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "initialize":
        return { ...state, orgsDropdown: action.orgsDropdown, orgIdToCode: action.orgIdToCode };
      case "setOrgs":
        return { ...state, orgs: action.orgs };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <OrgsContext.Provider value={{ state, dispatch, getOrgCode }}>{children}</OrgsContext.Provider>;
};

OrgsContextProvider.propTypes = {
  children: PropTypes.object
};

export default OrgsContextProvider;
