import React from "react";
import PropTypes from "prop-types";

const OrgNameFormatter = ({ value }) => {
  if (value && value.toLowerCase() === "no") value = "NONE";
  if (["BOC", "MSB", "NONE", null, ""].includes(value?.toUpperCase())) {
    value = null;
  }
  return <div>{value ? value : null}</div>;
};

OrgNameFormatter.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default OrgNameFormatter;
