import React from "react";
import PropTypes from "prop-types";
import wordsToNumbers from "words-to-numbers";

const RosterStatusFormatter = ({ value, row }) => {
  if (!value && row?.ebisMilbRosterStatus) {
    value = row.ebisMilbRosterStatus;
  }
  if (!value && row?.milbRosterStatus) {
    value = row.milbRosterStatus;
  }

  if (value) {
    let valueString = value.replace(/Il/g, "IL");
    valueString = valueString.replace(/\b\w+\b/g, word => {
      const num = wordsToNumbers(word);
      return num !== undefined ? num.toString() : word;
    });
    return <div>{valueString}</div>;
  }
  return null;
};

RosterStatusFormatter.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.object
};

export default RosterStatusFormatter;
