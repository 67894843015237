import BaseApi from "./BaseApi";

export default class MLBPlayerRequestApi extends BaseApi {
  constructor() {
    if (new.target === MLBPlayerRequestApi) {
      throw new TypeError("Cannot construct MLBPlayerRequestApi instances directly");
    }
  }

  static getMLBPlayerRequestsByYear = year => this.get(`mlbplayerrequest?wbcYear=${year}`);
  static resubmitPlayerRequest = playerProfileId => this.put(`mlbplayerrequest/resubmit?profileId=${playerProfileId}`);
  static updatePlayerRequestStatus = (playerProfileId, status) =>
    this.put(`mlbplayerrequest/updateRequestStatus?profileId=${playerProfileId}&newStatus=${status}`);
  static updatePlayerRequestComments = (playerProfileId, fedComments) =>
    this.put(`mlbplayerrequest/updateFedComments?profileId=${playerProfileId}&fedComments=${fedComments}`);
}
