import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { BestSelect } from "best-common-react";
import LoadingPitchTrackerTable from "./LoadingPitchTrackerTable";
import WBCContent from "../elements/WBCContent";
import { openUnsavedChangesModal } from "../elements/UnsavedChangesModal";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { useAlert } from "../hooks/useAlert";
import useDownloadFile from "../hooks/useDownloadFile";
import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import { MAIN, QUALIFYING, ALL_TOURNAMENT_TYPES, TOURNAMENT_TYPE_BY_ID } from "../../constants/TournamentTypeConstants";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import PitchTrackerContext from "../../contexts/PitchTrackerContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import ExportApi from "../../httpClients/ExportApi";
import { getHasPitchers } from "../../selectors/PitchTrackerSelectors";
import { getIsDirty, getIsValid } from "../../selectors/PitchTrackerSelectors";

const qualifyingOption = { value: QUALIFYING, label: "Qualifiers" };
const mainOption = { value: MAIN, label: "Tournament" };
const tournamentTypeOptions = [qualifyingOption, mainOption];
export const optionsByValue = {
  [QUALIFYING]: qualifyingOption,
  [MAIN]: mainOption
};

const PitchTrackerTableContainer = styled.div`
  overflow: auto;
  max-height: 400px;
`;

const TournamentTypeDropdownContainer = styled.div`
  width: 300px;
  margin-bottom: 24px;
`;

TournamentTypeDropdownContainer.displayName = "TournamentTypeDropdownContainer";

const Footer = styled.footer`
  margin-top: 16px;
  margin-bottom: 128px;
`;

Footer.displayName = "Footer";

const PitchTrackerButtonContainer = ({ children }) => <div className="d-flex justify-content-end">{children}</div>;

PitchTrackerButtonContainer.displayName = "PitchTrackerButtonContainer";

PitchTrackerButtonContainer.propTypes = {
  children: PropTypes.node
};

const PitchTrackerButton = styled(WBCPrimaryOutlinedButton)`
  &&& {
    white-space: nowrap;
    width: auto;
    min-width: 90px;
    display: inline-block !important;
    margin-left: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const PitchTrackerSection = styled(({ defaultTournamentType, ...rest }) => {
  /*
   * State
   */
  const [selectedTournamentTypeOption, setSelectedTournamentTypeOption] = useState(
    optionsByValue[defaultTournamentType]
  );

  /*
   * Variables
   */
  const {
    state,
    state: { saving, savingError, previewLoadingError },
    actions: { discardPitchCountUpdates, pitchCountsAddPlayerMode, savePitchCounts }
  } = useContext(PitchTrackerContext);
  const isMainTournamentEnabled = useIsFeatureEnabled("mainTournament");
  const fedTeamId = useContext(SelectedTeamContext).state.team.teamId;
  const {
    state: { cleanForm, onFormDirty },
    dispatch: tournamentFormDispatch
  } = useContext(TournamentFormContext);
  const globalModalContext = useContext(GlobalModalContext);
  const { selectedYear } = useContext(WBCYearsContext);

  const selectedTournamentType = useMemo(() => selectedTournamentTypeOption.value, [selectedTournamentTypeOption]);
  const isDirty = getIsDirty(state);
  const isValid = getIsValid(state);
  const hasPitchers = getHasPitchers(state);

  const savingPromiseActionsRef = useRef(null);
  const tournamentTypeOptionToSelectAfterSaveRef = useRef(null);

  /*
   * Callbacks
   */
  const onSave = useCallback(() => {
    savePitchCounts({ tournamentType: selectedTournamentType, fedTeamId });
    // WBCTeamSelect requires onSave to return promise
    return new Promise((resolve, reject) => {
      savingPromiseActionsRef.current = { resolve, reject };
    });
  }, [savePitchCounts, selectedTournamentType, fedTeamId]);

  const onSubmit = useCallback(
    e => {
      e.preventDefault();
      if (isDirty) {
        onSave();
      }
    },
    [isDirty, onSave]
  );

  const onTournamentTypeChange = useCallback(
    tournamentType => {
      if (isDirty) {
        tournamentTypeOptionToSelectAfterSaveRef.current = tournamentType;

        const saveArg = null;
        const onCancel = () => {
          discardPitchCountUpdates();
          setSelectedTournamentTypeOption(tournamentType);
        };
        const discardArg = null;

        openUnsavedChangesModal(globalModalContext, onSave, saveArg, onCancel, discardArg);
      } else {
        setSelectedTournamentTypeOption(tournamentType);
      }
    },
    [discardPitchCountUpdates, globalModalContext, isDirty, onSave]
  );

  const showAlert = useAlert();
  const showSuccessAlert = showAlert;
  const showErrorAlert = useCallback((errorMessage, id) => showAlert(errorMessage, "danger", id), [showAlert]);

  const addPlayerMode = () => {
    pitchCountsAddPlayerMode(true);
  };

  const downloadFile = useDownloadFile();
  const downloadFullPitchTrackerReport = useCallback(
    () => ExportApi.exportFullPitchTrackerReport(selectedTournamentType, selectedYear).then(downloadFile),
    [selectedTournamentType, downloadFile, selectedYear]
  );
  const downloadTeamPitchTrackerReport = useCallback(() => {
    const userTimezoneOffset = new Date().getTimezoneOffset();
    ExportApi.exportTeamPitchTrackerReport(fedTeamId, selectedTournamentType, userTimezoneOffset).then(downloadFile);
  }, [fedTeamId, selectedTournamentType, downloadFile]);

  /*
   * Effects
   */
  // register callbacks for save and cancel buttons
  useEffect(() => {
    tournamentFormDispatch({
      type: "setOnSave",
      onSave
    });
  }, [tournamentFormDispatch, onSave]);
  useEffect(() => {
    tournamentFormDispatch({
      type: "setOnCancel",
      onCancel: discardPitchCountUpdates
    });
  }, [tournamentFormDispatch, discardPitchCountUpdates]);

  /**
   * manage form dirty state and toggle save/cancel buttons enabled
   */
  useEffect(
    () => {
      if (isDirty) {
        onFormDirty();
      } else {
        cleanForm();
      }
    },
    // form is cleansed immediately upon save, but isDirty is still true if
    // there is a savingError, so watch for savingError too
    [onFormDirty, isDirty, cleanForm, savingError]
  );

  /**
   * show error when invalid, clear when valid
   */
  useEffect(() => {
    if (!isValid) {
      return showErrorAlert("Invalid pitch counts. One or more pitchers have pitches on required rest days.");
    }
  }, [isValid, showErrorAlert]);

  /**
   * handle when save completes
   */
  useEffect(() => {
    let clearAlert;
    const savingPromiseActions = savingPromiseActionsRef.current;
    const hasCompletedSaving = !!savingPromiseActions && !saving;

    if (hasCompletedSaving) {
      const { resolve, reject } = savingPromiseActions;
      const tournamentTypeOptionToSelectAfterSave = tournamentTypeOptionToSelectAfterSaveRef.current;

      if (savingError) {
        clearAlert = showErrorAlert("Error saving pitch counts.", "danger");
        reject(savingError);
      } else {
        if (tournamentTypeOptionToSelectAfterSave) {
          setSelectedTournamentTypeOption(tournamentTypeOptionToSelectAfterSave);
        }
        clearAlert = showSuccessAlert("Saved");
        // wait to complete to ensure success alert shows
        setTimeout(resolve, 500);
      }

      savingPromiseActionsRef.current = null;
      tournamentTypeOptionToSelectAfterSaveRef.current = null;
    }

    return clearAlert;
  }, [showSuccessAlert, showErrorAlert, saving, savingError]);

  /**
   * show error when preview fails
   */
  useEffect(() => {
    if (previewLoadingError) {
      return showErrorAlert(
        "Unexpected server error previewing pitcher rest days. Displayed rest days may be inaccurate."
      );
    }
  }, [previewLoadingError, showErrorAlert]);

  /*
   * Render
   */
  return (
    <section {...rest}>
      {isMainTournamentEnabled && (
        <TournamentTypeDropdownContainer>
          <BestSelect
            name="tournamentType"
            options={tournamentTypeOptions}
            value={selectedTournamentTypeOption}
            onChange={onTournamentTypeChange}
          />
        </TournamentTypeDropdownContainer>
      )}
      <form data-testid="pitch-tracker-form" onSubmit={onSubmit}>
        <input type="submit" disabled={!isDirty} style={{ display: "none" }} />
        <PitchTrackerTableContainer>
          <LoadingPitchTrackerTable tournamentType={selectedTournamentType} />
        </PitchTrackerTableContainer>
      </form>
      <Footer>
        <PitchTrackerButtonContainer>
          {hasPitchers && (
            <>
              <PitchTrackerButton className="btn-md" onClick={addPlayerMode}>
                Add Player
              </PitchTrackerButton>
              <PitchTrackerButton className="btn-md" onClick={downloadTeamPitchTrackerReport}>
                Export
              </PitchTrackerButton>
            </>
          )}
          <PitchTrackerButton className="btn-md" onClick={downloadFullPitchTrackerReport}>
            Export All Feds
          </PitchTrackerButton>
        </PitchTrackerButtonContainer>
      </Footer>
    </section>
  );
})`
  max-width: fit-content;
`;

PitchTrackerSection.displayName = "PitchTrackerSection";

PitchTrackerSection.propTypes = {
  defaultTournamentType: PropTypes.oneOf(ALL_TOURNAMENT_TYPES).isRequired
};

const getTournamentType = wbcYear =>
  wbcYear &&
  wbcYear.generalRosterSettings &&
  wbcYear.generalRosterSettings.tournamentTypeId &&
  TOURNAMENT_TYPE_BY_ID[wbcYear.generalRosterSettings.tournamentTypeId];

const PitchTracker = () => {
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const tournamentType = useMemo(() => getTournamentType(selectedWbcYear), [selectedWbcYear]);

  return (
    <WBCContent data-testid="pitch-tracker">
      {tournamentType && <PitchTrackerSection defaultTournamentType={tournamentType} />}
    </WBCContent>
  );
};

PitchTracker.displayName = "PitchTracker";

export default PitchTracker;
