import BaseApi from "./BaseApi";

export default class WBCBaseballInfoApi extends BaseApi {
  static getAllOrgs = () => this.get("orgs");

  static getAllClubs = () => this.get("clubs");

  static getActiveClubs = () => this.get("clubs/active");

  static getAllPositions = () => this.get("positions");

  static getAllLanguages = () => this.get("languages");
}
