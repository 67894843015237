import _ from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";

import DnDContext from "../../../contexts/DnDContext";
import RosterContext from "../../../contexts/RosterContext";
import { useDraggable } from "../../hooks/useDraggable";
import Placeholder from "./Placeholder";
import PositionRow from "./PositionRow";
import AuthContext from "../../../contexts/AuthContext";

const Border = styled.div`
  border: 1px solid ${props => props.theme["light-grey"]};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  width: 100%;
  padding-left: 12px;
  background-color: #f7f7f7;
  border-bottom: 1px solid ${props => props.theme["light-grey"]};

  font-size: 14px;
  font-weight: bold;
  font-family: Helvetica;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};

  @supports (-moz-appearance: none) {
    line-height: 32px;
  }
`;

const HeaderText = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  color: ${props => props.theme["dark-grey"]};
`;

const Minimum = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: ${({ invalid, theme }) => (invalid ? theme.wbcRed : theme["dark-grey"])};
`;

const List = styled.ol`
  margin: 0;
  padding-left: 0;
  list-style: none;
  counter-reset: item-counter;
`;

const PositionList = ({ className, droppableId, header, maximum, minimum }) => {
  // context(s)
  const dndContext = useContext(DnDContext);
  const rosterContext = useContext(RosterContext);
  const authContext = useContext(AuthContext);
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;

  const notDraggable = !(isBOCadmin || isFedAdmin || isTeamCoordinator);

  // variable(s)
  const { final: values, idToDataMap: hashTable } = rosterContext.state;
  const hash = hashTable[droppableId];
  const rows = values[hash];
  const remaining = _.range(rows.length, rows.length + Math.max(maximum - rows.length, 0));

  // hooks(s)
  useDraggable(droppableId, "final");

  // effect(s)
  useEffect(() => {
    if (dndContext?.state[droppableId]?.isMounted) {
      dndContext.dispatch({
        type: "listMetadata",
        droppableId: droppableId,
        listMetadata: { remaining: remaining?.length }
      });
    }
  }, [dndContext, droppableId, remaining.length]);

  return (
    <Border className={className}>
      <Header>
        <HeaderText>{header}</HeaderText>
        {minimum ? <Minimum invalid={rows.length < minimum}>{`minimum - ${minimum}`}</Minimum> : null}
      </Header>
      <Droppable type="ATHLETES" droppableId={droppableId} isDropDisabled={notDraggable}>
        {provided => (
          <List ref={provided.innerRef} {...provided.droppableProps}>
            {rows.map((row, i) => (
              <PositionRow
                key={row.profileId}
                row={row}
                index={i}
                droppableId={droppableId}
                isDragDisabled={notDraggable}
              />
            ))}
            {remaining.map(i => (
              <Placeholder key={`${droppableId}-p${i}`} dragId={`${droppableId}-p${i}`} index={i} />
            ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </Border>
  );
};

PositionList.propTypes = {
  className: PropTypes.string,
  droppableId: PropTypes.string,
  header: PropTypes.string,
  maximum: PropTypes.number,
  minimum: PropTypes.number
};

export default PositionList;
