import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Theme from "../../Theme";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import LoadingContext from "../../contexts/LoadingContext";

const LoadingCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-image: linear-gradient(196deg, ${Theme.wbcYellow}, ${Theme.wbcBlue});
  border-radius: 50%;
  animation: spin 2s linear infinite;
  @keyframes spin {
    from {
      transform: scale3d(1, 1, 1) rotateZ(0deg);
    }
    to {
      transform: scale3d(1, 1, 1) rotateZ(360deg);
    }
  }
`;

const InnerCircle = styled.div`
  width: 14px;
  height: 14px;
  background-color: ${Theme["light-grey"]};
  border-radius: 50%;
`;

const WBCLoadingButton = ({ className, onClick, disabled, text, alwaysShowSpinner, styles, height, width }) => {
  const { isLoading } = useContext(LoadingContext);
  return (
    <WBCPrimaryButton
      className={`btn-md ${className || ""}`.trim()}
      onClick={event => onClick(event)}
      disabled={disabled}
      styles={styles}
      height={height}
      width={width}
    >
      {alwaysShowSpinner || isLoading ? (
        <LoadingCircle>
          <InnerCircle />
        </LoadingCircle>
      ) : (
        text
      )}
    </WBCPrimaryButton>
  );
};

WBCLoadingButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  alwaysShowSpinner: PropTypes.bool,
  styles: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number
};

WBCLoadingButton.defaultProps = {
  text: "Save",
  onClick: () => {},
  alwaysShowSpinner: false
};

export default WBCLoadingButton;
