import PropTypes from "prop-types";
import useHasAdminAccess from "../hooks/useHasAdminAccess";

const WBCAdminAccess = ({ bocOnly, orElse, children }) => (useHasAdminAccess(bocOnly) ? children : orElse);

WBCAdminAccess.propTypes = {
  bocOnly: PropTypes.bool,
  orElse: PropTypes.node,
  children: PropTypes.node
};

WBCAdminAccess.defaultProps = {
  bocOnly: false,
  orElse: null
};

export default WBCAdminAccess;
