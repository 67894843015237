import React from "react";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import DisabledInputStyle from "../../../elements/DisabledInputStyle";
import { NumberInputWrapper, BreakoutTableData, BreakoutTableText } from "./PlayerModalStyling";

const PitcherBreakoutBamTotalsRow = ({ header, data }) => {
  return (
    <tr>
      <BreakoutTableData>
        <BreakoutTableText>Bam Totals</BreakoutTableText>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          <FormInput
            type="input"
            name="count"
            isDisabled
            value={data.pitchesThrown ? data.pitchesThrown : null}
            editable
            inputStyle={DisabledInputStyle}
            label=""
          />
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          <FormInput
            type="input"
            name="strikes"
            isDisabled
            value={data.strikesThrown ? data.strikesThrown : null}
            editable
            inputStyle={DisabledInputStyle}
            label=""
          />
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          <FormInput
            type="input"
            name="balls"
            isDisabled
            value={data.ballsThrown ? data.ballsThrown : null}
            editable
            inputStyle={DisabledInputStyle}
            label=""
          />
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          <FormInput
            type="input"
            name="highSpeed"
            isDisabled
            value={data.highestPitchSpeed ? data.highestPitchSpeed.toFixed(2) : null}
            editable
            inputStyle={DisabledInputStyle}
            label=""
          />
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          <FormInput
            type="input"
            name="avgSpeed"
            isDisabled
            value={data.averagePitchSpeed ? data.averagePitchSpeed.toFixed(2) : null}
            editable
            inputStyle={DisabledInputStyle}
            label=""
          />
        </NumberInputWrapper>
      </BreakoutTableData>
    </tr>
  );
};

PitcherBreakoutBamTotalsRow.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default PitcherBreakoutBamTotalsRow;
