import React, { useContext } from "react";
import ClubCommDashboardContext from "../../../contexts/ClubCommDashboardContext";
import { BestSelect } from "best-common-react";

const ClubCommStatusSelect = () => {
  const clubCommDbContext = useContext(ClubCommDashboardContext);

  const { status, statuses } = clubCommDbContext.state;

  return (
    <div className="form-group">
      <BestSelect
        name="eventReportStatusSelect"
        value={status}
        options={statuses}
        onChange={selection => {
          clubCommDbContext.dispatch({
            type: "setStatus",
            value: selection.value,
            label: selection.label
          });
        }}
      />
    </div>
  );
};

export default ClubCommStatusSelect;
