import React, { useContext } from "react";
import { Draggable } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import { areEqual } from "react-window";
import Player from "./Player";
import AuthContext from "../../../contexts/AuthContext";
import useHasAdminAccess from "../../hooks/useHasAdminAccess";

// TODO: when working, comment aout all this
// import { shallowDiffers } from "../../../utils/ObjectUtils";

// const areEqual = (prevProps, nextProps) => {
//   const { style: prevStyle, data: prevData, ...prevRest } = prevProps;
//   const { style: nextStyle, data: nextData, ...nextRest } = nextProps;

//   // do the default checks not concerning data
//   if (shallowDiffers(prevStyle, nextStyle) || shallowDiffers(prevRest, nextRest)) {
//     return false;
//   }

//   // compare droppableId
//   if (prevData.droppableId !== nextData.droppableId) {
//     return false;
//   }

//   // compare prevPlayer and nextPlayer
//   const prevPlayer = prevData.players[prevRest.index];
//   const nextPlayer = nextData.players[nextRest.index];

//   return !shallowDiffers(prevPlayer, nextPlayer);
// };

const PlayerRow = React.memo(({ data, index, style }) => {
  const authContext = useContext(AuthContext);
  const hasAdminAccess = useHasAdminAccess();
  const dataIndex = index - 1; // the header is at index 0

  const { players, droppableId, ...rest } = data;
  const { isBOCadmin } = authContext.state;
  const player = players[dataIndex] || {};
  const dragId = `${droppableId}-${player.profileId}`;

  return !players[dataIndex] ? null : (
    <Draggable
      index={dataIndex}
      draggableId={dragId}
      key={dragId}
      isDragDisabled={!hasAdminAccess || (!isBOCadmin && (!player.hasPpa || player.inFinalRoster))}
    >
      {(provided, snapshot) => (
        <Player
          provided={provided}
          snapshot={snapshot}
          player={player}
          style={style}
          droppableId={droppableId}
          index={dataIndex}
          {...rest}
        />
      )}
    </Draggable>
  );
}, areEqual);

PlayerRow.displayName = "Player Row";

PlayerRow.propTypes = {
  index: PropTypes.number,
  data: PropTypes.object,
  style: PropTypes.object
};

export default PlayerRow;
