import React from "react";
import PropTypes from "prop-types";
import { FormColumn4Wide, FormInput, BestLabel } from "best-common-react";
import WBCRadioButton from "../../elements/WBCRadioButton";
import { Label, Required } from "./CreateProfileFormStyles";

const EbisIdForm = ({ person, searchEnabled, setPerson, setUpSearch }) => {
  return (
    <div className="d-flex flex-wrap flex-lg-nowrap">
      <div className="d-flex align-items-center" style={{ width: "35px", paddingTop: "7px" }}>
        <WBCRadioButton checked={searchEnabled} onClick={() => setUpSearch({ isBioInfoSearch: false })} />
      </div>
      <div className="row w-100">
        <FormColumn4Wide className="pl-lg-0">
          <BestLabel>
            <Label required={searchEnabled}>Ebis ID</Label>
            <Required required={searchEnabled}>*</Required>
          </BestLabel>
          <FormInput
            editable
            type="text"
            name="ebisId"
            isRequired={searchEnabled}
            isDisabled={!searchEnabled}
            value={person.ebisId || ""}
            label=""
            onChangeFn={value => {
              if (value.length <= 10) {
                setPerson({ ...person, ebisId: value.replace(/\D/g, "") });
              }
            }}
          />
        </FormColumn4Wide>
      </div>
    </div>
  );
};

EbisIdForm.propTypes = {
  person: PropTypes.object,
  searchEnabled: PropTypes.bool,
  setPerson: PropTypes.func,
  setUpSearch: PropTypes.func
};

export default EbisIdForm;
