import React, { useContext } from "react";
import EmailNotificationsTable from "./EmailNotificationsTable";
import EmailNotificationsHeader from "./EmailNotificationsHeader";
import { EmailNotificationsContainer } from "./EmailNotificationsStyles";
import EmailNotificationsContext from "../../contexts/EmailNotificationsContext";
import styled from "styled-components";

const SendingScreen = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(30, 55, 109, 0.4);

  .loader-text {
    font-size: 1.5rem;
    font-weight: 300;
    color: #fff;
    margin-bottom: 1rem;
  }

  .loader {
    width: 60px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #fff 90%, #0000);
    background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
    background-size: calc(100% / 3) 100%;
    animation: l7 2s infinite linear;
  }
  @keyframes l7 {
    33% {
      background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
    }
    50% {
      background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
    }
    66% {
      background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
    }
  }
`;

const EmailNotifications = () => {
  const { isSending } = useContext(EmailNotificationsContext);

  return (
    <EmailNotificationsContainer>
      {isSending ? (
        <SendingScreen>
          <div className="loader-text">Sending Notifications</div>
          <div className="loader"></div>
        </SendingScreen>
      ) : (
        <>
          <EmailNotificationsHeader />
          <EmailNotificationsTable />
        </>
      )}
    </EmailNotificationsContainer>
  );
};

export default EmailNotifications;
