import React, { useReducer } from "react";
import PropTypes from "prop-types";
import ClubsContext from "../contexts/ClubsContext";

const initialState = {
  clubs: [],
  clubsDropdown: [],
  clubIdToName: {}
};

const reducer = (state, action) => {
  switch (action.type) {
    case "initialize":
      return { ...state, clubsDropdown: action.clubsDropdown, clubIdToName: action.clubIdToName };
    case "setClubs":
      return { ...state, clubs: action.clubs };
    default:
      return state;
  }
};

const ClubsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ClubsContext.Provider value={{ state, dispatch }}>{children}</ClubsContext.Provider>;
};

ClubsContextProvider.propTypes = {
  children: PropTypes.object
};

export default ClubsContextProvider;
