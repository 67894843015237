import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Theme from "../../../Theme";

const PlayerCountTileContainer = styled.div.attrs(() => ({
  className: "col-xs col-md-4 col-lg-3 player-count-tile-container"
}))`
  &&& {
    cursor: pointer;
    padding: 0;
  }
`;

const PlayerCount = styled.div.attrs(props => ({
  className: "d-flex flex-row m-1 p-2 player-count"
}))`
  background: ${props => (props.selected ? Theme["focus-color"] : Theme.white)};
  border: 1px solid ${Theme.grey};
  justify-content: space-between;
  & div {
    display: flex;
    align-items: center;
    color: ${Theme.wbcBlue};
    &.player-count-label-text {
      font-size: 16px;
    }
    &.player-count-value {
      font-size: 21px;
      font-weight: bold;
    }
  }
`;

const PlayerCountLabel = styled.div.attrs(() => ({
  className: "player-count-label"
}))``;

const PlayerCountLabelImage = styled.div.attrs(() => ({
  className: "player-count-label-image mr-2"
}))``;

const PlayerCountLabelText = styled.div.attrs(() => ({
  className: "player-count-label-text"
}))``;

const PlayerCountValue = styled.div.attrs(() => ({
  className: "player-count-value"
}))``;

const PlayerCountTile = ({ image, label, playerCount, onClick, selected }) => {
  return (
    <PlayerCountTileContainer onClick={onClick}>
      <PlayerCount selected={selected}>
        <PlayerCountLabel>
          <PlayerCountLabelImage>{image}</PlayerCountLabelImage>
          <PlayerCountLabelText>{label}</PlayerCountLabelText>
        </PlayerCountLabel>
        <PlayerCountValue>{playerCount}</PlayerCountValue>
      </PlayerCount>
    </PlayerCountTileContainer>
  );
};

PlayerCountTile.propTypes = {
  image: PropTypes.element,
  label: PropTypes.string,
  playerCount: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default PlayerCountTile;
