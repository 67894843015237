import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { isEmpty } from "../../utils/StringUtils";
import { formatDate } from "../../utils/DateUtils";
import FlagHolder from "../elements/WBCFlagHolder";
import { FormColumn2Wide, FormInput, BestLabel } from "best-common-react";
import EventTypeConstants from "../../constants/EventTypeConstants";
import AuthContext from "../../contexts/AuthContext";
import DisabledInputStyle from "../elements/DisabledInputStyle";
import EventReportStatusConstants from "../../constants/EventReportStatusConstants";

const FormTitleWrapper = styled.div`
  &&& {
    color: #666666;
  }
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Title = styled.div.attrs(() => ({
  className: "mt-0 mb-3"
}))`
  width: 100%;
  border-bottom: 1px solid #b9d5e3;
  font-size: 1rem;
`;

const EventInfoSection = ({ eventReport, dispatch }) => {
  const authContext = useContext(AuthContext);

  const { isBOCadmin, isFedAdmin } = authContext.state;

  const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

  return (
    <>
      <Title style={{ marginTop: "0px !important" }}>
        <FormTitleWrapper>Event Info</FormTitleWrapper>
      </Title>
      <div className={"row"}>
        <FormColumn2Wide>
          <div className="form-group">
            {!isEmpty(eventReport.teamCode) ? (
              <FlagHolder
                className={"mb-1"}
                style={{ width: "30px", height: "16px" }}
                src={`${FLAG_URL}/${eventReport.teamCode}.svg`}
              />
            ) : (
              <></>
            )}
            <br />
            <Bold>{eventReport.teamName}</Bold>
          </div>
        </FormColumn2Wide>
        <FormColumn2Wide>
          <div className="form-group">
            <BestLabel>Date</BestLabel>
            <br />
            {formatDate(eventReport.localEventDate ? eventReport.localEventDate : eventReport.eventDate)}
          </div>
        </FormColumn2Wide>
      </div>
      <div className="row">
        {!eventReport.gamePk ||
        (eventReport.gamePk && EventTypeConstants.EVENT_TYPES.EXHIBITION.ID === eventReport.eventTypeId) ? (
          <FormColumn2Wide>
            <div className="form-group">
              <FormInput
                isDisabled={false}
                name={"eventType"}
                label={"Type"}
                type={"select"}
                isRequired
                editable={
                  isBOCadmin ||
                  (isFedAdmin &&
                    !(eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id) &&
                    !(eventReport.eventReportStatusId === EventReportStatusConstants.Published.id))
                }
                options={EventTypeConstants.EVENT_TYPE_OPTIONS}
                value={{ value: eventReport.eventTypeId, label: eventReport.eventType }}
                onChangeFn={selected => {
                  dispatch({
                    type: "eventTypeOnChange",
                    eventTypeId: selected.value,
                    eventType: selected.label
                  });
                }}
              />
            </div>
          </FormColumn2Wide>
        ) : null}
        {(eventReport.gamePk || EventTypeConstants.EVENT_TYPES.EXHIBITION.CODE === eventReport.eventType) && (
          <FormColumn2Wide>
            <div className="form-group">
              <FormInput
                editable={
                  !eventReport.gamePk &&
                  (isBOCadmin ||
                    (isFedAdmin &&
                      !(eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id) &&
                      !(eventReport.eventReportStatusId === EventReportStatusConstants.Published.id)))
                }
                isRequired={!eventReport.gamePk}
                isDisabled={eventReport.gamePk !== null && eventReport.gamePk !== undefined}
                name="opponent"
                label={
                  EventTypeConstants.EVENT_TYPES.EXHIBITION.CODE === eventReport.eventType
                    ? "Exhibition Opponent"
                    : "Opponent"
                }
                type="text"
                value={eventReport.opponent}
                onChangeFn={value => {
                  dispatch({
                    type: "opponentOnChange",
                    opponent: value
                  });
                }}
                inputStyle={DisabledInputStyle}
              />
            </div>
          </FormColumn2Wide>
        )}
      </div>
    </>
  );
};

EventInfoSection.propTypes = {
  eventReport: PropTypes.object,
  dispatch: PropTypes.func
};

export default EventInfoSection;
