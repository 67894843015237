import React, { useState, useEffect, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import FederationTeamsCard from "./FederationTeamsCard";
import { InfoCard } from "best-common-react";
import WBCTeamApi from "../../../httpClients/WBCTeamApi";
import { QUALIFYING, MAIN } from "../../../constants/TournamentTypeConstants";
import CountryContext from "../../../contexts/CountryContext";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import FederationTeamConstants from "../../../constants/FederationTeamConstants";
import SettingsFormContext from "../../../contexts/SettingsFormContext";
import WBCCollapse from "../../elements/WBCCollapse";

const FederationCardWrapper = styled.div`
  padding-top: 25px;
`;

const FederationTeams = ({ mainTournamentDateRange, qualifyingTournamentDateRange, onDateRangeChange, canEdit }) => {
  // hooks
  const [qualifierList, setQualifierList] = useState([]);
  const [tournamentList, setTournamentList] = useState([]);
  const countryContext = useContext(CountryContext);
  const settingsFormContext = useContext(SettingsFormContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);

  // variable(s)
  const { countryIdToName, countries } = countryContext.state;
  const { teamList, onFormDirty } = settingsFormContext.state;

  // dispatches
  const { dispatch: countryDispatch } = countryContext;
  const { dispatch: settingsDispatch } = settingsFormContext;
  const { dispatch: selectedDispatch } = selectedTeamContext;

  // function(s)
  const setTeamList = useCallback(
    teamList => {
      settingsDispatch({
        type: "setTeamList",
        teamList: teamList
      });
      settingsDispatch({
        type: "setTeamListChanged",
        teamListChanged: true
      });
    },
    [settingsDispatch]
  );

  const refreshList = useCallback(() => {
    setQualifierList(
      teamList.filter(t => {
        const targetStateIsQualifier =
          t.targetState === FederationTeamConstants.STATUS.QUALIFIER ||
          t.targetState === FederationTeamConstants.STATUS.BOTH;
        const isNewQualifier =
          !t.targetState &&
          t.currentState &&
          (t.currentState.statusCode === FederationTeamConstants.STATUS.QUALIFIER ||
            t.currentState.statusCode === FederationTeamConstants.STATUS.BOTH);
        return targetStateIsQualifier || isNewQualifier;
      })
    );

    setTournamentList(
      teamList.filter(t => {
        const targetStateIsTournament =
          t.targetState === FederationTeamConstants.STATUS.TOURNAMENT ||
          t.targetState === FederationTeamConstants.STATUS.BOTH;
        const isNewTournament =
          !t.targetState &&
          t.currentState &&
          (t.currentState.statusCode === FederationTeamConstants.STATUS.TOURNAMENT ||
            t.currentState.statusCode === FederationTeamConstants.STATUS.BOTH);
        return targetStateIsTournament || isNewTournament;
      })
    );

    settingsDispatch({ type: "setTeamListChanged", teamListChanged: true });
    selectedDispatch({ type: "setFedTeams", fedTeams: teamList });
  }, [teamList, settingsDispatch, selectedDispatch]);

  const MoveQualifierToTournament = team => {
    onFormDirty();
    setTeamList(
      teamList.map(t =>
        t.countryId === team.countryId ? { ...t, targetState: FederationTeamConstants.STATUS.BOTH } : t
      )
    );
  };

  const RemoveTeam = (team, type) => {
    onFormDirty();
    // Teams that are not yet saved and exist in only one section should just be removed
    if (!team.teamId && team.targetState !== FederationTeamConstants.STATUS.BOTH) {
      setTeamList(teamList.filter(t => t.countryId !== team.countryId));
    } else {
      // Teams that are saved or exist in both sections should have their status updated
      setTeamList(
        teamList.map(t => {
          let targetState = t.targetState;
          if (t.countryId === team.countryId) {
            const stateToCheck = t.targetState || team.currentState.statusCode;
            if (stateToCheck === FederationTeamConstants.STATUS.BOTH) {
              targetState = FederationTeamConstants.STATUS[type === "qualifiers" ? "TOURNAMENT" : "QUALIFIER"];
            } else {
              targetState = FederationTeamConstants.STATUS.REMOVED;
            }
          }

          return { ...t, targetState };
        })
      );
    }
  };

  const filterDuplicateTeams = (arr1, arr2) => {
    const filter = t => {
      for (let i = 0; i < arr2.length; i++) {
        if (t.countryId === arr2[i].countryId) return false;
      }
      return true;
    };
    const filtered = arr1.filter(filter);
    const dups = arr1.filter(t => !filter(t)).map(t => t.countryId);

    return [filtered, dups];
  };

  const setTargetState = (team, addToStatus) => {
    const qualifierStatus = FederationTeamConstants.STATUS.QUALIFIER;
    const tournamentStatus = FederationTeamConstants.STATUS.TOURNAMENT;
    const removedStatus = FederationTeamConstants.STATUS.REMOVED;

    const savedStatus = team.currentState ? team.currentState.statusCode : "";
    const targetStatus = team.targetState ? team.targetState : "";
    const currStatus = targetStatus !== "" ? targetStatus : savedStatus;

    // Adding a new team or a removed team
    if (targetStatus === removedStatus || (savedStatus === removedStatus && targetStatus === "")) {
      team.targetState = addToStatus;
      return true;
    }
    // Handle when a team is added from one list to the other
    else if (
      (currStatus === qualifierStatus && addToStatus === tournamentStatus) ||
      (currStatus === tournamentStatus && addToStatus === qualifierStatus)
    ) {
      team.targetState = FederationTeamConstants.STATUS.BOTH;
      return true;
    }
  };

  const addNewTeams = (teams, status) => {
    const [filtered, dups] = filterDuplicateTeams(teams, teamList);
    let listUpdated = false;

    if (dups.length > 0) {
      for (let id of dups) {
        for (let team of teamList) {
          if (team.countryId === id) {
            listUpdated = setTargetState(team, status);
          }
        }
      }
    }

    const addedTeams = [];
    teams.forEach(team => {
      if (!dups.includes(team.countryId)) {
        team.targetState = status;
        addedTeams.push(team);
      } else {
        addedTeams.push(teamList.find(t2 => t2.countryId === team.countryId));
      }
    });

    const sortedTeams = teamList
      .filter(team => !dups.includes(team.countryId))
      .concat(addedTeams)
      .map(t => ({ ...t, name: countryIdToName[t.countryId] }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    setTeamList(sortedTeams);

    if (filtered.length > 0 || listUpdated) {
      onFormDirty();
    }
  };

  const AddQualifierTeams = teams => {
    addNewTeams(teams, FederationTeamConstants.STATUS.QUALIFIER);
  };

  const AddTournamentTeams = teams => {
    addNewTeams(teams, FederationTeamConstants.STATUS.TOURNAMENT);
  };

  const loadTeamList = useCallback(() => {
    if (selectedYear) {
      WBCTeamApi.getFederationTeamsByYear(selectedYear).then(teams => {
        const teamsWithName = teams.map(team => ({
          ...team,
          name: countryIdToName[team.countryId]
        }));
        settingsDispatch({
          type: "setTeamList",
          teamList: _.sortBy(teamsWithName, ["name"])
        });
      });
    }
  }, [selectedYear, countryIdToName, settingsDispatch]);

  // effect(s)
  // load the teams
  useEffect(loadTeamList, [loadTeamList]);

  // load countries
  useEffect(() => {
    if (!countries?.length) {
      WBCTeamApi.getCountries().then(countriesResponse => {
        const countryOptions = countriesResponse.map(c => ({
          value: c.countryId,
          label: c.countryName,
          code: c.countryCode.toLowerCase()
        }));
        countryDispatch({ type: "setCountries", countries: countriesResponse });
        settingsDispatch({ type: "setCountryOptions", countryOptions });
      });
    }
  }, [countries, countryDispatch, settingsDispatch]);

  // every time the list changes, filter out the qualifying and tournament teams
  useEffect(refreshList, [refreshList]);

  return (
    <div className="p-4">
      <WBCCollapse title="Federation Teams">
        <FederationCardWrapper>
          <InfoCard>
            <FederationTeamsCard
              canEdit={canEdit}
              tournamentType={QUALIFYING}
              title="Qualifiers"
              teamList={qualifierList}
              moveTeamOnClick={MoveQualifierToTournament}
              addTeams={AddQualifierTeams}
              removeTeam={RemoveTeam}
              dateRange={qualifyingTournamentDateRange}
              onDateRangeChange={onDateRangeChange}
            />
          </InfoCard>
        </FederationCardWrapper>
        <FederationCardWrapper>
          <InfoCard>
            <FederationTeamsCard
              canEdit={canEdit}
              tournamentType={MAIN}
              title="Tournament"
              teamList={tournamentList}
              addTeams={AddTournamentTeams}
              removeTeam={RemoveTeam}
              dateRange={mainTournamentDateRange}
              onDateRangeChange={onDateRangeChange}
            />
          </InfoCard>
        </FederationCardWrapper>
      </WBCCollapse>
    </div>
  );
};

FederationTeams.displayName = "FederationTeams";

FederationTeams.propTypes = {
  mainTournamentDateRange: PropTypes.object.isRequired,
  qualifyingTournamentDateRange: PropTypes.object.isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  canEdit: PropTypes.bool
};

export default FederationTeams;
