import BaseApi from "./BaseApi";

export default class UsersApi extends BaseApi {
  static getUsers = () => this.get("accounts");
  static updateCountryAssignments = assignments => this.put("accounts/country-assignments", assignments);
  static syncUsersFromLDAP = () => this.get("accounts/sync");
  static getUserRoleOptions = () => this.get("accounts/roles");
  static updateRoleAssignments = assignments => this.put("accounts/roles", assignments);
  static updateOrgAssignments = assignments => this.put("accounts/org-assignments", assignments);
  static updateNotificationAssignments = assignments => this.put("accounts/notification-assignments", assignments);
}
