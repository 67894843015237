import React, { useContext, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";
import OrgsContext from "../../../contexts/OrgsContext";
import { BestSelect } from "best-common-react";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";
import ExportApi from "../../../httpClients/ExportApi";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import useDownloadFile from "../../hooks/useDownloadFile";
import FileHelper from "../../../httpClients/FileHelper";

const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))`
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;
const Title = styled.div.attrs(() => ({
  className: "ml-2"
}))`
  &&& {
    color: ${props => props.theme.wbcBlue};
  }
`;

const Line = styled.hr`
  border-top: 1px solid #ccc;
  margin-top: 3px;
`;

const Export = styled.i.attrs(() => ({
  className: "fas fa-download"
}))`
  margin-left: auto;
  margin-top: auto;
  cursor: pointer;
  float: right;
  color: ${props => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const MLBPlayerDetailsHeader = () => {
  const { orgs } = useContext(OrgsContext).state;
  const { teams } = useContext(SelectedTeamContext).state;
  const {
    playerStatuses,
    orgFilter,
    fedFilter,
    statusSelected,
    setOrgFilter,
    setFedFilter,
    setStatusSelected
  } = useContext(MLBPlayerSummaryContext);
  const { selectedWbcYear } = useContext(WBCYearsContext);

  const allOrgs = { value: null, label: "All Organizations" };
  const allFeds = { value: null, label: "All Federations" };
  const allStatuses = { label: "All Club Request Statuses", value: null };
  const statusOptions = [allStatuses, ...playerStatuses];

  const exportMLBPlayerSummariesFn = useCallback(() => {
    ExportApi.exportMLBPlayerSummaries(
      selectedWbcYear?.year,
      orgFilter,
      fedFilter,
      statusSelected?.value,
      (url, headers) => ({
        url,
        headers
      })
    ).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  }, [selectedWbcYear?.year, orgFilter, fedFilter, statusSelected]);

  const federationOptions =
    teams?.length > 0 ? [allFeds, ...teams.map(team => ({ value: team?.value, label: team?.label }))] : [allFeds];
  const orgOptions =
    orgs?.length > 0
      ? [
          allOrgs,
          ...orgs
            .filter(org => !["BOC", "MSB", "NONE"].includes(org?.orgCode?.toUpperCase()))
            .map(org => ({ value: org?.orgId, label: org?.orgName }))
        ]
      : [allOrgs];

  return (
    <div className="form-group">
      <Title>
        MLB Player Summaries
        <Export onClick={exportMLBPlayerSummariesFn}></Export>
      </Title>
      <Line />
      <FiltersContainer>
        <Filter>
          {
            <BestSelect
              name="orgsDropdown"
              value={orgFilter ? orgOptions.find(org => org.value == orgFilter) : allOrgs}
              options={orgOptions}
              onChange={org => {
                setOrgFilter(org.value);
              }}
            />
          }
        </Filter>
        <Filter>
          {
            <BestSelect
              name="federationsDropdown"
              value={fedFilter ? federationOptions.find(fed => fed.value == fedFilter) : allFeds}
              options={federationOptions}
              onChange={fed => {
                setFedFilter(fed.value);
              }}
            />
          }
        </Filter>
        <Filter>
          {
            <BestSelect
              name="requestStatusDropdown"
              value={statusSelected}
              options={statusOptions}
              onChange={status => {
                setStatusSelected(status);
              }}
            />
          }
        </Filter>
      </FiltersContainer>
    </div>
  );
};

export default MLBPlayerDetailsHeader;
