import { createSelector } from "reselect";
import { values } from "lodash";
import LabelConstants from "../constants/LabelConstants";

const getDocumentEntities = state => state.documents.entities;

const getDocumentEntitiesIds = state => state.documents.entityIds;

const getWbcYear = state => state.profile.wbcYear;

const getDocuments = createSelector(getDocumentEntitiesIds, getDocumentEntities, (ids, documentEntities) =>
  ids.map(id => documentEntities[id])
);

const getHasDocuments = createSelector(getDocuments, documents => documents.length > 0);

const getDeletedDocumentEntities = state => state.documents.deletedEntities;

const getDeletedDocuments = createSelector(getDeletedDocumentEntities, deletedDocumentEntities =>
  values(deletedDocumentEntities)
);

const getLabelEntities = state => state.labels.entities;

const getLabels = createSelector(getLabelEntities, labelEntities => values(labelEntities));

const getIsValid = createSelector(getDocuments, documents => documents.every(document => !!document.label));

const getIsDirty = createSelector(
  getDocuments,
  getDeletedDocuments,
  (documents, deletedDocuments) =>
    deletedDocuments.length > 0 || documents.some(({ isNew, isDirty }) => isNew || isDirty)
);

const getDocumentToDocumentRequestMapper = createSelector(
  getWbcYear,
  wbcYear => ({ documentId, label, fileName, file, isNew }) => ({
    label,
    playerDocsId: isNew ? null : documentId,
    wbcYear,
    fileName,
    file: file || null
  })
);

const getSaveDocumentsRequest = createSelector(
  getDocuments,
  getDeletedDocuments,
  getDocumentToDocumentRequestMapper,
  (documents, deletedDocuments, mapDocumentToDocumentRequest) => ({
    savedDocumentData: documents.map(mapDocumentToDocumentRequest),
    deletedDocuments: deletedDocuments.filter(({ isNew }) => !isNew).map(({ documentId }) => documentId)
  })
);

const getHasPpa = createSelector(getDocuments, documents =>
  documents.some(({ label: { labelId } }) => labelId === LabelConstants.LABEL.PPA)
);

const getHasNppa = createSelector(getDocuments, documents =>
  documents.some(({ label: { labelId } }) => labelId === LabelConstants.LABEL.NPPA)
);

// This seems incorrect and we should probably exclude PPA/NPPA, but this matches the server logic
const getHasOtherDocs = createSelector(getHasDocuments, hasDocuments => hasDocuments);

export {
  getDocuments,
  getHasDocuments,
  getDeletedDocuments,
  getLabels,
  getIsValid,
  getIsDirty,
  getSaveDocumentsRequest,
  getHasPpa,
  getHasNppa,
  getHasOtherDocs
};
