import { AlertConstants } from "best-common-react";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import EventReportStatusConstants from "../../constants/EventReportStatusConstants";
import EventTypeConstants from "../../constants/EventTypeConstants";
import AuthContext from "../../contexts/AuthContext";
import EventReportApi from "../../httpClients/EventReportApi";
import { EventReportContextConsumer } from "../../providers/clubcomm/EventReportContextProvider";
import ButtonHolder from "../elements/ButtonHolder";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { useAlert } from "../hooks/useAlert";
import { useDiscardChanges } from "../hooks/useDiscardChanges";
import { useError } from "../hooks/useError";
import HorizontalNav from "../navigation/HorizontalNav";
import UnsavedChangesPrompt from "../protected/UnsavedChangesPrompt";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import EventReport from "./EventReport";

const EventReportComponent = withRouter(({ history, match: { params } }) => {
  const authContext = useContext(AuthContext);

  const showAlert = useAlert();
  const showErrors = useError();
  const showDiscardModal = useDiscardChanges();
  const { isBOCadmin } = authContext.state;

  const onClose = () => {
    history.goBack();
  };

  const setFormClean = dispatch => {
    if (dispatch) {
      dispatch({
        type: "setFormDirty",
        formDirty: false
      });
    }
  };

  const setEventReportStatus = (dispatch, eventReportStatus, eventReportStatusId) => {
    if (dispatch) {
      dispatch({
        type: "setEventReportStatus",
        eventReportStatus,
        eventReportStatusId
      });
    }
  };

  const onSave = eventReport => {
    if (eventReport.opponent && eventReport.eventTypeId && eventReport.teamId) {
      const requestBody = {
        opponent: eventReport.opponent,
        eventTypeId: eventReport.eventTypeId
      };
      EventReportApi.updateEventReport(eventReport.eventReportId, eventReport.teamId, requestBody)
        .then(() => {
          showAlert(`Saved`, AlertConstants.TYPES.SUCCESS);
        })
        .catch(() => {
          showAlert(`Error while saving Non-Game Day report`, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const onUpdateStatus = (eventReport, dispatch, status) => {
    const requestBody = {
      opponent: eventReport.opponent,
      eventTypeId: eventReport.eventTypeId,
      eventType: eventReport.eventType,
      statusId: status.id
    };

    EventReportApi.updateEventReport(eventReport.eventReportId, eventReport.teamId, requestBody)
      .then(response => {
        if (response.errorList.length > 0) {
          const errorList = response.errorList[0].split("\n");
          showErrors(errorList);
        } else {
          showAlert(status.name, AlertConstants.TYPES.SUCCESS);
          setEventReportStatus(dispatch, status.name, status.id);

          if (status.id === EventReportStatusConstants.Published.id) {
            history.goBack();
          }
        }
      })
      .catch(() => {
        showAlert(`Error while submitting event report`, AlertConstants.TYPES.DANGER);
      });
  };

  return (
    <>
      <EventReportContextConsumer>
        {({ state: { canUpdateReportStatus, eventReport, formDirty }, dispatch }) => {
          return (
            <HorizontalNav title={`MLB Player Report`}>
              <ButtonHolder>
                <div>
                  <UnsavedChangesPrompt
                    when={formDirty}
                    onCancel={() => onClose()}
                    onSave={() => onSave(eventReport)}
                    navigate={path => history.push(path)}
                  />
                  <WBCAdminAccess>
                    {eventReport.eventReportStatusId === EventReportStatusConstants.Preliminary.id ? (
                      <WBCPrimaryButton
                        width={124}
                        className={"btn-md"}
                        disabled={!canUpdateReportStatus}
                        onClick={() => {
                          onUpdateStatus(eventReport, dispatch, EventReportStatusConstants.Submitted);
                        }}
                      >
                        Submit to BOC
                      </WBCPrimaryButton>
                    ) : eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id && isBOCadmin ? (
                      <WBCPrimaryButton
                        width={124}
                        className={"btn-md"}
                        disabled={!canUpdateReportStatus}
                        onClick={() => {
                          onUpdateStatus(eventReport, dispatch, EventReportStatusConstants.Published);
                        }}
                      >
                        Publish to Clubs
                      </WBCPrimaryButton>
                    ) : null}
                  </WBCAdminAccess>
                </div>
                <div className={"ml-2"}>
                  <WBCAdminAccess bocOnly>
                    {eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id && isBOCadmin ? (
                      <WBCPrimaryOutlinedButton
                        width={124}
                        className={"btn-md"}
                        disabled={!canUpdateReportStatus}
                        onClick={() => {
                          onUpdateStatus(eventReport, dispatch, EventReportStatusConstants.Preliminary);
                        }}
                      >
                        Return to Fed
                      </WBCPrimaryOutlinedButton>
                    ) : null}
                  </WBCAdminAccess>
                </div>
                <div className={"mr-2"}>
                  {(!eventReport.gamePk ||
                    (eventReport.gamePk && EventTypeConstants.EVENT_TYPES.EXHIBITION.ID === eventReport.eventTypeId)) &&
                  (isBOCadmin || eventReport.eventReportStatusId === EventReportStatusConstants.Preliminary.id) ? (
                    <div className={"ml-2"}>
                      <WBCAdminAccess>
                        <WBCPrimaryButton
                          className={"btn-md"}
                          disabled={
                            !formDirty ||
                            !eventReport.reportBy?.length ||
                            (eventReport.eventTypeId === EventTypeConstants.EVENT_TYPES.EXHIBITION.ID &&
                              !eventReport.opponent?.length)
                          }
                          onClick={() => {
                            onSave(eventReport);
                            setFormClean(dispatch);
                          }}
                        >
                          Save
                        </WBCPrimaryButton>
                      </WBCAdminAccess>
                    </div>
                  ) : null}
                </div>
                <div>
                  <WBCAdminAccess
                    orElse={
                      <WBCPrimaryOutlinedButton className="btn-md" onClick={onClose}>
                        Close
                      </WBCPrimaryOutlinedButton>
                    }
                  >
                    <div className="mr-2">
                      {formDirty ? (
                        <WBCPrimaryOutlinedButton
                          className={"btn-md"}
                          onClick={() => {
                            showDiscardModal(() => {
                              onClose();
                              setFormClean(dispatch);
                            });
                          }}
                        >
                          Cancel
                        </WBCPrimaryOutlinedButton>
                      ) : (
                        <WBCPrimaryOutlinedButton className="btn-md" onClick={onClose}>
                          Close
                        </WBCPrimaryOutlinedButton>
                      )}
                    </div>
                  </WBCAdminAccess>
                </div>
              </ButtonHolder>
            </HorizontalNav>
          );
        }}
      </EventReportContextConsumer>
      <EventReport teamId={params.teamId} eventReportId={params.eventReportId} />
    </>
  );
});

export default EventReportComponent;
