import React, { useContext } from "react";
import { handleLogin } from "../hooks/useOkta";
import WBCPrimaryImageButton from "../elements/WBCPrimaryImageButton";
import styled from "styled-components";
import AuthContext from "../../contexts/AuthContext";

const Alert = styled.div`
  color: ${props => props.theme["mlb-red"]};
  text-shadow: 0 1px 1px #000000;
  margin-bottom: 0.5rem;
  letter-spacing: 0.5px;
  font-size: 12px;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  font-stretch: normal;
  line-height: normal;
`;

const LoginForm = () => {
  const authContext = useContext(AuthContext);

  const { userInfoFailed } = authContext.state;

  const styles = {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    text: {
      fontSize: "13px"
    }
  };

  return (
    <>
      {userInfoFailed ? (
        <Alert type="danger">
          <div>username or password is incorrect</div>
        </Alert>
      ) : null}
      <WBCPrimaryImageButton
        text="Sign in with MLB SSO"
        onClick={() => {
          handleLogin(null, () => {});
        }}
        height={28}
        width={205}
        styles={styles}
      />
    </>
  );
};

export default LoginForm;
