import React, { useReducer } from "react";
import UserManagementFormContext from "../contexts/UserManagementFormContext";
import PropTypes from "prop-types";

const initialState = {
  onFormDirty: () => {},
  onSave: () => {},
  onCancel: () => {},
  modalStatus: false,
  formDirtyBool: false
};

const UserManagementFormContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setFormControls":
        return { ...state, onFormDirty: action.onFormDirty, onSave: action.onSave, onCancel: action.onCancel };
      case "setOnSave":
        return { ...state, onSave: action.onSave };
      case "setOnCancel":
        return { ...state, onCancel: action.onCancel };
      case "setFormDirty":
        return { ...state, formDirtyBool: action.formDirty };
      case "setModalStatus":
        return { ...state, modalStatus: action.modalStatus };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserManagementFormContext.Provider value={{ state, dispatch }}>{children}</UserManagementFormContext.Provider>
  );
};

UserManagementFormContextProvider.propTypes = {
  children: PropTypes.object
};

export default UserManagementFormContextProvider;
