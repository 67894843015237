import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import AuthContext from "../../contexts/AuthContext";

const Text = styled.div`
  text-align: left;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: var(--dark-grey);
`;

const ButtonContainer = styled.div`
  display: flex;
`;

const Button = styled(WBCPrimaryOutlinedButton)`
  width: auto !important;
`;

const Save = styled(Button)`
  margin-right: 8px;
`;

const WarningModalBody = ({ text }) => <Text>{text}</Text>;

const WarningModalFooter = ({ saveFn }) => {
  const authContext = useContext(AuthContext);
  const globalModalContext = useContext(GlobalModalContext);

  const { isBOCadmin } = authContext.state;
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });

  return (
    <ButtonContainer>
      {!isBOCadmin ? null : <Save onClick={saveFn}>Save</Save>}
      <Button onClick={onClose}>Cancel</Button>
    </ButtonContainer>
  );
};

WarningModalBody.propTypes = {
  text: PropTypes.string
};

WarningModalFooter.propTypes = {
  saveFn: PropTypes.func
};

WarningModalBody.displayName = "BodyContent";
WarningModalFooter.displayName = "FooterContent";

export { WarningModalBody, WarningModalFooter };
