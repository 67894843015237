import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { BestSelect, Input } from "best-common-react";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../../utils/WBCYearPredicates";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";

const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))`
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;

const MLBPlayerRequestsHeader = () => {
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);
  const { nameSearchFilter, setNameSearchFilter } = useContext(MLBPlayerRequestContext);

  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);

  const handleWBCYearChange = useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear]);
  const handleNameSearchFilter = search => setNameSearchFilter(search?.target?.value);
  return (
    <div>
      <FiltersContainer>
        <Filter>
          <BestSelect
            name="yearDropdown"
            value={selectedWbcYear}
            options={yearOptions}
            onChange={handleWBCYearChange}
          />
        </Filter>
        <Filter>
          <Input placeholder="type to search" value={nameSearchFilter} onChange={handleNameSearchFilter}></Input>
        </Filter>
      </FiltersContainer>
    </div>
  );
};

export default MLBPlayerRequestsHeader;
