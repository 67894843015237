import React, { useState, useEffect, useContext, useCallback } from "react";
import styled, { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import { isEmpty } from "../../utils/StringUtils";

const TabContainer = styled.ul.attrs(() => ({
  className: "wbc-tab-container"
}))`
  &&& {
    background-color: ${props => props.styles.backgroundColor};
    list-style: none;
    @media (max-width: 1023px) {
      display: ${props => (props.hideOnMobile ? `none` : `flex`)};
    }
    @media (min-width: 1024px) {
      display: flex;
    }
    margin: 0;
    padding: 0;
    border: 1px solid ${props => props.styles.borderColor};
    flex-wrap: wrap;
  }
`;

const Tab = styled.li.attrs(() => ({
  className: "wbc-tab"
}))`
  &&& {
    min-width: 107px;
    height: 31px;
    padding: 7px 19px;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: center;
    color: ${props => (props.active ? props.styles.activeColor : props.styles.defaultColor)};
    background-color: ${props => (props.active ? props.styles.backgroundColor : undefined)};
    border-left: ${props => (props.active ? `1px solid ${props.styles.borderColor}` : undefined)};
    border-right: ${props => (props.active ? `1px solid ${props.styles.borderColor}` : undefined)};

    :first-child {
      border-left: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;

const Indicator = styled.span`
  &&& {
    font-size: 12px;
    display: ${props => (props.dirty ? "inline-block" : "none")};
    color: ${props => props.theme["almost-black"]};
  }
`;

const ComponentContainer = styled.div.attrs(props => ({
  className: `wbc-tab-component-container ${props.hasPadding ? "p-4" : ""}`
}))`
  &&& {
    background-color: ${props => props.theme.white};
    border-left: 1px solid ${props => props.styles.borderColor};
    border-right: 1px solid ${props => props.styles.borderColor};
    border-bottom: 1px solid ${props => props.styles.borderColor};
  }
`;

const updateTabs = (tabList, activeTabIndex) => {
  const tabs = tabList ? tabList.map(t => ({ ...t, active: false, dirty: false })) : [];
  if (tabs.length > 0) {
    if (tabs[activeTabIndex]) {
      tabs[activeTabIndex].active = true;
    } else {
      tabs[0].active = true;
    }
  }
  return tabs;
};

const containsNewTabs = (list1, list2) => {
  if (list1.length !== list2.length) {
    return true;
  }
  for (let i = 0; i < list1.length; i++) {
    if (list1[i].name !== list2[i].name) {
      return true;
    }
  }
  return false;
};

const renderContent = active => {
  if (active) {
    if (typeof active.component === typeof {}) {
      return active.component;
    } else {
      const RenderComponent = active.component;
      return <RenderComponent />;
    }
  }
  return <div>No Component Found</div>;
};

const TabSwitcher = ({ className, tabs, styles, onSwitch, hasPadding, canChange, activeTabIndex, hideOnMobile }) => {
  // hooks
  const Theme = useContext(ThemeContext);
  //Initialize tabsState to our now updated list of tabs
  const [tabsState, setTabsState] = useState(updateTabs(tabs, activeTabIndex));

  // variables
  const DefaultStyle = {
    tab: {
      backgroundColor: Theme["white"],
      borderColor: Theme["grey-two"],
      activeColor: Theme["primary"],
      defaultColor: Theme["almost-black"]
    },
    container: {
      backgroundColor: Theme["almost-white"],
      borderColor: Theme["grey-two"]
    }
  };
  const CompStyles = {
    tab: { ...DefaultStyle.tab, ...styles.tab },
    container: { ...DefaultStyle.container, ...styles.container }
  };

  //Find the tab that should be active by default
  const active = tabsState.find(t => t.active);

  // functions
  const setActiveTab = useCallback(
    index => {
      const copyTabs = tabsState.map(t => ({ ...t, active: false }));
      if (copyTabs[index]) copyTabs[index].active = true;
      else copyTabs[0].active = true;
      setTabsState(copyTabs);
      onSwitch(index);
    },
    [tabsState, onSwitch]
  );

  // effects
  // if tabs in props change, update tabs state
  useEffect(() => {
    if (containsNewTabs(tabs, tabsState)) {
      setTabsState(updateTabs(tabs, activeTabIndex));
    }
  }, [tabs, tabsState, activeTabIndex]);

  // update active tab
  useEffect(() => {
    if (activeTabIndex !== null) {
      setActiveTab(activeTabIndex);
    }
  }, [activeTabIndex, setActiveTab]);

  return (
    <div className={isEmpty(className) ? undefined : className.trim()}>
      <TabContainer hideOnMobile={hideOnMobile} styles={CompStyles.container}>
        {tabsState.map((t, index) => (
          <Tab
            active={t.active}
            onClick={() => {
              onSwitch(index);
              if (canChange()) {
                setActiveTab(index);
              }
            }}
            key={index}
            styles={CompStyles.tab}
          >
            {t.name}{" "}
            <Indicator dirty={t.dirty} styles={CompStyles.tab}>
              *
            </Indicator>
          </Tab>
        ))}
      </TabContainer>
      <ComponentContainer hasPadding={hasPadding} styles={CompStyles.container}>
        {renderContent(active)}
      </ComponentContainer>
    </div>
  );
};

TabSwitcher.propTypes = {
  /** tabs to display in the tab switcher */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tab: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func])
    })
  ).isRequired,
  /** overrideable styling for the tab switcher */
  styles: PropTypes.shape({
    tab: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
      activeColor: PropTypes.string,
      defaultColor: PropTypes.string
    }),
    container: PropTypes.shape({
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string
    })
  }),
  /** callback when tab is switched */
  onSwitch: PropTypes.func,
  /** does the tab switcher give default padding to its children */
  hasPadding: PropTypes.bool,
  /** callback to determine if the tab can switch */
  canChange: PropTypes.func,
  /** the index of the currently active tab to display */
  activeTabIndex: PropTypes.number,
  /** are the tabs hidden when in mobile widths */
  hideOnMobile: PropTypes.bool,
  /** className override */
  className: PropTypes.string
};

TabSwitcher.defaultProps = {
  styles: { tab: {}, container: {} },
  onSwitch: () => {},
  canChange: () => {
    return true;
  },
  hasPadding: true,
  activeTabIndex: null,
  hideOnMobile: false
};

export default TabSwitcher;
