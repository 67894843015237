import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Theme from "../../Theme";
import GlobalModalContext from "../../contexts/GlobalModalContext";

const InfoCircle = styled.i.attrs(() => ({
  className: "fas fa-info-circle fa-md pl-2"
}))`
  &&& {
    color: ${Theme.wbcBlue};
    font-size: 0.9rem;
    line-height: 14px;
    height: 14px;
    cursor: pointer;
  }
`;

const Container = styled.span.attrs(() => ({}))`
  ${props =>
    props.tableHeader
      ? `
    position: relative; 
    left: 45px; 
    float: right;`
      : ""}
`;

const PosititionKeyIcon = ({ tableHeader }) => {
  const globalModalContext = useContext(GlobalModalContext);
  const { dispatch: globalModalDispatch } = globalModalContext;

  const openPositionKeyModal = event => {
    event.preventDefault();
    event.stopPropagation();
    globalModalDispatch({ type: "openPositionKeyModal" });
  };

  return (
    <Container tableHeader={tableHeader}>
      <InfoCircle onClick={openPositionKeyModal} />
    </Container>
  );
};

PosititionKeyIcon.defaultProps = {
  tableHeader: false
};

PosititionKeyIcon.propTypes = {
  tableHeader: PropTypes.bool
};

export default PosititionKeyIcon;
