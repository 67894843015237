import styled from "styled-components";

const ButtonHolder = styled.div.attrs(() => ({
  className: "d-flex mt-1 flex-wrap"
}))`
  &&& {
    @media (max-width: 767px) {
      padding-right: 0px;
    }
    @media (min-width: 768px) {
      margin-right: 15px;
    }
  }
`;

export default ButtonHolder;
