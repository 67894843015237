import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const ReportIconContainer = styled.div.attrs(() => ({
  className: "d-flex"
}))`
  height: 18px;
  width: 18px;
`;

const Circle = styled.i.attrs(() => ({
  className: "fas fa-check-circle fa-lg"
}))`
  &&& {
    height: 18px;
    width: 18px;
    color: ${props => (props.completed ? "#3eb049" : props.theme["grey"])};
    margin-top: 3px;
  }
`;

const ReportFormatter = ({ row }) => {
  return (
    <div className="d-flex justify-content-center">
      <ReportIconContainer>
        {row.orgCode !== null && row.orgCode !== "NO" ? (
          <>
            <Circle completed={row.completed} />
          </>
        ) : (
          <></>
        )}
      </ReportIconContainer>
    </div>
  );
};

ReportFormatter.propTypes = {
  row: PropTypes.object
};

export default ReportFormatter;
