import React, { useCallback, useContext, useEffect } from "react";
import { MLBPlayersDashboardTab } from "../MLBPlayersDashboardStyles";
import ChronicConditionsTable from "./ChronicConditionsTable";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import ChronicConditionsContext from "../../../contexts/ChronicConditionsContext";
import ChronicConditionsHeader from "./ChronicConditionsHeader";
import ChronicConditionsApi from "../../../httpClients/ChronicConditionsApi";

const ChronicConditions = () => {
  const chronicConditionContext = useContext(ChronicConditionsContext);
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const { setPlayerConditions, setInsuranceStatuses, setInsuranceActions, updatePlayers } = chronicConditionContext;

  const loadChronicConditionsByYear = useCallback(() => {
    if (selectedWbcYear?.year) {
      ChronicConditionsApi.getChronicConditionsByYear(selectedWbcYear.year).then(response => {
        setPlayerConditions(
          response?.length > 0
            ? response
                .sort((a, b) => a.lastName.localeCompare(b.lastName))
                .map(player => ({
                  ...player,
                  rosterStatus: player?.mlbRosterStatus ? player?.mlbRosterStatus : player?.milbRosterStatus,
                  isChecked: false,
                  disabled: false
                }))
            : []
        );
      });
    }
  }, [selectedWbcYear.year, setPlayerConditions]);

  const loadInsuranceStatusesAndActions = useCallback(() => {
    const allStatus = { label: "All Insurance Statuses", value: null };

    ChronicConditionsApi.getAllInsuranceStatuses().then(response => {
      if (response?.length > 0) {
        setInsuranceStatuses([
          allStatus,
          ...response
            .filter(status => status?.insuranceStatusId !== 5)
            .map(status => ({ label: status?.insuranceStatusName, value: status?.insuranceStatusId }))
        ]);
        setInsuranceActions([
          ...response.map(status => ({
            action: status?.action,
            value: status?.insuranceStatusId,
            name: status?.insuranceStatusName,
            isActive: false
          }))
        ]);
      }
    });
  }, [setInsuranceActions, setInsuranceStatuses]);

  useEffect(() => {
    if (selectedWbcYear?.year) {
      loadChronicConditionsByYear();
    }
  }, [selectedWbcYear, loadChronicConditionsByYear, updatePlayers]);

  useEffect(() => {
    loadInsuranceStatusesAndActions();
  }, [loadInsuranceStatusesAndActions]);

  return (
    <MLBPlayersDashboardTab>
      <ChronicConditionsHeader loadChronicConditionsByYearFn={loadChronicConditionsByYear} />
      <ChronicConditionsTable />
    </MLBPlayersDashboardTab>
  );
};

export default ChronicConditions;
