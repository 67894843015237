import React, { useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useCellResize } from "../../hooks/useCellResize";

const Container = styled.div`
  height: 32px;
  display: flex;
  background-color: ${props => props.theme["almost-white"]};
`;

const ColumnCellStyle = styled.div`
  display: flex;
  padding: 8px;
  cursor: ${props => (props.sortable ? "pointer" : undefined)};
  background-color: ${props => props.theme["almost-white"]};
  width: ${props => (props.width ? `${props.width}px` : undefined)};
  min-width: ${props => (props.width ? `${props.width}px` : undefined)};

  /* overflow */
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ColumnCellText = styled.div`
  /* font */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const Arrow = styled.span`
  padding-left: 4px;
  /* font */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

const ColumnCell = ({ column, pixelWidth, onSort, currentSortedColumn }) => {
  const width = column.width || pixelWidth;
  const { col, dir } = currentSortedColumn;
  const onClick = () => onSort(column);

  return (
    <ColumnCellStyle width={width} sortable={column.sortable} onClick={onClick}>
      <ColumnCellText>{column.name}</ColumnCellText>
      {column.key !== col ? null : <Arrow>{dir === "ASC" ? "▲" : "▼"}</Arrow>}
    </ColumnCellStyle>
  );
};

const PlayerHeader = ({ length, columns, onSort, sortColumn: initSortCol, sortDirection: initSortDir }) => {
  // hooks
  const lengthRef = useRef(0);
  const [sortedColumn, setSortedColumn] = useState({ col: initSortCol, dir: initSortDir });
  const pixelWidth = useCellResize("player-table", 4, 740, 80);

  // functions
  const sortColumn = column => {
    if (!column.sortable) return;

    // change the state of the sorted column
    const { col: oldCol, dir: oldDir } = sortedColumn;
    let newCol = column.key,
      newDir = "ASC";

    // sorting the same column
    if (oldCol === newCol) {
      newDir = !oldDir ? "ASC" : oldDir === "ASC" ? "DESC" : null;
      newCol = !newDir ? null : newCol;
    }

    // only changes the arrow
    setSortedColumn({ col: newCol, dir: newDir });

    // onSort actually changes the data
    if (onSort) {
      onSort(newCol, newDir);
    }
  };

  // every time the length changes, trigger a resize to re-calculate pixelWidth
  useLayoutEffect(() => {
    if (length !== lengthRef.current) {
      lengthRef.current = length;

      // non-IE resize
      if (!window.document.documentMode) {
        window.dispatchEvent(new Event("resize"));
      } else {
        // IE-resize
        const resizeEvent = window.document.createEvent("UIEvents");
        resizeEvent.initUIEvent("resize", true, false, window, 0);
        window.dispatchEvent(resizeEvent);
      }
    }
  }, [length, lengthRef]);

  return (
    <Container>
      {columns.map(c => (
        <ColumnCell
          key={c.key}
          column={c}
          pixelWidth={pixelWidth}
          onSort={sortColumn}
          currentSortedColumn={sortedColumn}
        />
      ))}
    </Container>
  );
};

ColumnCell.propTypes = {
  column: PropTypes.object,
  pixelWidth: PropTypes.number,
  onSort: PropTypes.func,
  currentSortedColumn: PropTypes.object
};

PlayerHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  onSort: PropTypes.func,
  length: PropTypes.number,
  sortColumn: PropTypes.string,
  sortDirection: PropTypes.string
};

export default PlayerHeader;
