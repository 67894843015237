import { useRef, useState, useCallback, useEffect, useLayoutEffect } from "react";
import { calculateColumnWidth } from "../../utils/TableUtils";

/**
 * Calculates the column widths of the cells in rows.
 * @param {string} selector - the id or data-uuid of the row element or the element
 * @param {number} numColumns - number of columns to calculate width for
 * @param {number} usedSpace - total size in pixels of defined widths
 * @param {number} minColumnWidth - the min column width of each column
 * @param {any} value - used to trigger resize based on external factors
 */
export const useCellResize = (selector, numColumns, usedSpace, minColumnWidth = 0, value = undefined) => {
  const valueRef = useRef(value);
  const [pixelWidth, setPixelWidth] = useState(0);
  const resize = useCallback(() => {
    const el = document.getElementById(selector) || document.querySelector(selector);
    const [val] = calculateColumnWidth(el, numColumns, usedSpace);
    const width = Math.max(val, minColumnWidth);
    if (pixelWidth !== width) {
      setPixelWidth(width);
    }
  }, [selector, pixelWidth, numColumns, usedSpace, minColumnWidth]);

  // effects
  // on resize re-calculate pixel width
  useEffect(() => {
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  // initialize or on prop change re-calculate
  useLayoutEffect(() => {
    if (!pixelWidth || valueRef.current !== value) {
      valueRef.current = value;
      resize();
    }
  }, [pixelWidth, resize, value, valueRef]);

  return pixelWidth;
};
