import React from "react";
import PropTypes from "prop-types";
import useHasAdminAccess from "../hooks/useHasAdminAccess";

const WBCAccessControl = ({ input, noDiv, bocOnly, includesTc }) => {
  const elem = input(!useHasAdminAccess(bocOnly, includesTc));
  return noDiv ? elem : <div>{elem}</div>;
};

WBCAccessControl.propTypes = {
  input: PropTypes.func.isRequired,
  noDiv: PropTypes.bool,
  bocOnly: PropTypes.bool,
  includesTc: PropTypes.bool
};

WBCAccessControl.defaultProps = {
  noDiv: false,
  bocOnly: false
};

export default WBCAccessControl;
