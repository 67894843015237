import React, { useContext, useEffect, useState } from "react";
import PlayerCountContainer from "../shared/PlayerCountContainer";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";
import OrgsContext from "../../../contexts/OrgsContext";
import PlayerCountTile from "../shared/PlayerCountTile";
import { ReactSVG } from "react-svg";

const MLBPlayersByOrg = () => {
  const { filteredPlayerSummaries, orgFilter, setOrgFilter, sortPlayerCounts } = useContext(MLBPlayerSummaryContext);
  const { orgs } = useContext(OrgsContext).state;

  const [playerCounts, setPlayerCounts] = useState([]);

  useEffect(() => {
    if (orgs?.length > 0) {
      const playerCountsMap = {};
      orgs
        .filter(org => !["BOC", "MSB", "NONE"].includes(org.orgCode?.toUpperCase()))
        .forEach(org => {
          playerCountsMap[org.orgId] = {
            orgId: org.orgId,
            orgCode: org.orgCode,
            statsTeamId: org.statsTeamId,
            playerCount: 0
          };
        });

      filteredPlayerSummaries.forEach(summary => {
        const org = summary.orgId;
        const playerCountsForOrg = playerCountsMap[org];
        if (playerCountsForOrg) {
          playerCountsMap[org] = { ...playerCountsForOrg, playerCount: playerCountsForOrg.playerCount + 1 };
        }
      });

      setPlayerCounts(Object.values(playerCountsMap));
    }
  }, [filteredPlayerSummaries, orgs]);

  const handlePlayerCountClick = playerCount => {
    setOrgFilter(playerCount.orgId === orgFilter ? null : playerCount.orgId);
  };

  return (
    <PlayerCountContainer title="MLB Players by Org">
      {sortPlayerCounts(playerCounts, "orgCode").map(playerCount => {
        return (
          <PlayerCountTile
            key={playerCount.orgId}
            image={
              <ReactSVG
                src={`https://www.mlbstatic.com/team-logos/${playerCount.statsTeamId}.svg`}
                alt="org-logo"
                beforeInjection={svg => {
                  svg.setAttribute("style", "height: 30px; width: 30px");
                }}
              />
            }
            label={playerCount.orgCode}
            playerCount={playerCount.playerCount}
            onClick={e => {
              handlePlayerCountClick(playerCount);
            }}
            selected={playerCount.orgId === orgFilter}
          />
        );
      })}
      {}
    </PlayerCountContainer>
  );
};

export default MLBPlayersByOrg;
