import React from "react";
import PropTypes from "prop-types";
import adaptToBCRTableCell from "./adaptToBCRTableCell";
import DocumentDownloadLink from "./DocumentDownloadLink";

const style = { fontSize: "14px", color: "#0083c0" };

const DocumentsTableFileNameCell = ({ document, document: { isNew, fileName } }) =>
  isNew ? <div style={style}>{fileName}</div> : <DocumentDownloadLink document={document} style={style} />;

DocumentsTableFileNameCell.displayName = "DocumentsTableFileNameCell";

DocumentsTableFileNameCell.propTypes = {
  document: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    documentId: PropTypes.number.isRequired,
    isNew: PropTypes.bool.isRequired
  }).isRequired
};

export default adaptToBCRTableCell(DocumentsTableFileNameCell);
