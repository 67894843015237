import React, { useContext, useEffect, useMemo } from "react";
import styled from "styled-components";
import UserRoleConstants from "../../constants/UserRoleConstants";
import UserManagementContext from "../../contexts/UserManagementContext";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import ReactDataGrid from "react-data-grid";
import UserManagementFormContext from "../../contexts/UserManagementFormContext";
import WBCAccessControl from "../protected/WBCAccessControl";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import OrgsContext from "../../contexts/OrgsContext";
import Theme from "../../Theme";

const UserManagementHeader = styled.div`
  text-align: left;
  color: #666666;
`;

const TextStyle = styled.div`
  text-align: left;
`;

const DropdownIndicator = props => {
  const caretStyle = {
    color: "rgb(169, 169, 169)",
    fontSize: "1.1em"
  };
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <i className="fas fa-caret-down" style={caretStyle} />
      </components.DropdownIndicator>
    )
  );
};

const getRoleValue = (row, countryAssignmentUpdates, roleOptions) => {
  if (countryAssignmentUpdates.length === 0) return null;
  return roleOptions.find(obj => {
    const account = countryAssignmentUpdates.find(a => a.accountId === row.accountId) || {};
    return obj.roleId === account.roleId;
  });
};

const getFedUserValue = (row, countryAssignmentUpdates, countries) => {
  const { countryIds = [] } = countryAssignmentUpdates.find(a => a.accountId === row.accountId);
  return countries.find(({ countryId }) => {
    return countryId === countryIds[0];
  });
};

const getOrgUserValue = (row, orgAssignmentUpdates, orgs) => {
  const orgId = orgAssignmentUpdates.find(update => update.accountId === row.accountId)?.orgId;
  return orgs.find(({ value }) => value === orgId);
};

const getFedAdminValue = (row, countryAssignmentUpdates, countries) => {
  const { countryIds = [] } = countryAssignmentUpdates.find(a => a.accountId === row.accountId);
  return countryIds.map(assignedCountryId => countries.find(({ countryId }) => countryId === assignedCountryId));
};

const getNotificationsUserValue = (row, notificationAssignmentUpdates, notificationOptions) => {
  const userNotifications = notificationAssignmentUpdates.find(a => a.accountId === row.accountId);
  if (userNotifications?.notificationTypeIds?.length > 0) {
    return userNotifications.notificationTypeIds.map(assignedNotificationId =>
      notificationOptions.find(({ value }) => value === assignedNotificationId)
    );
  }
  return [];
};

const RoleFormatter = ({ row }) => {
  // context(s)
  const userManagementContext = useContext(UserManagementContext);
  const userManagementFormContext = useContext(UserManagementFormContext);

  // variables
  const { onFormDirty } = userManagementFormContext.state;
  const { roleAssignmentUpdates, roleOptions } = userManagementContext.state;
  const currentRole = useMemo(() => {
    return getRoleValue(row, roleAssignmentUpdates, roleOptions) || "";
  }, [roleAssignmentUpdates, roleOptions, row]);

  return (
    <WBCAccessControl
      input={isDisabled => (
        <>
          {!isDisabled ? (
            <div onClick={e => e.stopPropagation()}>
              <Select
                components={{ DropdownIndicator }}
                isDisabled={isDisabled}
                id="best-select-grid"
                options={userManagementContext.state.roleOptions}
                value={currentRole}
                onChange={val => {
                  userManagementContext.dispatch({
                    type: "updateRoleAssignments",
                    roleId: !val.roleId ? null : val.roleId,
                    accountId: row.accountId,
                    roleName: !val.roleName ? null : val.roleName
                  });
                  if (val.roleName !== UserRoleConstants.CLUB_RECIPIENT) {
                    userManagementContext.dispatch({
                      type: "updateOrgAssignments",
                      orgId: null,
                      accountId: row.accountId
                    });
                  }
                  if (val.roleName === UserRoleConstants.CLUB_RECIPIENT) {
                    userManagementContext.dispatch({
                      type: "updateCountryAssignments",
                      countryIds: [],
                      accountId: row.accountId
                    });
                  }
                  onFormDirty();
                }}
                menuPortalTarget={document.body}
              />
            </div>
          ) : (
            <TextStyle>{currentRole ? currentRole.label : null}</TextStyle>
          )}
        </>
      )}
    />
  );
};

const FederationsFormatter = ({ row }) => {
  // context(s)
  const userManagementContext = useContext(UserManagementContext);
  const userManagementFormContext = useContext(UserManagementFormContext);

  // variable(s)
  const { countries, countryAssignmentUpdates, roleAssignmentUpdates, roleOptions } = userManagementContext.state;
  const currentRole = getRoleValue(row, roleAssignmentUpdates, roleOptions);

  // effect(s)
  // TODO: remove this
  useEffect(() => {}, [userManagementContext.state.roleAssignmentUpdates]);

  if (currentRole) {
    switch (currentRole.roleName) {
      case UserRoleConstants.FED_TEAM_USER: {
        const fedUserValue = getFedUserValue(row, countryAssignmentUpdates, countries);
        return (
          <WBCAccessControl
            input={isDisabled => (
              <>
                {!isDisabled ? (
                  <div onClick={e => e.stopPropagation()}>
                    <Select
                      components={{ DropdownIndicator }}
                      isDisabled={isDisabled}
                      id="best-select-grid"
                      options={userManagementContext.state.countries}
                      value={fedUserValue}
                      onChange={val => {
                        userManagementContext.dispatch({
                          type: "updateCountryAssignments",
                          countryIds: !val.countryId ? [] : [val.countryId],
                          accountId: row.accountId
                        });
                        userManagementFormContext.state.onFormDirty();
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                ) : (
                  <TextStyle>{fedUserValue ? fedUserValue.label.toString() : null}</TextStyle>
                )}
              </>
            )}
          />
        );
      }
      case UserRoleConstants.FED_TEAM_ADMIN:
      case UserRoleConstants.TEAM_COORDINATOR: {
        const fedAdminValue = getFedAdminValue(row, countryAssignmentUpdates, countries);

        return (
          <WBCAccessControl
            input={isDisabled => (
              <>
                {!isDisabled ? (
                  <div onClick={e => e.stopPropagation()}>
                    <Select
                      components={{ DropdownIndicator }}
                      id={"best-select-grid"}
                      isDisabled={isDisabled}
                      isMulti
                      options={userManagementContext.state.countries}
                      value={fedAdminValue}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isClearable={false}
                      onChange={data => {
                        userManagementContext.dispatch({
                          type: "updateCountryAssignments",
                          countryIds: !data ? [] : data.map(value => value.countryId),
                          accountId: row.accountId
                        });
                        userManagementFormContext.state.onFormDirty();
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                ) : (
                  <TextStyle>{fedAdminValue.map(val => val.label).join(", ")}</TextStyle>
                )}
              </>
            )}
          />
        );
      }
      case UserRoleConstants.BOC_ADMIN:
        return <TextStyle>All</TextStyle>;
      case UserRoleConstants.BOC_VIEWER:
        return <TextStyle>All</TextStyle>;
      default:
        return <TextStyle>N/A</TextStyle>;
    }
  } else {
    return <TextStyle>N/A</TextStyle>;
  }
};

const OrgFormatter = ({ row }) => {
  // context(s)
  const userManagementContext = useContext(UserManagementContext);
  const userManagementFormContext = useContext(UserManagementFormContext);
  const orgsContext = useContext(OrgsContext);

  // variable(s)
  const { orgsDropdown } = orgsContext.state;
  const {
    dispatch: userManagementContextDispatch,
    state: { orgAssignmentUpdates, orgs, roleAssignmentUpdates, roleOptions }
  } = userManagementContext;
  const currentRole = getRoleValue(row, roleAssignmentUpdates, roleOptions);

  useEffect(() => {
    userManagementContextDispatch({
      type: "setOrgs",
      orgs: orgsDropdown.filter(org => org.label !== "NONE")
    });
  }, [orgsDropdown, userManagementContextDispatch]);

  if (currentRole) {
    const orgUserValue = getOrgUserValue(row, orgAssignmentUpdates, orgs);

    switch (currentRole.roleName) {
      case UserRoleConstants.CLUB_RECIPIENT: {
        return (
          <WBCAccessControl
            input={isDisabled => (
              <>
                {!isDisabled ? (
                  <div onClick={e => e.stopPropagation()}>
                    <Select
                      id="best-select-grid"
                      components={{ DropdownIndicator }}
                      isDisabled={isDisabled}
                      options={orgs}
                      value={orgUserValue}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      isClearable={false}
                      onChange={opt => {
                        userManagementContext.dispatch({
                          type: "updateOrgAssignments",
                          orgId: opt.value,
                          accountId: row.accountId
                        });
                        userManagementFormContext.state.onFormDirty();
                      }}
                      menuPortalTarget={document.body}
                    />
                  </div>
                ) : (
                  <TextStyle>{orgUserValue?.length ? orgUserValue.map(org => org.label).join() : "NONE"}</TextStyle>
                )}
              </>
            )}
          />
        );
      }
      default: {
        return <>N/A</>;
      }
    }
  } else {
    return <>N/A</>;
  }
};

const NotificationsFormatter = ({ row }) => {
  const notificationStyles = {
    input: provided => ({
      ...provided,
      display: "flex"
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? Theme.wbcBlue : Theme.white
    })
  };

  // context(s)
  const userManagementContext = useContext(UserManagementContext);
  const userManagementFormContext = useContext(UserManagementFormContext);

  // variable(s)
  const { notificationOptions, notificationAssignmentUpdates } = userManagementContext.state;

  const userNotifications = getNotificationsUserValue(row, notificationAssignmentUpdates, notificationOptions);

  const ValueContainerWithCount = props => {
    const input = React.Children.toArray(props?.children).filter(child => [components.Input].includes(child.type));

    const ValueLabel = styled.div`
      color: hsl(0, 0%, 20%);
    `;

    const BadgeCount = styled.div`
      color: ${Theme.wbcBlue};
      display: flex;
      font-weight: bold;
      font-size: 0.75rem;
      margin-left: 0.25rem;
      margin-bottom: 0.125rem;
    `;

    return (
      <components.ValueContainer {...props}>
        <div className="value-container-with-count d-flex flex-row align-items-center w-100">
          <ValueLabel className="d-flex flex-row">
            {props.selectProps?.value?.length > 0 ? props.selectProps.value[0].label : props?.selectProps?.placeholder}
          </ValueLabel>
          {props.selectProps?.value?.length > 1 && <BadgeCount>+{props.selectProps?.value?.length - 1}</BadgeCount>}
          {input}
        </div>
      </components.ValueContainer>
    );
  };

  ValueContainerWithCount.propTypes = {
    selectProps: PropTypes.object,
    children: PropTypes.node
  };

  const OptionWithSelectedIndicator = props => {
    return (
      <components.Option {...props}>
        <div className="user-option-container d-flex flex-row">
          <i className={`fas ${props.isSelected ? "fa-times" : "fa-plus"} d-flex mt-1 mr-4`} />
          <div className="d-flex">{props.data.label}</div>
        </div>
      </components.Option>
    );
  };

  OptionWithSelectedIndicator.propTypes = {
    data: PropTypes.object,
    isSelected: PropTypes.bool
  };

  return (
    <WBCAccessControl
      input={isDisabled => (
        <>
          {!isDisabled ? (
            <div onClick={e => e.stopPropagation()}>
              <Select
                components={{
                  ValueContainer: ValueContainerWithCount,
                  Option: OptionWithSelectedIndicator,
                  DropdownIndicator
                }}
                id={"best-select-grid"}
                isDisabled={isDisabled}
                isMulti
                options={notificationOptions}
                value={userNotifications}
                className="basic-multi-select"
                classNamePrefix="select"
                isClearable={false}
                hideSelectedOptions={false}
                onChange={data => {
                  userManagementContext.dispatch({
                    type: "updateNotificationAssignmentUpdates",
                    notificationTypeIds: data ? data.map(d => d.value) : [],
                    accountId: row.accountId
                  });
                  userManagementFormContext.state.onFormDirty();
                }}
                menuPortalTarget={document.body}
                styles={notificationStyles}
              />
            </div>
          ) : (
            <TextStyle>{userNotifications.map(val => val.label).join(", ")}</TextStyle>
          )}
        </>
      )}
    />
  );
};

const TextFormatter = ({ value }) => <TextStyle>{value}</TextStyle>;

const columns = [
  {
    key: "blankStart",
    name: " ",
    width: 25
  },
  {
    key: "lastName",
    name: <UserManagementHeader>Last</UserManagementHeader>,
    sortable: true,
    width: 120,
    formatter: TextFormatter
  },
  {
    key: "firstName",
    name: <UserManagementHeader>First</UserManagementHeader>,
    sortable: true,
    width: 120,
    formatter: TextFormatter
  },
  {
    key: "email",
    name: <UserManagementHeader>User Name</UserManagementHeader>,
    sortable: true,
    width: 250,
    formatter: TextFormatter
  },
  {
    key: "roles",
    name: <UserManagementHeader>Role</UserManagementHeader>,
    sortable: false,
    width: 225,
    formatter: RoleFormatter
  },
  {
    key: "countryAssignments",
    name: <UserManagementHeader>Federations</UserManagementHeader>,
    sortable: false,
    width: 400,
    formatter: FederationsFormatter
  },
  {
    key: "orgAssignments",
    name: <UserManagementHeader>Org</UserManagementHeader>,
    sortable: false,
    width: 125,
    formatter: OrgFormatter
  },
  {
    key: "accountNotifications",
    name: <UserManagementHeader>Notifications</UserManagementHeader>,
    sortable: false,
    width: 300,
    formatter: NotificationsFormatter
  }
];

const UserManagementTable = () => {
  // context(s)
  const {
    dispatch,
    state: { displayedUsers, sortFilters }
  } = useContext(UserManagementContext);

  // effect(s)
  useEffect(() => {
    WBCTeamApi.getCountries({ withAssociatedTeams: true }).then(countries => {
      dispatch({
        type: "setCountries",
        countries
      });
    });
  }, [dispatch]);

  return (
    <div className={"mt-4"}>
      <ReactDataGrid
        columns={columns}
        rowGetter={i => displayedUsers[i]}
        rowsCount={displayedUsers ? displayedUsers.length : 0}
        minHeight={400}
        onGridSort={(col, direction) => dispatch({ type: "setSortFilters", col: col, direction: direction })}
        sortColumn={sortFilters.key}
        sortDirection={sortFilters.direction}
        // rowRenderer={RowRenderer}
      />
    </div>
  );
};

// The code below is commented out and not used because of a known issue with
// React Data Grid: Scrolling issues while having custom dynamic row heights https://github.com/adazzle/react-data-grid/issues/730
// Will revisit if the issue is fixed.

// const CustomSelect = ({ isDisabled, row }) => {
//   const userManagementContext = useContext(UserManagementContext);
//   const userManagementFormContext = useContext(UserManagementFormContext);
//   const { countries, assignmentUpdates } = userManagementContext.state;
//   const fedAdminValue = getFedAdminValue(row, assignmentUpdates, countries);
//
//   let id = "";
//   if (fedAdminValue.length < 4) id = "best-select-grid";
//   if (fedAdminValue.length >= 4 && fedAdminValue.length <= 9) id = "user-fed-admin-select-size2";
//   if (fedAdminValue >= 10) id = "user-fed-admin-select-size3";
//
//   return (
//     <Select
//       id={id}
//       isDisabled={isDisabled}
//       isMulti
//       options={userManagementContext.state.countries}
//       value={fedAdminValue}
//       className="basic-multi-select"
//       classNamePrefix="select"
//       isClearable={false}
//       onChange={data => {
//         userManagementContext.dispatch({
//           type: "updateUserAssignments",
//           teamIds: !data ? [] : data.map(value => value.teamId),
//           accountId: row.accountId
//         });
//         userManagementFormContext.state.onFormDirty();
//       }}
//       menuPortalTarget={document.body}
//     />
//   );
// };
// CustomSelect.propTypes = {
//   isDisabled: PropTypes.bool,
//   row: PropTypes.object
// };
// const RowRenderer = ({ renderBaseRow, ...props }) => {
//   const userManagementContext = useContext(UserManagementContext);
//
//   const { countries, assignmentUpdates } = userManagementContext.state;
//   const fedAdminValue = getFedAdminValue(props.row, assignmentUpdates, countries);
//
//   let rowSize = "35px";
//   if (props.row.role && props.row.role.roleName === UserRoleConstants.FED_TEAM_ADMIN && fedAdminValue) {
//     if (fedAdminValue.length >= 4) rowSize = "85px";
//     if (fedAdminValue.length >= 8) rowSize = "125px";
//     if (fedAdminValue.length >= 12) rowSize = "185px";
//   }
//
//   return <div>{renderBaseRow({ ...props, height: rowSize })}</div>;
// };

// RowRenderer.propTypes = {
//   renderBaseRow: PropTypes.func,
//   props: PropTypes.object,
//   row: PropTypes.object,
//   role: PropTypes.string
// };

RoleFormatter.propTypes = {
  row: PropTypes.object
};

FederationsFormatter.propTypes = {
  row: PropTypes.object
};

OrgFormatter.propTypes = {
  row: PropTypes.object
};

NotificationsFormatter.propTypes = {
  row: PropTypes.object
};

TextFormatter.propTypes = {
  value: PropTypes.string
};

export default UserManagementTable;
