export default {
  STATUS: {
    REQUEST_REQUIRED: "Request Required",
    CANCELLED: "Cancelled",
    SUBMITTED_TO_BOC: "Submitted to BOC",
    SUBMITTED_TO_CLUB: "Submitted to Club",
    APPROVED: "Approved",
    DENIED: "Denied"
  },
  ACTIONS: {
    SUBMIT_TO_BOC: "Submit Request to BOC",
    SUBMIT_TO_CLUB: "Submit Request to Club",
    SUBMIT_TO_WBCI: "Submit Request to WBCI",
    APPROVE: "Approve",
    DENY: "Deny",
    CANCEL: "Cancel",
    RESUBMIT: "Resubmit to BOC"
  },
  MODAL_TEXT: {
    SUBMIT: {
      headerText: "Submit Request",
      content: "Are you sure you want to submit a request for the following person?"
    },
    APPROVE: {
      headerText: "Approve Request",
      content: "Are you sure you want to approve the request for the following person?"
    },
    DENY: {
      headerText: "Deny Request",
      content: "Are you sure you want to deny the request for the following person?"
    },
    CANCEL: {
      headerText: "Are you sure you want to cancel this request?",
      content: "This action cannot be undone."
    },
    RESUBMIT: {
      headerText: "Resubmit Request",
      content: "Are you sure you want to resubmit the request for the following person?"
    }
  }
};
