import BaseApi from "./BaseApi";

export default class ProspectivePlayersApi extends BaseApi {
  static getProspectivePlayers = (year, fedTeamId) => this.get(`prospectiveplayers/${year}/${fedTeamId}`);

  static checkPlayerMatches = wbcPlayer => this.post(`profiles/matches`, wbcPlayer);
  static checkStaffMatches = wbcPlayer => this.post(`profiles/matches?personType=STAFF`, wbcPlayer);

  static searchByEbisId = (profileType, ebisId) => this.get(`${profileType}/searchByEbisId/${ebisId}`);
  static updateProspectivePlayersList = (year, countryCode) =>
    this.get(
      `prospectiveplayers/${year}?countryCode=${countryCode}`,
      {},
      {
        retryAttempts: 0,
        timeout: 300000
      }
    );
}
