import React, { useContext } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import BuildContext from "../../contexts/BuildContext";
import { useOkta, setupRefresh } from "../hooks/useOkta";
import AuthApi from "../../httpClients/AuthApi";
import RouteConstants from "../../constants/RouteConstants";

const SERVICES_URL = window.MLBBest.envVariables.REACT_APP_SERVICES_URL;
const ACTUATOR_INFO_URL = `${SERVICES_URL}/actuator/info`;
const frontendGitInfo = require("../../gitInfo.txt");

const OktaLogin = withRouter(({ history, children }) => {
  const authContext = useContext(AuthContext);
  const buildContext = useContext(BuildContext);

  const { dispatch: authDispatch } = authContext;
  const { dispatch: buildDispatch } = buildContext;

  if (window.location.hash) {
    useOkta(window.location.hash, () => {
      if (!authContext.state.loggedIn) {
        const getUserInfo = () => {
          AuthApi.getUserInfo()
            .then(({ data }) => {
              authDispatch({ type: "setUserInfo", userInfo: data });
              history.push(RouteConstants.BASE);
            })
            .catch(() => {
              authDispatch({ type: "setUserInfoFailed", userInfoFailed: true });
              history.push(RouteConstants.LOGIN);
            });
        };

        const getBuildInfo = () => {
          const promises = [axios.get(ACTUATOR_INFO_URL), frontendGitInfo];

          Promise.all(promises).then(results => {
            const beCommitID = results[0]?.git?.commit?.id;
            const feCommitID = results[1] ? results[1]?.default : "";
            const buildInfo = { backendCommitId: beCommitID, frontendCommitId: feCommitID };
            buildDispatch({ type: "setBuildInfo", buildInfo });
          });
        };

        authDispatch({ type: "login" });
        getUserInfo();
        getBuildInfo();
      }
    });
  } else if (authContext.state.loggedIn) {
    setupRefresh();
  }

  return <>{children}</>;
});

OktaLogin.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default OktaLogin;
