import React, { useContext, useEffect } from "react";
import EligibilityFormatter from "./EligibilityFormatter";
import BirthDateFormatter from "../table/BirthDateFormatter";
import PlayerLinkFormatter from "../table/PlayerLinkFormatter";
import BirthCountryFormatter from "../table/BirthCountryFormatter";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import EligibilityListApi from "../../httpClients/EligibilityListApi";
import DocsFormatter from "../interest-list/DocsFormatter";
import CheckMarkFormatter from "../elements/CheckMarkFormatter";
import WBCDataTable from "../data-table/WBCDataTable";

const columns = [
  {
    key: "submittedBy",
    name: "Submitted By",
    sortable: true,
    width: 150
  },
  {
    key: "docs",
    name: " ",
    sortable: true,
    width: 55,
    formatter: DocsFormatter
  },
  {
    key: "lastName",
    name: "Last",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter
  },
  {
    key: "firstName",
    name: "First",
    sortable: true,
    width: 120,
    formatter: PlayerLinkFormatter
  },
  {
    key: "pastWbcFlag",
    name: "Past WBC",
    sortable: true,
    width: 90,
    formatter: CheckMarkFormatter
  },
  {
    key: "birthDate",
    name: "Birth Date",
    sortable: true,
    width: 100,
    formatter: BirthDateFormatter
  },
  {
    key: "birthCountryId",
    name: "Birth Country",
    sortable: true,
    width: 170,
    formatter: BirthCountryFormatter
  },
  {
    key: "eligibility",
    name: "Eligibility",
    sortable: true,
    formatter: EligibilityFormatter
  }
];

const PlayerEligibilityTable = () => {
  // context(s)
  const { selectedYear } = useContext(WBCYearsContext);
  const playerEligibilityContext = useContext(PlayerEligibilityContext);

  // variable(s)
  const {
    displayedPlayers,
    sortFilters,
    team: { teamId }
  } = playerEligibilityContext.state;
  const { dispatch: eligibilityDispatch } = playerEligibilityContext;

  useEffect(() => {
    if (selectedYear && teamId !== undefined) {
      EligibilityListApi.getPlayerEligibilityList(selectedYear, teamId).then(response => {
        eligibilityDispatch({ type: "setPlayers", players: response });
      });
    }
  }, [teamId, selectedYear, eligibilityDispatch]);

  return (
    <div className="row">
      <div className="col-12 full-page-table dropdown-table">
        <WBCDataTable
          useBCR
          id={"player-eligibility-table"}
          data={displayedPlayers}
          columns={columns}
          minColumnWidth={270}
          sortFunc={(col, direction) =>
            eligibilityDispatch({
              type: "setSortFilters",
              sortFilters: { key: col, direction: direction }
            })
          }
          sortColumn={sortFilters.key}
          sortDirection={sortFilters.direction}
        />
      </div>
    </div>
  );
};

export default PlayerEligibilityTable;
