import React, { useReducer } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import ProspectivePlayerContext from "../contexts/ProspectivePlayerContext";

const initialState = {
  prospectivePlayers: [],
  displayedPlayers: [],
  playerFilter: "",
  onFormDirty: () => {},
  formDirtyBool: false
};

const ProspectivePlayersProvider = ({ children }) => {
  const formatForComparison = str => (!str ? "" : str.replace(/[^A-Za-z]+/g, "").toLowerCase());

  const filterPlayers = (players, playerFilter) => {
    return players.filter(player => {
      if (playerFilter === "") return true;
      return (
        _.startsWith(formatForComparison(player.lastName), formatForComparison(playerFilter)) ||
        _.startsWith(formatForComparison(player.firstName), formatForComparison(playerFilter)) ||
        _.startsWith(formatForComparison(player.lastName + player.firstName), formatForComparison(playerFilter))
      );
    });
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "setProspectivePlayers":
        return {
          ...state,
          prospectivePlayers: action.prospectivePlayers,
          displayedPlayers: filterPlayers(action.prospectivePlayers, state.playerFilter)
        };
      case "setPlayerFilter":
        return {
          ...state,
          playerFilter: action.playerFilter,
          displayedPlayers: filterPlayers(state.prospectivePlayers, action.playerFilter)
        };
      case "addPlayerToInterestList":
        const interestAdded = state.prospectivePlayers.map(p => {
          return {
            ...p,
            onInterestList: p.profileId === action.profileId ? true : p.onInterestList
          };
        });
        return {
          ...state,
          prospectivePlayers: interestAdded,
          displayedPlayers: filterPlayers(interestAdded, state.playerFilter)
        };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <ProspectivePlayerContext.Provider value={{ state, dispatch }}>{children}</ProspectivePlayerContext.Provider>;
};

ProspectivePlayersProvider.propTypes = {
  children: PropTypes.object
};

export default ProspectivePlayersProvider;
