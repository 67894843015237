import React, { useReducer } from "react";
import PropTypes from "prop-types";
import LoginContext from "../contexts/LoginContext";

const initialState = {
  showResetPassword: false,
  resetMessage: ""
};

const LoginContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    if (action.type === "toggleResetPassword") {
      return { ...state, showResetPassword: !state.showResetPassword };
    } else if (action.type === "setResetMessage") {
      return { ...state, resetMessage: action.resetMessage };
    } else {
      return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <LoginContext.Provider value={{ state, dispatch }}>{children}</LoginContext.Provider>;
};

LoginContextProvider.propTypes = {
  children: PropTypes.object
};

export default LoginContextProvider;
