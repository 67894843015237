import BaseApi from "./BaseApi";

export default class EligibilityListApi extends BaseApi {
  static getPlayerEligibilityList = (wbcYear, fedTeamId) => this.get(`eligibilitylist/${wbcYear}/${fedTeamId}`);

  static addPlayerToEligibilityList = (year, teamId, profileId) =>
    this.put(`eligibilitylist/${year}?profileId=${profileId}&fedTeamId=${teamId}`);

  static approveProfileEligibility = (year, teamId, profileId) =>
    this.put(`eligibilitylist/${year}/${teamId}/approve/${profileId}`);

  static denyProfileEligibility = (year, teamId, profileId, reason) =>
    this.put(`eligibilitylist/${year}/${teamId}/deny/${profileId}`, { reason });

  static setToPendingProfileEligibility = (year, teamId, profileId, reason) =>
    this.put(`eligibilitylist/${year}/${teamId}/pending/${profileId}`, { reason });
}
