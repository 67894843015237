import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";

const BodyContent = styled.div`
  text-align: left;
  margin-bottom: 100px;
`;

const AreYouSureModalBodyContent = ({ body }) => {
  // context(s)
  const globalModalContext = React.useContext(GlobalModalContext);

  return <BodyContent>{globalModalContext.state.content || body}</BodyContent>;
};

const AreYouSureModalFooterContent = ({ onDiscardChanges, discardChangesButtonText }) => {
  // context(s)
  const globalModalContext = React.useContext(GlobalModalContext);

  // function(s)
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });

  return (
    <div className="d-flex">
      <WBCPrimaryButton
        width={142}
        onClick={event => {
          event.stopPropagation();
          onDiscardChanges();
          onClose();
        }}
      >
        {discardChangesButtonText ? discardChangesButtonText : "Discard Changes"}
      </WBCPrimaryButton>
      <div className="pl-2">
        <WBCPrimaryOutlinedButton
          width={142}
          onClick={event => {
            event.stopPropagation();
            onClose();
          }}
        >
          Back
        </WBCPrimaryOutlinedButton>
      </div>
    </div>
  );
};

AreYouSureModalBodyContent.propTypes = {
  body: PropTypes.string
};

AreYouSureModalFooterContent.propTypes = {
  onDiscardChanges: PropTypes.func,
  discardChangesButtonText: PropTypes.string,
  onBackClicked: PropTypes.func,
  discardChangesFnArg: PropTypes.any
};

AreYouSureModalBodyContent.defaultProps = {
  body: "You have unsaved changes that will be lost."
};

AreYouSureModalBodyContent.displayName = "BodyContent";
AreYouSureModalFooterContent.displayName = "FooterContent";

export { AreYouSureModalBodyContent, AreYouSureModalFooterContent };
