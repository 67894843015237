import React, { useContext } from "react";
import PropTypes from "prop-types";
import OrgsContext from "../../contexts/OrgsContext";

const OrgFormatter = ({ value }) => {
  const orgContext = useContext(OrgsContext);
  return <div>{orgContext.getOrgCode(value)}</div>;
};

OrgFormatter.propTypes = {
  value: PropTypes.number
};

export default OrgFormatter;
