import React, { useCallback, useContext } from "react";
import Select from "react-select";
import styled from "styled-components";

import PitchTrackerContext from "../../contexts/PitchTrackerContext";

const PitchTrackerPitcherSelectContainer = styled.div`
  display: inline-flex;
  input {
    margin-right: 0.25rem;
    width: 100px;
  }
`;

const PitchTrackerPitcherSelect = () => {
  const {
    state: { addPlayerMode, editPlayerMode, pitcherSelectOptions },
    actions: { addPitcherToPitchCounts, pitchCountsEditPlayerNewProfile }
  } = useContext(PitchTrackerContext);

  const handlePlayerSelect = useCallback(
    selection => {
      selection.value.wasManuallyAdded = true;
      if (addPlayerMode) {
        addPitcherToPitchCounts(selection.value);
      } else if (editPlayerMode) {
        pitchCountsEditPlayerNewProfile(selection.value);
      }
    },
    [addPitcherToPitchCounts, addPlayerMode, editPlayerMode, pitchCountsEditPlayerNewProfile]
  );

  const noAvailablePlayers = useCallback(() => "No Available Players", []);

  return (
    <PitchTrackerPitcherSelectContainer>
      <Select
        className="pitch-tracker-pitcher-select"
        isSearchable={true}
        noOptionsMessage={noAvailablePlayers}
        onChange={handlePlayerSelect}
        options={pitcherSelectOptions}
        placeholder={"Search Players"}
      />
    </PitchTrackerPitcherSelectContainer>
  );
};

export default PitchTrackerPitcherSelect;
