import { useEffect, useContext, useCallback, useRef } from "react";
import CountryContext from "../../contexts/CountryContext";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import _ from "lodash";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import AuthContext from "../../contexts/AuthContext";
import { findSelectedTeamByCountry } from "../../utils/FedTeamUtils";

const teamState = new Set(["QL", "TR", "QT"]);

export const useTeamsByYear = () => {
  // contexts
  const authContext = useContext(AuthContext);
  const { selectedYear } = useContext(WBCYearsContext);
  const countryContext = useContext(CountryContext);
  const selectedTeamContext = useContext(SelectedTeamContext);

  // variables
  const mostRecentlyRequestedYearRef = useRef(null);

  // based on the arguement, set which context to use

  // instance variables
  const { dispatch } = selectedTeamContext;
  const { dispatch: selectedTeamDispatch } = selectedTeamContext;
  const { loggedIn } = authContext.state;
  const { countryDropdown } = countryContext.state;
  const { teamListChanged, team: selectedTeam } = selectedTeamContext.state;

  // function(s)
  const getFederationTeamsByYear = useCallback(() => {
    if (
      loggedIn &&
      selectedYear &&
      countryDropdown &&
      countryDropdown.length > 0 &&
      (mostRecentlyRequestedYearRef.current !== selectedYear || teamListChanged)
    ) {
      mostRecentlyRequestedYearRef.current = selectedYear;
      WBCTeamApi.getFederationTeamsByYear(selectedYear).then(allTeams => {
        if (mostRecentlyRequestedYearRef.current === selectedYear) {
          const teamList = allTeams
            .filter(team => team.currentState && teamState.has(team.currentState.statusCode))
            .map(team => {
              const country = countryDropdown.find(c => c.value === team.countryId) || {
                label: "",
                code: "",
                value: 0
              };
              country.teamId = team.teamId;
              country.finalizedStatus = team.finalizedStatus;
              return country;
            });

          const teams = _.sortBy(teamList, [t => t.label]);
          const team = findSelectedTeamByCountry(teams, selectedTeam);
          dispatch({ type: "setTeams", teams, team });
        }
      });
    }
  }, [loggedIn, selectedYear, countryDropdown, teamListChanged, selectedTeam, dispatch]);

  // Get the countries for the BOC's team select
  useEffect(getFederationTeamsByYear, [getFederationTeamsByYear]);

  useEffect(() => {
    if (teamListChanged) {
      getFederationTeamsByYear();
      selectedTeamDispatch({ type: "setTeamListChanged", teamListChanged: false });
    }
  }, [selectedTeamDispatch, teamListChanged, getFederationTeamsByYear]);
};
