import React, { useContext } from "react";
import styled from "styled-components";
import GlobalModalContext from "../../../contexts/GlobalModalContext";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 16px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const CatcherModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const { lastName, firstName } = globalModalContext.state.content.player;
  return (
    <BodyContent>
      <ModalText>
        Only players with the position of C can be added as a Catcher.
        <br />
        Do you wish to update the position for{" "}
        <Bold>
          {firstName} {lastName}
        </Bold>{" "}
        to C?
      </ModalText>
    </BodyContent>
  );
};

CatcherModalBody.displayName = "BodyContent";

export default CatcherModalBody;
