import React, { useContext, useEffect } from "react";
import { FormColumn2Wide } from "best-common-react";
import PropTypes from "prop-types";
import styled from "styled-components";
import EventReportStatusConstants from "../../constants/EventReportStatusConstants";
import EventReportContext from "../../contexts/clubcomm/EventReportContext";
import GameReportAddPlayersContext from "../../contexts/clubcomm/GameReportAddPlayersContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import PlayerReportModalContext from "../../contexts/PlayerReportModalContext";
import EventReportApi from "../../httpClients/EventReportApi";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import EventInfoSection from "./EventInfoSection";
import EventReportPlayerTable from "./EventReportPlayerTable";
import StatusInfoSection from "./StatusInfoSection";

const Content = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 80vh;
`;

const HistoryTableWrapper = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: row-reverse;
`;

const EventReport = ({ teamId, eventReportId }) => {
  // hooks
  const eventReportContext = useContext(EventReportContext);
  const globalModalContext = useContext(GlobalModalContext);
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const gameReportPlayersContext = useContext(GameReportAddPlayersContext);

  // variables
  const {
    dispatch,
    state: { eventReport }
  } = eventReportContext;
  const { dispatch: reportDispatch } = playerReportModalContext;

  // effects
  useEffect(() => {
    dispatch({
      type: "setFormDirty",
      formDirty: false
    });

    const promiseList = [
      EventReportApi.getEventReportForTeam(teamId, eventReportId),
      EventReportApi.getEventReportPlayersInfo(teamId, eventReportId)
    ];

    Promise.all(promiseList).then(results => {
      const [eventReport, eventPlayers] = results;
      dispatch({
        type: "initializeEventReportAndPlayers",
        eventReport,
        eventPlayers
      });
      reportDispatch({ type: "resetSortFilters" });
    });
  }, [dispatch, reportDispatch, teamId, eventReportId]);

  return (
    <div>
      <Content>
        <div className="row">
          <FormColumn2Wide>
            <EventInfoSection eventReport={eventReport} dispatch={dispatch} />
          </FormColumn2Wide>
          <FormColumn2Wide>
            <StatusInfoSection />
          </FormColumn2Wide>
        </div>
        <div className="row no-gutters mt-3" style={{ width: "100%" }}>
          <HistoryTableWrapper>
            <EventReportPlayerTable />
          </HistoryTableWrapper>
          <ButtonWrapper>
            <WBCAdminAccess
              bocOnly={
                eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
                eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
              }
            >
              <WBCPrimaryOutlinedButton
                onClick={() => {
                  globalModalContext.dispatch({
                    type: "openAddPlayerModal",
                    content: {
                      teamId: teamId,
                      eventReportId: eventReportId
                    },
                    closeCallback: () => {
                      gameReportPlayersContext.dispatch({
                        type: "setSelectedProspectivePlayer",
                        selectedProspectivePlayer: undefined
                      });
                    }
                  });
                }}
              >
                Add Player
              </WBCPrimaryOutlinedButton>
            </WBCAdminAccess>
          </ButtonWrapper>
        </div>
      </Content>
    </div>
  );
};

EventReport.propTypes = {
  teamId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  eventReportId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

export default EventReport;
