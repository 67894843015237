import React, { useContext } from "react";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";
import PropTypes from "prop-types";
import GlobalModalContext from "../../contexts/GlobalModalContext";

const openUnsavedChangesModal = (
  globalModalContext,
  saveChangesFn,
  saveChangesFnArg,
  discardChangesFn,
  discardChangesFnArg
) => {
  globalModalContext.dispatch({
    type: "openUnsavedChangesModal",
    saveChangesFn,
    saveChangesFnArg,
    discardChangesFn,
    discardChangesFnArg
  });
};

const UnsavedChangesModalBodyContent = () => {
  return (
    <div data-testid="global-unsaved-changes-modal-body" style={{ float: "left", marginBottom: "100px" }}>
      Do you want to save your changes before continuing?
    </div>
  );
};

const UnsavedChangesModalFooterContent = ({ onSave, onSaveArg, onCancel, onCancelArg, globalModal }) => {
  const globalModalContext = useContext(GlobalModalContext);
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });
  return (
    <div data-testid="global-unsaved-changes-modal-footer" className={"d-flex"}>
      <WBCPrimaryButton
        data-testid="global-unsaved-changes-modal-save-button"
        styles={{ width: "142px" }}
        disabled={false}
        onClick={event => {
          event.stopPropagation();
          onSave(onSaveArg);
          if (globalModal) onClose();
        }}
      >
        Save Changes
      </WBCPrimaryButton>
      <div className={"pl-2"}>
        <WBCPrimaryOutlinedButton
          data-testid="global-unsaved-changes-modal-discard-button"
          styles={{ width: "142px" }}
          onClick={event => {
            event.stopPropagation();
            onCancel(onCancelArg);
            if (globalModal) onClose();
          }}
        >
          Discard Changes
        </WBCPrimaryOutlinedButton>
      </div>
    </div>
  );
};

UnsavedChangesModalFooterContent.propTypes = {
  formStatus: PropTypes.bool,
  onSave: PropTypes.func,
  onSaveArg: PropTypes.any,
  onCancel: PropTypes.func,
  onCancelArg: PropTypes.any,
  globalModal: PropTypes.bool
};

UnsavedChangesModalFooterContent.defaultProps = {
  formStatus: true,
  onSave: () => {},
  onCancel: () => {}
};

UnsavedChangesModalFooterContent.displayName = "FooterContent";
UnsavedChangesModalBodyContent.displayName = "BodyContent";

export { UnsavedChangesModalBodyContent, UnsavedChangesModalFooterContent, openUnsavedChangesModal };
