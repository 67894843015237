import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CommentsWrapper = styled.div`
  width: 218px;
  text-align: left;
  padding: 0 12px;
  max-height: 250px;
  overflow-y: auto;
`;

const Title = styled.h1`
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
  padding-bottom: 4px;
  border-bottom: 1px solid #b9d5e3;
`;

const Comments = styled.p`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.black};
  white-space: normal;
  word-break: break-word;
  margin-bottom: 0;
`;

const CommentsContent = ({ content, title, hasTitle = true }) => {
  return (
    <CommentsWrapper>
      {hasTitle && <Title>{title || "Comments"}</Title>}
      <Comments>{content}</Comments>
    </CommentsWrapper>
  );
};

CommentsContent.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  hasTitle: PropTypes.bool
};

export default CommentsContent;
