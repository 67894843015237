import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import DisabledInputStyle from "../../../elements/DisabledInputStyle";
import { NumberInputWrapper, BreakoutTableData, BreakoutTableText } from "./PlayerModalStyling";
import WBCNumberInput from "../../../elements/WBCNumberInput";

const BreakoutTablePitchesText = styled.div`
  width: 115px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
`;

const BreakoutRow = styled.tr`
  &&& {
    input {
      height: 28px;
    }
  }
`;

const RequiredIndicator = styled.span`
  margin-left: 4px;
  color: ${props => props.theme["mlb-red"]};
  font-weight: bold;
`;

const PitcherBreakoutRow = ({ header, data, pitchType, disabled, totalPitches, total, required }) => {
  const playerReportContext = useContext(PlayerReportModalContext);

  const { dispatch } = playerReportContext;

  // Initialize the breakout properties
  if (!totalPitches) {
    data.count = data.count === undefined ? null : data.count;
    data.strikes = data.strikes === undefined ? null : data.strikes;
    data.balls = data.balls === undefined || isNaN(data.balls) ? 0 : data.balls;
    data.highSpeed = data.highSpeed === undefined ? null : data.highSpeed;
    data.avgSpeed = data.avgSpeed === undefined ? null : data.avgSpeed;
  } else {
    data.totalPitches = data.totalPitches === undefined ? null : data.totalPitches;
  }

  const countFieldUpdated = newValue => {
    if (totalPitches) {
      dispatch({
        type: "setPitcherField",
        value: newValue,
        pitchType: "totalPitches"
      });
    } else {
      dispatch({
        type: "setCount",
        value: newValue,
        pitchType: pitchType
      });
    }
  };

  return (
    <BreakoutRow>
      <BreakoutTableData>
        {totalPitches ? (
          <BreakoutTablePitchesText>
            {header}
            {required ? <RequiredIndicator>*</RequiredIndicator> : null}
          </BreakoutTablePitchesText>
        ) : (
          <BreakoutTableText>
            {header}
            {required ? <RequiredIndicator>*</RequiredIndicator> : null}
          </BreakoutTableText>
        )}
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          {disabled && (
            <FormInput
              type="input"
              name="count"
              isDisabled
              value={totalPitches ? data.totalPitches : data.count}
              editable
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
          {!disabled && (
            <WBCNumberInput
              type="number"
              step="1"
              max="999"
              positiveOnly
              editable
              name="count"
              value={totalPitches ? data.totalPitches : data.count}
              onChange={value => {
                countFieldUpdated(value);
              }}
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          {totalPitches ? null : disabled ? (
            <FormInput
              type="input"
              name="strikes"
              isDisabled
              value={data.strikes}
              editable
              inputStyle={DisabledInputStyle}
              label=""
            />
          ) : (
            <WBCNumberInput
              step="1"
              max="999"
              positiveOnly
              editable
              name="strikes"
              value={data.strikes}
              onChange={value => {
                dispatch({
                  type: "setStrikes",
                  value: value,
                  pitchType: pitchType
                });
              }}
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          {totalPitches ? null : (
            <FormInput
              type="input"
              name="balls"
              isDisabled
              value={data.balls}
              editable
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          {!totalPitches && !total && disabled && (
            <FormInput
              type="input"
              name="highSpeed"
              isDisabled
              value={data.highSpeed}
              editable
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
          {!totalPitches && !total && !disabled && (
            <WBCNumberInput
              type="number"
              step="0.01"
              editable
              name="highSpeed"
              value={data.highSpeed}
              onChange={value => {
                dispatch({
                  type: "setPitcherField",
                  value: value,
                  pitchType: pitchType,
                  statKey: "highSpeed"
                });
              }}
              inputStyle={DisabledInputStyle}
              label=""
              positiveOnly
            />
          )}
        </NumberInputWrapper>
      </BreakoutTableData>
      <BreakoutTableData>
        <NumberInputWrapper>
          {!totalPitches && !total && disabled && (
            <FormInput
              type="input"
              name="avgSpeed"
              isDisabled
              value={data.avgSpeed}
              editable
              inputStyle={DisabledInputStyle}
              label=""
            />
          )}
          {!totalPitches && !total && !disabled && (
            <WBCNumberInput
              type="number"
              step="0.01"
              editable
              name="avgSpeed"
              value={data.avgSpeed}
              onChange={value => {
                dispatch({
                  type: "setPitcherField",
                  value: value,
                  pitchType: pitchType,
                  statKey: "avgSpeed"
                });
              }}
              inputStyle={DisabledInputStyle}
              label=""
              positiveOnly
            />
          )}
        </NumberInputWrapper>
      </BreakoutTableData>
    </BreakoutRow>
  );
};

PitcherBreakoutRow.propTypes = {
  header: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  pitchType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  totalPitches: PropTypes.bool,
  total: PropTypes.bool,
  required: PropTypes.bool
};

PitcherBreakoutRow.defaultProps = {
  totalPitches: false,
  total: false,
  disabled: false,
  required: false
};

export default PitcherBreakoutRow;
