export default {
  ACCOUNT: "fas fa-user-circle",
  BULLHORN_ICON: "fas fa-bullhorn",
  CLIPBOARD_ICON: "fas fa-clipboard-list",
  HELP_ICON: "fas fa-question-circle",
  SETTINGS_ICON: "fas fa-cog",
  TROPHY_ICON: "fas fa-trophy",
  USERS_ICON: "fas fa-users",
  EMAIL_NOTIFICATIONS_ICON: "fas fa-paper-plane"
};
