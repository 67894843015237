import styled from "styled-components";

const FormTitleWrapper = styled.div`
  color: #666666;
`;

const Required = styled.span`
  color: ${props => props.theme["mlb-red"]};
  padding-left: 5px;
`;

export { FormTitleWrapper, Required };
