import React, { useState, useRef } from "react";
import { MenuButton, MenuIcon, ContextMenu } from "../../elements/ContextMenu";
import CommentsContent from "../../elements/CommentsContent";
import styled from "styled-components";

const OverflowContatiner = styled.div`
  cursor: pointer;
  text-align: center;
  font-weight: lighter;
  color: ${props => props.theme.wbcBlue};
  &:hover {
    text-decoration: none;
  }
`;
const EligibilityPopover = styled(ContextMenu)`
  padding: 8px 0px;
  background-color: ${props => props.theme.white};
`;

const OverflowFormatter = (row, hasTitle = true, hasIcon = true, iconName = "fa-comment") => {
  const targetRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = value => setIsOpen(value !== undefined ? value : !isOpen);
  const { value } = row;
  if (row?.value && row.value.length >= 1) {
    return (
      <>
        <MenuButton ref={targetRef} onClick={() => setIsOpen(!isOpen)}>
          {hasIcon ? <MenuIcon iconName={iconName} /> : <OverflowContatiner>{row.value}</OverflowContatiner>}
        </MenuButton>
        <EligibilityPopover targetRef={targetRef} isOpen={isOpen} toggleMenu={toggleMenu}>
          <CommentsContent hasTitle={hasTitle} content={row.value} />
        </EligibilityPopover>
      </>
    );
  }
  return <></>;
};

export default OverflowFormatter;
