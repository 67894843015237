import React, { useContext, useEffect, useState } from "react";
import WBCDataTable from "../data-table/WBCDataTable";
import PlayerLinkFormatter from "../table/PlayerLinkFormatter";
import EmailNotificationsContext from "../../contexts/EmailNotificationsContext";
import styled from "styled-components";
import WBCCheckbox from "../elements/WBCCheckbox";
import PropTypes from "prop-types";
import RosterStatusFormatter from "../mlb-players/mlb-player-summary/MLBPlayerRosterStatusFormatter";
import AuthContext from "../../contexts/AuthContext";
import { sortPlayers } from "../roster/RosterSortHelper";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";

const CheckboxContainer = styled.div`
  cursor: pointer;
`;

const EmailNotificationsTable = () => {
  const emailNotificationsContext = useContext(EmailNotificationsContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const { emails, emailIdsToSend, setEmailIdsToSend, filteredEmails, sortFilters } = useContext(
    EmailNotificationsContext
  );
  const { isBOCadmin } = useContext(AuthContext).state;
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(filteredEmails.map(d => ({ ...d, isChecked: false })));
  }, [filteredEmails]);

  useEffect(() => {
    if (emailIdsToSend.length === 0) {
      setSelectedIndexes([]);
    }
  }, [emailIdsToSend]);

  const DateFormatter = row => {
    return row.value ? new Date(row.value).toLocaleDateString() : "";
  };

  const NotificationSelectCheckbox = ({ ...props }) => {
    return isBOCadmin ? (
      <CheckboxContainer>
        <WBCCheckbox checked={props.row.isChecked} />
      </CheckboxContainer>
    ) : null;
  };

  NotificationSelectCheckbox.propTypes = {
    row: PropTypes.any,
    value: PropTypes.any,
    rowIdx: PropTypes.any,
    idx: PropTypes.any
  };

  const columns = [
    { name: "", key: "id", width: 50, formatter: NotificationSelectCheckbox, isSelected: true },
    { name: "Notification", key: "notificationName", width: 200, sortable: true },
    { name: "Date Created", key: "createdTs", width: 180, formatter: DateFormatter, sortable: true },
    { name: "Sent to FED", key: "sentToFed", width: 180, formatter: DateFormatter, sortable: true },
    { name: "Sent to CLUB", key: "sentToClub", width: 180, formatter: DateFormatter, sortable: true },
    { name: "Last", key: "lastName", width: 180, formatter: PlayerLinkFormatter, sortable: true },
    { name: "First", key: "firstName", width: 180, formatter: PlayerLinkFormatter, sortable: true },
    { name: "Pos", key: "position", width: 75, sortable: true },
    { name: "Org", key: "org", width: 75, sortable: true },
    { name: "Club", key: "club", width: 180, sortable: true },
    { name: "IL", key: "injuredListFlag", width: 180, sortable: true },
    { name: "40-man", key: "fortyMan", width: 180 },
    { name: "MLB Roster Status", key: "rosterStatus", width: 200, formatter: RosterStatusFormatter, sortable: true },
    { name: "Federation", key: "federation", width: 600, sortable: true }
  ];

  return (
    <WBCDataTable
      useBCR
      rowKey="id"
      index
      columns={columns}
      headerRowHeight={40}
      minHeight={400}
      rowHeight={45}
      data={data}
      rowSelection={{
        showCheckbox: false,
        selectBy: {
          indexes: selectedIndexes
        }
      }}
      enableRowSelect={true}
      onRowClick={(rowIdx, row, column) => {
        if (column?.idx === 0 && isBOCadmin) {
          if (selectedIndexes.includes(rowIdx)) {
            setSelectedIndexes(selectedIndexes.filter(i => i !== rowIdx));
            setEmailIdsToSend(emailIdsToSend.filter(id => id !== row.id));
            row.isChecked = false;
          } else {
            setSelectedIndexes(selectedIndexes.concat(rowIdx));
            setEmailIdsToSend(emailIdsToSend.concat(row.id));
            row.isChecked = true;
          }
        }
      }}
      sortFunc={(col, dir) => {
        emailNotificationsContext.dispatch({
          col,
          dir,
          type: "sortEmails",
          emails: sortPlayers(col, dir, emails, false)
        });
        tableSortFilterContext.dispatch({
          type: "setSortFilters",
          sortFilters: { col, dir }
        });
      }}
      sortColumn={sortFilters.col}
      sortDirection={sortFilters.dir}
    />
  );
};

export default EmailNotificationsTable;
