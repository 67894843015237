import React from "react";
import Settings from "../components/settings/Settings";
import SettingsFormContextProvider from "../providers/SettingsFormContextProvider";

const SettingsContainer = () => {
  return (
    <SettingsFormContextProvider>
      <Settings />
    </SettingsFormContextProvider>
  );
};

export default SettingsContainer;
