import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import ProfileRequestConstants from "../../constants/ProfileRequestConstants";

const BodyContent = styled.div`
  margin: 1rem 0;
`;

const PersonBioContainer = styled.div`
  margin: 1rem 0;
`;

const Content = styled.div`
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
`;

const PersonBioTable = styled.table`
  thead {
    background-color: ${props => props.theme["almost-white"]};
    color: ${props => props.theme["dark-grey"]};
  }
  tbody {
    background-color: ${props => props.theme.white};
  }
`;

const ConfirmSubmitRequestModalContent = ({ content, submitProfile }) => {
  const contentWithProfileType =
    submitProfile?.profileType?.toUpperCase() === "PLAYER"
      ? content.replace("person", "player")
      : submitProfile?.profileType?.toUpperCase() === "STAFF"
      ? content.replace("person", "staff")
      : content;

  return (
    <>
      <BodyContent>
        <PersonBioContainer className="table-responsive">
          <Content>{contentWithProfileType}</Content>
          <PersonBioTable className="table">
            <thead>
              <tr>
                <th>Last</th>
                <th>First</th>
                <th>Org</th>
                <th>Club</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{submitProfile?.lastName}</td>
                <td>{submitProfile?.firstName}</td>
                <td>{submitProfile?.org || submitProfile?.orgCode}</td>
                <td>{submitProfile?.club}</td>
              </tr>
            </tbody>
          </PersonBioTable>
        </PersonBioContainer>
      </BodyContent>
    </>
  );
};

const ConfirmSubmitRequestModalFooterContent = ({ onConfirmRequestFn, updateStatusButtonText }) => {
  // context(s)
  const globalModalContext = React.useContext(GlobalModalContext);

  // function(s)
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });

  return (
    <>
      <div className="d-flex">
        <WBCPrimaryButton
          width={200}
          onClick={event => {
            event.stopPropagation();
            onConfirmRequestFn();
            onClose();
          }}
        >
          {updateStatusButtonText ? updateStatusButtonText : ProfileRequestConstants.ACTIONS.SUBMIT_TO_BOC}
        </WBCPrimaryButton>
        <div className="pl-2">
          <WBCPrimaryOutlinedButton
            width={142}
            onClick={event => {
              event.stopPropagation();
              onClose();
            }}
          >
            Back
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </>
  );
};

ConfirmSubmitRequestModalContent.propTypes = {
  content: PropTypes.string,
  submitProfile: PropTypes.any
};

ConfirmSubmitRequestModalFooterContent.propTypes = {
  onConfirmRequestFn: PropTypes.func,
  updateStatusButtonText: PropTypes.string
};

ConfirmSubmitRequestModalContent.displayName = "BodyContent";
ConfirmSubmitRequestModalFooterContent.displayName = "FooterContent";

export { ConfirmSubmitRequestModalContent, ConfirmSubmitRequestModalFooterContent };
