import { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";

export const useBOCdropdown = () => {
  const authContext = useContext(AuthContext);
  const globalFedDropdownContext = useContext(GlobalFedDropdownContext);
  const { isBOC, isFedAdmin, isFedUser, isTeamCoordinator } = authContext.state;
  const { dispatch } = globalFedDropdownContext;

  useEffect(() => {
    dispatch({
      type: "setState",
      showingState: isBOC || isFedAdmin || isFedUser || isTeamCoordinator,
      dropdownState: isBOC || isFedAdmin || isTeamCoordinator
    });
  }, [dispatch, isBOC, isFedAdmin, isFedUser, isTeamCoordinator]);
};
