import React, { useState, useContext, useEffect } from "react";
import { Icon } from "best-common-react";
import styled from "styled-components";
import Theme from "../../Theme";
import WBCMobileNavContext from "../../contexts/WBCMobileNavContext";
import PropTypes from "prop-types";

const Title = styled.div.attrs(() => ({
  className: "d-flex justify-content-between"
}))`
  font-size: 16px;
  font-weight: bold;
  padding: 8px 1rem;
  padding-left: 32px;
  color: white !important;
  background-color: ${Theme.mlbNavy};
  border-style: solid;
  border-color: #000000;
  ${props => (props.first ? `border-width: 1px 1px 1px 1px;` : `border-width: 0px 1px 1px 1px;`)}
  @media (min-width: 768px) {
  }
  &:hover {
    cursor: pointer;
  }
`;

const Links = styled.div.attrs(() => ({
  className: "pl-4 pt-2 pb-2"
}))`
  background-color: #142958;
`;

const WBCMobileNavSection = ({ title, icon, children, first }) => {
  // hooks
  const [expanded, setExpanded] = useState(false);
  const wbcMobileNavContext = useContext(WBCMobileNavContext);

  const { openSection } = wbcMobileNavContext.state;

  useEffect(() => setExpanded(openSection === title), [openSection, title]);

  return (
    <>
      <Title
        first={first}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (wbcMobileNavContext) {
            wbcMobileNavContext.dispatch({ type: "setOpenSection", openSection: expanded ? "" : title });
          } else {
            setExpanded(!expanded);
          }
        }}
      >
        <div>
          <Icon style={{ marginRight: "10px", color: Theme.wbcBlue }} iconName={icon} />
          {title}
        </div>
        <Icon
          style={{ marginRight: "10px", color: Theme.white }}
          iconName={expanded ? "fa-chevron-up" : "fa-chevron-down"}
        />
      </Title>
      {expanded ? <Links>{children}</Links> : null}
    </>
  );
};

WBCMobileNavSection.propTypes = {
  title: PropTypes.any,
  icon: PropTypes.string,
  children: PropTypes.any,
  first: PropTypes.bool
};

WBCMobileNavSection.defaultProps = {
  first: false
};

export default WBCMobileNavSection;
