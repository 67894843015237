import React, { useContext } from "react";
import styled from "styled-components";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import WBCRadioButton from "../../elements/WBCRadioButton";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 16px;
`;

const Bold = styled.span.attrs(() => ({}))`
  font-weight: bold;
`;
const InfielderModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const { selectedPosition } = globalModalContext.state;
  const { lastName, firstName } = globalModalContext.state.content.player;

  const setPosition = position => {
    globalModalContext.dispatch({
      type: "setSelectedPosition",
      position: position
    });
  };

  return (
    <BodyContent>
      <ModalText>
        Only players with the position of 1B, 2B, 3B, SS, or IF can be added as an infielder.
        <br />
        Do you wish to update the position for{" "}
        <Bold>
          {firstName} {lastName}
        </Bold>{" "}
        to one of the following?
        <div className="mt-3 d-flex">
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "1B"} onClick={() => setPosition("1B")} label="1B" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "2B"} onClick={() => setPosition("2B")} label="2B" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "3B"} onClick={() => setPosition("3B")} label="3B" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "SS"} onClick={() => setPosition("SS")} label="SS" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "IF"} onClick={() => setPosition("IF")} label="IF" />
          </div>
        </div>
      </ModalText>
    </BodyContent>
  );
};

InfielderModalBody.displayName = "BodyContent";

export default InfielderModalBody;
