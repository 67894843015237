import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tooltip from "../../elements/Tooltip";

const Text = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.black};

  /* ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CellText = ({ className, children, placement }) => {
  // hooks
  const textRef = useRef(null);
  const [isEllipsisVisible, setIsEllipsisVisible] = useState(true);

  // effects
  const checkForEllipsis = useCallback(() => {
    if (!textRef.current) return;
    const { offsetWidth, scrollWidth } = textRef.current;
    const result = offsetWidth < scrollWidth;
    if (result !== isEllipsisVisible) {
      setIsEllipsisVisible(result);
    }
  }, [textRef, isEllipsisVisible]);

  // initialize
  useEffect(checkForEllipsis, [checkForEllipsis]);

  // ellipsis check
  useEffect(() => {
    window.addEventListener("resize", checkForEllipsis);
    return () => window.removeEventListener("resize", checkForEllipsis);
  }, [checkForEllipsis, setIsEllipsisVisible]);

  return (
    <>
      <Text ref={textRef} className={className}>
        {children}
      </Text>
      {!isEllipsisVisible ? null : (
        <Tooltip placement={placement} targetRef={textRef}>
          {children}
        </Tooltip>
      )}
    </>
  );
};

CellText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"])
};

CellText.defaultProps = {
  placement: "bottom"
};

export default CellText;
