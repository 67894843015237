import React, { useContext, useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { BestLabel, FormInput, TextArea } from "best-common-react";
import PositionsContext from "../../../../contexts/PositionsContext";
import WBCCheckbox from "../../../elements/WBCCheckbox";
import PropTypes from "prop-types";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import { PlayerTypeComponents } from "./WBCPlayerModal";
import ReportCheckmark from "./ReportCheckmark";
import MobileView from "../../../temp/MobileView";
import WBCAccessControl from "../../../protected/WBCAccessControl";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";
import EventReportStatusConstants from "../../../../constants/EventReportStatusConstants";
import StaffApi from "../../../../httpClients/StaffApi";

const BoldText = styled.div`
  font-weight: bold;
  font-size: 14px;
`;
const PlayerName = styled.div.attrs(() => ({
  className: "ml-2 mr-2"
}))`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  text-align: left;
`;

const PositionsDropdownStyle = styled.div.attrs(() => ({
  className: "ml-2 mr-2"
}))`
  text-align: left;
`;

const FormTitleWrapper = styled.div`
  &&& {
    color: #666666;
  }
`;

const Title = styled.div.attrs(() => ({
  className: " mt-0 mb-3 "
}))`
  text-align: left;
  width: 100%;
  border-bottom: 1px solid #b9d5e3;
  font-size: 16px;
`;

const BodyContent = styled.div.attrs(() => ({
  className: "ml-2 mr-2"
}))`
  text-align: left;
`;

const SideWorkCompStyle = styled.div.attrs(() => ({
  className: "ml-2 mr-3"
}))`
  margin-bottom: 23px;
  text-align: left;

  &&&& {
    textarea {
      :disabled {
        color: black;
      }
    }
  }
`;

const CheckBoxContainer = styled.div.attrs(() => ({
  className: "d-flex align-items-center"
}))``;

const CheckBoxOuterContainer = styled.div.attrs(() => ({
  className: "mb-3"
}))`
  @media (max-width: 576px) {
    .col-sm-6 {
      flex: 0 0 50% !important;
      max-width: 50% !important;
    }
  }
`;

const PlayerReportModalBody = ({ children, content }) => {
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const positionsContext = useContext(PositionsContext);
  const eventReportContext = useContext(EventReportContext);
  const commentsArea = useRef(null);
  const { posIdToAbbrev, profilePositionsDropdown } = positionsContext.state;
  const playerReportModalDispatch = playerReportModalContext.dispatch;
  const { eventPlayers, eventPlayersInfo, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];
  const currentEventPlayerInfo = eventPlayersInfo[currentIndex];
  const { eventReport } = eventReportContext.state;

  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    StaffApi.getTeamStaff(eventReport.teamId, eventReport.tournamentType).then(data => {
      const allStaff = [].concat(data?.coaches || [], data?.managers || [], data?.trainers || []);
      setCoaches(allStaff);

      if (currentEventPlayer?.reportById) {
        const reportBy = allStaff.find(staff => staff.profileId === currentEventPlayer.reportById);
        if (reportBy) {
          currentEventPlayer.reportByLabel = `${reportBy?.fullName?.lastName}, ${reportBy?.fullName?.firstName}`;
          playerReportModalDispatch({
            type: "reportByOnChange",
            reportById: currentEventPlayer.reportById,
            reportByLabel: currentEventPlayer.reportByLabel
          });
        }
      }
    });
  }, [currentEventPlayer, eventReport, playerReportModalDispatch]);

  const formDirtyFn = () => {
    playerReportModalDispatch({
      type: "setFormDirty",
      formDirty: true
    });
  };

  return (
    <div>
      {currentEventPlayer ? (
        <WBCAccessControl
          bocOnly={
            eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
            eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
          }
          input={isDisabled => (
            <div>
              <div className="row">
                <div className="col-lg-6 col-md-12 form-group">
                  <PlayerName>
                    <div>
                      <BoldText>Player</BoldText>
                      <div>
                        {currentEventPlayerInfo.orgCode} - {currentEventPlayerInfo.playerName}
                      </div>
                    </div>
                    <MobileView maxWidth={1023}>
                      <ReportCheckmark player={currentEventPlayer} />
                    </MobileView>
                  </PlayerName>
                </div>
                <div className="col-lg-6 col-md-12">
                  <PositionsDropdownStyle>
                    <FormInput
                      isDisabled={isDisabled}
                      name="positionDropdown"
                      label="Position"
                      type="select"
                      options={profilePositionsDropdown
                        .concat()
                        .sort((pos1, pos2) => (pos1.orderNum > pos2.orderNum ? 1 : -1))}
                      isRequired={!isDisabled}
                      value={{
                        value: currentEventPlayer.positionId,
                        label: posIdToAbbrev[currentEventPlayer.positionId]
                          ? posIdToAbbrev[currentEventPlayer.positionId]
                          : ""
                      }}
                      onChangeFn={value => {
                        const { value: positionId, playerType } = value;
                        playerReportModalDispatch({
                          type: "setPosition",
                          positionId,
                          playerType,
                          children: PlayerTypeComponents[playerType]
                        });
                        formDirtyFn();
                      }}
                      editable
                    />
                  </PositionsDropdownStyle>
                </div>
                <div className="col-lg-6 col-md-12">
                  <FormInput
                    name={"coachDropdown"}
                    label={"Report By"}
                    type={"select"}
                    isRequired={false}
                    options={coaches.map(({ profileId, fullName }) => ({
                      value: profileId,
                      label: fullName.lastName + ", " + fullName.firstName
                    }))}
                    value={{
                      value: currentEventPlayer.reportById,
                      label: currentEventPlayer.reportByLabel
                    }}
                    onChangeFn={value => {
                      playerReportModalDispatch({
                        type: "reportByOnChange",
                        reportById: value.value,
                        reportByLabel: value.label
                      });
                    }}
                    editable
                  />
                </div>
              </div>
              <BodyContent>{children}</BodyContent>
              <SideWorkCompStyle>
                <Title>
                  <FormTitleWrapper>Side Work</FormTitleWrapper>
                </Title>
                <CheckBoxOuterContainer>
                  <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 form-group">
                      <CheckBoxContainer>
                        <WBCCheckbox
                          checked={currentEventPlayer.sideWork ? currentEventPlayer.sideWork.weightWork : false}
                          onClick={() => {
                            playerReportModalDispatch({
                              type: "toggleWeightWork",
                              toggle: !(currentEventPlayer.sideWork || { weightWork: false }).weightWork
                            });
                            formDirtyFn();
                          }}
                          isDisabled={isDisabled}
                        />
                        <BoldText className={"ml-1"}>Weight Work</BoldText>
                      </CheckBoxContainer>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 form-group">
                      <CheckBoxContainer>
                        <WBCCheckbox
                          checked={currentEventPlayer.sideWork ? currentEventPlayer.sideWork.warmUps : false}
                          onClick={() => {
                            playerReportModalDispatch({
                              type: "toggleWarmUps",
                              toggle: !(currentEventPlayer.sideWork || { warmUps: false }).warmUps
                            });
                            formDirtyFn();
                          }}
                          isDisabled={isDisabled}
                        />
                        <BoldText className={"ml-1"}>Warm-Ups</BoldText>
                      </CheckBoxContainer>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 form-group">
                      <CheckBoxContainer>
                        <WBCCheckbox
                          checked={currentEventPlayer.sideWork ? currentEventPlayer.sideWork.conditioning : false}
                          onClick={() => {
                            playerReportModalDispatch({
                              type: "toggleConditioning",
                              toggle: !(currentEventPlayer.sideWork || { conditioning: false }).conditioning
                            });
                            formDirtyFn();
                          }}
                          isDisabled={isDisabled}
                        />
                        <BoldText className={"ml-1"}>Conditioning</BoldText>
                      </CheckBoxContainer>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 form-group">
                      <CheckBoxContainer>
                        <WBCCheckbox
                          checked={currentEventPlayer.sideWork ? currentEventPlayer.sideWork.other : false}
                          onClick={() => {
                            playerReportModalDispatch({
                              type: "toggleOther",
                              toggle: !(currentEventPlayer.sideWork || { other: false }).other
                            });
                            formDirtyFn();
                          }}
                          isDisabled={isDisabled}
                        />
                        <BoldText className={"ml-1"}>Other Activities</BoldText>
                      </CheckBoxContainer>
                    </div>
                  </div>
                </CheckBoxOuterContainer>
                <BestLabel required={!isDisabled && currentEventPlayer.playerType === "DNP"}>Comments</BestLabel>
                <TextArea
                  ref={commentsArea}
                  onChange={() => {
                    playerReportModalDispatch({
                      type: "setSideWorkComments",
                      comments: commentsArea.current.value
                    });
                    formDirtyFn();
                  }}
                  disabled={isDisabled}
                  value={
                    currentEventPlayer.sideWork && currentEventPlayer.sideWork.comments
                      ? currentEventPlayer.sideWork.comments
                      : ""
                  }
                />
              </SideWorkCompStyle>
            </div>
          )}
        />
      ) : null}
    </div>
  );
};

PlayerReportModalBody.propTypes = {
  content: PropTypes.object,
  children: PropTypes.object
};

PlayerReportModalBody.displayName = "BodyContent";

export default PlayerReportModalBody;
