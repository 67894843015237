import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styled from "styled-components";
import { NumberInput } from "best-common-react";
import PitchTrackerContext from "../../contexts/PitchTrackerContext";

const PitchCountNumberInput = styled(NumberInput)`
  &&& {
    font-size: 14px;
    text-align: center;
    padding: 0;
    background: none;
  }
`;

const PitchCountInput = ({ pitchCount, ...rest }) => {
  const { profileId, date, pitches, requiredToRest } = pitchCount;
  const {
    actions: { updatePitchCount }
  } = useContext(PitchTrackerContext);
  const onChange = useCallback(
    updatedPitchesStr => {
      updatePitchCount({
        pitchCount: {
          ...pitchCount,
          pitches: parseInt(updatedPitchesStr, 10) || 0
        }
      });
    },
    [pitchCount, updatePitchCount]
  );

  // prevent non-numeric input
  const onKeyPress = useCallback(e => {
    if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    }
  }, []);

  return (
    <PitchCountNumberInput
      type="number"
      name={`pitchCount-${profileId}-${date}`}
      className={classNames("pitch-count-input", {
        "is-invalid": requiredToRest
      })}
      value={pitches || ""}
      min={0}
      max={199}
      onFocus={({ target }) => {
        target.select();
      }}
      onKeyPress={onKeyPress}
      onChange={onChange}
      autoComplete="off"
      autoFill="off"
      {...rest}
    />
  );
};

PitchCountInput.displayName = "PitchCountInput";

PitchCountInput.propTypes = {
  pitchCount: PropTypes.shape({
    profileId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    pitches: PropTypes.number.isRequired,
    requiredToRest: PropTypes.bool.isRequired
  })
};

export default PitchCountInput;
