import React from "react";
import MLBPlayersRequestsTable from "./MLBPlayerRequestsTable";
import MLBRequestSelects from "./MLBRequestSelects";
import styled from "styled-components";

const MLBPlayerRequestBodyContainer = styled.div`
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-start;
  gap: 1.5rem;
`;

const RequestBody = () => {
  return (
    <MLBPlayerRequestBodyContainer>
      <MLBRequestSelects />
      <MLBPlayersRequestsTable />
    </MLBPlayerRequestBodyContainer>
  );
};

export default RequestBody;
