import styled from "styled-components";

const WBCContent = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 74vh;
  overflow: scroll;
`;

export default WBCContent;
