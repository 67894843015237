const readFile = async file => {
  const fileReader = new FileReader();
  const promise = new Promise(resolve => {
    fileReader.addEventListener("loadend", () => {
      resolve(fileReader.result);
    });
  });
  fileReader.readAsDataURL(file);

  return await promise;
};

export default readFile;
