import React from "react";
import PropTypes from "prop-types";
import Responsive from "react-responsive";

const MobileView = ({ maxWidth, ...props }) => <Responsive {...props} maxWidth={maxWidth} />;

MobileView.propTypes = {
  maxWidth: PropTypes.number
};

MobileView.defaultProps = {
  maxWidth: 767
};

export default MobileView;
