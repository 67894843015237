import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { AlertConstants } from "best-common-react";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import InterestListApi from "../../httpClients/InterestListApi";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import { useAlert } from "../hooks/useAlert";
import { useError } from "../hooks/useError";
import ProspectivePlayerContext from "../../contexts/ProspectivePlayerContext";
import AuthContext from "../../contexts/AuthContext";
import InterestListContext from "../../contexts/InterestListContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";

const AddInterestContainer = styled.div`
  color: ${props => props.theme[props.isOnInterestList ? "grey" : "wbcBlue"]};
  cursor: ${props => (props.isOnInterestList ? "no-drop" : "pointer")};
  text-align: center;
`;

const AddInterestFormatter = ({ row }) => {
  // hook(s)
  const { selectedYear } = useContext(WBCYearsContext);
  const globalModalContext = useContext(GlobalModalContext);
  const authContext = useContext(AuthContext);
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = authContext.state;
  const prospectivePlayersContext = useContext(ProspectivePlayerContext);
  const [onInterestList, setOnInterestList] = useState(row.onInterestList);
  const showErrors = useError();
  const showAlert = useAlert();
  const [addingToInterestList, setAddingToInterestList] = useState(false);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const { team } = selectedTeamContext.state;
  const interestListNotFinalized = !team.finalizedStatus?.finalizedInterestDate;

  const canAddToInterestList = isBOCadmin || isTeamCoordinator || (isFedAdmin && interestListNotFinalized);

  // functions
  const closeModal = () => globalModalContext.dispatch({ type: "closeModal" });
  const adminAddToInterestList = () => {
    const { fedTeamId, profileId } = row;
    InterestListApi.addPlayerToInterestList(selectedYear, fedTeamId, profileId, true)
      .then(() => {
        closeModal();
        showAlert("Player added to Interest List");
        prospectivePlayersContext.dispatch({
          type: "addPlayerToInterestList",
          profileId
        });
      })
      .catch(({ response }) => showAlert(response.data.message, AlertConstants.TYPES.DANGER));
  };

  const save = () => {
    if (onInterestList) return;
    if (!addingToInterestList) {
      setAddingToInterestList(true);

      const { fedTeamId, profileId } = row;
      InterestListApi.addPlayerToInterestList(selectedYear, fedTeamId, profileId)
        .then(() => {
          setAddingToInterestList(false);
          showAlert("Player added to Interest List");
          setOnInterestList(true);

          prospectivePlayersContext.dispatch({
            type: "addPlayerToInterestList",
            profileId: profileId
          });
        })
        .catch(({ response }) => {
          setAddingToInterestList(false);

          // TODO: make back end return custom error response code
          if (response?.data?.message?.includes("is already on the interest list")) {
            showAlert("This player is already on your Interest List", AlertConstants.TYPES.WARNING);
          } else {
            showErrors(response.data.message, adminAddToInterestList);
          }
        });
    }
  };

  // effect(s)
  useEffect(() => {
    setOnInterestList(row.onInterestList);
  }, [row.onInterestList, setOnInterestList]);

  // component
  return (
    <AddInterestContainer isOnInterestList={onInterestList} onClick={save}>
      {canAddToInterestList && <i className="fas fa-user-plus fa-xs" />}
    </AddInterestContainer>
  );
};

AddInterestFormatter.propTypes = {
  row: PropTypes.object
};

export default AddInterestFormatter;
