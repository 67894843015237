import React, { useContext, useEffect, useCallback } from "react";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import styled from "styled-components";
import ProspectivePlayersApi from "../../../httpClients/ProspectivePlayersApi";
import { AlertConstants, FormInput } from "best-common-react";
import { useAlert } from "../../hooks/useAlert";
import GameReportAddPlayersContext from "../../../contexts/clubcomm/GameReportAddPlayersContext";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import _ from "lodash";
import PositionContext from "../../../contexts/PositionsContext";
import Moment from "moment";
import EventReportContext from "../../../contexts/clubcomm/EventReportContext";
import MainRosterApi from "../../../httpClients/MainRosterApi";
import QualifyingRosterApi from "../../../httpClients/QualifyingRosterApi";

const BodyContent = styled.div`
  text-align: left;
`;

const disallowedPositions = new Set(["UN", "ATC", "CC", "MA", "CO"]);

const AddPlayerModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const gameReportPlayersContext = useContext(GameReportAddPlayersContext);
  const positionContext = useContext(PositionContext);
  const eventReportContext = useContext(EventReportContext);
  const { currentWbcYear, selectedYear } = useContext(WBCYearsContext);
  const showAlert = useAlert();

  // variables
  const { posIdToAbbrev, playerPositionsDropdown: positionsDropdown } = positionContext.state;
  const { teamId: fedTeamId } = globalModalContext.state.content;
  const {
    prospectivePlayers,
    selectedProspectivePlayer = {},
    selectedPosition = {},
    positionDropdownEnabled,
    provisionalPlayers
  } = gameReportPlayersContext.state;
  const { eventPlayers, gamePk } = eventReportContext.state.eventReport;
  const { dispatch: playersDispatch } = gameReportPlayersContext;

  const eventPlayersMap = eventPlayers.reduce((acc, player) => {
    acc[player.profileId] = true;
    return acc;
  }, {});

  // functions
  const formatDate = dateInput => {
    if (!!dateInput) {
      const date = Moment(dateInput)
        .utc()
        .format("MM/DD/YYYY");
      if (date !== "Invalid date") {
        return date;
      }
    }
    return "";
  };

  const playerLabel = useCallback(
    prospectivePlayer => {
      const pos = posIdToAbbrev[prospectivePlayer.positionId] || "";
      return `${prospectivePlayer.lastName}, ${prospectivePlayer.firstName} - ${pos} - ${formatDate(
        prospectivePlayer.birthDate
      )}`;
    },
    [posIdToAbbrev]
  );

  // effects
  useEffect(() => {
    ProspectivePlayersApi.getProspectivePlayers(selectedYear, fedTeamId)
      .then(prospectivePlayers => {
        playersDispatch({
          type: "setProspectivePlayers",
          prospectivePlayers: prospectivePlayers.map(pp => ({
            ...pp,
            value: pp.profileId,
            label: playerLabel(pp)
          }))
        });
      })
      .catch(response => showAlert(response.toString(), AlertConstants.TYPES.DANGER));
  }, [selectedYear, fedTeamId, playersDispatch, showAlert, playerLabel]);

  useEffect(() => {
    const isMainTournamentEvent = currentWbcYear?.generalRosterSettings?.tournamentTypeId === 2;
    const api = isMainTournamentEvent ? MainRosterApi : QualifyingRosterApi;
    const promises = [api.getProvisionalRoster(fedTeamId), api.getFinalRoster(fedTeamId)];
    Promise.all(promises).then(([provisional, final]) => {
      playersDispatch({
        type: "setProvisionalPlayers",
        provisionalPlayers: provisional.players.concat(final.players).map(p => p.profileId)
      });
    });
  }, [fedTeamId, playersDispatch, currentWbcYear]);

  const onChange = useCallback(
    player => {
      playersDispatch({ type: "setSelectedProspectivePlayer", selectedProspectivePlayer: player });
      if (disallowedPositions.has(posIdToAbbrev[player.positionId])) {
        playersDispatch({ type: "setPositionDropdownEnabled", positionDropdownEnabled: true });
      } else {
        playersDispatch({ type: "setPositionDropdownEnabled", positionDropdownEnabled: false });
      }
    },
    [posIdToAbbrev, playersDispatch]
  );

  const positions = positionsDropdown
    .concat()
    .sort((pos1, pos2) => (pos1.orderNum > pos2.orderNum ? 1 : -1))
    .map(p => {
      return {
        ...p,
        label: p.label + " - " + p.name
      };
    });

  return (
    <BodyContent>
      <FormInput
        name={"orgDropdown"}
        label={"Player"}
        type={"select"}
        isRequired={true}
        options={prospectivePlayers
          .filter(
            pp =>
              !eventPlayersMap[pp.profileId] &&
              pp.positionId &&
              (gamePk || _.includes(provisionalPlayers, pp.profileId))
          )
          .sort((pp1, pp2) => pp1.label.localeCompare(pp2.label))}
        value={{
          value: selectedProspectivePlayer.profileId,
          label: !_.isEmpty(selectedProspectivePlayer) ? selectedProspectivePlayer.label : null
        }}
        onChangeFn={selectedProspectivePlayer => {
          onChange(selectedProspectivePlayer);
        }}
        editable
      />
      {positionDropdownEnabled ? (
        <FormInput
          name={"posDropdown"}
          label={"Position"}
          type={"select"}
          isRequired={true}
          options={positions}
          value={{
            value: selectedPosition,
            label: !_.isEmpty(selectedPosition) ? selectedPosition.label : null
          }}
          onChangeFn={pos => {
            playersDispatch({ type: "setSelectedPosition", selectedPosition: pos });
          }}
          editable
        />
      ) : (
        <></>
      )}
    </BodyContent>
  );
};

AddPlayerModalBody.displayName = "BodyContent";

export default AddPlayerModalBody;
