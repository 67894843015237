import React, { useContext } from "react";
import styled from "styled-components";
import { TextArea, BestLabel } from "best-common-react";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 25px;
`;

const BodyContent = styled.div`
  text-align: left;
`;

const DenyEligibilityModalBody = () => {
  const playerEligibilityContext = useContext(PlayerEligibilityContext);

  let textarea = null;

  const onTextChange = () => {
    playerEligibilityContext.dispatch({ type: "setPlayerProfileToDenyReason", reason: textarea.value });
  };

  return (
    <BodyContent>
      <ModalText>Please include a reason the player is not eligible</ModalText>
      <BestLabel required>Reason for Eligibility Denial</BestLabel>
      <TextArea ref={el => (textarea = el)} onChange={onTextChange}></TextArea>
    </BodyContent>
  );
};

DenyEligibilityModalBody.displayName = "BodyContent";

export default DenyEligibilityModalBody;
