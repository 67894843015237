import React, { useContext } from "react";
import styled from "styled-components";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import WBCRadioButton from "../../elements/WBCRadioButton";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 16px;
`;

const Bold = styled.span.attrs(() => ({}))`
  font-weight: bold;
`;
const OutfielderModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const { selectedPosition } = globalModalContext.state;
  const { lastName, firstName } = globalModalContext.state.content.player;

  const setPosition = position => {
    globalModalContext.dispatch({
      type: "setSelectedPosition",
      position: position
    });
  };

  return (
    <BodyContent>
      <ModalText>
        Only players with the position of LF, CF, RF or OF can be added as an outfielder.
        <br />
        Do you wish to update the position for{" "}
        <Bold>
          {firstName} {lastName}
        </Bold>{" "}
        to one of the following?
        <div className="mt-3 d-flex">
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "LF"} onClick={() => setPosition("LF")} label="LF" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "CF"} onClick={() => setPosition("CF")} label="CF" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "RF"} onClick={() => setPosition("RF")} label="RF" />
          </div>
          <div className="mr-3">
            <WBCRadioButton checked={selectedPosition === "OF"} onClick={() => setPosition("OF")} label="OF" />
          </div>
        </div>
      </ModalText>
    </BodyContent>
  );
};

OutfielderModalBody.displayName = "BodyContent";

export default OutfielderModalBody;
