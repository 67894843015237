import React, { useContext } from "react";
import styled from "styled-components";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import FlagHolder from "../../elements/WBCFlagHolder";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
  margin-bottom: 16px;
`;

const FederationWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: ${props => props.theme.white};
  border: 1px solid ${props => props.theme["grey"]};
  margin-bottom: 16px;
`;

const FederationName = styled.span`
  font-family: Helvetica;
  font-size: 16px;
  color: ${props => props.theme.wbcBlue};
  margin-left: 5px;
`;

const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

const FinalizeRosterModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);

  const { team, rosterType } = globalModalContext.state.content;

  return (
    <BodyContent>
      <ModalText>
        {`Please confirm you want to finalize the roster for the following federation's ${rosterType}.`}
      </ModalText>
      <FederationWrapper>
        <FlagHolder src={`${FLAG_URL}/${team.code}.svg`} />
        <FederationName>{team.label}</FederationName>
      </FederationWrapper>
    </BodyContent>
  );
};

FinalizeRosterModalBody.displayName = "BodyContent";

export default FinalizeRosterModalBody;
