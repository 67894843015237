import React, { useContext } from "react";
import PropTypes from "prop-types";
import { BestSelect } from "best-common-react";
import InterestListContext from "../../contexts/InterestListContext";
import PositionsContext from "../../contexts/PositionsContext";
import WBCAccessControl from "../protected/WBCAccessControl";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import AuthContext from "../../contexts/AuthContext";

const InterestListPositionsFormatter = ({ row }) => {
  const interestListContext = useContext(InterestListContext);
  const positionsContext = useContext(PositionsContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const authContext = useContext(AuthContext);
  const { team } = selectedTeamContext.state;
  const isNotFinalized = !team.finalizedStatus?.finalizedInterestDate;
  const { isBOCadmin } = authContext.state;

  return (
    <WBCAccessControl
      input={isDisabled => (
        <>
          {!isDisabled && (isBOCadmin || isNotFinalized) ? (
            <div onClick={e => e.stopPropagation()}>
              <BestSelect
                isDisabled={isDisabled}
                id="grid"
                name="positionDropdown"
                value={{
                  value: positionsContext.state.posAbbrevToId[row.pos],
                  label: row.pos
                }}
                options={positionsContext.state.profilePositionsDropdown
                  .concat()
                  .sort((pos1, pos2) => (pos1.orderNum > pos2.orderNum ? 1 : -1))}
                onChange={value => {
                  interestListContext.dispatch({
                    type: "updatePosition",
                    pos: value.label,
                    profileId: row.profileId
                  });
                }}
                portal={document.body}
                menuPlacement="auto"
              />
            </div>
          ) : (
            <>{row.pos}</>
          )}
        </>
      )}
    />
  );
};

InterestListPositionsFormatter.propTypes = {
  row: PropTypes.object
};

export default InterestListPositionsFormatter;
