import React from "react";
import PropTypes from "prop-types";
import WBCCollapse from "../../elements/WBCCollapse";
import { InfoCard } from "best-common-react";
import styled from "styled-components";

const PlayerCountCollapse = styled(WBCCollapse)`
  margin-top: 1.5rem;
`;

const PlayerCountTilesContainer = styled.div.attrs(() => ({
  className: "d-flex row player-count-tiles-container"
}))``;

const PlayerCountCard = styled(InfoCard).attrs(() => ({
  className: "player-count-card"
}))`
  &&& .card-body {
    padding: 0.25rem 1.125rem;
  }
`;

const PlayerCountContainer = ({ title, children }) => {
  return (
    <PlayerCountCollapse title={title} className="player-count-collapse">
      <PlayerCountCard title="">
        <PlayerCountTilesContainer>{children}</PlayerCountTilesContainer>
      </PlayerCountCard>
    </PlayerCountCollapse>
  );
};

PlayerCountContainer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any
};

export default PlayerCountContainer;
