import React, { useContext, useState, useEffect, useMemo } from "react";
import { ThemeContext } from "styled-components";
import PropTypes from "prop-types";
import ProspectivePlayersFormBody from "../prospective-players/ProspectivePlayersFormBody";
import WBCModal from "../elements/WBCModal";
import { UnsavedChangesModalBodyContent, UnsavedChangesModalFooterContent } from "../elements/UnsavedChangesModal";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import ProspectivePlayerContext from "../../contexts/ProspectivePlayerContext";
import InterestListForm from "../interest-list/InterestListForm";
import { withRouter } from "react-router-dom";
import QualifyingRosterForm from "../roster/QualifyingRosterForm";
import TabSwitcher from "../temp/TabSwitcher";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import FederationTeamConstants from "../../constants/FederationTeamConstants";
import MainTournamentRosterForm from "../roster/MainTournamentRosterForm";
import PitchTrackerContainer from "../../containers/PitchTrackerContainer";
import AuthContext from "../../contexts/AuthContext";
import CoachingStaffContainer from "../../containers/CoachingStaffContainer";

const DefaultTabList = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  }
];

const QualifierTabList = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Qualifying Rosters",
    component: QualifyingRosterForm,
    id: "qualifying"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  }
];

const MainTournamentTabList = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Tournament Rosters",
    component: MainTournamentRosterForm,
    id: "tournament"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  }
];

const BothTabList = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Qualifying Rosters",
    component: QualifyingRosterForm,
    id: "qualifying"
  },
  {
    name: "Tournament Rosters",
    component: MainTournamentRosterForm,
    id: "tournament"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  }
];

const DefaultTabListWithPitchTracker = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Pitch Tracker",
    component: PitchTrackerContainer,
    id: "tracker"
  }
];

const QualifierTabListWithPitchTracker = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Qualifying Rosters",
    component: QualifyingRosterForm,
    id: "qualifying"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  },
  {
    name: "Pitch Tracker",
    component: PitchTrackerContainer,
    id: "tracker"
  }
];

const MainTournamentTabListWithPitchTracker = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Tournament Rosters",
    component: MainTournamentRosterForm,
    id: "tournament"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  },
  {
    name: "Pitch Tracker",
    component: PitchTrackerContainer,
    id: "tracker"
  }
];

const BothTabListWithPitchTracker = [
  {
    name: "Prospective Players",
    component: ProspectivePlayersFormBody,
    id: "prospective"
  },
  {
    name: "Interest List",
    component: InterestListForm,
    id: "interest"
  },
  {
    name: "Qualifying Rosters",
    component: QualifyingRosterForm,
    id: "qualifying"
  },
  {
    name: "Tournament Rosters",
    component: MainTournamentRosterForm,
    id: "tournament"
  },
  {
    name: "Coaching Staff",
    component: CoachingStaffContainer,
    id: "coachingStaff"
  },
  {
    name: "Pitch Tracker",
    component: PitchTrackerContainer,
    id: "tracker"
  }
];

// TODO: Make a Tournament Tab List containing Roster Tab

const TabManager = withRouter(({ history, location, resetButtons }) => {
  // context(s)
  const authContext = useContext(AuthContext);
  const themeContext = useContext(ThemeContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const prosPlayersFormContext = useContext(ProspectivePlayerContext);
  const formContext = tournamentFormContext.state ? tournamentFormContext : prosPlayersFormContext;
  const selectedTeamContext = useContext(SelectedTeamContext);

  // state
  const [destTab, setDestTab] = useState(null);
  const [isOpen, setModalOpen] = useState(false);
  const [tabList, setTabList] = useState(BothTabListWithPitchTracker);

  // variable(s)
  const { teamId: selectedTeamId } = selectedTeamContext.state.team;
  const searchParams = new URLSearchParams(location.search);
  let tabId = searchParams.get("tab") || "prospective";
  let tabIndex = tabList.findIndex(t => t.id === tabId);
  if (tabIndex === -1) {
    tabIndex = 0;
    tabId = "prospective";
  }
  const [tabToActivate, setTabToActivate] = useState(tabIndex);
  const {
    features: { mainTournament, coachingStaff },
    isBOCadmin
  } = authContext.state;

  // function(s)
  const canChangeFunc = () => {
    if (formContext.state.formDirtyBool) {
      setModalOpen(true);
      return tabToActivate && isOpen;
    } else {
      return true;
    }
  };

  const onSwitch = index => {
    setDestTab(index);
    setTabToActivate(null);
    const { id } = tabList[index];
    if (!formContext.state.formDirtyBool && searchParams.get("tab") !== id) {
      searchParams.set("tab", id);
      history.push(`?${searchParams.toString()}`);
    }
  };

  const postModalActions = () => {
    setTabToActivate(destTab);
    setModalOpen(false);
    formContext.dispatch({ type: "setFormDirty", formDirty: false });
    formContext.dispatch({ type: "setModalStatus", modalStatus: false });
    resetButtons();
  };

  const TabListWithNoTournaments = useMemo(() => (isBOCadmin ? DefaultTabListWithPitchTracker : DefaultTabList), [
    isBOCadmin
  ]);
  const TabListWithQualifierTournament = useMemo(
    () => (isBOCadmin ? QualifierTabListWithPitchTracker : QualifierTabList),
    [isBOCadmin]
  );
  const TabListWithMainTournament = useMemo(
    () => (isBOCadmin ? MainTournamentTabListWithPitchTracker : MainTournamentTabList),
    [isBOCadmin]
  );
  const TabListWithBothTournaments = useMemo(() => (isBOCadmin ? BothTabListWithPitchTracker : BothTabList), [
    isBOCadmin
  ]);

  // effect(s)
  useEffect(() => {
    if (selectedTeamId) {
      WBCTeamApi.getFederationTeamByTeamId(selectedTeamId).then(team => {
        switch (team.currentState.statusCode) {
          case FederationTeamConstants.STATUS.QUALIFIER:
            setTabList(TabListWithQualifierTournament);
            setTabToActivate(tabIndex);
            break;
          case FederationTeamConstants.STATUS.TOURNAMENT:
            if (mainTournament) {
              setTabList(TabListWithMainTournament);
            } else {
              setTabList(TabListWithNoTournaments);
            }
            break;
          case FederationTeamConstants.STATUS.BOTH:
            if (mainTournament) {
              setTabList(TabListWithBothTournaments);
              setTabToActivate(tabIndex);
            } else {
              setTabList(TabListWithQualifierTournament);
              setTabToActivate(tabIndex);
            }
            break;
          default:
            setTabList(TabListWithNoTournaments);
        }
      });
    }
  }, [
    selectedTeamId,
    tabIndex,
    mainTournament,
    TabListWithQualifierTournament,
    TabListWithNoTournaments,
    TabListWithMainTournament,
    TabListWithBothTournaments
  ]);

  useEffect(() => {
    setTabToActivate(tabIndex);
  }, [tabIndex, tabList]);

  /**
   * The unsaved changes modal for the tabs will need to be updated once there is functionality on other tabs
   * We will need to detect the active tab and use the appropriate form context save/cancel
   */
  return (
    <div>
      <WBCModal isOpen={isOpen} toggleModal={setModalOpen} headerText="You have unsaved changes" onClose={() => {}}>
        <UnsavedChangesModalBodyContent />
        <UnsavedChangesModalFooterContent
          onSave={() => {
            tournamentFormContext.state.onSave();
            postModalActions();
          }}
          onCancel={() => {
            tournamentFormContext.state.onCancel();
            postModalActions();
          }}
        />
      </WBCModal>
      <TabSwitcher
        hasPadding={false}
        hideOnMobile={false}
        tabs={coachingStaff ? tabList : tabList.filter(tab => tab.id !== "coachingStaff")}
        styles={{
          tab: { defaultColor: themeContext["wbcBlue"], borderColor: "#d2d2d2" },
          container: { borderColor: "#d2d2d2" }
        }}
        onSwitch={onSwitch}
        canChange={canChangeFunc}
        activeTabIndex={tabToActivate}
      />
    </div>
  );
});

TabManager.propTypes = {
  resetButtons: PropTypes.func
};

TabManager.defaultProps = {
  resetButtons: () => {}
};

export default TabManager;
