import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

// create a status selct that is a styled button that takes in a status and a number and an onCLick

const StatusSelectButton = styled(Link)`
  color: ${props => props.theme.wbcBlue};
  display: ${props => props.display || "block"};

  &:hover {
    color: ${props => props.theme.wbcBlue} !important;
    text-decoration: none !important;
  }
  // add more padding only on the bottom
  padding: 0 0 0.75rem 0;
`;

const StyledPipe = styled.span`
  color: ${props => props.theme.wbcBlue};
  font-weight: extra-bold;

  font-size: 1.25rem;
`;

const StatusSelect = ({ statusName, number, onClick, isSelected }) => {
  const style = {
    fontWeight: isSelected ? "bold" : "normal"
  };

  return (
    <StatusSelectButton onClick={onClick} style={style} to="#">
      {isSelected && <StyledPipe>{"| "}</StyledPipe>}
      {"" + statusName} ({number})
    </StatusSelectButton>
  );
};

StatusSelect.propTypes = {
  statusName: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired
};

export default StatusSelect;
