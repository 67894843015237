import { useEffect } from "react";
import OktaAuth from "@okta/okta-auth-js";

const authClient = new OktaAuth({
  url: window.MLBBest.envVariables.REACT_APP_OKTA_BASE_URL,
  clientId: window.MLBBest.envVariables.REACT_APP_OKTA_CLIENT_ID,
  issuer: window.MLBBest.envVariables.REACT_APP_OKTA_ISSUER,
  autoRenew: true,
  redirectUri: window.MLBBest.envVariables.REACT_APP_OKTA_REDIRECT_URI
});

export const handleLogin = (hash, onLogin) => {
  authClient.tokenManager.get("accessToken").then(tokens => {
    if (tokens) {
      onLogin();
    } else if (hash) {
      authClient.token.parseFromUrl().then(token => {
        token.forEach(t => {
          if (t.idToken) {
            authClient.tokenManager.add("idToken", t);
          }
          if (t.accessToken) {
            authClient.tokenManager.add("accessToken", t);
          }
        });
        onLogin();
      });
    } else {
      authClient.token.getWithRedirect({
        responseType: ["token", "id_token"],
        scopes: ["openid", "profile", "email"],
        nonce: "uSeRInfo",
        expireEarlySeconds: 120
      });
    }
  });
  authClient.tokenManager.get("idToken");
};

export const setupRefresh = () => {
  authClient.tokenManager.on("expired", (key, expiredToken) => {
    authClient.tokenManager.get(key);
  });
};

export const useOkta = (hash, onLogin) => {
  useEffect(() => {
    setupRefresh();
    handleLogin(hash, onLogin);
  }, [hash, onLogin]);
};
