import React from "react";
import styled from "styled-components";
import { Input } from "best-common-react";

const PitchCountRestDay = styled(props => <Input type="text" value="rest" disabled {...props} />)`
  &&& {
    font-size: 14px;
    text-align: center;
    color: #222222;
  }
`;

PitchCountRestDay.displayName = "PitchCountRestDay";

export default PitchCountRestDay;
