import React, { useRef, useState, useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tooltip from "../../elements/Tooltip";
import RouteConstants from "../../../constants/RouteConstants";

const Text = styled.div`
  cursor: pointer;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.wbcBlue};

  /* ellipsis */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

// TODO: refactor to use ../elements/TruncatedText

const LinkText = withRouter(({ history, className, children, placement, row, profileType }) => {
  // hooks
  const textRef = useRef(null);
  const [isEllipsisVisible, setIsEllipsisVisible] = useState(true);

  // function(s)
  const redirectToPlayer = () => {
    history.push(
      RouteConstants.PROFILE.replace(":playerId", row.profileId)
        .replace(":fedTeamId", row.fedTeamId)
        .replace(":profileType", profileType || RouteConstants.PLAYER)
    );
  };

  const checkForEllipsis = useCallback(() => {
    if (!textRef.current) return;
    const { offsetWidth, scrollWidth } = textRef.current;
    const result = offsetWidth < scrollWidth;
    if (result !== isEllipsisVisible) {
      setIsEllipsisVisible(result);
    }
  }, [textRef, isEllipsisVisible]);

  // effects
  // initialize
  useEffect(checkForEllipsis, [checkForEllipsis]);

  // ellipsis check
  useEffect(() => {
    window.addEventListener("resize", checkForEllipsis);
    return () => window.removeEventListener("resize", checkForEllipsis);
  }, [checkForEllipsis, setIsEllipsisVisible]);

  return (
    <>
      <Text ref={textRef} className={className} onClick={redirectToPlayer}>
        {children}
      </Text>
      {!isEllipsisVisible ? null : (
        <Tooltip placement={placement} targetRef={textRef}>
          {children}
        </Tooltip>
      )}
    </>
  );
});

LinkText.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  row: PropTypes.object,
  history: PropTypes.object,
  profileType: PropTypes.string
};

LinkText.defaultProps = {
  placement: "bottom"
};

export default LinkText;
