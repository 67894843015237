import { useContext } from "react";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import React from "react";

const HelpAndSupportModalFooter = () => {
  // hook(s)
  const globalModalContext = useContext(GlobalModalContext);

  return (
    <div>
      <div className={"d-flex"}>
        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
            }}
          >
            Close
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

HelpAndSupportModalFooter.displayName = "FooterContent";

export default HelpAndSupportModalFooter;
