import React, { useContext } from "react";
import styled from "styled-components";
import { BestTitle, FormInput, Icon, BestLabel } from "best-common-react";
import CountryContext from "../../contexts/CountryContext";
import ProfileContext from "../../contexts/ProfileContext";
import FormHelper from "../elements/FormHelper";
import { FormTitleWrapper } from "./shared/ProfileComponents";
import WBCAccessControl from "../protected/WBCAccessControl";
import UncontrolledFormInput from "../elements/UncontrolledFormInput";
import UncontrolledInput from "../elements/UncontrolledInput";

const Text = styled.div`
  display: inline-block;
  font-size: 14px;
  padding: 0.5rem 0.25rem;
`;

const PhoneNumberWrapper = styled.div`
  display: flex;
  align-items: center;

  .form-group {
    width: 100%;
  }
`;

const PhoneTypeOptions = [
  { label: "Mobile", value: "Mobile" },
  { label: "Home", value: "Home" },
  { label: "Other", value: "Other" }
];

const EmailTypeOptions = [
  { label: "Personal", value: "Personal" },
  { label: "Other", value: "Other" }
];

const isEmailValid = value => (value && value !== "" ? FormHelper.isValidEmail(value) : true);

const ProfileContactInfoForm = () => {
  // hooks
  const countryContext = useContext(CountryContext);
  const profileContext = useContext(ProfileContext);

  // variables
  const { countryCode, phoneType, phoneNumber, emailType, emailAddress } = profileContext.state;
  const { countryIdToName, countryDropdown, countryIdToDialCode } = countryContext.state;

  // email
  const validEmail = isEmailValid(emailAddress);

  // functions
  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  return (
    <>
      <BestTitle>
        <FormTitleWrapper>Contact Information</FormTitleWrapper>
      </BestTitle>
      <WBCAccessControl
        input={isDisabled => (
          <div className="row">
            <div className="col-xl-3 col-lg-6">
              <FormInput
                editable
                name="phoneTypeDropdown"
                label="Phone Type"
                type="select"
                isDisabled={isDisabled}
                options={PhoneTypeOptions}
                value={{
                  value: phoneType,
                  label: phoneType || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "phoneType")}
              />
            </div>
            <div className="col-xl-3 col-lg-6">
              <FormInput
                isDisabled={isDisabled}
                name="countryCodeDropdown"
                label="Country Code"
                type="select"
                options={countryDropdown}
                value={{
                  value: countryCode,
                  label: countryIdToName[countryCode] || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "countryCode")}
                editable
              />
            </div>
            <div className="col-xl-6 col-lg-11 col-md-11 col-sm-11 col-xs-11 pl-0">
              <PhoneNumberWrapper>
                <Text>{countryCode ? <div>+{countryCode ? countryIdToDialCode[countryCode] : ""}</div> : null}</Text>
                <UncontrolledFormInput
                  id="phoneNumber"
                  label="Phone Number"
                  number
                  disabled={isDisabled}
                  value={phoneNumber}
                  postChange={value => onProfileChange(value, "phoneNumber")}
                />
              </PhoneNumberWrapper>
            </div>
          </div>
        )}
      />
      <div className="row">
        <div className="col-lg-3 col-md-12 col-sm-12">
          <WBCAccessControl
            input={isDisabled => (
              <FormInput
                editable
                name="emailTypeDropdown"
                label="Email Type"
                type="select"
                isDisabled={isDisabled}
                options={EmailTypeOptions}
                value={{
                  value: emailType,
                  label: emailType || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "emailType")}
              />
            )}
          />
        </div>
        <div className="col-lg-9 col-md-12 col-sm-12 pl-0">
          <div className="d-flex">
            <div className="col pr-2">
              <WBCAccessControl
                input={disabled => (
                  <>
                    {disabled ? (
                      <>
                        <BestLabel>Email</BestLabel>
                        <UncontrolledInput
                          id="email-1"
                          disabled={disabled}
                          value={emailAddress || ""}
                          postChange={value => onProfileChange(value, "emailAddress")}
                        />
                      </>
                    ) : (
                      <UncontrolledFormInput
                        id="email"
                        label="Email"
                        rightLabel={validEmail ? "" : "invalid email"}
                        disabled={disabled}
                        value={emailAddress || ""}
                        postChange={value => onProfileChange(value, "emailAddress")}
                      />
                    )}
                  </>
                )}
              />
            </div>
            <div>
              <div className="mt-4">
                {emailAddress ? (
                  <div>
                    {validEmail ? (
                      <Icon style={{ color: "green" }} iconName="fa-check-circle" />
                    ) : (
                      <Icon style={{ color: "red" }} iconName="fa-times-circle" />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileContactInfoForm;
