import React, { useCallback, useContext } from "react";
import LoadingDocumentsTable from "./LoadingDocumentsTable";
import BrowseFiles from "../elements/BrowseFiles";
import AuthContext from "../../contexts/AuthContext";
import DocumentsContext from "../../contexts/DocumentsContext";

const DocumentsSection = () => {
  const {
    actions: { uploadFiles }
  } = useContext(DocumentsContext);
  const {
    state: { isBOCadmin, isFedAdmin, isTeamCoordinator }
  } = useContext(AuthContext);

  const isReadOnly = !isBOCadmin && !isFedAdmin && !isTeamCoordinator;
  const onFileAdd = useCallback(
    file => {
      const files = Array.isArray(file) ? file : [file];
      uploadFiles({ files });
    },
    [uploadFiles]
  );

  return (
    <div>
      {!isReadOnly && <BrowseFiles onFileAdd={onFileAdd} />}
      <LoadingDocumentsTable isReadOnly={isReadOnly} />
    </div>
  );
};

DocumentsSection.displayName = "DocumentsSection";

export default DocumentsSection;
