import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import { BestSelect } from "best-common-react";
import { isCurrentOrPastTournamentYear } from "../../../utils/WBCYearPredicates";
import ToggleButtons from "../../temp/ToggleButtons";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";

const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))`
  flex-direction: row;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;

const MLBPlayersByFilters = () => {
  const {
    positionFilter,
    positionFilterOptions,
    rosterListFilter,
    rosterListOptions,
    setPositionFilter,
    setRosterListFilter,
    setSortBy,
    sortBy,
    sortByOptions
  } = useContext(MLBPlayerSummaryContext);
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);

  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);

  const handleWBCYearChange = useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear]);

  const handleSortByChange = sortByValue => {
    const sortBy = sortByOptions.find(option => option.value === sortByValue);
    setSortBy(sortBy);
  };

  return (
    <div className="form-group">
      <FiltersContainer>
        <Filter>
          {selectedWbcYear && (
            <BestSelect
              name="yearsDropdown"
              value={selectedWbcYear}
              options={yearOptions}
              onChange={handleWBCYearChange}
            />
          )}
        </Filter>
        <Filter>
          {rosterListFilter && (
            <BestSelect
              name="rosterListFilterDropdown"
              value={rosterListFilter}
              options={rosterListOptions}
              onChange={setRosterListFilter}
            />
          )}
        </Filter>
        <Filter>
          {positionFilter && (
            <BestSelect
              name="positionFilterDropdown"
              value={positionFilter}
              options={positionFilterOptions}
              onChange={setPositionFilter}
            />
          )}
        </Filter>
        <Filter>
          {sortBy && (
            <ToggleButtons
              id="sort-player-summaries-by"
              value={sortBy}
              options={sortByOptions}
              onChange={handleSortByChange}
            />
          )}
        </Filter>
      </FiltersContainer>
    </div>
  );
};

export default MLBPlayersByFilters;
