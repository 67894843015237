import { useContext, useEffect } from "react";
import { groupBy, values } from "lodash";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import ProfileContext from "../../contexts/ProfileContext";
import CountryContext from "../../contexts/CountryContext";
import AuthContext from "../../contexts/AuthContext";

export const useProfileFedTeams = (profileId, isNew) => {
  // contexts
  const authContext = useContext(AuthContext);
  const countryContext = useContext(CountryContext);
  const profileContext = useContext(ProfileContext);

  // variables
  const { countries } = countryContext.state;
  const { isBOC, isFedAdmin } = authContext.state;
  const { dispatch: profileDispatch } = profileContext;

  const conditionalAPICall = isBOC !== undefined && isBOC !== null && isNew !== undefined && isNew !== null;

  useEffect(() => {
    if (!conditionalAPICall || ((isBOC || isFedAdmin) && !isNew)) {
      WBCTeamApi.getFedTeamsMatchingProfile(profileId).then(teamsResponse => {
        const countriesById = countries.reduce(
          (acc, country) => ({
            ...acc,
            [country.countryId]: country
          }),
          {}
        );

        const teams = values(groupBy(teamsResponse, ({ countryId }) => countryId))
          .map(teamsForCountry => {
            const hasMultiple = teamsForCountry.length > 1;
            return teamsForCountry
              .filter(({ countryId }) => countriesById.hasOwnProperty(countryId))
              .map(({ teamId: fedTeamId, profileId, countryId, wbcYear }) => {
                const { countryName } = countriesById[countryId];
                return {
                  fedTeamId,
                  profileId,
                  countryId,
                  wbcYear,
                  value: fedTeamId,
                  label: hasMultiple ? `${countryName} (${wbcYear})` : countryName
                };
              });
          })
          .flat()
          .sort((a, b) => (a.label > b.label ? 1 : -1));

        profileDispatch({ type: "setSelectedProfileTeams", teams });
      });
    }
  }, [countries, profileDispatch, isBOC, isNew, isFedAdmin, profileId, conditionalAPICall]);
};
