import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { AlertConstants, BestDropdown } from "best-common-react";
import _ from "lodash";
import styled from "styled-components";
import { MAIN } from "../../constants/TournamentTypeConstants";
import CountryContext from "../../contexts/CountryContext";
import SettingsFormContext from "../../contexts/SettingsFormContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import history from "../../history/history";
import WBCTeamApi from "../../httpClients/WBCTeamApi";
import WBCYearApi from "../../httpClients/WBCYearApi";
import ButtonHolder from "../elements/ButtonHolder";
import MobileFullWidth from "../elements/MobileFullWidth";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { useAlert } from "../hooks/useAlert";
import { useClearSectionsOpen } from "../hooks/useClearSectionsOpen";
import { useDiscardChanges } from "../hooks/useDiscardChanges";
import HorizontalNav from "../navigation/HorizontalNav";
import UnsavedChangesPrompt from "../protected/UnsavedChangesPrompt";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import FederationTeams from "./federation-teams/FederationTeams";
import GeneralRosterSettings from "./GeneralRosterSettings";
import TournamentSettings from "./TournamentSettings";
import ProspectivePlayerUpload from "./ProspectivePlayerUpload";
import AuthContext from "../../contexts/AuthContext";
import { isSameOrBefore } from "../../utils/DateUtils";
import { isCurrentOrPastTournamentYear } from "../../utils/WBCYearPredicates";

const DropdownStyle = styled(MobileFullWidth).attrs(() => ({
  className: "pl-4 pr-4"
}))`
  &&& {
    width: 300px;
  }
`;

const calculateFinalRosterMax = tournamentSettings => {
  const catchersMax = parseInt(tournamentSettings.positions.catchers.catchersMax);
  const pitchersMax = parseInt(tournamentSettings.positions.pitchers.pitchersMax);
  const infieldMax = parseInt(tournamentSettings.positions.infield.infieldMax);
  const outfieldMax = parseInt(tournamentSettings.positions.outfield.outfieldMax);

  const catchers = isNaN(catchersMax) ? 0 : catchersMax;
  const pitchers = isNaN(pitchersMax) ? 0 : pitchersMax;
  const infield = isNaN(infieldMax) ? 0 : infieldMax;
  const outfield = isNaN(outfieldMax) ? 0 : outfieldMax;
  tournamentSettings.rosterNumbers.finalRosterMax = catchers + pitchers + infield + outfield;
};

const Settings = () => {
  // context(s)
  const authContext = useContext(AuthContext);
  const countryContext = useContext(CountryContext);
  const settingsFormContext = useContext(SettingsFormContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const { changeSelectedYear, currentWbcYear, reloadWbcYears, selectedYear, wbcYears } = useContext(WBCYearsContext);
  const [saveAndCancelButtonsDisabled, setSaveAndCancelButtonsDisabled] = useState(true);
  const [asterisk, setAsterisk] = useState("");
  const showAlert = useAlert();
  const showDiscardModal = useDiscardChanges();
  // should be initialized to currentWbcYear, but since currentWbcYear
  // is loaded asynchronously, useEffect below sets initial value
  // pull url year

  const [editedCurrentWbcYear, setEditedCurrentWbcYear] = useState(null);

  // variable(s)
  const { dispatch: settingsDispatch } = settingsFormContext;
  const { dispatch: selectedTeamDispatch } = selectedTeamContext;
  const { countryIdToName } = countryContext.state;
  const { settingsChanged, teamListChanged, teamList } = settingsFormContext.state;
  const { features } = authContext.state;
  const yearOptions = wbcYears.filter(isCurrentOrPastTournamentYear);
  const [canEdit, setCanEdit] = useState(false);

  // funtion(s)
  const setTeamList = useCallback(
    teamList => {
      settingsDispatch({ type: "setTeamList", teamList });
    },
    [settingsDispatch]
  );

  useEffect(() => {
    if (editedCurrentWbcYear) {
      WBCTeamApi.getFederationTeamsByYear(editedCurrentWbcYear.year).then(teams => {
        let teamsWithName = [];
        if (teams?.length) {
          teamsWithName = teams.map(team => ({
            ...team,
            name: countryIdToName[team.countryId]
          }));
        }
        setTeamList(_.sortBy(teamsWithName, ["name"]));
      });
      changeSelectedYear(editedCurrentWbcYear.year);
    }
  }, [editedCurrentWbcYear]);

  // TODO: use SelectedTeamContext and useTeamsByYear
  const refreshTeamList = useCallback(() => {
    if (editedCurrentWbcYear) {
      WBCTeamApi.getFederationTeamsByYear(editedCurrentWbcYear.year).then(teams => {
        const teamsWithName = teams.map(team => ({
          ...team,
          name: countryIdToName[team.countryId]
        }));
        setTeamList(_.sortBy(teamsWithName, ["name"]));
      });
    }
  }, [editedCurrentWbcYear, setTeamList, countryIdToName]);

  useEffect(() => {
    setCanEdit(currentWbcYear?.year === editedCurrentWbcYear?.year);
  }, [editedCurrentWbcYear]);
  const setFormToDirty = useCallback(() => {
    settingsDispatch({
      type: "setSettingsChanged",
      settingsChanged: true
    });
    setSaveAndCancelButtonsDisabled(false);
    setAsterisk("*");
  }, [settingsDispatch]);

  const mainTournamentDateRange = useMemo(
    () => editedCurrentWbcYear && editedCurrentWbcYear.mainTournamentSettings.dateRange,
    [editedCurrentWbcYear]
  );
  const qualifyingTournamentDateRange = useMemo(
    () => editedCurrentWbcYear && editedCurrentWbcYear.qualifyingTournamentSettings.dateRange,
    [editedCurrentWbcYear]
  );
  const onDateRangeChange = useCallback(
    ({ tournamentType, dateRange }) => {
      const settingsProp = tournamentType === MAIN ? "mainTournamentSettings" : "qualifyingTournamentSettings";
      setEditedCurrentWbcYear(prev => ({
        ...prev,
        [settingsProp]: {
          ...prev[settingsProp],
          dateRange
        }
      }));
      setFormToDirty();
    },
    [setFormToDirty, setEditedCurrentWbcYear]
  );
  const isValid = useMemo(() => {
    return (
      mainTournamentDateRange &&
      qualifyingTournamentDateRange &&
      isSameOrBefore(mainTournamentDateRange.startDate, mainTournamentDateRange.endDate) &&
      isSameOrBefore(qualifyingTournamentDateRange.startDate, qualifyingTournamentDateRange.endDate)
    );
  }, [mainTournamentDateRange, qualifyingTournamentDateRange]);

  const onFormDirty = useCallback(
    (property, newValue) => {
      switch (property) {
        case "ROSTER_SETTINGS":
          setEditedCurrentWbcYear(prev => ({ ...prev, generalRosterSettings: newValue }));
          break;
        case "QUAL_TOURN_SETTINGS":
          calculateFinalRosterMax(newValue);
          setEditedCurrentWbcYear(prev => ({ ...prev, qualifyingTournamentSettings: newValue }));
          break;
        case "MAIN_TOURN_SETTINGS":
          calculateFinalRosterMax(newValue);
          setEditedCurrentWbcYear(prev => ({ ...prev, mainTournamentSettings: newValue }));
          break;
        default:
          break;
      }
      setFormToDirty();
    },
    [editedCurrentWbcYear, setFormToDirty, setEditedCurrentWbcYear]
  );

  const onSave = useCallback(() => {
    const promiseList = [];
    let teamsCallIndex = -1;

    if (settingsChanged) {
      // if main tournament is not enabled, make sure this is always set to qualifying tournament
      if (!features.mainTournament && editedCurrentWbcYear.generalRosterSettings) {
        editedCurrentWbcYear.generalRosterSettings.tournamentTypeId = 1;
      }
      promiseList.push(WBCYearApi.saveSettings(editedCurrentWbcYear));
    }

    if (teamListChanged) {
      promiseList.push(WBCTeamApi.saveTeams(teamList));
      teamsCallIndex = promiseList.length - 1;
    }

    if (promiseList.length === 0) return;
    Promise.all(promiseList)
      .then(results => {
        reloadWbcYears();
        if (teamsCallIndex !== -1) {
          if (results[0].status) {
            showAlert("Error", AlertConstants.TYPES.DANGER);
          }

          const teamsWithName = results[teamsCallIndex].map(team => ({
            ...team,
            name: countryIdToName[team.countryId]
          }));

          !!teamsWithName ? setTeamList(_.sortBy(teamsWithName, ["name"])) : setTeamList([]);
          settingsDispatch({
            type: "setTeamListChanged",
            settingsChanged: false
          });
          selectedTeamDispatch({ type: "setTeamListChanged", teamListChanged: true });
        }

        showAlert("Saved!");
        setSaveAndCancelButtonsDisabled(true);
        setAsterisk("");
      })
      .catch(error => {
        showAlert("Error saving Settings", AlertConstants.TYPES.DANGER);
      });
  }, [
    editedCurrentWbcYear,
    settingsChanged,
    teamListChanged,
    features.mainTournament,
    teamList,
    reloadWbcYears,
    showAlert,
    setTeamList,
    settingsDispatch,
    selectedTeamDispatch,
    countryIdToName
  ]);

  const onYearChange = newYear => {
    if (newYear.year !== selectedYear && !saveAndCancelButtonsDisabled) {
      showDiscardModal(() => {
        setEditedCurrentWbcYear(wbcYears.find(year => year.year === newYear.year));
        setSaveAndCancelButtonsDisabled(true);
        setAsterisk("");
      });
    } else {
      changeSelectedYear(newYear.year);
    }
  };

  const onCancel = useCallback(() => {
    const year = wbcYears?.find(year => year.year === selectedYear);
    setEditedCurrentWbcYear(prev => prev || year);
    calculateFinalRosterMax(year.qualifyingTournamentSettings);
    calculateFinalRosterMax(year.mainTournamentSettings);
    refreshTeamList();
    setSaveAndCancelButtonsDisabled(true);
    setAsterisk("");
  }, [currentWbcYear, refreshTeamList]);

  // effect hooks
  useClearSectionsOpen();

  // effect(s)
  useEffect(() => {
    // prevent possible race condition of saving before settings loaded from server
    setSaveAndCancelButtonsDisabled(
      saveAndCancelButtonsDisabled || !mainTournamentDateRange || !qualifyingTournamentDateRange
    );
  }, [saveAndCancelButtonsDisabled, mainTournamentDateRange, qualifyingTournamentDateRange]);

  useEffect(() => {
    settingsDispatch({
      type: "setFormControls",
      onFormDirty,
      onCancel,
      onSave
    });
  }, [settingsDispatch, onFormDirty, onCancel, onSave]);

  // set initial state since currentWbcYear loads asynchronously
  useEffect(() => {
    if (!!selectedYear) {
      setEditedCurrentWbcYear(wbcYears?.find(year => year.year === selectedYear));
    }
  }, [selectedYear]);

  return (
    editedCurrentWbcYear && (
      <div>
        <UnsavedChangesPrompt when={!saveAndCancelButtonsDisabled} onCancel={onCancel} onSave={onSave} />
        <HorizontalNav title={`Settings${asterisk}`}>
          <ButtonHolder>
            <WBCAdminAccess>
              <div className="mr-2">
                <WBCPrimaryButton
                  className="btn-md"
                  onClick={onSave}
                  disabled={saveAndCancelButtonsDisabled || !isValid}
                >
                  Save
                </WBCPrimaryButton>
              </div>
              <div>
                <WBCPrimaryOutlinedButton
                  className="btn-md"
                  onClick={() => showDiscardModal(onCancel)}
                  disabled={saveAndCancelButtonsDisabled}
                >
                  Cancel
                </WBCPrimaryOutlinedButton>
              </div>
            </WBCAdminAccess>
          </ButtonHolder>
        </HorizontalNav>
        <DropdownStyle>
          <BestDropdown
            title=""
            id="settings"
            value={editedCurrentWbcYear}
            options={yearOptions}
            onChange={onYearChange}
          />
        </DropdownStyle>
        <FederationTeams
          canEdit={canEdit}
          mainTournamentDateRange={mainTournamentDateRange}
          qualifyingTournamentDateRange={qualifyingTournamentDateRange}
          onDateRangeChange={onDateRangeChange}
        />
        <GeneralRosterSettings
          settings={editedCurrentWbcYear.generalRosterSettings}
          changeFn={onFormDirty}
          canEdit={canEdit}
        />
        <TournamentSettings
          title="Qualifying Tournament Rosters"
          settings={editedCurrentWbcYear.qualifyingTournamentSettings}
          changeFn={onFormDirty}
          changeCode="QUAL_TOURN_SETTINGS"
          canEdit={canEdit}
        />
        <TournamentSettings
          title="Main Tournament Rosters"
          settings={editedCurrentWbcYear.mainTournamentSettings}
          changeFn={onFormDirty}
          changeCode="MAIN_TOURN_SETTINGS"
          canEdit={canEdit}
        />
        <WBCAdminAccess>
          <ProspectivePlayerUpload />
        </WBCAdminAccess>
      </div>
    )
  );
};

export default Settings;
