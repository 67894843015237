import React, { useContext } from "react";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";

const PositionKeyModalFooter = () => {
  // contexts && hooks
  const globalModalContext = useContext(GlobalModalContext);

  return (
    <div>
      <div className={"d-flex"}>
        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
            }}
          >
            Close
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

PositionKeyModalFooter.displayName = "FooterContent";

export default PositionKeyModalFooter;
