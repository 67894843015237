import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabContainer = styled.ul.attrs(() => ({
  className: "wbc-tab-container"
}))`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  border: 1px solid #d2d2d2;
  background-color: ${props => props.theme["almost-white"]};

  @media (max-width: 1023px) {
    display: ${props => (props.hideOnMobile ? "none" : undefined)};
  }
`;

const Tab = styled.li.attrs(() => ({
  className: "wbc-tab"
}))`
  min-width: 107px;
  height: 31px;
  padding: 7px 19px;
  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;
  color: ${props => props.theme.wbcBlue};
  background-color: ${props => (props.active ? props.theme.white : undefined)};
  border-left: ${props => (props.active ? "1px solid #d2d2d2" : undefined)};
  border-right: ${props => (props.active ? "1px solid #d2d2d2" : undefined)};

  :first-child {
    border-left: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

const ComponentContainer = styled.div`
  background-color: ${props => props.theme.white};
  border-left: 1px solid #d2d2d2;
  border-right: 1px solid #d2d2d2;
  border-bottom: 1px solid #d2d2d2;
`;

const renderContent = active => {
  if (active) {
    if (typeof active.component === typeof {}) {
      return active.component;
    } else {
      const RenderComponent = active.component;
      return <RenderComponent />;
    }
  }
  return <div>No Component Found</div>;
};

const getActiveIndex = (activeTab, tabs) => {
  return typeof activeTab === "number"
    ? activeTab
    : Math.max(
        0,
        tabs.findIndex(tab => tab.key === activeTab)
      );
};

const ControlledTabSwitcher = ({ className, tabs, activeTab, onSwitch, hideOnMobile }) => {
  // hooks
  const [uncontrolledIndex, setUncontrolledIndex] = useState(0);

  // if an activeTab is provided, this component is now considered 'controlled'
  const isControlled = activeTab !== undefined;
  const activeIndex = isControlled ? getActiveIndex(activeTab, tabs) : uncontrolledIndex;

  // functions
  const onTabClick = (index, tabKey) => {
    if (isControlled) {
      if (onSwitch) {
        onSwitch(index, tabKey);
      }
    } else {
      setUncontrolledIndex(index);
    }
  };

  return (
    <div className={className}>
      <TabContainer hideOnMobile={hideOnMobile}>
        {tabs.map((tab, i) => (
          <Tab key={tab.key || i} active={activeIndex === i} onClick={() => onTabClick(i, tab.key)}>
            {tab.name}
          </Tab>
        ))}
      </TabContainer>
      <ComponentContainer>{renderContent(tabs[activeIndex])}</ComponentContainer>
    </div>
  );
};

ControlledTabSwitcher.propTypes = {
  tabs: PropTypes.array.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  onSwitch: PropTypes.func,
  hideOnMobile: PropTypes.bool
};

export default ControlledTabSwitcher;
