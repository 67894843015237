import React, { useContext } from "react";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import BoxScoreTable from "./BoxScoreTable";

const topRow = ["W/L/SV", "ERA", "GS", "CG", "IP"];
const bottomRow = ["H", "R", "ER", "HR", "HB", "BB", "SO", "WHIP", "HLD", "GF"];

const PitcherBoxScore = () => {
  const playerReportModalContext = useContext(PlayerReportModalContext);

  const { eventPlayers, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];
  const { wins, losses, saves } = currentEventPlayer.stats.pitching;

  const mapLabelToStat = label => {
    switch (label) {
      case "W/L/SV":
        return `${wins}/${losses}/${saves}`;
      case "ERA":
        return currentEventPlayer.stats.pitching.era.toFixed(2);
      case "GS":
        return currentEventPlayer.stats.pitching.gamesStarted;
      case "CG":
        return currentEventPlayer.stats.pitching.completeGames;
      case "IP":
        return currentEventPlayer.stats.pitching.inningsPitched;
      case "H":
        return currentEventPlayer.stats.pitching.hits;
      case "R":
        return currentEventPlayer.stats.pitching.runs;
      case "ER":
        return currentEventPlayer.stats.pitching.earnedRuns;
      case "HR":
        return currentEventPlayer.stats.pitching.homeRuns;
      case "HB":
        return currentEventPlayer.stats.pitching.hitBatsmen;
      case "BB":
        return currentEventPlayer.stats.pitching.baseOnBalls;
      case "SO":
        return currentEventPlayer.stats.pitching.strikeOuts;
      case "WHIP":
        return currentEventPlayer.stats.pitching.whip.toFixed(2);
      case "HLD":
        return currentEventPlayer.stats.pitching.holds;
      case "GF":
        return currentEventPlayer.stats.pitching.gamesFinished;
      default:
        return null;
    }
  };

  return <BoxScoreTable topRowHeaders={topRow} bottomRowHeaders={bottomRow} statsMap={mapLabelToStat} />;
};

export default PitcherBoxScore;
