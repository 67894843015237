import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import WBCCheckbox from "../elements/WBCCheckbox";
import InterestListContext from "../../contexts/InterestListContext";
import AuthContext from "../../contexts/AuthContext";

const Container = styled.div.attrs(() => ({
  className: "d-flex justify-content-center"
}))`
  &&& {
    color: ${props => props.theme["dark-grey"]};
  }
`;

const Check = styled.i.attrs(() => ({
  className: "fas fa-check fa-md"
}))`
  &&& {
    color: ${props => props.theme["dark-grey"]};
    font-size: 1rem;
  }
`;

const PastWbcFormatter = ({ row }) => {
  const authContext = useContext(AuthContext);
  const interestListContext = useContext(InterestListContext);

  const { isBOCadmin } = authContext.state;

  const updatePastWbcFlag = () => {
    interestListContext.dispatch({
      type: "updatePastWbcFlag",
      pastWbcFlag: !row.pastWbcFlag,
      profileId: row.profileId
    });
  };

  return (
    <Container>
      {isBOCadmin ? (
        <WBCCheckbox checked={row.pastWbcFlag} onClick={updatePastWbcFlag} isDisabled={!isBOCadmin} />
      ) : row.pastWbcFlag ? (
        <Check />
      ) : null}
    </Container>
  );
};

PastWbcFormatter.propTypes = {
  row: PropTypes.object
};

export default PastWbcFormatter;
