import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import DocumentsContext from "../../contexts/DocumentsContext";

const DocumentDownloadLink = ({ document: { fileName, documentId }, ...rest }) => {
  const {
    actions: { requestDocumentDownload }
  } = useContext(DocumentsContext);

  const onClick = useCallback(
    e => {
      e.preventDefault();

      requestDocumentDownload({ documentId });
    },
    [documentId, requestDocumentDownload]
  );

  return (
    <div onClick={onClick} {...rest}>
      {fileName}
    </div>
  );
};

DocumentDownloadLink.displayName = DocumentDownloadLink;

DocumentDownloadLink.propTypes = {
  document: PropTypes.shape({
    fileName: PropTypes.string.isRequired,
    documentId: PropTypes.number.isRequired
  }).isRequired
};

export default DocumentDownloadLink;
