import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactDataGrid from "react-data-grid";
import { DataTable } from "best-common-react";

const DataTableWrapper = styled.div`
  .react-grid-Grid {
    border: none;
  }

  .react-grid-Cell {
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 300;
    color: ${props => props.theme.black};
  }

  .react-grid-Main {
    outline: none;
  }

  /* Tried using &&&, didn't work */
  .react-grid-HeaderCell-sortable > span.pull-right {
    bottom: 6px;
    color: ${props => props.theme["dark-grey"]} !important;
  }

  /* Tried using &&&, didn't work */
  .react-grid-HeaderCell {
    font-weight: bold;
    color: ${props => props.theme["dark-grey"]} !important;
    border-bottom: 1px solid #f2f3f4 !important;
  }
`;

const HeaderText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme["dark-grey"]};
`;

class Renderers {
  static HeaderRenderer = ({ column }) => <HeaderText>{column.name}</HeaderText>;
}

const WBCDataTable = ({ className, columns: cols, useBCR, ...rest }) => {
  /**
   * headerRender is an element because of the following issue:
   * https://github.com/adazzle/react-data-grid/issues/1710#issuecomment-516392467
   */
  const columns = cols.map(c => ({ headerRenderer: <Renderers.HeaderRenderer />, ...c }));
  return (
    <DataTableWrapper className={className}>
      {useBCR ? <DataTable columns={cols} {...rest} /> : <ReactDataGrid columns={columns} {...rest} />}
    </DataTableWrapper>
  );
};

WBCDataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.array,
  useBCR: PropTypes.bool
};

WBCDataTable.defaultProps = {
  useBCR: false
};

export default WBCDataTable;
