import React, { useContext } from "react";
import RouteConstants from "../constants/RouteConstants";
import AuthContext from "../contexts/AuthContext";
import { Redirect } from "react-router-dom";

const HomeContainer = () => {
  const authContext = useContext(AuthContext);
  const loginNavigation = state => {
    if (state) {
      if (state.isBOC || state.isFedAdmin || state.isFedUser || state.isTeamCoordinator) {
        return <Redirect to={RouteConstants.FEDERATION_TEAMS} />;
      } else {
        return <Redirect to={RouteConstants.INVALID} />;
      }
    } else {
      return <Redirect to={RouteConstants.BASE} />;
    }
  };

  return (
    <div>
      {authContext.state.loggedIn ? (
        <div>{loginNavigation(authContext.state)}</div>
      ) : (
        <Redirect to={RouteConstants.BASE} />
      )}
    </div>
  );
};

export default HomeContainer;
