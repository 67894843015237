import React, { useContext } from "react";
import styled from "styled-components";
import PositionsContext from "../../contexts/PositionsContext";
import WBCDataTable from "../data-table/WBCDataTable";
import Theme from "../../Theme";

const BodyContent = styled.div`
  text-align: left;
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${Theme["dark-grey"]};
  margin-bottom: 16px;
  .react-grid-Viewport {
    border-top: solid 1px #d2d2d2;
  }
  .react-grid-Container {
    border: solid 1px #d2d2d2;
  }
  .react-grid-Cell {
    border-bottom-color: #d2d2d2;
  }
`;

const columns = [
  {
    key: "abbrv",
    name: "Abbreviation",
    width: 150,
    sortable: false
  },
  {
    key: "position",
    name: "Position",
    resizable: false,
    sortable: false
  }
];

const PositionKeyModalBody = () => {
  const positionsContext = useContext(PositionsContext);

  const { profilePositionsDropdown: positionsDropdown } = positionsContext.state;
  const data = positionsDropdown
    .concat()
    .sort((pos1, pos2) => (pos1.orderNum > pos2.orderNum ? 1 : -1))
    .map(pos => {
      return {
        abbrv: pos.label,
        position: pos.name
      };
    });

  return (
    <BodyContent>
      <WBCDataTable useBCR columns={columns} data={data} height={171} />
    </BodyContent>
  );
};

PositionKeyModalBody.displayName = "BodyContent";

export default PositionKeyModalBody;
