import React from "react";
import SharedRosterForm from "./SharedRosterForm";
import { QUALIFYING } from "../../constants/TournamentTypeConstants";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";

const QualifyingRosterForm = () => (
  <TournamentTypeContext.Provider value={QUALIFYING}>
    <SharedRosterForm />
  </TournamentTypeContext.Provider>
);

export default QualifyingRosterForm;
