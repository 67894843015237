export default {
  // NOTE: if we use a staff view, positions are obsolete
  POSITIONS: {
    1: "Pitcher",
    2: "Catcher",
    3: "1st Baseman",
    4: "2nd Baseman",
    5: "3rd Baseman",
    6: "Shortstop",
    7: "Left Fielder",
    8: "Center Fielder",
    9: "Right Fielder",
    10: "Designated Hitter",
    11: "Left-Handed Reliever",
    12: "Left-Handed Starter",
    13: "Right-Handed Reliever",
    14: "Right-Handed Starter",
    15: "Infielder",
    16: "Utility",
    17: "Outfielder",
    18: "Pinch Hitter",
    19: "Unknown",
    20: "Athletic Trainer",
    21: "Conditioning Coach",
    22: "Manager",
    23: "Coach"
  },
  STAFF: {
    COACH: {
      value: "COACH",
      category: 5,
      positionIds: [21, 23]
    },
    MANAGER: {
      value: "MANAGER",
      category: 6,
      positionIds: [22]
    },
    TRAINER: {
      value: "TRAINER",
      category: 7,
      positionIds: [20]
    }
  },
  PLAYER: {
    CATCHER: "Catcher",
    PITCHER: "Pitcher",
    INFIELD: "Infield",
    OUTFIELD: "Outfield"
  }
};
