import React, { useContext } from "react";
import axios from "axios";
import LoadingContext from "../contexts/LoadingContext";
import AuthContext from "../contexts/AuthContext";
import PropTypes from "prop-types";

const HttpInterceptor = ({ children }) => {
  const { setLoading } = useContext(LoadingContext);
  const authContext = useContext(AuthContext);

  let pendingRequest = 0;
  axios.interceptors.request.use(config => {
    pendingRequest++;
    setLoading(true);

    const { accessToken = {} } = JSON.parse(localStorage.getItem("okta-token-storage"));

    if (config.url.indexOf("/oauth/token") < 0 || config.headers.addToken) {
      config.headers.Authorization = `Bearer ${accessToken.accessToken}`;
    }
    return config;
  });

  axios.interceptors.response.use(
    resp => {
      pendingRequest--;
      if (!resp) return null;
      if (pendingRequest === 0) setLoading(false);

      // NOTE: This is not nice, but for some reason the userinfo request seems to reuse resp
      // and ends up with the wrong response if you do resp.data
      if (resp.config && resp.config.url.indexOf("userinfo") === -1) {
        return resp.data;
      }
      return resp;
    },
    err => {
      pendingRequest--;
      if (pendingRequest === 0) setLoading(false);
      if (!!err.response && (err.response.status === 401 || err.response.status === 403)) {
        authContext.dispatch({ type: "logout" });
      }
      if (err?.response?.config?.retryAttempts === 0) {
        // Abort the request to prevent built in retry logic (?)
        console.log("Aborting request: ", err.request);
        err.request.abort();
      }
      return Promise.reject(err);
    }
  );

  return <div>{children}</div>;
};

HttpInterceptor.propTypes = {
  children: PropTypes.object
};

export default HttpInterceptor;
