import React from "react";
import PitcherBreakout from "./PitcherBreakout";
import PlayerComments from "./PlayerComments";
import BoxScore from "./BoxScore";
import { ContainerStyle } from "./PlayerModalStyling";

const PitcherReport = () => {
  return (
    <ContainerStyle>
      <BoxScore />
      <PlayerComments />
      <PitcherBreakout />
    </ContainerStyle>
  );
};

export default PitcherReport;
