import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import WBCHorizontalFieldWrapper from "../../elements/WBCHorizontalFieldWrapper";
import WBCHorizontalFieldInput from "../../elements/WBCHorizontalFieldInput";
import DisabledInputStyle from "../../elements/DisabledInputStyle";

const LeftSection = styled.div`
  margin-bottom: 24px;
`;

const ProvisionalSettings = ({ settings, isDisabled, changeFn, changeCode }) => (
  <LeftSection className={"col-sm-12 col-md-6"}>
    <div>
      <div>
        <WBCHorizontalFieldWrapper label="Provisional Max" required={!isDisabled}>
          <WBCHorizontalFieldInput label="Players">
            <FormInput
              type="number"
              name="provisionalMax"
              value={settings.rosterNumbers.provisionalMax}
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, {
                  ...settings,
                  rosterNumbers: { ...settings.rosterNumbers, provisionalMax: value }
                })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
        </WBCHorizontalFieldWrapper>
      </div>
      <div>
        <WBCHorizontalFieldWrapper label="Managers" required={!isDisabled}>
          <WBCHorizontalFieldInput label="Min">
            <FormInput
              type="number"
              name="managersMin"
              value={
                settings.managers.managersMin !== null && settings.managers.managersMin !== undefined
                  ? settings.managers.managersMin.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, managers: { ...settings.managers, managersMin: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
          <WBCHorizontalFieldInput label="Max">
            <FormInput
              type="number"
              name="managersMax"
              value={
                settings.managers.managersMax !== null && settings.managers.managersMax !== undefined
                  ? settings.managers.managersMax.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, managers: { ...settings.managers, managersMax: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
        </WBCHorizontalFieldWrapper>
      </div>
      <div>
        <WBCHorizontalFieldWrapper label="Coaches" required={!isDisabled}>
          <WBCHorizontalFieldInput label="Min">
            <FormInput
              type="number"
              name="coachesMin"
              value={
                settings.coaches.coachesMin !== null && settings.coaches.coachesMin !== undefined
                  ? settings.coaches.coachesMin.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, coaches: { ...settings.coaches, coachesMin: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
          <WBCHorizontalFieldInput label="Max">
            <FormInput
              type="number"
              name="coachesMax"
              value={
                settings.coaches.coachesMax !== null && settings.coaches.coachesMax !== undefined
                  ? settings.coaches.coachesMax.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, coaches: { ...settings.coaches, coachesMax: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
        </WBCHorizontalFieldWrapper>
      </div>
      <div>
        <WBCHorizontalFieldWrapper label="Trainers" required={!isDisabled}>
          <WBCHorizontalFieldInput label="Min">
            <FormInput
              type="number"
              name="trainersMin"
              value={
                settings.trainers.trainersMin !== null && settings.trainers.trainersMin !== undefined
                  ? settings.trainers.trainersMin.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, trainers: { ...settings.trainers, trainersMin: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
          <WBCHorizontalFieldInput label="Max">
            <FormInput
              type="number"
              name="trainersMax"
              value={
                settings.trainers.trainersMax !== null && settings.trainers.trainersMax !== undefined
                  ? settings.trainers.trainersMax.toString()
                  : null
              }
              editable
              isRequired={!isDisabled}
              onChangeFn={value =>
                changeFn(changeCode, { ...settings, trainers: { ...settings.trainers, trainersMax: value } })
              }
              isDisabled={isDisabled}
              inputStyle={DisabledInputStyle}
            />
          </WBCHorizontalFieldInput>
        </WBCHorizontalFieldWrapper>
      </div>
    </div>
  </LeftSection>
);

ProvisionalSettings.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  changeFn: PropTypes.func.isRequired,
  changeCode: PropTypes.string.isRequired
};

export default ProvisionalSettings;
