import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import classNames from "classnames";
import { isEmpty } from "../../utils/StringUtils";

const Input = styled.input.attrs(() => ({
  className: "form-control"
}))`
  &&& {
    color: black;

    :disabled {
      background-color: ${props => props.theme["light-grey"]};
    }

    :focus {
      background-color: #fbf8cb;
    }
  }
`;

/**
 * An input component that is uncontrolled for phone number inputs that only accept numbers.
 * This component allows for an action to be performed after the text in the box has been updated
 * after a specified customizable timeout.
 */
const UncontrolledNumberInput = ({
  timeout,
  postChange,
  required,
  className,
  value: initValue,
  numberLimit,
  ...rest
}) => {
  // hooks
  const timeoutRef = useRef(null);
  const [value, setValue] = useState(initValue || "");

  // variables
  const inputClassName = classNames(className, {
    "is-invalid": required && (value === undefined || value === null || (typeof value === "string" && isEmpty(value)))
  });

  // functions
  const onChange = e => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }

    const updatedVal = e.target.value.replace(/[^0-9]/g, "");
    if (updatedVal !== value && updatedVal.length <= numberLimit) {
      setValue(updatedVal);

      timeoutRef.current = setTimeout(
        value => {
          if (postChange) {
            postChange(value);
          }
        },
        timeout,
        updatedVal
      );
    }
  };

  // effects
  useEffect(() => {
    if (initValue !== undefined) {
      if (typeof initValue === "string") setValue(initValue.replace(/[^0-9]/g, ""));
      if (typeof initValue === "number") setValue(initValue);
    }
  }, [initValue]);

  // render
  return <Input className={inputClassName} onChange={onChange} value={value} {...rest} />;
};

UncontrolledNumberInput.propTypes = {
  required: PropTypes.bool,
  timeout: PropTypes.number,
  postChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  numberLimit: PropTypes.number
};

UncontrolledNumberInput.defaultProps = {
  timeout: 150,
  numberLimit: 19,
  required: false
};

export default UncontrolledNumberInput;
