import React, { useContext } from "react";
import { Route } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import PropTypes from "prop-types";
import styled from "styled-components";
import PermissionCheck from "./PermissionCheck";
import WBCTeamSelect from "../elements/WBCTeamSelect";
import GlobalFedDropdownContext from "../../contexts/GlobalFedDropdownContext";

const Container = styled.div`
  @media (max-width: 1023px) {
    height: 88vh;
    width: 100vw;
    margin-top: 58px;
  }

  @media (min-width: 1024px) {
    height: 88vh;
    margin-right: 30px;
    margin-top: 58px;
    margin-left: 50px;
  }
`;

const WhiteBackgroundHolder = styled.div`
  margin-top: 75px;
  background-color: #ffffff;
  border: solid 1px #d2d2d2;
  width: 100%;
  height: 86vh;
  overflow: scroll;

  @media (min-width: 1024px) {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }

  @media (max-width: 1023px) {
    margin-top: 67px;
  }
`;

const PlainHolder = styled.div`
  margin-top: ${props => props.padding};
  height: 100%;
  width: 100%;

  @media (min-width: 1024px) {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
`;

const PrivateRoute = ({ component: Component, whiteBackground, feature, ...rest }) => {
  const authContext = useContext(AuthContext);
  const globalFedTeamContext = useContext(GlobalFedDropdownContext);

  const { showingState } = globalFedTeamContext.state;

  return (
    <Route
      {...rest}
      render={props => (
        <div>
          <Container>
            {whiteBackground ? (
              <WhiteBackgroundHolder>
                <PermissionCheck authContext={authContext} Component={Component} feature={feature} props={props} />
              </WhiteBackgroundHolder>
            ) : (
              <div>
                {showingState ? (
                  <div>
                    <WBCTeamSelect />
                    <PlainHolder padding="0px">
                      <PermissionCheck
                        authContext={authContext}
                        Component={Component}
                        feature={feature}
                        props={props}
                      />
                    </PlainHolder>
                  </div>
                ) : (
                  <PlainHolder padding="80px">
                    <PermissionCheck authContext={authContext} Component={Component} feature={feature} props={props} />
                  </PlainHolder>
                )}
              </div>
            )}
          </Container>
        </div>
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  location: PropTypes.object,
  whiteBackground: PropTypes.bool,
  feature: PropTypes.string
};

export default PrivateRoute;
