import React, { useContext, useEffect, useMemo, useCallback } from "react";
import { DropdownItem, Icon, NavbarUsernameDropdown } from "best-common-react";
import DesktopTabletView from "../../components/temp/DesktopTabletView";
import logo from "../../assets/logo.png";
import AuthContext from "../../contexts/AuthContext";
import styled from "styled-components";
import Theme from "../../Theme";
import NavIconConstants from "../../constants/NavIconConstants";
import RouteConstants from "../../constants/RouteConstants";
import MobileNavContext from "../../contexts/MobileNavContext";
import WBCMobileNavBigLink from "./WBCMobileNavBigLink";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import UserSection from "./UserSection";
import MobileView from "../../components/temp/MobileView";
import NavBar from "../../components/temp/NavBar";
import NavBarSignout from "../../components/temp/NavbarSignout";
import NavigationLinks from "../../components/temp/NavigationLinks";
import NavigationVertical from "../../components/temp/NavigationVertical";
import WBCYearsNavMenu from "./WBCYearsNavMenu";
import FeatureToggler from "../elements/FeatureToggler";
import PopoverOnHover from "../elements/PopoverOnHover";
import FeatureConstants from "../../constants/FeatureConstants";
import IconNavigationLink from "./IconNavigationLink";
import ReactToolTip from "react-tooltip";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { isCurrentOrPastTournamentYear } from "../../utils/WBCYearPredicates";
import mlbLogo from "../../assets/mlb-logo.svg";
import ImgNavigationLink from "./ImgNavigationLink";

const UsernameDropdown = styled.div`
  position: relative;

  &&& .dropdown-menu {
    margin: 0;
    padding: 6px 0;
    right: 5px;
    top: 30px !important;
    transform: none !important;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #162a57;
    border-radius: 0;
  }
`;

const SignOut = styled(DropdownItem)`
  &&& {
    padding: 0 13px;
    background-color: inherit;
    text-align: center;
    font-weight: normal;
    color: ${props => props.theme.white};
    font-family: Helvetica;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }
`;

const WhiteBar = styled.div`
  background-color: ${props => props.theme.white};
  width: 100%;
  height: 20px;
  position: absolute;
  z-index: 100;
`;

const VerticalNavCustomStyle = {
  width: "50px",
  backgroundColor: Theme.mlbNavy,
  boxShadow: "2px 0 6px 0 rgba(0, 0, 0, 0.1)"
};

const Spacer = styled.div`
  background-color: ${props => props.theme.mlbNavy};
  height: ${props => props.height};
  width: 100%;
`;

const StaticNavIcon = styled.li`
  padding: 10px 20px 10px 0;
`;

const StaticMobileNavIcon = styled.div`
  border-width: 0px 1px 1px;
  justify-content: space-between !important;
  display: flex !important;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 1rem;
  padding-left: 32px;
  color: white !important;
  background-color: #1e376d;
  border-style: solid;
  border-color: #000000;
  cursor: pointer;
`;

const StaticIconWrapper = styled.div`
  padding: 5px 0.875rem;
  cursor: pointer;
`;

const StaticMobileIconWrapper = styled.div`
  cursor: pointer;
`;

const WBCNavigation = () => {
  // context(s)
  const authContext = useContext(AuthContext);
  const globalModalContext = useContext(GlobalModalContext);
  const mobileNavContext = useContext(MobileNavContext);
  const { wbcYears, currentWbcYear } = useContext(WBCYearsContext);

  // variables
  const { mobileNavOpen } = mobileNavContext.state;
  const { loggedIn, email, isBOC } = authContext.state;
  const { dispatch: mobileDispatch } = mobileNavContext;
  const navYears = useMemo(() => wbcYears.filter(isCurrentOrPastTournamentYear), [wbcYears]);

  // function(s)
  const logout = () => authContext.dispatch({ type: "logout" });
  const toggleMobileNav = useCallback(
    value => {
      mobileDispatch({
        mobileNavOpen: typeof value === "boolean" ? value : !mobileNavOpen,
        type: "TOGGLE_MOBILE_NAV"
      });
    },
    [mobileDispatch, mobileNavOpen]
  );

  const helpModal = () => {
    globalModalContext.dispatch({
      type: "openHelpAndSupportModal"
    });
  };

  // effect(s)
  // set mobile nav function
  useEffect(() => {
    mobileDispatch({
      toggleMobileNav,
      type: "SET_MOBILE_NAV_FUNC"
    });
  }, [mobileDispatch, toggleMobileNav]);

  // component
  return (
    <div>
      {loggedIn ? (
        <NavBar vertical displayEnvIndicator={false} mobileMax={1023}>
          <NavBarSignout
            loggedIn={loggedIn}
            color={Theme.mlbNavy}
            displayEnvIndicator={false}
            mobileMax={1023}
            desktopMin={1024}
          >
            <UsernameDropdown>
              <NavbarUsernameDropdown username={email} color="#ffffff">
                <SignOut onClick={logout}>Sign Out</SignOut>
              </NavbarUsernameDropdown>
            </UsernameDropdown>
          </NavBarSignout>
          <NavigationVertical
            loggedIn={loggedIn}
            isLoginTab={!loggedIn}
            logo={logo}
            styles={VerticalNavCustomStyle}
            mobileMax={1023}
            desktopMin={1024}
            expandStyleHidden="xl"
            expandStyleStyled="lg"
          >
            <NavigationLinks
              topMargin={isBOC ? 300 : 105}
              color="#142958"
              open={mobileNavOpen}
              mobileMax={1023}
              desktopMin={1024}
            >
              <DesktopTabletView minWidth={1024}>
                {navYears.length > 1 ? (
                  <PopoverOnHover
                    content={<WBCYearsNavMenu years={navYears} />}
                    position="right"
                    padding={-16}
                    align="start"
                    transitionDuration={0.35}
                  >
                    {({ isOpen: isPopoverOpen }) => (
                      <IconNavigationLink
                        toggleMobileNav={mobileNavOpen}
                        to={RouteConstants.FEDERATION_TEAMS}
                        icon={NavIconConstants.TROPHY_ICON}
                        isPopoverOpen={isPopoverOpen}
                        transitionDuration={0.35}
                      />
                    )}
                  </PopoverOnHover>
                ) : (
                  <IconNavigationLink
                    toggleMobileNav={mobileNavOpen}
                    to={RouteConstants.FEDERATION_TEAMS}
                    icon={NavIconConstants.TROPHY_ICON}
                  />
                )}
                {isBOC && (
                  <IconNavigationLink
                    toggleMobileNav={mobileNavOpen}
                    to={RouteConstants.PLAYER_ELIGIBILITY}
                    icon={NavIconConstants.CLIPBOARD_ICON}
                    tooltip="Player Eligibility"
                  />
                )}
                {isBOC && (
                  <>
                    <ImgNavigationLink
                      toggleMobileNav={mobileNavOpen}
                      to={RouteConstants.MLB_PLAYERS}
                      imgSrc={mlbLogo}
                      tooltip="MLB Affiliated Players"
                    />
                    <IconNavigationLink
                      toggleMobileNav={mobileNavOpen}
                      icon={NavIconConstants.EMAIL_NOTIFICATIONS_ICON}
                      to={RouteConstants.EMAIL_NOTIFICATIONS}
                      tooltip={"Email Notifications"}
                    />
                  </>
                )}
                {currentWbcYear && (
                  <FeatureToggler feature={FeatureConstants.CLUB_COMM}>
                    <IconNavigationLink
                      toggleMobileNav={mobileNavOpen}
                      to={RouteConstants.CLUB_COMM}
                      icon={NavIconConstants.BULLHORN_ICON}
                      tooltip="Club Communication"
                    />
                  </FeatureToggler>
                )}
                {isBOC && (
                  <>
                    <IconNavigationLink
                      toggleMobileNav={mobileNavOpen}
                      to={RouteConstants.SETTINGS}
                      icon={NavIconConstants.SETTINGS_ICON}
                      tooltip="Settings"
                    />
                    <IconNavigationLink
                      toggleMobileNav={mobileNavOpen}
                      to={RouteConstants.USERS}
                      icon={NavIconConstants.USERS_ICON}
                      tooltip="Users"
                    />
                  </>
                )}
                <StaticNavIcon>
                  <StaticIconWrapper data-place="right" data-tip={"Help"} onClick={helpModal}>
                    <Icon iconName={NavIconConstants.HELP_ICON} />
                    <ReactToolTip disable={/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)} />
                  </StaticIconWrapper>
                </StaticNavIcon>
              </DesktopTabletView>
              <MobileView maxWidth={1023}>
                <Spacer height="80px" />
                <div>
                  {navYears.map((year, index) => (
                    <div key={year.year}>
                      <WBCMobileNavBigLink
                        hasTopBorder={index === 0}
                        key={year.year}
                        title={year.year}
                        toggleMobileNav={toggleMobileNav}
                        icon={NavIconConstants.TROPHY_ICON}
                        to={`${RouteConstants.FEDERATION_TEAMS}?tab=prospective&year=${year.year}`}
                      />
                      {currentWbcYear && (
                        <FeatureToggler feature={FeatureConstants.CLUB_COMM}>
                          <WBCMobileNavBigLink
                            key={"Club Communication"}
                            title={"Club Communication"}
                            toggleMobileNav={toggleMobileNav}
                            icon={NavIconConstants.BULLHORN_ICON}
                            to={RouteConstants.CLUB_COMM}
                          />
                        </FeatureToggler>
                      )}
                    </div>
                  ))}
                  {isBOC && (
                    <WBCMobileNavBigLink
                      title="Player Eligibility"
                      toggleMobileNav={toggleMobileNav}
                      icon={NavIconConstants.CLIPBOARD_ICON}
                      to={RouteConstants.PLAYER_ELIGIBILITY}
                    />
                  )}

                  <WBCMobileNavBigLink
                    title="MLB Affiliated Players"
                    toggleMobileNav={toggleMobileNav}
                    imgSrc={mlbLogo}
                    to={RouteConstants.MLB_PLAYERS}
                  />

                  {isBOC && (
                    <>
                      <WBCMobileNavBigLink
                        title="Settings"
                        toggleMobileNav={toggleMobileNav}
                        icon={NavIconConstants.SETTINGS_ICON}
                        to={RouteConstants.SETTINGS}
                      />
                      <WBCMobileNavBigLink
                        title="Users"
                        toggleMobileNav={toggleMobileNav}
                        icon={NavIconConstants.USERS_ICON}
                        to={RouteConstants.USERS}
                      />
                    </>
                  )}

                  {isBOC && (
                    <WBCMobileNavBigLink
                      title="Email Notifications"
                      toggleMobileNav={toggleMobileNav}
                      icon={NavIconConstants.EMAIL_NOTIFICATIONS_ICON}
                      to={RouteConstants.EMAIL_NOTIFICATIONS}
                    />
                  )}
                  <StaticMobileNavIcon
                    onClick={() => {
                      toggleMobileNav();
                      helpModal();
                    }}
                  >
                    <StaticMobileIconWrapper>
                      <Icon
                        style={{ marginRight: "10px", color: Theme.wbcBlue }}
                        iconName={NavIconConstants.HELP_ICON}
                      />
                      Help
                    </StaticMobileIconWrapper>
                  </StaticMobileNavIcon>
                  <UserSection />
                </div>
              </MobileView>
            </NavigationLinks>
          </NavigationVertical>
        </NavBar>
      ) : (
        <WhiteBar />
      )}
    </div>
  );
};

export default WBCNavigation;
