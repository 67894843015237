import React, { useRef, useContext, forwardRef } from "react";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../history/history";
import GlobalModalContext from "../../contexts/GlobalModalContext";

const initial = { url: "", state: undefined };

const DiscardPrompt = forwardRef(({ isDirty, onConfirm, onDiscard }, ref) => {
  // hooks
  const locationRef = useRef(initial);
  const globalModalContext = useContext(GlobalModalContext);

  // variables
  const { dispatch } = globalModalContext;
  const { isOpen } = globalModalContext.state;

  // functions
  const goToLocation = () => {
    const { url, state } = locationRef.current;

    if (url) {
      locationRef.current = initial;
      history.push(url, state);
    }
  };

  const onDiscardModal = () => {
    if (onDiscard) onDiscard();
    goToLocation();
  };

  const onSave = () => {
    if (onConfirm) onConfirm();
    goToLocation();
  };

  const onPrompt = location => {
    if (isDirty && !isOpen) {
      locationRef.current = {
        url: location.pathname + location.search,
        state: location.state
      };
      dispatch({
        type: `open${ref.current === "cancel" ? "Discard" : "UnsavedChanges"}Modal`,
        discardChangesFn: onDiscardModal,
        saveChangesFn: onSave
      });
      return false;
    }

    return true;
  };

  return <Prompt message={onPrompt} />;
});

DiscardPrompt.propTypes = {
  isDirty: PropTypes.bool.isRequired,
  onDiscard: PropTypes.func,
  onConfirm: PropTypes.func
};

DiscardPrompt.displayName = "DiscardPrompt";

export default DiscardPrompt;
