export default {
  Preliminary: {
    id: 1,
    name: "Preliminary"
  },
  Submitted: {
    id: 2,
    name: "Submitted"
  },
  Published: {
    id: 3,
    name: "Published"
  }
};
