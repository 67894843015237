import React from "react";
import PropTypes from "prop-types";
import GlobalModalContextProvider from "../GlobalModalContextProvider";

const ModalProviderWrapper = ({ children }) => <GlobalModalContextProvider>{children}</GlobalModalContextProvider>;

ModalProviderWrapper.propTypes = {
  children: PropTypes.object
};
export default ModalProviderWrapper;
