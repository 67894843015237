import React, { useReducer } from "react";
import PropTypes from "prop-types";
import ClubCommDashboardContext from "../../contexts/ClubCommDashboardContext";
import _ from "lodash";

const initialStatuses = [
  {
    label: "All Statuses",
    value: "0"
  },
  {
    label: "Preliminary",
    value: "1"
  },
  {
    label: "Submitted",
    value: "2"
  },
  {
    label: "Published",
    value: "3"
  }
];

const initialState = {
  eventReports: [],
  team: {
    teamId: 0,
    label: "",
    value: 0,
    countryCode: "",
    code: ""
  },
  teams: [],
  status: initialStatuses[0],
  statuses: initialStatuses,
  org: { value: 0 },
  orgs: [],
  opponentFilter: {},
  opponents: [],
  filtersDirty: false
};

const allTeam = {
  label: "All Federations",
  value: 0,
  teamId: 0,
  countryCode: "",
  code: ""
};

const allOrg = {
  label: "All Orgs",
  value: 0,
  orgId: 0
};

const ClubCommDbContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setEventReports":
        return {
          ...state,
          eventReports: action.eventReports
        };
      case "setTeam":
        return {
          ...state,
          team: {
            value: action.value,
            label: action.label,
            teamId: action.teamId,
            countryCode: action.countryCode,
            code: action.code
          },
          filtersDirty: true
        };
      case "setTeams":
        return {
          ...state,
          team: state.teams.length >= 1 ? state.team : allTeam,
          teams: [allTeam, ...action.teams]
        };
      case "setStatus":
        return {
          ...state,
          status: {
            value: action.value,
            label: action.label
          },
          filtersDirty: true
        };
      case "setOrg":
        return {
          ...state,
          org: {
            value: action.value,
            label: action.label,
            orgId: action.orgId
          },
          filtersDirty: true
        };
      case "setOrgs":
        return {
          ...state,
          org: state.orgs.length >= 1 ? state.org : allOrg,
          orgs: [allOrg, ...action.orgs]
        };
      case "setOpponentFilter":
        return {
          ...state,
          opponentFilter: {
            value: action.value,
            label: action.label
          },
          filtersDirty: true
        };
      case "setOpponents":
        const opponentFilterValid = _.find(action.opponents, o => {
          return o.value === state.opponentFilter.value && o.label === state.opponentFilter.label;
        });
        return {
          ...state,
          opponentFilter:
            state.opponentFilter.value === undefined || !opponentFilterValid
              ? action.opponents[0]
              : { ...state.opponentFilter },
          opponents: action.opponents
        };
      case "resetFilters":
        return {
          ...state,
          team: allTeam,
          status: initialStatuses[0],
          org: allOrg,
          opponentFilter: state.opponents.length > 0 ? state.opponents[0] : null,
          filtersDirty: false
        };
      default:
        return { ...state };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <ClubCommDashboardContext.Provider value={{ state, dispatch }}>{children}</ClubCommDashboardContext.Provider>;
};

ClubCommDbContextProvider.propTypes = {
  children: PropTypes.object
};

export default ClubCommDbContextProvider;
