import { isString } from "lodash";

const formatProfileName = ({ lastName, firstName, fullName }) =>
  fullName
    ? isString(fullName)
      ? fullName
      : `${fullName.lastName}, ${fullName.firstName}`
    : `${lastName}, ${firstName}`;

const openDocumentsModal = ({
  profile: { profileId, fedTeamId, wbcYear, lastName, firstName, fullName },
  labelTypeId,
  globalModalDispatch,
  onSaveSuccess
}) => {
  const headerText = `Docs for ${formatProfileName({ lastName, firstName, fullName })}`;
  const content = { profileId, fedTeamId, labelTypeId };

  globalModalDispatch({
    type: "openDocumentsModal",
    payload: {
      headerText,
      content,
      onSaveSuccess
    }
  });
};

export default openDocumentsModal;
