import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import {
  removeNodeById,
  getBottomCoord,
  getTopCoord,
  getRightCoord,
  getLeftCoord,
  isBottomFlipped,
  isTopFlipped
} from "../../utils/PortalUtils";
import { randomString } from "../../utils/StringUtils";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`;

const isTargetWithinPopper = (element, uniqueId) => {
  if (!element || element.nodeName.toLowerCase() === "body") return false;

  return element.id === uniqueId || isTargetWithinPopper(element.parentNode, uniqueId);
};

const isTargetHiddenFromContainer = (container, target) => {
  // if the box is completely outside, it doesn't overlap
  if (
    target.xMax < container.xMin ||
    target.xMin > container.xMax ||
    target.yMax < container.yMin ||
    target.yMin > container.yMax
  ) {
    return true;
  }

  // if it's encased completely, it is NOT hidden
  if (
    target.xMin >= container.xMin &&
    target.xMax <= container.xMax &&
    target.yMin >= container.yMin &&
    target.yMax <= container.yMax
  ) {
    return false;
  }

  // the box crosses the border, so we consider it hidden
  return true;
};

/**
 * This is a fork of the reactstrap Popper, which has a bug in
 * version 8.0.1 where the browser freezes if the window is ever
 * resized. Until that is resolved, this file is being used.
 */
const Popper = ({ targetRef, placement, offsetX, offsetY, isOpen, togglePopper, className, children }) => {
  // hook(s)
  const containerRef = useRef(null);
  const [uniqueId] = useState(`popper-${randomString()}`);
  const [position, setPosition] = useState({ x: -1000, y: -1000 });

  // variable(s)
  const { x, y } = position;
  const style = { transform: `translate3d(${x}px, ${y}px, 0px)` };
  const { offsetHeight: nodeHeight = 0, offsetWidth: nodeWidth = 0 } = containerRef.current || {};

  // create node that the popper will bein
  const node = document.getElementById(uniqueId) || document.createElement("div");
  node.setAttribute("id", uniqueId);

  // function(s)
  const closeIfClickedOutside = useCallback(
    e => {
      if (
        isOpen &&
        containerRef.current &&
        targetRef.current &&
        !containerRef.current.contains(e.target) &&
        !targetRef.current.contains(e.target)
      ) {
        togglePopper(false);
      }
    },
    [targetRef, isOpen, togglePopper]
  );

  const calculatePosition = useCallback(
    e => {
      if (isOpen && targetRef.current) {
        const { x, y, right, bottom } = targetRef.current.getBoundingClientRect();
        const { offsetHeight: targetHeight, offsetWidth: targetWidth } = targetRef.current;

        // calculate max y value to stick popper
        let yTop = null;
        if (e && e.type === "scroll" && !isTargetWithinPopper(e.target.parentNode, uniqueId)) {
          const bounds = e.target.getBoundingClientRect();
          yTop = bounds.top;

          // check if target intersects borders
          const point1 = { xMin: bounds.x, xMax: bounds.right, yMin: bounds.y, yMax: bounds.bottom };
          const point2 = { xMin: x, xMax: right, yMin: y, yMax: bottom };

          if (isTargetHiddenFromContainer(point1, point2)) {
            togglePopper(false);
            return;
          }
        }

        if (placement === "top") {
          let yCoord = getTopCoord(y, offsetY, targetHeight, nodeHeight);

          // set y coord max/min based on scroll container
          if (yTop != null && isTopFlipped(y, offsetY, nodeHeight)) {
            yCoord = Math.max(yCoord, yTop, 0);
          } else {
            yCoord = Math.min(yCoord, window.innerHeight - nodeHeight - offsetY);
          }

          setPosition({
            x: Math.round(x + offsetX),
            y: yCoord
          });
        } else if (placement === "right") {
          setPosition({
            x: getRightCoord(x, offsetX, targetWidth, nodeWidth),
            y: Math.round(y + offsetY)
          });
        } else if (placement === "left") {
          setPosition({
            x: getLeftCoord(x, offsetX, targetWidth, nodeWidth),
            y: Math.round(y + offsetY)
          });
        } else {
          let yCoord = getBottomCoord(y, offsetY, targetHeight, nodeHeight);

          // set y coord max/min based on scroll container
          if (isBottomFlipped(y, offsetY, targetHeight, nodeHeight)) {
            yCoord = Math.min(yCoord, window.innerHeight - nodeHeight - offsetY);
          } else if (yTop != null) {
            yCoord = Math.max(yCoord, yTop, 0);
          }

          setPosition({
            x: Math.round(x + offsetX),
            y: yCoord
          });
        }
      }
    },
    [targetRef, uniqueId, isOpen, placement, offsetX, offsetY, nodeHeight, nodeWidth, togglePopper]
  );

  // effect(s)
  // calculate position
  useEffect(calculatePosition, [calculatePosition]);

  // add to/remove from DOM
  useEffect(() => {
    if (isOpen) {
      document.body.appendChild(node);
    } else {
      removeNodeById(uniqueId);
    }
  }, [node, isOpen, uniqueId]);

  // event listener(s)
  useEffect(() => {
    window.addEventListener("click", closeIfClickedOutside);
    return () => {
      window.removeEventListener("click", closeIfClickedOutside);
    };
  }, [closeIfClickedOutside]);

  // scroll && resize listeners
  useEffect(() => {
    window.addEventListener("resize", calculatePosition);
    window.addEventListener("scroll", calculatePosition, true);
    return () => {
      window.removeEventListener("resize", calculatePosition);
      window.removeEventListener("scroll", calculatePosition, true);
    };
  }, [calculatePosition]);

  return ReactDOM.createPortal(
    <Container ref={containerRef} className={className} style={style}>
      {children}
    </Container>,
    node
  );
};

Popper.propTypes = {
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  isOpen: PropTypes.bool,
  togglePopper: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any
};

Popper.defaultProps = {
  placement: "bottom",
  offsetX: 0,
  offsetY: 0
};

export default Popper;
