import { useContext } from "react";
import AuthContext from "../../contexts/AuthContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";

const useHasAdminAccess = (bocOnly = false, includesTc = true) => {
  const { isBOCadmin, isFedAdmin, isTeamCoordinator } = useContext(AuthContext).state;
  const { isCurrentYearSelected } = useContext(WBCYearsContext);

  return isBOCadmin || (!bocOnly && (isFedAdmin || (includesTc && isTeamCoordinator)) && isCurrentYearSelected);
};

export default useHasAdminAccess;
