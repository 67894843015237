import React, { useContext } from "react";
import styled from "styled-components";
import NavIconConstants from "../../constants/NavIconConstants";
import WBCMobileNavProvider from "../../providers/WBCMobileNavProvider";
import WBCMobileNavSection from "./WBCMobileNavSection";
import AuthContext from "../../contexts/AuthContext";

const SignOut = styled.div.attrs(() => ({ className: "ml-3 d-flex justify-content-between" }))`
  padding: 4px;
  font-size: 14px !important;
  color: #ffffff !important;
  &:hover {
    color: #dddddd !important;
    cursor: pointer;
    text-decoration: underline;
  }
`;

const UserSection = () => {
  const authContext = useContext(AuthContext);
  return (
    <WBCMobileNavProvider openSection={authContext.state.email}>
      <WBCMobileNavSection title={authContext.state.email} icon={NavIconConstants.ACCOUNT}>
        <SignOut
          onClick={() => {
            authContext.dispatch({ type: "logout" });
          }}
        >
          Sign Out
        </SignOut>
      </WBCMobileNavSection>
    </WBCMobileNavProvider>
  );
};

export default UserSection;
