import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import EligibilityTeamSelect from "./EligibilityTeamSelect";
import PlayerEligibilitySearch from "./PlayerEligibilitySearch";
import PlayerEligibilityTable from "./PlayerEligibilityTable";
import EligibilityFilterSelect from "./EligibilityFilterSelect";
import EligibilityYearSelect from "./EligibilityYearSelect";
import MobileFullWidth from "../elements/MobileFullWidth";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";

const Content = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 80vh;
`;

const QuickSearchContainer = styled(MobileFullWidth).attrs(() => ({
  className: "mr-3"
}))`
  width: 240px;
`;

const PlayerEligibilityForm = () => {
  const playerEligibilityContext = useContext(PlayerEligibilityContext);
  const { dispatch: eligibilityDispatch } = playerEligibilityContext;

  useEffect(() => {
    return () => {
      eligibilityDispatch({
        type: "setStatusFilter",
        value: 1,
        label: "Pending"
      });
    };
  }, [eligibilityDispatch]);

  return (
    <Content>
      <div className="d-flex flex-wrap">
        <QuickSearchContainer>
          <EligibilityYearSelect />
        </QuickSearchContainer>
        <QuickSearchContainer>
          <EligibilityTeamSelect />
        </QuickSearchContainer>
        <QuickSearchContainer>
          <EligibilityFilterSelect />
        </QuickSearchContainer>
        <QuickSearchContainer>
          <PlayerEligibilitySearch />
        </QuickSearchContainer>
      </div>
      <PlayerEligibilityTable />
    </Content>
  );
};

export default PlayerEligibilityForm;
