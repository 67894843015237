import React, { useContext } from "react";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import { useAlert } from "../../../hooks/useAlert";
import EventReportApi from "../../../../httpClients/EventReportApi";
import WBCLoadingButton from "../../../elements/WBCLoadingButton";
import WBCPrimaryOutlinedButton from "../../../elements/WBCPrimaryOutlinedButton";
import styled from "styled-components";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";
import DesktopTabletView from "../../../temp/DesktopTabletView";
import MobileView from "../../../temp/MobileView";
import WBCAdminAccess from "../../../protected/WBCAdminAccess";
import AuthContext from "../../../../contexts/AuthContext";
import EventReportStatusConstants from "../../../../constants/EventReportStatusConstants";

const PlayerType = {
  PITCHER: "pitcher",
  CATCHER: "catcher",
  POSITION: "positionPlayer",
  DNP: "dnp"
};

const StyledSaveAndCancel = styled.div.attrs(() => ({}))`
  &&& {
    position: ${props => (props.absolute ? "absolute" : "initial")};
    right: -20px;
    top: 175px;

    button {
      width: 90px;
      @media (max-width: 1023px) {
        width: 100%;
      }

      &[name="primary-button"] {
        margin-bottom: ${props => (props.absolute ? "0" : "8px")};
      }
    }
  }
`;

const SaveAndCancelModalComp = () => {
  const authContext = useContext(AuthContext);
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const eventReportContext = useContext(EventReportContext);
  const { eventReport } = eventReportContext.state;
  const modalDispatch = playerReportModalContext.dispatch;
  const { eventPlayers, fedTeamId } = playerReportModalContext.state;
  const { isBOCviewer, isFedUser, isBOCadmin, isTeamCoordinator } = authContext.state;
  const showAlert = useAlert();

  const onSave = () => {
    const updatePlayersRequest = [];
    eventPlayers.forEach(ep => updatePlayersRequest.push({ [PlayerType[ep.playerType]]: ep }));

    EventReportApi.updateEventReportPlayerInfo(fedTeamId, eventPlayers[0].eventReportId, updatePlayersRequest)
      .then(() => {
        showAlert("Saved", "success");
        EventReportApi.getEventReportPlayersInfo(fedTeamId, eventPlayers[0].eventReportId).then(eventPlayers => {
          eventReportContext.dispatch({
            type: "setEventPlayers",
            eventPlayers
          });
        });
      })
      .catch(response => {
        showAlert(`${response.toString()}, while saving player report`, "danger");
      });
  };

  const buttons = fixed => {
    return (
      <div style={{ position: fixed ? "fixed" : "auto" }}>
        <WBCAdminAccess
          bocOnly={
            eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
            eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
          }
        >
          <WBCLoadingButton
            onClick={event => {
              event.stopPropagation();
              onSave();
              modalDispatch({
                type: "closeModal"
              });
              modalDispatch({
                type: "setFormDirty",
                formDirty: false
              });
            }}
            text={"Save All"}
          />
        </WBCAdminAccess>

        <div className={"pt-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              modalDispatch({
                type: "closeModal"
              });
              modalDispatch({
                type: "setFormDirty",
                formDirty: false
              });
            }}
          >
            {isBOCviewer ||
            isFedUser ||
            isTeamCoordinator ||
            (!isBOCadmin && eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id) ||
            eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
              ? "Close"
              : "Cancel All"}
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    );
  };

  return (
    <>
      <DesktopTabletView minWidth={1024}>
        <StyledSaveAndCancel absolute={true}>{buttons(true)}</StyledSaveAndCancel>
      </DesktopTabletView>

      <MobileView maxWidth={1023}>
        <StyledSaveAndCancel>{buttons()}</StyledSaveAndCancel>
      </MobileView>
    </>
  );
};

export default SaveAndCancelModalComp;
