import BaseApi from "./BaseApi";

export default class WBCYearApi extends BaseApi {
  static getCurrentYearInfo = () => this.get("years/current");

  static getYears = () => this.get("years");

  static getYearInfo = year => this.get(`years/${year}`);

  static saveSettings = settings => this.post("years", settings);
}
