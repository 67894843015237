import React, { useContext, useEffect } from "react";
import OrgsContext from "../../../contexts/OrgsContext";
import { BestSelect } from "best-common-react";
import ClubCommDashboardContext from "../../../contexts/ClubCommDashboardContext";

const ClubCommOrgSelect = () => {
  const orgsContext = useContext(OrgsContext);
  const clubCommDbContext = useContext(ClubCommDashboardContext);

  const { org, orgs } = clubCommDbContext.state;
  const { orgsDropdown } = orgsContext.state;
  const { dispatch } = clubCommDbContext;

  useEffect(() => {
    dispatch({ type: "setOrgs", orgs: orgsDropdown });
  }, [dispatch, orgsDropdown]);

  return (
    <div className="form-group">
      <BestSelect
        name="eventReportOrgSelect"
        value={org}
        options={orgs}
        onChange={selection => {
          dispatch({
            type: "setOrg",
            value: selection.value,
            label: selection.label
          });
        }}
      />
    </div>
  );
};

export default ClubCommOrgSelect;
