import React, { useContext } from "react";
import styled from "styled-components";
import WBCCollapse from "../elements/WBCCollapse";
import BrowseFiles from "../elements/BrowseFiles";
import PlayerUploadApi from "../../httpClients/PlayerUploadApi";
import AuthContext from "../../contexts/AuthContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import ExportApi from "../../httpClients/ExportApi";
import FileHelper from "../../httpClients/FileHelper";
import { AlertConstants } from "best-common-react";
import { useAlert } from "../hooks/useAlert";

const TournamentSettingsContainer = styled.div`
  padding: 24px;
  overflow: auto;
  text-align: left;
`;

const DownloadTemplateWrapper = styled.div`
  margin-top: -8px;
`;

const DownloadTemplateStyle = styled.i.attrs(() => ({
  className: "fas fa-download"
}))`
  cursor: pointer;
  font-size: 14px;
  color: ${props => props.theme.wbcBlue};
  &&& {
    line-height: inherit;
  }
`;

const DownloadTemplateTextStyle = styled.span`
  color: ${props => props.theme.wbcBlue};
  cursor: pointer;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
`;

const ProspectivePlayerUpload = () => {
  const authContext = useContext(AuthContext);
  const { currentYear } = useContext(WBCYearsContext);
  const { features } = authContext.state;
  const showAlert = useAlert();

  const onFileAdd = file => {
    if (file.length > 0 && file[0] !== null) {
      const f = file[0];
      let data = new FormData();
      data.append("file", f);
      data.append("name", f.name);

      PlayerUploadApi.uploadProspectivePlayers(currentYear, data)
        .then(() => {
          showAlert("Prospective players successfully uploaded");
        })
        .catch(({ response }) => showAlert(response.data.message, AlertConstants.TYPES.DANGER));
    }
  };

  const downloadTemplate = () => {
    ExportApi.exportUploadTemplate((url, headers) => ({
      url,
      headers
    })).then(data => {
      const test = new Blob([data.url], { type: data.headers["content-type"] });
      const url = window.URL.createObjectURL(test);
      FileHelper.downloadFile(url, data.headers);
    });
  };

  return (
    <>
      {features.playerUpload ? (
        <TournamentSettingsContainer>
          <div>
            <WBCCollapse title="Upload Prospective Players">
              <div style={{ width: "400px" }}>
                <BrowseFiles fileTypes={[".xlsx"]} onFileAdd={onFileAdd} />
                <DownloadTemplateWrapper className={"ml-auto"}>
                  <DownloadTemplateStyle onClick={downloadTemplate}>
                    {" "}
                    <DownloadTemplateTextStyle>download template</DownloadTemplateTextStyle>
                  </DownloadTemplateStyle>
                </DownloadTemplateWrapper>
              </div>
            </WBCCollapse>
          </div>
        </TournamentSettingsContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default ProspectivePlayerUpload;
