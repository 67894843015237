export const ALL_ROSTERS = "All Rosters";
export const INTEREST_LIST = "Interest List";
export const AVAILABLE_LIST = "Available List";
export const PROVISIONAL_LIST = "Provisional List";
export const FINAL_ROSTER = "Final Roster";

const INTEREST_LIST_HIERARCHY = [INTEREST_LIST];
const AVAILABLE_LIST_HIERARCHY = INTEREST_LIST_HIERARCHY.concat(AVAILABLE_LIST);
const PROVISIONAL_LIST_HIERARCHY = AVAILABLE_LIST_HIERARCHY.concat(PROVISIONAL_LIST);
const FINAL_ROSTER_HIERARCHY = PROVISIONAL_LIST_HIERARCHY.concat(FINAL_ROSTER);

export const ROSTER_LIST_HIERARCHY = {
  [INTEREST_LIST]: INTEREST_LIST_HIERARCHY,
  [AVAILABLE_LIST]: AVAILABLE_LIST_HIERARCHY,
  [PROVISIONAL_LIST]: PROVISIONAL_LIST_HIERARCHY,
  [FINAL_ROSTER]: FINAL_ROSTER_HIERARCHY
};
