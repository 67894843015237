import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const NavbarHolder = styled.div.attrs(() => ({
  id: "navbarSupportedContent"
}))`
  @media (max-width: ${props => props.mobileMax}px) {
    height: calc(100vh);
    margin-left: -1rem;
    margin-right: -1rem;
    overflow: scroll;
    background-color: ${props => props.color};
  }
  @media (min-width: ${props => props.desktopMin}px) {
    margin-top: -1rem;
    margin-bottom: -1rem;
    height: 60px;
  }
`;

const Navbar = styled.ul.attrs(() => ({
  className: "navbar-nav flex-column mr-auto"
}))`
  @media (min-width: ${props => props.desktopMin}px) {
    margin-top: ${props => props.topMargin}px;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  margin-top: 22px;
`;

const Spacer = styled.div`
  height: 22px;
  background-color: #1e376d;
`;

const NavigationLinks = ({ isProd, open, color, children, topMargin, mobileMax, desktopMin }) => {
  const collapseClass = open ? "show" : "hide";
  return (
    <Wrapper>
      <Spacer />
      <NavbarHolder
        isProd={isProd}
        className={`collapse navbar-collapse ${collapseClass}`}
        color={color}
        mobileMax={mobileMax}
        desktopMin={desktopMin}
      >
        <Navbar topMargin={topMargin} mobileMax={mobileMax} desktopMin={desktopMin}>
          {children}
        </Navbar>
      </NavbarHolder>
    </Wrapper>
  );
};

NavigationLinks.propTypes = {
  isProd: PropTypes.bool,
  open: PropTypes.bool,
  color: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.object),
  topMargin: PropTypes.number,
  mobileMax: PropTypes.number,
  desktopMin: PropTypes.number
};

NavigationLinks.defaultProps = {
  isProd: false,
  open: false,
  color: "#041e42",
  topMargin: "170",
  children: {},
  mobileMax: 1023,
  desktopMin: 1024
};

export default NavigationLinks;
