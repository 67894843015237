import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { FormInput } from "best-common-react";
import WBCHorizontalFieldWrapper from "../../elements/WBCHorizontalFieldWrapper";
import WBCHorizontalFieldInput from "../../elements/WBCHorizontalFieldInput";
import DisabledInputStyle from "../../elements/DisabledInputStyle";

const RightSection = styled.div``;

const FinalSettings = ({ settings, isDisabled, changeFn, changeCode }) => (
  <RightSection className={"col-sm-12 col-md-6"}>
    <div>
      <WBCHorizontalFieldWrapper label="Final Roster Max" required={!isDisabled}>
        <WBCHorizontalFieldInput label="Players">
          <FormInput
            type="number"
            name="finalRosterMax"
            value={
              settings.rosterNumbers.finalRosterMax !== null && settings.rosterNumbers.finalRosterMax !== undefined
                ? settings.rosterNumbers.finalRosterMax.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, { ...settings, rosterNumbers: { ...settings.rosterNumbers, finalRosterMax: value } })
            }
            isDisabled
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
      </WBCHorizontalFieldWrapper>
    </div>
    <div>
      <WBCHorizontalFieldWrapper label="Catchers" required={!isDisabled}>
        <WBCHorizontalFieldInput label="Min">
          <FormInput
            type="number"
            name="catchersMin"
            value={
              settings.positions.catchers.catchersMin !== null && settings.positions.catchers.catchersMin !== undefined
                ? settings.positions.catchers.catchersMin.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  catchers: {
                    ...settings.positions.catchers,
                    catchersMin: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
        <WBCHorizontalFieldInput label="Max">
          <FormInput
            type="number"
            name="catchersMax"
            value={
              settings.positions.catchers.catchersMax !== null && settings.positions.catchers.catchersMax !== undefined
                ? settings.positions.catchers.catchersMax.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  catchers: {
                    ...settings.positions.catchers,
                    catchersMax: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
      </WBCHorizontalFieldWrapper>
    </div>
    <div>
      <WBCHorizontalFieldWrapper label="Pitchers" required={!isDisabled}>
        <WBCHorizontalFieldInput label="Min">
          <FormInput
            type="number"
            name="pitchersMin"
            value={
              settings.positions.pitchers.pitchersMin !== null && settings.positions.pitchers.pitchersMin !== undefined
                ? settings.positions.pitchers.pitchersMin.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  pitchers: {
                    ...settings.positions.pitchers,
                    pitchersMin: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
        <WBCHorizontalFieldInput label="Max">
          <FormInput
            type="number"
            name="pitchersMax"
            value={
              settings.positions.pitchers.pitchersMax !== null && settings.positions.pitchers.pitchersMax !== undefined
                ? settings.positions.pitchers.pitchersMax.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  pitchers: {
                    ...settings.positions.pitchers,
                    pitchersMax: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
      </WBCHorizontalFieldWrapper>
    </div>
    <div>
      <WBCHorizontalFieldWrapper label="Infield" required={!isDisabled}>
        <WBCHorizontalFieldInput label="Min">
          <FormInput
            type="number"
            name="infieldMin"
            value={settings.positions.infield.infieldMin}
            editable
            isRequired={!isDisabled && settings.positions.infield.infieldMin !== 0}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  infield: {
                    ...settings.positions.infield,
                    infieldMin: value
                  }
                }
              })
            }
            isDisabled
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
        <WBCHorizontalFieldInput label="Max">
          <FormInput
            type="number"
            name="infieldMax"
            value={
              settings.positions.infield.infieldMax !== null && settings.positions.infield.infieldMax !== undefined
                ? settings.positions.infield.infieldMax.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  infield: {
                    ...settings.positions.infield,
                    infieldMax: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
      </WBCHorizontalFieldWrapper>
    </div>
    <div>
      <WBCHorizontalFieldWrapper label="Outfield" required={!isDisabled}>
        <WBCHorizontalFieldInput label="Min">
          <FormInput
            type="number"
            name="outfieldMin"
            value={settings.positions.outfield.outfieldMin}
            editable
            isRequired={!isDisabled && settings.positions.outfield.outfieldMin !== 0}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  outfield: {
                    ...settings.positions.outfield,
                    outfieldMin: value
                  }
                }
              })
            }
            isDisabled
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
        <WBCHorizontalFieldInput label="Max">
          <FormInput
            type="number"
            name="outfieldMax"
            value={
              settings.positions.outfield.outfieldMax !== null && settings.positions.outfield.outfieldMax !== undefined
                ? settings.positions.outfield.outfieldMax.toString()
                : null
            }
            editable
            isRequired={!isDisabled}
            onChangeFn={value =>
              changeFn(changeCode, {
                ...settings,
                positions: {
                  ...settings.positions,
                  outfield: {
                    ...settings.positions.outfield,
                    outfieldMax: value
                  }
                }
              })
            }
            isDisabled={isDisabled}
            inputStyle={DisabledInputStyle}
          />
        </WBCHorizontalFieldInput>
      </WBCHorizontalFieldWrapper>
    </div>
  </RightSection>
);

FinalSettings.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  changeFn: PropTypes.func.isRequired,
  changeCode: PropTypes.string.isRequired
};

export default FinalSettings;
