import RosterApi from "./RosterApi";
import { QUALIFYING } from "../constants/TournamentTypeConstants";

export default class QualifyingRosterApi extends RosterApi {
  static getAvailableRoster = fedTeamId => super.getAvailableRoster(QUALIFYING, fedTeamId);
  static getProvisionalRoster = fedTeamId => super.getProvisionalRoster(QUALIFYING, fedTeamId);
  static getFinalRoster = fedTeamId => super.getFinalRoster(QUALIFYING, fedTeamId);

  static updateTournamentRoster = (fedTeamId, rosterUpdates) =>
    super.updateTournamentRoster(QUALIFYING, fedTeamId, rosterUpdates);
  static updateUniforms = (fedTeamId, uniformUpdates) => super.updateUniforms(fedTeamId, uniformUpdates);
  static updatePosition = (fedTeamId, profileId, positionId) => super.updateUniforms(fedTeamId, profileId, positionId);
}
