import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  border: solid 1px ${props => props.theme["grey"]};
  margin-top: 8px;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px 8px;
  font-size: 11px;

  color: ${props => props.theme["dark-grey"]};
  background-color: ${props => props.theme["almost-white"]};

  div {
    width: 30px;
    text-align: center;
  }
`;

const TopRowStats = styled(TopRow)`
  color: black;
  background-color: white;
  font-size: 13px;
`;

const BottomRow = styled(TopRow)`
  justify-content: space-around;
`;

const BottomRowStats = styled(TopRowStats)`
  justify-content: space-around;
`;

const BoxScoreTable = ({ topRowHeaders, bottomRowHeaders, statsMap }) => {
  return (
    <Wrapper>
      <TopRow>
        {topRowHeaders.map(header => (
          <div key={header}>{header}</div>
        ))}
      </TopRow>
      <TopRowStats>
        {topRowHeaders.map(header => (
          <div key={header}>{statsMap(header)}</div>
        ))}
      </TopRowStats>
      <BottomRow>
        {bottomRowHeaders.map(header => (
          <div key={header}>{header}</div>
        ))}
      </BottomRow>
      <BottomRowStats>
        {bottomRowHeaders.map(header => (
          <div key={header}>{statsMap(header)}</div>
        ))}
      </BottomRowStats>
    </Wrapper>
  );
};

BoxScoreTable.propTypes = {
  topRowHeaders: PropTypes.arrayOf(PropTypes.string),
  bottomRowHeaders: PropTypes.arrayOf(PropTypes.string),
  statsMap: PropTypes.func
};

export default BoxScoreTable;
