import React from "react";
import PropTypes from "prop-types";
import PositionList from "./PositionList";
import { PositionContainer } from "./PositionHelpers";

const Pitchers = ({ settings }) => (
  <PositionContainer>
    <PositionList
      droppableId="pitcher-list"
      header="Pitchers"
      maximum={settings.pitchers.pitchersMax}
      minimum={settings.pitchers.pitchersMin}
    />
  </PositionContainer>
);

Pitchers.propTypes = {
  settings: PropTypes.object
};

export default Pitchers;
