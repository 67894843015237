import React from "react";
import PropTypes from "prop-types";
import adaptToBCRTableCell from "./adaptToBCRTableCell";
import Moment from "moment";

const DocumentsTableUploadedCell = ({ document: { createdTs } }) => (
  <div>{createdTs ? Moment(createdTs).format("MM/DD/YYYY") : null}</div>
);

DocumentsTableUploadedCell.displayName = "DocumentsTableUploadedCell";

DocumentsTableUploadedCell.propTypes = {
  document: PropTypes.shape({
    createdTs: PropTypes.string.isRequired
  }).isRequired
};

export default adaptToBCRTableCell(DocumentsTableUploadedCell);
