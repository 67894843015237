import React, { useReducer } from "react";
import PropTypes from "prop-types";
import ProfileContext from "../contexts/ProfileContext";

const initialState = {
  currentlySelectedTeam: {},
  teams: [],
  profiles: [],
  isDirty: false,
  isSaving: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "initialize":
      return { ...state, ...action.profile, isDirty: false, isSaving: false };
    case "cleanForm":
    case "saveRequest":
      return { ...state, isSaving: true };
    case "saveSuccess":
      return { ...state, isDirty: false, isSaving: false };
    case "saveFailure":
      return { ...state, isSaving: false };
    case "setProfileInfo":
      return { ...state, isDirty: true, [action.key]: action.value };
    case "setProfileHistory":
      return { ...state, isDirty: false, profileHistory: action.value };
    case "setProfileCountry":
      return { ...state, isDirty: true, countryId: action.countryId, provinceId: null, stateId: null };
    case "setProfileOrganization":
      return { ...state, isDirty: true, orgId: action.orgId, clubId: action.clubId };
    case "setSelectedProfileTeams":
      return { ...state, teams: action.teams };
    case "setSelectedTeam":
      return { ...state, currentlySelectedTeam: action.currentlySelectedTeam };
    default:
      return state;
  }
};

const ProfileContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <ProfileContext.Provider value={{ state, dispatch }}>{children}</ProfileContext.Provider>;
};

ProfileContextProvider.propTypes = {
  children: PropTypes.object
};

export default ProfileContextProvider;
