import React, { useContext } from "react";
import { FormTitleWrapper, Title } from "./PlayerModalStyling";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import PositionAndCatcherBoxScore from "./PositionAndCatcherBoxScore";
import PitcherBoxScore from "./PitcherBoxScore";

const BoxScoreComponents = {
  POSITION: PositionAndCatcherBoxScore,
  CATCHER: PositionAndCatcherBoxScore,
  PITCHER: PitcherBoxScore
};

const BoxScore = () => {
  const playerReportModalContext = useContext(PlayerReportModalContext);

  const { eventPlayers, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];

  const BoxScoreComponent = BoxScoreComponents[currentEventPlayer.playerType];

  return (
    <>
      {currentEventPlayer.stats?.batting?.gamesPlayed || currentEventPlayer.stats?.pitching?.gamesPlayed ? (
        <div className="form-group">
          <Title>
            <FormTitleWrapper>Box Score</FormTitleWrapper>
          </Title>
          <BoxScoreComponent />
        </div>
      ) : null}
    </>
  );
};

export default BoxScore;
