import React, { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { NavHamburger, NavigationLogo } from "best-common-react";
import logo from "../../assets/logo.png";
import Theme from "../../Theme";
import MobileNavContext from "../../contexts/MobileNavContext";
import AuthContext from "../../contexts/AuthContext";
import BuildContext from "../../contexts/BuildContext";
import PropTypes from "prop-types";
import EnvironmentIndicator from "./EnvironmentIndicator";
import LoadingContext from "../../contexts/LoadingContext";
import MobileView from "../../components/temp/MobileView";
import DesktopTabletView from "../temp/DesktopTabletView";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const loadingLogo = css`
  animation: ${rotate} 2s infinite linear;
`;

const HorizontalNavHolder = styled.div`
  &&& {
    z-index: 1051;
    position: absolute;
    left: 0px;
    top: 22px;
    width: 72px;
  }
`;

const NavContainer = styled.div`
  &&& {
    background-color: white;
    width: 100vw;
    height: 100%;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  }
`;

const LogoHolder = styled.div`
  &&& {
    z-index: 1052;
    float: left;
  }
`;

const LogoLink = styled(Link)`
  &&& {
    padding: 0;
  }
`;

const Title = styled.div`
  &&& {
    @media (min-width: 1024px) {
      font-size: 1.5rem;
    }
    @media (max-width: 1023px) {
      font-size: 18px;
      margin-top: 5px;
    }
  }
  color: ${props => props.color};
  font-weight: bold;
`;

const LogoPaddingStyle = styled.div`
  &&& {
    @media (min-width: 1024px) {
      padding-right: 15px;
    }
    @media (max-width: 1023px) {
      padding-right: 5px;
    }
  }
`;

const LogoLoadingAnimation = styled.div`
  ${props => (props.loading === "true" ? loadingLogo : "")};
`;

const StyledChildren = styled.div.attrs(() => ({
  className: "ml-auto"
}))`
  &&& {
    @media (max-width: 1023px) {
      margin-bottom: 5px;
    }
  }
`;

const HorizontalNav = ({ children, title }) => {
  const mobileNavContext = useContext(MobileNavContext);
  const authContext = useContext(AuthContext);
  const buildContext = useContext(BuildContext);
  const { isLoading } = useContext(LoadingContext);

  let builds = [];
  if (buildContext.state.buildInfo.backendCommitId) {
    builds.push({ name: "B", build: buildContext.state.buildInfo.backendCommitId });
  }
  if (buildContext.state.buildInfo.frontendCommitId) {
    builds.push({ name: "F", build: buildContext.state.buildInfo.frontendCommitId });
  }

  return (
    <HorizontalNavHolder>
      <LogoHolder>
        <LogoLink className="" to="/">
          <LogoPaddingStyle>
            <LogoLoadingAnimation loading={isLoading.toString()}>
              <MobileView maxWidth={1023}>
                <NavigationLogo logo={logo} builds={builds} height={45}>
                  <EnvironmentIndicator />
                </NavigationLogo>
              </MobileView>
              <DesktopTabletView minWidth={1024}>
                <NavigationLogo logo={logo} builds={builds} height={73}>
                  <EnvironmentIndicator />
                </NavigationLogo>
              </DesktopTabletView>
            </LogoLoadingAnimation>
          </LogoPaddingStyle>
        </LogoLink>
      </LogoHolder>
      <NavContainer>
        <div className={"d-flex align-self-center"}>
          <Title color={Theme.wbcBlue}>{title}</Title>
          <StyledChildren>{children}</StyledChildren>
          <MobileView maxWidth={1023}>
            {authContext.state.loggedIn ? (
              <NavHamburger
                open={mobileNavContext.state.mobileNavOpen}
                toggle={mobileNavContext.state.toggleMobileNav}
                color={Theme.wbcBlue}
              />
            ) : null}
          </MobileView>
        </div>
      </NavContainer>
    </HorizontalNavHolder>
  );
};

HorizontalNav.propTypes = {
  children: PropTypes.object,
  title: PropTypes.string
};

HorizontalNav.defaultProps = {
  title: "Welcome"
};

export default HorizontalNav;
