import React from "react";
import HorizontalNav from "../components/navigation/HorizontalNav";

const InvalidPermissionsContainer = () => {
  return (
    <div>
      <HorizontalNav title={"Invalid Permissions"} />
      <div>You do not have valid permissions.</div>
      <div>Please contact the service desk.</div>
    </div>
  );
};

export default InvalidPermissionsContainer;
