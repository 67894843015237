import React from "react";
import PropTypes from "prop-types";
import DownloadDocumentsButton from "../elements/DownloadDocumentsButton";

const DocsFormatter = ({ row }) => {
  return (
    <>
      {row.hasDocuments ? (
        <DownloadDocumentsButton profileId={row.profileId} teamId={row.fedTeamId} clickableWidth="39px" />
      ) : null}
    </>
  );
};

DocsFormatter.propTypes = {
  row: PropTypes.object
};

export default DocsFormatter;
