import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";

const BodyContent = styled.div`
  margin-bottom: 100px;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const DiscardModalBody = ({ text }) => (
  <BodyContent>{text || "You have unsaved changes that will be lost"}</BodyContent>
);

const DiscardModalFooter = ({ onDiscard: onDiscardFn, discardText }) => {
  // context(s)
  const globalModalContext = useContext(GlobalModalContext);

  // function(s)
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });
  const onDiscard = () => {
    if (onDiscardFn) onDiscardFn();
    onClose();
  };

  return (
    <ButtonWrapper>
      <WBCPrimaryButton width={142} onClick={onDiscard}>
        {discardText || "Discard Changes"}
      </WBCPrimaryButton>
      <div className="pl-2">
        <WBCPrimaryOutlinedButton width={142} onClick={onClose}>
          Back
        </WBCPrimaryOutlinedButton>
      </div>
    </ButtonWrapper>
  );
};

DiscardModalBody.displayName = "BodyContent";
DiscardModalFooter.displayName = "FooterContent";

DiscardModalBody.propTypes = {
  text: PropTypes.elementType
};

DiscardModalFooter.propTypes = {
  onDiscard: PropTypes.func,
  discardText: PropTypes.elementType
};

export { DiscardModalBody, DiscardModalFooter };
