import BaseApi from "./BaseApi";

export default class ExportApi extends BaseApi {
  static exportProspectivePlayersList = (year, fedTeamId, sortFilterCol, sortFilterDir, playerFilter, transform) =>
    this.download(
      `getProspectivePlayersReport?wbcYear=${year}&fedTeamId=${fedTeamId}&sortFilterCol=${sortFilterCol}&sortFilterDir=${sortFilterDir}&playerFilter=${playerFilter}`,
      null,
      null,
      "blob",
      transform
    );

  static exportInterestListList = (year, fedTeamId, sortFilterCol, sortFilterDir, transform) =>
    this.download(
      `getInterestListPlayersReport?wbcYear=${year}&fedTeamId=${fedTeamId}&sortFilterCol=${sortFilterCol}&sortFilterDir=${sortFilterDir}`,
      null,
      null,
      "blob",
      transform
    );

  static exportAvailablePlayersListList = (year, tournamentType, fedTeamId, sortFilterCol, sortFilterDir, transform) =>
    this.download(
      `getAvailablePlayersListReport?wbcYear=${year}&tournamentType=${tournamentType}&fedTeamId=${fedTeamId}&sortFilterCol=${sortFilterCol}&sortFilterDir=${sortFilterDir}`,
      null,
      null,
      "blob",
      transform
    );

  static exportProvisionalRosterList = (year, fedTeamId, tournamentType, sortFilterCol, sortFilterDir, transform) =>
    this.download(
      `getProvisionalRosterReport?wbcYear=${year}&fedTeamId=${fedTeamId}&sortFilterCol=${sortFilterCol}&sortFilterDir=${sortFilterDir}&tournamentType=${tournamentType}`,
      null,
      null,
      "blob",
      transform
    );

  static exportFinalRoster = (year, fedTeamId, tournamentType, transform) =>
    this.download(
      `getFinalRosterReport?wbcYear=${year}&fedTeamId=${fedTeamId}&tournamentType=${tournamentType}`,
      null,
      null,
      "blob",
      transform
    );

  static exportUploadTemplate = transform => this.download(`getUploadTemplate`, null, null, "blob", transform);

  static exportFullPitchTrackerReport = (tournamentType, wbcYear) =>
    this.download(
      `getPitchTrackerReport?tournamentType=${tournamentType}&wbcYear=${wbcYear}`,
      null,
      null,
      "blob",
      (blob, headers) => ({
        blob,
        headers
      })
    );

  static exportTeamPitchTrackerReport = (fedTeamId, tournamentType, userTimezoneOffset) =>
    this.download(
      `getPitchTrackerReport?fedTeamId=${fedTeamId}&tournamentType=${tournamentType}&userTimezoneOffset=${userTimezoneOffset}`,
      null,
      null,
      "blob",
      (blob, headers) => ({
        blob,
        headers
      })
    );

  static exportMLBPlayerSummaries = (wbcYear, orgId, fedCountryId, requestStatus, transform) =>
    this.download(
      `getPlayerSummariesReport?wbcYear=${wbcYear}` +
        `${orgId !== null ? `&orgId=${orgId}` : ""}` +
        `${fedCountryId !== null ? `&fedCountryId=${fedCountryId}` : ""}` +
        `${requestStatus !== null ? `&requestStatus=${requestStatus}` : ""}`,
      null,
      null,
      "blob",
      transform
    );
}
