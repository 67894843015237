import Theme from "../../Theme";
import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const DisabledButton = styled.button.attrs(() => ({
  name: "disabled-button"
}))`
  border: 1px solid;
  cursor: ${props => (props.disabled ? "disabled" : "pointer")};
  ${props => (props.disabled ? "background-color: #f2f3f4 !important;" : "")}
  ${props => (props.disabled ? `color: ${props.theme.grey} !important;` : "")}
  ${props => (props.disabled ? "border-color: #c9c9c9 !important;" : "")}
`;

const PrimaryButton = styled(DisabledButton).attrs(() => ({
  className: "btn btn-primary",
  name: "primary-button"
}))`
  &&& {
    color: #ffffff;
    background-color: ${props => props.theme.primary};
    border-color: ${props => props.theme.primary};
  }
`;
const WBCPrimaryButtonStyled = styled(PrimaryButton).attrs(() => ({
  className: "justify-content-center d-flex",
  type: "submit"
}))`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.height}px`};
  border-radius: 4px;
  font-family: Helvetica;
  font-size: 13px;
  font-weight: bold;
  background-color: ${Theme.wbcBlue};
  border-color: ${Theme.wbcBlue};
  color: ${Theme.white};

  /* firefox only */
  @supports (-moz-appearance: none) {
    line-height: ${props => `${props.height - 2}px`};
  }
`;

const WBCPrimaryButton = ({ styles, children, disabled, ...props }) => (
  <WBCPrimaryButtonStyled style={styles} {...props} disabled={disabled}>
    {children}
  </WBCPrimaryButtonStyled>
);

WBCPrimaryButton.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  styles: PropTypes.object,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

WBCPrimaryButtonStyled.defaultProps = {
  width: 90,
  height: 26
};

export { WBCPrimaryButton, DisabledButton };
