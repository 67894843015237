import React, { useState, useContext, useEffect, useCallback } from "react";
import HorizontalNav from "../../components/navigation/HorizontalNav";
import TabManager from "../../components/tabs/TabManager";
import WBCLoadingButton from "../../components/elements/WBCLoadingButton";
import WBCPrimaryOutlinedButton from "../../components/elements/WBCPrimaryOutlinedButton";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import TournamentFormContext from "../../contexts/TournamentFormContext";
import UnsavedChangesPrompt from "../protected/UnsavedChangesPrompt";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import { useBOCdropdown } from "../hooks/useBOCdropdown";
import styled from "styled-components";
import { useDiscardChanges } from "../hooks/useDiscardChanges";

const NavButtonsStyle = styled.div.attrs(() => ({
  className: "d-flex mt-1"
}))`
  &&& {
    @media (min-width: 1024px) {
      padding-right: 15px;
    }

    @media (max-width: 1023px) {
      padding-left: 3px;
      padding-right: 0px;
    }
  }
`;

const TournamentComponent = () => {
  // state && context(s)
  const [asterisk, setAsterisk] = useState("");
  const [buttonToggle, toggleButtons] = useState(true);
  const { selectedYear } = useContext(WBCYearsContext);
  const tournamentFormContext = useContext(TournamentFormContext);
  const showDiscardModal = useDiscardChanges();

  // variable(s)
  const { dispatch: tournamentDispatch } = tournamentFormContext;
  const { onSave, onCancel, modalStatus } = tournamentFormContext.state;

  // function(s)
  const resetButtons = () => {
    toggleButtons(true);
    setAsterisk("");
  };

  const onFormDirty = useCallback(() => {
    toggleButtons(false);
    tournamentDispatch({ type: "setModalStatus", modalStatus: true });
    setAsterisk("*");
    tournamentDispatch({
      type: "setFormDirty",
      formDirty: true
    });
  }, [tournamentDispatch]);

  const cleanForm = useCallback(() => {
    resetButtons();
    tournamentDispatch({ type: "setModalStatus", modalStatus: false });
    tournamentDispatch({ type: "setFormDirty", formDirty: false });
  }, [tournamentDispatch]);

  const onSaveClicked = useCallback(() => {
    const promiseMaybe = onSave();
    cleanForm();
    return promiseMaybe;
  }, [cleanForm, onSave]);

  const onCancelClicked = () => {
    onCancel();
    cleanForm();
  };

  // effect(s)
  useBOCdropdown();

  useEffect(() => {
    tournamentDispatch({
      type: "setOnFormDirty",
      onFormDirty
    });
    tournamentDispatch({
      type: "setCleanForm",
      cleanForm
    });
  }, [tournamentDispatch, onFormDirty, cleanForm]);

  return (
    <div>
      <UnsavedChangesPrompt when={modalStatus} onSave={onSaveClicked} onCancel={onCancelClicked} />
      <HorizontalNav title={selectedYear ? selectedYear + asterisk : ""}>
        <NavButtonsStyle>
          <WBCAdminAccess>
            <div className="mr-2">
              <WBCLoadingButton onClick={onSaveClicked} disabled={buttonToggle} />
            </div>
          </WBCAdminAccess>
          <div>
            <WBCPrimaryOutlinedButton
              className="btn-md"
              onClick={() => showDiscardModal(onCancelClicked)}
              disabled={buttonToggle}
            >
              Cancel
            </WBCPrimaryOutlinedButton>
          </div>
        </NavButtonsStyle>
      </HorizontalNav>
      <TabManager resetButtons={resetButtons} />
    </div>
  );
};

export default TournamentComponent;
