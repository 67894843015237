import html2pdf from "html2pdf.js";

const DEFAULT_EXPORT_OPTIONS = {
  footer: {
    includePageNumbers: false,
    pageNumberFontSize: 10,
    includeExportedDateTime: false,
    exportedDateTimeFontSize: 8
  },
  html2pdf: {
    filename: "export.pdf",
    margin: 0.25,
    html2canvas: {
      scale: 2,
      logging: false // set to true for debugging
    },
    image: { type: "jpeg", quality: 1 },
    jsPDF: {
      unit: "in",
      format: "letter",
      orientation: "portrait"
    }
  }
};

export const addPageBreak = (parent, childrenClassName, pageBreakStyle) => {
  const pageBreakElements = parent.querySelectorAll(childrenClassName);
  pageBreakElements.forEach(el => {
    el.setAttribute("style", pageBreakStyle);
  });
};

export const cloneHtmlElementForPdfExport = id => {
  return document.getElementById(id).cloneNode(true);
};

export const exportHtmlElementToPdf = (htmlElement, opts) => {
  const combinedOpts = {
    footer: { ...DEFAULT_EXPORT_OPTIONS.footer, ...opts.footer },
    html2pdf: {
      ...DEFAULT_EXPORT_OPTIONS.html2pdf,
      ...opts.html2pdf,
      html2canvas: { ...DEFAULT_EXPORT_OPTIONS.html2pdf.html2canvas, ...opts.html2pdf.html2canvas },
      image: { ...DEFAULT_EXPORT_OPTIONS.html2pdf.image, ...opts.html2pdf.image },
      jsPDF: { ...DEFAULT_EXPORT_OPTIONS.html2pdf.jsPDF, ...opts.html2pdf.jsPDF }
    }
  };

  return html2pdf()
    .set(combinedOpts.html2pdf)
    .from(htmlElement)
    .toPdf()
    .get("pdf")
    .then(pdf => {
      if (combinedOpts.footer.includePageNumbers || combinedOpts.footer.includeExportedDateTime) {
        addFooterToPdf(pdf, combinedOpts.footer);
      }
    })
    .save();
};

const addFooterToPdf = (pdf, opts) => {
  const pdfTotalPages = pdf.internal.getNumberOfPages();
  const exportedDate = new Date();

  for (let i = 1; i <= pdfTotalPages; i++) {
    pdf.setPage(i);
    if (opts.includePageNumbers) {
      addPageNumbersToFooter(pdf, pdfTotalPages, opts.pageNumberFontSize);
    }
    if (opts.includeExportedDateTime) {
      addExportedOnToFooter(pdf, exportedDate, opts.exportedDateTimeFontSize);
    }
  }
};

const addExportedOnToFooter = (pdf, exportedDate, fontSize) => {
  const exportedTime = Intl.DateTimeFormat("en-US", {
    month: "numeric",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZoneName: "short"
  }).format(exportedDate);

  pdf.setFontSize(fontSize);
  pdf.text(
    `Exported on: ${exportedTime}`,
    xPdfCoordinate({ pdf, multiplier: 0.5 }),
    yPdfCoordinate({ pdf, offset: -0.1 }),
    "center"
  );
};

const addPageNumbersToFooter = (pdf, pdfTotalPages, fontSize) => {
  let pageCurrent = pdf.internal.getCurrentPageInfo().pageNumber;
  pdf.setFontSize(fontSize);
  pdf.text(
    "Page " + pageCurrent + " of " + pdfTotalPages,
    xPdfCoordinate({ pdf, multiplier: 0.5 }),
    yPdfCoordinate({ pdf, offset: -0.25 }),
    "center"
  );
};

const pdfCoordinate = ({ size, offset = 0.0, multiplier = 1.0 }) => {
  return (size + offset) * multiplier;
};

const xPdfCoordinate = ({ pdf, offset = 0.0, multiplier = 1.0 }) => {
  return pdfCoordinate({ size: pdf.internal.pageSize.getWidth(), offset, multiplier });
};

const yPdfCoordinate = ({ pdf, offset = 0.0, multiplier = 1.0 }) => {
  return pdfCoordinate({ size: pdf.internal.pageSize.getHeight(), offset, multiplier });
};
