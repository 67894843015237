import React, { useContext, useState } from "react";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import { useAlert } from "../../hooks/useAlert";
import EventReportApi from "../../../httpClients/EventReportApi";
import EventReportContext from "../../../contexts/clubcomm/EventReportContext";
import PropTypes from "prop-types";

const RemovePlayerModalFooter = ({ content }) => {
  const globalModalContext = useContext(GlobalModalContext);
  const eventReportContext = useContext(EventReportContext);
  const showAlert = useAlert();
  const [removingPlayer, setRemovingPlayer] = useState(false);

  const removePlayer = (player, eventReportId, teamId) => {
    if (!removingPlayer) {
      setRemovingPlayer(true);
      EventReportApi.removeEventReportPlayers(teamId, eventReportId, { ids: [player.id] })
        .then(() => {
          globalModalContext.dispatch({
            type: "closeModal"
          });
          showAlert("Player deleted", "success");
          EventReportApi.getEventReportPlayersInfo(teamId, eventReportId)
            .then(response => {
              eventReportContext.dispatch({
                type: "setEventPlayers",
                eventPlayers: response
              });
            })
            .catch(() => {
              showAlert("Error while refreshing event players", "danger");
            });
        })
        .catch(() => {
          showAlert("Error while removing player", "danger");
        });
    }
  };

  return (
    <div>
      <div className={"d-flex"}>
        <WBCPrimaryButton
          width={154}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            removePlayer(content.playerInfo, content.playerInfo.eventReportId, content.teamId);
          }}
        >
          Delete Player
        </WBCPrimaryButton>

        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              globalModalContext.dispatch({
                type: "closeModal"
              });
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

RemovePlayerModalFooter.propTypes = {
  content: PropTypes.object
};

RemovePlayerModalFooter.displayName = "FooterContent";

export default RemovePlayerModalFooter;
