import React, { useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import adaptToBCRTableCell from "./adaptToBCRTableCell";
import DocumentsContext from "../../contexts/DocumentsContext";

const mapLabelToOption = ({ labelId: value, text: label }) => ({ value, label });
const mapOptionToLabel = ({ value: labelId, label: text }) => ({ labelId, text });

const DocumentsTableLabelSelect = ({ document: { documentId, label }, labels }) => {
  const {
    actions: { updateLabel }
  } = useContext(DocumentsContext);

  const labelOptions = useMemo(() => labels.map(mapLabelToOption), [labels]);
  const selectedLabelOption = useMemo(() => label && mapLabelToOption(label), [label]);

  const onChange = useCallback(
    selectedLabelOption => {
      updateLabel({ documentId, label: mapOptionToLabel(selectedLabelOption) });
    },
    [documentId, updateLabel]
  );
  const onContainerClicked = useCallback(e => e.stopPropagation(), []);

  return (
    <div onClick={onContainerClicked}>
      <Select
        id="best-select-grid"
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable={false}
        isClearable={false}
        menuPlacement={"auto"}
        menuPortalTarget={document.body}
        options={labelOptions}
        value={selectedLabelOption}
        onChange={onChange}
      />
    </div>
  );
};

DocumentsTableLabelSelect.displayName = "DocumentsTableLabelSelect";

const labelPropTypes = PropTypes.shape({
  labelId: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired
});

DocumentsTableLabelSelect.propTypes = {
  document: PropTypes.shape({
    documentId: PropTypes.number.isRequired,
    label: labelPropTypes
  }).isRequired,
  labels: PropTypes.arrayOf(labelPropTypes).isRequired
};

const DocumentsTableLabelCell = ({ isReadOnly, document, document: { label }, ...rest }) =>
  isReadOnly ? <div>{label.text}</div> : <DocumentsTableLabelSelect document={document} {...rest} />;

DocumentsTableLabelCell.displayName = "DocumentsTableLabelCell";

DocumentsTableLabelCell.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    label: labelPropTypes
  }).isRequired
};

export default adaptToBCRTableCell(DocumentsTableLabelCell);
