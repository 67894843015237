import React, { useContext, useState, useEffect } from "react";
import { FormColumn2Wide, FormInput } from "best-common-react";
import { useTeamsByYear } from "../../hooks/useTeamsByYear";
import Moment from "moment";
import EventTypeConstants from "../../../constants/EventTypeConstants";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import AuthContext from "../../../contexts/AuthContext";
import styled from "styled-components";
import { minFormDate } from "../../../utils/DateUtils";

const Row = styled.div.attrs({
  className: "row"
})`
  text-align: left;
`;

const AddNonGameDayModalBody = () => {
  const globalModalContext = useContext(GlobalModalContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const authContext = useContext(AuthContext);
  const [teamIdValue, setTeamIdValue] = useState(null);

  const { dispatch } = globalModalContext;
  const { eventReport } = globalModalContext.state;
  const { teams } = selectedTeamContext.state;
  const { isBOC, isFedAdmin } = authContext.state;

  useTeamsByYear();

  useEffect(() => {
    if (teams) {
      const newTeamIdValue = teams.find(function(team) {
        if (eventReport.teamId && team.teamId === eventReport.teamId) {
          return team;
        }
        return null;
      });
      setTeamIdValue(newTeamIdValue);
    }
  }, [teams, eventReport.teamId]);

  return (
    <Row>
      {(isBOC || isFedAdmin) && (
        <FormColumn2Wide>
          <FormInput
            isDisabled={false}
            name={"teamId"}
            label={"Federation"}
            type={"select"}
            isRequired
            editable
            options={teams}
            value={teamIdValue}
            onChangeFn={selected => {
              dispatch({
                type: "setEventReport",
                eventReport: { ...eventReport, teamId: selected.teamId, selectedTeam: selected }
              });
            }}
          />
        </FormColumn2Wide>
      )}
      <FormColumn2Wide>
        <FormInput
          name="eventDate"
          type="date"
          timeZone="America/New_York"
          minDate={minFormDate}
          maxDate={Moment(new Date())}
          label={"Date"}
          value={eventReport.eventDate ? Moment(eventReport.eventDate, "MM/DD/YYYY") : eventReport.eventDate || ""}
          onChangeFn={value => {
            dispatch({
              type: "setEventReport",
              eventReport: { ...eventReport, eventDate: value }
            });
          }}
          autoComplete="off"
          isRequired
          editable
        />
      </FormColumn2Wide>
      <FormColumn2Wide>
        <FormInput
          isDisabled={false}
          name={"eventType"}
          label={"Type"}
          type={"select"}
          isRequired
          editable
          options={EventTypeConstants.EVENT_TYPE_OPTIONS}
          value={eventReport.eventType}
          onChangeFn={selected => {
            dispatch({
              type: "setEventReport",
              eventReport: { ...eventReport, eventType: selected, eventTypeId: selected.value }
            });
          }}
        />
      </FormColumn2Wide>
      <FormColumn2Wide>
        {eventReport.eventTypeId === EventTypeConstants.EVENT_TYPES.EXHIBITION.ID && (
          <FormInput
            editable
            isRequired
            name="opponent"
            label="Exhibition Opponent"
            type="text"
            value={eventReport.opponent}
            onChangeFn={value => {
              dispatch({
                type: "setEventReport",
                eventReport: { ...eventReport, opponent: value }
              });
            }}
          />
        )}
      </FormColumn2Wide>
    </Row>
  );
};

AddNonGameDayModalBody.displayName = "BodyContent";

export default AddNonGameDayModalBody;
