import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DraggableRow = styled.div`
  display: flex;
  min-height: 35px;
  border-bottom: 1px solid #ececec;

  :last-child {
    border-bottom: none;
  }

  :focus {
    outline: none;
  }

  background-color: ${({ isOpen }) => (isOpen ? "#f2f8fe" : undefined)};
  box-shadow: ${({ isOpen, isDragging }) => (isOpen || isDragging ? "0 1px 4px 0 rgba(0, 0, 0, 0.2)" : undefined)};
`;

const IconContainer = styled.div`
  cursor: pointer;
  color: ${props => (props.disabled ? props.theme.grey : props.theme.wbcBlue)};

  :focus {
    outline: none;
  }

  &&& {
    i {
      font-size: 1.32em;
    }
  }
`;

const EllipsisContainer = styled(IconContainer)`
  -webkit-text-stroke: 1px #ffffff;
`;

const DragIconContainer = styled(IconContainer)`
  cursor: grab;
  display: inline-block;
  margin: 0 8px;
`;

const ListText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.black};
`;

const BoldText = styled(ListText)`
  font-weight: bold;
  color: ${props => props.theme["dark-grey"]};
`;

const VerticalEllipses = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  return (
    <EllipsisContainer className={className} ref={ref} {...rest}>
      <i className="fas fa-ellipsis-v fa-lg"></i>
    </EllipsisContainer>
  );
});

const DragIcon = forwardRef((props, ref) => {
  const { className, ...rest } = props;
  return (
    <DragIconContainer ref={ref} className={className} {...rest}>
      <i className="fas fa-grip-lines fa-lg"></i>
    </DragIconContainer>
  );
});

VerticalEllipses.propTypes = {
  className: PropTypes.string
};

DragIcon.propTypes = {
  className: PropTypes.string
};

VerticalEllipses.displayName = "VerticalEllipses";
DragIcon.displayName = "DragIcon";

export { DraggableRow, VerticalEllipses, DragIcon, ListText, BoldText };
