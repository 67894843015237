import PropTypes from "prop-types";
import React from "react";
import BuildContextProvider from "../BuildContextProvider";
import WBCYearsContextProvider from "../WBCYearsContextProvider";
import FinalizedStatusContextProvider from "../FinalizedStatusContextProvider";
import BaseballContextProviderWrapper from "./BaseballContextProviderWrapper";
import GeographyContextProviderWrapper from "./GeographyContextProviderWrapper";
import ModalProviderWrapper from "./ModalProviderWrapper";
import ProfileContextProviderWrapper from "./ProfileContextProviderWrapper";
import SelectedTeamContextProviderWrapper from "./SelectedTeamContextProviderWrapper";
import UIContextProviderWrapper from "./UIContextProviderWrapper";

const ContextProviderWrapper = ({ children }) => {
  return (
    <BuildContextProvider>
      <WBCYearsContextProvider>
        <UIContextProviderWrapper>
          <ModalProviderWrapper>
            <GeographyContextProviderWrapper>
              <BaseballContextProviderWrapper>
                <SelectedTeamContextProviderWrapper>
                  <FinalizedStatusContextProvider>
                    <ProfileContextProviderWrapper>{children}</ProfileContextProviderWrapper>
                  </FinalizedStatusContextProvider>
                </SelectedTeamContextProviderWrapper>
              </BaseballContextProviderWrapper>
            </GeographyContextProviderWrapper>
          </ModalProviderWrapper>
        </UIContextProviderWrapper>
      </WBCYearsContextProvider>
    </BuildContextProvider>
  );
};

ContextProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ContextProviderWrapper;
