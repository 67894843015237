import React, { useContext, useEffect, useState } from "react";
import Moment from "moment";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import RouteConstants from "../../constants/RouteConstants";
import { MAIN, QUALIFIERS } from "../../constants/TournamentTypeConstants";
import AuthContext from "../../contexts/AuthContext";
import ClubCommDashboardContext from "../../contexts/ClubCommDashboardContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import EventReportApi from "../../httpClients/EventReportApi";
import Theme from "../../Theme";
import { properNoun } from "../../utils/StringUtils";
import WBCDataTable from "../data-table/WBCDataTable";
import { useAlert } from "../hooks/useAlert";
import CellText from "../roster/components/CellText";
import { sortList } from "./ClubCommSortHelper";

const StatusColorMapping = {
  Submitted: "green",
  Preliminary: "red",
  Published: "black"
};

const EventTable = styled(WBCDataTable)`
  .react-grid-Viewport {
    height: ${props => `${props.viewportHeight}px`};
  }
`;

const ClubCommDateFormatter = withRouter(({ history, row }) => {
  const openEventReport = () => {
    history.push(
      RouteConstants.EVENT_REPORT.replace(":teamId", row.teamId).replace(":eventReportId", row.eventReportId)
    );
  };

  return (
    <div style={{ color: Theme.wbcBlue, cursor: "pointer" }} onClick={openEventReport}>
      {formatDate(row.localEventDate ? row.localEventDate : row.eventDate)}
    </div>
  );
});

const ClubCommEventFormatter = ({ row }) => (
  <div className="d-flex">
    {row.eventType && row.eventType?.toUpperCase() === "WORKOUT" ? (
      <>
        <span style={{ fontWeight: "bold" }}>{row.teamName}&nbsp;</span> <span>Workout</span>
      </>
    ) : (
      <CellText>
        <span style={{ fontWeight: "bold" }}>{row.teamName}&nbsp;</span>
        <span>vs. {row.opponent}</span>
      </CellText>
    )}
  </div>
);

const ClubCommStatusFormatter = val => <div style={{ color: StatusColorMapping[val.value] }}>{val.value}</div>;

const ClubCommPlayerInfoFormatter = ({ row }) => (
  <div>
    {row.completedPlayerCount ? row.completedPlayerCount : 0} of {row.totalPlayerCount ? row.totalPlayerCount : 0}
  </div>
);

const ClubCommExhibitionFormatter = ({ row }) => (
  <div>
    {row.eventType && row.eventType?.toUpperCase() === "EXHIBITION" ? <i className="fas fa-check fa-lg"></i> : ""}
  </div>
);

const ClubCommReportTypeFormatter = ({ row: { tournamentTypeId } }) => (
  <div>{tournamentTypeId === 1 ? properNoun(QUALIFIERS) : properNoun(MAIN)}</div>
);

const columns = [
  {
    key: "eventDate",
    name: "Date",
    width: 100,
    sortable: true,
    formatter: ClubCommDateFormatter
  },
  {
    key: "event",
    name: "Event",
    width: 318,
    sortable: true,
    formatter: ClubCommEventFormatter
  },
  {
    key: "eventReportStatus",
    name: "Report Status",
    width: 175,
    sortable: true,
    formatter: ClubCommStatusFormatter
  },
  {
    key: "playerInfo",
    name: "MLB Player Info",
    width: 175,
    sortable: true,
    formatter: ClubCommPlayerInfoFormatter
  },
  {
    key: "eventType",
    name: "Exhibition",
    width: 125,
    sortable: true,
    formatter: ClubCommExhibitionFormatter
  },
  {
    key: "tournamentType",
    name: "Report Type",
    sortable: true,
    formatter: ClubCommReportTypeFormatter
  }
];

const formatDate = dateInput => (!dateInput ? "" : Moment(dateInput).format("MM/DD/YYYY"));

const ClubCommTable = () => {
  // hook(s)
  const clubCommDbContext = useContext(ClubCommDashboardContext);
  const authContext = useContext(AuthContext);
  const selectedTeamContext = useContext(SelectedTeamContext);
  const { selectedYear, currentYear } = useContext(WBCYearsContext);
  const [filteredEventReports, setFilteredEventReports] = useState([]);
  const [sortFilter, setSortFilter] = useState({
    dir: "DESC",
    col: "eventDate"
  });
  const showAlert = useAlert();

  // variable(s)
  const { isBOC, isFedAdmin, isFedUser, isTeamCoordinator } = authContext.state;
  const { eventReports, team, status, org, opponentFilter } = clubCommDbContext.state;
  const year = isBOC ? selectedYear : currentYear;

  // effect(s)
  useEffect(() => {
    if (eventReports !== []) setFilteredEventReports(eventReports);
  }, [eventReports]);

  useEffect(() => {
    if (year) {
      if (isFedUser || isTeamCoordinator) {
        if (selectedTeamContext.state.team.teamId) {
          EventReportApi.getEventReports(year, selectedTeamContext.state.team.teamId, status.value, org.value)
            .then(response => {
              if (
                opponentFilter.label !== null &&
                opponentFilter.label !== undefined &&
                opponentFilter.label !== "All Opponents"
              ) {
                response = response.filter(
                  report =>
                    report.opponent != null && report.opponent.toLowerCase() === opponentFilter.label.toLowerCase()
                );
              }
              setFilteredEventReports(sortList(sortFilter.col, sortFilter.dir, response, false));
            })
            .catch(() => {
              showAlert(`Error while loading event reports`, "danger");
            });
        }
      } else {
        EventReportApi.getEventReports(year, team.teamId, status.value, org.value)
          .then(response => {
            setFilteredEventReports(sortList(sortFilter.col, sortFilter.dir, response, true));
          })
          .catch(() => {
            showAlert(`Error while loading event reports`, "danger");
          });
      }
    }
  }, [
    year,
    team,
    status,
    org,
    opponentFilter,
    selectedTeamContext.state.team,
    isFedAdmin,
    isFedUser,
    isTeamCoordinator,
    showAlert,
    isBOC,
    sortFilter.col,
    sortFilter.dir
  ]);

  return (
    <div>
      {filteredEventReports.length > 0 ? (
        <EventTable
          useBCR
          columns={columns}
          enableRowSelect={null}
          rowScrollTimeout={null}
          headerRowHeight={40}
          minHeight={400}
          rowHeight={45}
          data={filteredEventReports}
          sortFunc={(col, dir) => {
            setFilteredEventReports(sortList(col, dir, filteredEventReports, isBOC || isFedAdmin));
            setSortFilter({ col, dir });
          }}
          sortColumn={sortFilter.col}
          sortDirection={sortFilter.dir}
        />
      ) : (
        <div>No results to display.</div>
      )}
    </div>
  );
};

const formatterProps = {
  row: PropTypes.object
};

ClubCommDateFormatter.propTypes = formatterProps;
ClubCommEventFormatter.propTypes = formatterProps;
ClubCommStatusFormatter.propTypes = formatterProps;
ClubCommExhibitionFormatter.propTypes = formatterProps;
ClubCommPlayerInfoFormatter.propTypes = formatterProps;
ClubCommReportTypeFormatter.propTypes = formatterProps;

export default ClubCommTable;
