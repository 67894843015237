import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import GlobalModalContext from "../contexts/GlobalModalContext";

const initialState = {
  // global
  isOpen: false,
  content: null,
  modalType: "",
  closeCallback: noop,

  // case specific

  // error modal
  overrideFn: noop,

  // are you sure modal
  discardChangesFn: noop,
  discardChangesButtonText: null,

  // unsaved changes modal
  saveChangesFn: noop,
  saveChangesFnArg: null,
  discardChangesFnArg: null,

  // add non-game day modal
  addEventReportFn: noop,
  eventReport: null,

  selectedPosition: "",

  // documents modal
  onSaveSuccess: noop,

  // confirm status update modal
  onConfirmUpdateFn: noop,
  updateStatusButtonText: null,
  cancellationReason: "",
  profileId: null,

  // confirm status update modal
  onConfirmRequestFn: noop,
  submitButtonText: null,
  submitProfile: null
};

/**
 * The cases here are for each modal type (i.e.
 * error, info, etc.)
 */
const GlobalModalContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "openErrorModal":
        return {
          ...state,
          isOpen: true,
          modalType: "errorModal",
          content: action.content,
          overrideFn: action.overrideFn,
          headerText: action.headerText || "Error",
          closeCallback: action.closeCallback
        };
      case "openWarningModal":
        return {
          ...state,
          isOpen: true,
          modalType: "warningModal",
          headerText: "Are you sure?",
          content: action.content,
          overrideFn: action.saveFn
        };
      case "openDenyEligibilityModal":
        return {
          ...state,
          isOpen: true,
          modalType: "denyEligibilityModal",
          content: action.content,
          headerText: action.headerText || "Player Eligibility",
          closeCallback: action.closeCallback
        };
      case "openAreYouSureModal":
        return {
          ...state,
          isOpen: true,
          modalType: "areYouSureModal",
          headerText: action.headerText || "Are you sure?",
          discardChangesFn: action.discardChangesFn,
          discardChangesButtonText: action.discardChangesButtonText,
          content: action.content
        };
      case "openNoEbisMatchModal":
        return {
          ...state,
          isOpen: true,
          modalType: "noEbisMatchModal",
          headerText: "No match found",
          content: action.content
        };
      case "openUnsavedChangesModal":
        return {
          ...state,
          isOpen: true,
          modalType: "unsavedChangesModal",
          headerText: "You have unsaved changes",
          saveChangesFn: action.saveChangesFn,
          saveChangesFnArg: action.saveChangesFnArg,
          discardChangesFn: action.discardChangesFn,
          discardChangesFnArg: action.discardChangesFnArg
        };
      case "openFinalizeRosterModal":
        return {
          ...state,
          isOpen: true,
          modalType: "finalizeRosterModal",
          content: action.content,
          headerText: action.headerText || "Finalize Roster"
        };
      case "openCatcherModal":
        return {
          ...state,
          isOpen: true,
          modalType: "catcherModal",
          content: action.content,
          headerText: "Error",
          closeCallback: noop
        };
      case "openPitcherModal":
        return {
          ...state,
          isOpen: true,
          modalType: "pitcherModal",
          content: action.content,
          headerText: "Error",
          closeCallback: noop
        };
      case "openInfielderModal":
        return {
          ...state,
          isOpen: true,
          modalType: "infielderModal",
          content: action.content,
          headerText: "Error",
          closeCallback: noop
        };
      case "openOutfielderModal":
        return {
          ...state,
          isOpen: true,
          modalType: "outfielderModal",
          content: action.content,
          headerText: "Error",
          closeCallback: noop
        };
      case "openAddNonGameDayModal":
        return {
          ...state,
          isOpen: true,
          modalType: "addNonGameDayModal",
          content: action.content,
          headerText: "Add Non-Game Day",
          addEventReportFn: action.addEventReportFn,
          eventReport: action.eventReport,
          closeCallback: noop
        };
      case "openPositionKeyModal":
        return {
          ...state,
          isOpen: true,
          modalType: "positionKeyModal",
          content: action.content,
          headerText: "Positions",
          closeCallback: noop
        };
      case "closeModal":
        return {
          ...state,
          isOpen: false,
          content: null,
          selectedPosition: ""
        };
      case "setCloseCallback":
        return {
          ...state,
          closeCallback: action.closeCallback
        };
      case "setSelectedPosition":
        return {
          ...state,
          selectedPosition: action.position
        };
      case "setEventReport":
        return {
          ...state,
          eventReport: action.eventReport
        };
      case "openAddPlayerModal":
        return {
          ...state,
          isOpen: true,
          modalType: "addPlayerModal",
          content: action.content,
          headerText: "Add Player",
          closeCallback: action.closeCallback
        };
      case "openRemovePlayerModal":
        return {
          ...state,
          isOpen: true,
          content: action.content,
          modalType: "removePlayerModal",
          headerText: "Delete Player"
        };
      case "openHelpAndSupportModal":
        return {
          ...state,
          isOpen: true,
          modalType: "helpAndSupportModal",
          headerText: "Help & Support"
        };
      case "openDiscardModal":
        return {
          ...state,
          isOpen: true,
          modalType: "discardModal",
          content: action.content,
          headerText: action.headerText || "Are you sure?",
          discardChangesFn: action.discardChangesFn
        };
      case "openDocumentsModal": {
        const { headerText, content, onSaveSuccess } = action.payload;
        return {
          ...state,
          isOpen: true,
          modalType: "documentsModal",
          headerText,
          content,
          onSaveSuccess: onSaveSuccess || noop
        };
      }
      case "openConfirmCancelModal": {
        return {
          ...state,
          isOpen: true,
          modalType: "confirmCancelModal",
          headerText: action.headerText || "Are you sure you want to cancel this request?",
          content: action.content,
          onConfirmUpdateFn: action.onConfirmUpdateFn,
          onCancel: action.onCancel,
          profileId: action.profileId,
          updateStatusButtonText: action.updateStatusButtonText
        };
      }
      case "openConfirmSubmitModal": {
        return {
          ...state,
          isOpen: true,
          modalType: "confirmSubmitModal",
          headerText: action.headerText || "Submit Request",
          content: action.content,
          onConfirmRequestFn: action.onConfirmRequestFn,
          onCancel: action.onCancel,
          submitProfile: action.submitProfile,
          updateStatusButtonText: action.updateStatusButtonText
        };
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <GlobalModalContext.Provider value={{ state, dispatch }}>{children}</GlobalModalContext.Provider>;
};

GlobalModalContextProvider.propTypes = {
  children: PropTypes.object
};

export default GlobalModalContextProvider;
