import styled from "styled-components";

const MobileFullWidth = styled.div`
  &&&& {
    @media (max-width: 575px) {
      width: 100%;
      margin-right: 0 !important;
    }
  }
`;

export default MobileFullWidth;
