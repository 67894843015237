import React, { useContext } from "react";
import PropTypes from "prop-types";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import { Input } from "best-common-react";

const PlayerEligibilitySearch = () => {
  const playerEligibilityContext = useContext(PlayerEligibilityContext);
  return (
    <div className="form-group">
      <Input
        placeholder="begin typing to search players"
        onChange={e => {
          playerEligibilityContext.dispatch({ type: "setPlayerFilter", playerFilter: e.target.value });
        }}
        type="text"
      ></Input>
    </div>
  );
};

PlayerEligibilitySearch.propTypes = {
  rootStore: PropTypes.object
};

export default PlayerEligibilitySearch;
