import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DownloadDocumentsButton from "../../elements/DownloadDocumentsButton";
import CommentsContent from "../../elements/CommentsContent";
import { MenuButton, MenuIcon, ContextMenu } from "../../elements/ContextMenu";

const FlyoutWrapper = styled.span`
  margin-top: 4px;
`;

const CommentsPopover = styled(ContextMenu)`
  padding: 8px 0px;
  background-color: ${props => props.theme.white};
`;

const PlayerHistoryIconFormatter = ({ row }) => {
  // hooks && variable(s)
  const targetRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { hasDocuments, fedTeamId, miscInfo, profileId } = row;

  // function(s)
  const toggleMenu = value => setIsOpen(value !== undefined ? value : !isOpen);

  return (
    <>
      {hasDocuments && (!miscInfo || miscInfo === "") ? (
        <DownloadDocumentsButton
          profileId={profileId}
          teamId={fedTeamId}
          clickableWidth="39px"
          documents={row.documents}
        />
      ) : null}
      {miscInfo && miscInfo !== "" ? (
        <FlyoutWrapper>
          <MenuButton ref={targetRef} onClick={() => setIsOpen(!isOpen)}>
            <MenuIcon iconName="fa-comment" />
          </MenuButton>
          <CommentsPopover targetRef={targetRef} isOpen={isOpen} toggleMenu={toggleMenu}>
            <CommentsContent content={miscInfo} />
          </CommentsPopover>
        </FlyoutWrapper>
      ) : null}
    </>
  );
};

PlayerHistoryIconFormatter.propTypes = {
  row: PropTypes.object
};

export default PlayerHistoryIconFormatter;
