import React, { useContext } from "react";
import styled from "styled-components";
import { AlertConstants, Icon } from "best-common-react";
import PropTypes from "prop-types";
import PlayerDocumentsApi from "../../httpClients/PlayerDocumentsApi";
import FileHelper from "../../httpClients/FileHelper";
import TournamentTypeContext from "../../contexts/TournamentTypeContext";
import { useAlert } from "../hooks/useAlert";

const DownloadButton = styled.button.attrs(() => ({
  type: "button",
  className: "btn btn-sm btn-link"
}))`
  cursor: pointer !important;
  &&& {
    width: ${props => (props.clickableWidth ? props.clickableWidth : "auto")};
    padding-top: 2px;
  }
`;

const DownloadIcon = styled(Icon)`
  cursor: pointer !important;
  &&& {
    font-size: 1.2em;
    -webkit-text-stroke: 1px #ffffff;

    i {
      cursor: pointer !important;
    }
  }
`;

const DownloadDocumentsButton = ({ className, clickableWidth, profileId, teamId, docLabelIds, documents }) => {
  // hook(s)
  const tournamentType = useContext(TournamentTypeContext);
  const showAlert = useAlert();

  const downloadDocuments = () => {
    const documentPromise = documents
      ? Promise.resolve(documents)
      : docLabelIds.length === 0
      ? PlayerDocumentsApi.getPlayerDocuments(profileId, teamId, tournamentType)
      : PlayerDocumentsApi.getPlayerDocumentsByLabel(profileId, teamId, docLabelIds, tournamentType);
    documentPromise
      .then(documents => {
        const promises = documents.map(document => {
          return PlayerDocumentsApi.downloadDocument(teamId, document.playerDocsId, profileId, (url, headers) => {
            return {
              url,
              headers
            };
          });
        });

        Promise.all(promises).then(function(dataList) {
          dataList.forEach((data, i) =>
            setTimeout(() => FileHelper.downloadFile(data.url, data.headers), 200 + i * 200)
          );
        });
      })
      .catch(response => showAlert(response.data.message, AlertConstants.TYPES.DANGER));
  };

  return (
    <DownloadButton className={className} clickableWidth={clickableWidth} onClick={downloadDocuments}>
      <DownloadIcon iconName="fa-paperclip" className="fa-lg" />
    </DownloadButton>
  );
};

DownloadDocumentsButton.propTypes = {
  profileId: PropTypes.number.isRequired,
  teamId: PropTypes.number.isRequired,
  clickableWidth: PropTypes.string,
  className: PropTypes.string,
  docLabelIds: PropTypes.array,
  documents: PropTypes.array
};

DownloadDocumentsButton.defaultProps = {
  docLabelIds: []
};

export default DownloadDocumentsButton;
