import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import PitchTrackerContext from "../../contexts/PitchTrackerContext";

const EditIcon = styled.i.attrs(() => ({
  className: "fas fa-pencil-alt"
}))`
  color: ${props => props.theme.wbcBlue};
  cursor: pointer;
  margin: 0.5rem;
  font-size: 1.16em;
`;

const TableHeaderEdit = styled.th`
  white-space: nowrap;
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  background-color: rgb(247, 247, 247);
  max-width: 2rem;
`;

const PitchTrackerTableEditRowFormatter = ({ profileId, isFinalRosterPitcher }) => {
  const {
    state: { editPlayerMode },
    actions: { pitchCountsEditPlayerMode, pitchCountsEditPlayerProfileId }
  } = useContext(PitchTrackerContext);

  const handleEditPlayer = useCallback(
    profileId => {
      pitchCountsEditPlayerMode(true);
      pitchCountsEditPlayerProfileId(profileId);
    },
    [pitchCountsEditPlayerMode, pitchCountsEditPlayerProfileId]
  );

  return (
    <TableHeaderEdit>
      {!isFinalRosterPitcher && !editPlayerMode ? <EditIcon onClick={() => handleEditPlayer(profileId)} /> : <></>}
    </TableHeaderEdit>
  );
};

PitchTrackerTableEditRowFormatter.propTypes = {
  profileId: PropTypes.number,
  isFinalRosterPitcher: PropTypes.bool
};

export default PitchTrackerTableEditRowFormatter;
