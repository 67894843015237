import React, { useReducer, useState, useMemo, useEffect } from "react";
import EmailNotificationsContext from "../contexts/EmailNotificationsContext";
import PropTypes from "prop-types";

const DEFAULT_NOTIFICATION_TYPE_FILTER = { label: "All Notifications", value: null };
const DEFAULT_COUNTRY_FILTER = { label: "All Federations", value: null };
const DEFAULT_STATUS_FILTER = { label: "All Statuses", value: null };

const INITIAL_STATE = {
  emails: [],
  emailIdsToSend: [],
  isSending: false,
  notificationFilter: DEFAULT_NOTIFICATION_TYPE_FILTER,
  countryFilter: DEFAULT_COUNTRY_FILTER,
  statusFilter: DEFAULT_STATUS_FILTER,
  federations: [],
  nameSearchFilter: null,
  sortFilters: {
    col: "createdTs",
    dir: "DESC"
  }
};

const EmailNotificationsContextProvider = ({ children }) => {
  const [emails, setEmails] = useState(INITIAL_STATE.emails);
  const [emailsDisplayed, setEmailsDisplayed] = useState(INITIAL_STATE.emails);
  const [emailIdsToSend, setEmailIdsToSend] = useState(INITIAL_STATE.emailIdsToSend);
  const [isSending, setIsSending] = useState(INITIAL_STATE.isSending);
  const [notificationFilter, setNotificationFilter] = useState(INITIAL_STATE.notificationFilter);
  const [federations, setFederations] = useState(INITIAL_STATE.insuranceStatuses);
  const [nameSearchFilter, setNameSearchFilter] = useState(INITIAL_STATE.nameSearchFilter);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);
  const [countryFilter, setCountryFilter] = useState(INITIAL_STATE.countryFilter);
  const [statusFilter, setStatusFilter] = useState(INITIAL_STATE.statusFilter);

  const reducer = (state, action) => {
    switch (action.type) {
      case "sortEmails":
        setEmails(action.emails);
        return {
          ...state,
          sortFilters: {
            ...state.sortFilters,
            col: action.col,
            dir: action.dir
          },
          emails: [...action.emails]
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  const filterByStatus = email => {
    switch (statusFilter.value) {
      // all sent notifs
      case 0:
        return email.sentToFed || email.sentToClub;
      case 1:
        return email.sentToFed;
      case 2:
        return email.sentToClub;
      case 3:
        return !email.sentToFed && !email.sentToClub;
      case null:
        return true;
      default:
        return false;
    }
  };

  // change this to useEffect
  const filteredEmails = useMemo(() => {
    return emails.filter(
      email =>
        (!nameSearchFilter ||
          email?.firstName?.toLowerCase().includes(nameSearchFilter?.toLowerCase()) ||
          email?.lastName?.toLowerCase().includes(nameSearchFilter?.toLowerCase())) &&
        (notificationFilter.value == null || email.notificationTypeName == notificationFilter.value) &&
        (countryFilter.value == null || email.federation == countryFilter.label) &&
        (statusFilter.value == null || filterByStatus(email))
    );
  }, [emails, notificationFilter, nameSearchFilter, countryFilter, statusFilter]);

  return (
    <EmailNotificationsContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        emails,
        setEmails,
        emailIdsToSend,
        setEmailIdsToSend,
        isSending,
        setIsSending,
        notificationFilter,
        setNotificationFilter,
        federations,
        setFederations,
        nameSearchFilter,
        setNameSearchFilter,
        filteredEmails,
        countryFilter,
        setCountryFilter,
        statusFilter,
        setStatusFilter
      }}
    >
      {children}
    </EmailNotificationsContext.Provider>
  );
};

EmailNotificationsContextProvider.propTypes = {
  children: PropTypes.object
};

export default EmailNotificationsContextProvider;
