import React, { useReducer } from "react";
import PropTypes from "prop-types";
import GameReportAddPlayersContext from "../../contexts/clubcomm/GameReportAddPlayersContext";

const initialState = {
  prospectivePlayers: [],
  selectedProspectivePlayer: undefined,
  selectedPosition: undefined,
  positionDropdownEnabled: false,
  provisionalPlayers: []
};

const GameReportAddPlayersContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setProspectivePlayers":
        return { ...state, prospectivePlayers: action.prospectivePlayers };
      case "setSelectedProspectivePlayer":
        return { ...state, selectedProspectivePlayer: action.selectedProspectivePlayer, selectedPosition: undefined };
      case "setPositionDropdownEnabled":
        return { ...state, positionDropdownEnabled: action.positionDropdownEnabled };
      case "setSelectedPosition":
        return { ...state, selectedPosition: action.selectedPosition };
      case "setProvisionalPlayers":
        return { ...state, provisionalPlayers: action.provisionalPlayers };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <GameReportAddPlayersContext.Provider value={{ state, dispatch }}>{children}</GameReportAddPlayersContext.Provider>
  );
};

GameReportAddPlayersContextProvider.propTypes = {
  children: PropTypes.object
};

export default GameReportAddPlayersContextProvider;
