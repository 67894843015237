import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RouteConstants from "../../constants/RouteConstants";
import useQuery from "../hooks/useQuery";

const NavItemsList = styled.ul`
  margin: 0;
  padding: 4px 0 8px 0;
  background-color: ${props => props.theme.mlbDarkNavy};
`;
const NavItem = styled.li``;
const NavItemLink = styled(Link)`
  padding: 0 48px 2px 12px;
  font-size: 14px;
  color: ${props => props.theme.white};

  &:hover {
    text-decoration: none;
  }
`;

const WBCYearsNavMenu = ({ years }) => {
  const query = useQuery();

  return (
    <NavItemsList>
      {years.map(({ year }) => {
        query.set("year", year);
        return (
          <NavItem key={year}>
            <NavItemLink to={`${RouteConstants.FEDERATION_TEAMS}?${query.toString()}`}>{year}</NavItemLink>
          </NavItem>
        );
      })}
    </NavItemsList>
  );
};

WBCYearsNavMenu.propTypes = {
  years: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number.isRequired
    })
  ).isRequired
};

export default WBCYearsNavMenu;
