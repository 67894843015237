import React from "react";
import PropTypes from "prop-types";

const ProfileHistoryDescriptionFormatter = ({ row }) => {
  let { description, documents } = row;

  if (description.includes("uploaded")) {
    // get all the labels in description
    const labels = description.split(",");
    labels[labels.length - 1] = labels[labels.length - 1].replace(" uploaded", "");

    // get all the labels in the documents
    const docLabels = documents.flatMap(doc => doc.labels).map(label => label.text);

    // compare the 2 sets of labels
    description = "";
    labels.forEach((label, index) => {
      label = label.trim();

      if (index !== 0) {
        description += ", ";
      }

      const labelIndex = docLabels.indexOf(label);
      if (labelIndex !== -1) {
        description += `${label}`;
        docLabels.splice(labelIndex, 1); // only want to find the label at specific index once
      } else if (docLabels.length === 1) {
        description += ` ${label} (changed to ${docLabels[0]})`;
      } else {
        description += ` ${label} (deleted)`;
      }
    });
    description += " uploaded";
  }

  return <>{description}</>;
};

ProfileHistoryDescriptionFormatter.propTypes = {
  row: PropTypes.shape({
    description: PropTypes.string.isRequired,
    documents: PropTypes.arrayOf(
      PropTypes.shape({
        labels: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string.isRequired
          })
        )
      })
    ).isRequired
  }).isRequired
};

export default ProfileHistoryDescriptionFormatter;
