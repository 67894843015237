import React, { useReducer } from "react";
import PropTypes from "prop-types";
import StateAndProvinceContext from "../contexts/StateAndProvinceContext";

const initialState = {
  states: [],
  provinces: [],
  stateDropdown: [],
  provinceDropdown: [],
  stateIdToName: {},
  provinceIdToName: {}
};

const StateAndProvinceContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "initializeStates":
        return {
          ...state,
          stateDropdown: action.stateDropdown,
          stateIdToName: action.stateIdToName
        };
      case "initializeProvinces":
        return {
          ...state,
          provinceDropdown: action.provinceDropdown,
          provinceIdToName: action.provinceIdToName
        };
      case "setStates":
        return { ...state, states: action.states };
      case "setProvinces":
        return { ...state, provinces: action.provinces };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <StateAndProvinceContext.Provider value={{ state, dispatch }}>{children}</StateAndProvinceContext.Provider>;
};

StateAndProvinceContextProvider.propTypes = {
  children: PropTypes.object
};

export default StateAndProvinceContextProvider;
