import React, { useReducer, useMemo, useState } from "react";
import PropTypes from "prop-types";
import MLBPlayerRequestContext from "../contexts/MLBPlayerRequestContext";

const DEFAULT_REQUEST_STATUS_FILTER = { label: "All", value: null };

const INITIAL_STATE = {
  playerRequests: [],
  requestStatusFilter: DEFAULT_REQUEST_STATUS_FILTER,
  playerStatuses: [],
  nameSearchFilter: null,
  shouldFetchPlayerRequests: false,
  sortFilters: {
    col: "lastName",
    dir: "ASC"
  }
};

const MLBPlayerRequestContextProvider = ({ children }) => {
  const [playerRequests, setPlayerRequests] = useState(INITIAL_STATE.playerRequests);
  const [requestStatusFilter, setRequestStatusFilter] = useState(INITIAL_STATE.requestStatusFilter);
  const [playerStatuses, setPlayerStatuses] = useState(INITIAL_STATE.playerStatuses);
  const [nameSearchFilter, setNameSearchFilter] = useState(INITIAL_STATE.nameSearchFilter);
  const [shouldFetchPlayerRequests, setShouldFetchPlayerRequests] = useState(INITIAL_STATE.shouldFetchPlayerRequests);
  const [sortFilters, setSortFilters] = useState(INITIAL_STATE.sortFilters);

  const filteredPlayerRequests = useMemo(() => {
    return playerRequests.filter(
      player =>
        (!nameSearchFilter ||
          player?.firstName?.toLowerCase().includes(nameSearchFilter?.toLowerCase()) ||
          player?.lastName?.toLowerCase().includes(nameSearchFilter?.toLowerCase())) &&
        (requestStatusFilter.value == null || player.requestStatus == requestStatusFilter.label)
    );
  }, [playerRequests, requestStatusFilter, nameSearchFilter]);

  const reducer = (state, action) => {
    switch (action.type) {
      case "sortPlayers":
        setPlayerRequests(action.playerRequests);
        return {
          ...state,
          sortFilters: {
            ...state.sortFilters,
            col: action.col,
            dir: action.dir
          },
          playerRequests: [...action.playerRequests]
        };
      default:
        return { ...state };
    }
  };
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <MLBPlayerRequestContext.Provider
      value={{
        state,
        dispatch,
        sortFilters,
        playerRequests,
        setPlayerRequests,
        requestStatusFilter,
        setRequestStatusFilter,
        playerStatuses,
        setPlayerStatuses,
        nameSearchFilter,
        setNameSearchFilter,
        filteredPlayerRequests,
        shouldFetchPlayerRequests,
        setShouldFetchPlayerRequests
      }}
    >
      {children}
    </MLBPlayerRequestContext.Provider>
  );
};

MLBPlayerRequestContextProvider.propTypes = {
  children: PropTypes.object
};

export default MLBPlayerRequestContextProvider;
