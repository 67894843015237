import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { AlertConstants } from "best-common-react";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { useAlert } from "../hooks/useAlert";
import DocumentsContext from "../../contexts/DocumentsContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import PlayerDocumentsApi from "../../httpClients/PlayerDocumentsApi";
import { getIsValid, getIsDirty, getSaveDocumentsRequest } from "../../selectors/DocumentSelectors";

const defaultErrorMessage = "Unexpected error uploading documents.";

const DocumentsModalFooter = ({ onSaveSuccess }) => {
  const {
    state: documentsState,
    state: {
      profile: { profileId, fedTeamId }
    }
  } = useContext(DocumentsContext);
  const { dispatch: globalModalDispatch } = useContext(GlobalModalContext);

  const [savingDocuments, setSavingDocuments] = useState(false);

  const isValid = getIsValid(documentsState);
  const isDirty = getIsDirty(documentsState);

  // TODO: useAlert
  const showAlert = useAlert();
  const addSuccessAlert = useCallback(() => showAlert("Documents saved"), [showAlert]);
  const addErrorAlert = useCallback(message => showAlert(message || defaultErrorMessage, AlertConstants.TYPES.DANGER), [
    showAlert
  ]);
  const onClose = useCallback(() => globalModalDispatch({ type: "closeModal" }), [globalModalDispatch]);
  const onSaveSuccessResponse = useCallback(
    ({ status, message }) => {
      switch (status) {
        case 200:
          addSuccessAlert();
          break;
        case 202:
        default:
          addErrorAlert(message);
          break;
      }

      onSaveSuccess(documentsState);
      onClose();
    },
    [addSuccessAlert, addErrorAlert, onClose, onSaveSuccess, documentsState]
  );
  const onSaveErrorResponse = useCallback(({ message }) => addErrorAlert(message), [addErrorAlert]);
  const onSave = useCallback(() => {
    if (!savingDocuments) {
      setSavingDocuments(true); // doesn't need to be reset in success case because modal closes
      PlayerDocumentsApi.uploadDocuments(profileId, fedTeamId, getSaveDocumentsRequest(documentsState)).then(
        onSaveSuccessResponse,
        () => {
          setSavingDocuments(false);
          onSaveErrorResponse();
        }
      );
    }
  }, [
    documentsState,
    profileId,
    fedTeamId,
    onSaveSuccessResponse,
    onSaveErrorResponse,
    savingDocuments,
    setSavingDocuments
  ]);
  const onCancelClick = useCallback(() => {
    if (isDirty) {
      globalModalDispatch({
        type: "openUnsavedChangesModal",
        saveChangesFn: onSave,
        discardChangesFn: onClose
      });
    } else {
      onClose();
    }
  }, [isDirty, globalModalDispatch, onClose, onSave]);

  return (
    <div className={"d-flex"}>
      <WBCPrimaryButton styles={{ width: "142px" }} disabled={!isDirty || !isValid} onClick={onSave}>
        Save Documents
      </WBCPrimaryButton>
      <div className={"pl-2"}>
        <WBCPrimaryOutlinedButton styles={{ width: "142px" }} onClick={onCancelClick}>
          Cancel
        </WBCPrimaryOutlinedButton>
      </div>
    </div>
  );
};

// MUST BE "FooterContent" because of WBCModal
DocumentsModalFooter.displayName = "FooterContent";

DocumentsModalFooter.propTypes = {
  onSaveSuccess: PropTypes.func.isRequired
};

export default DocumentsModalFooter;
