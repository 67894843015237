import React, { useContext } from "react";
import styled from "styled-components";
import WBCPrimaryOutlinedButton from "../../../elements/WBCPrimaryOutlinedButton";
import PropTypes from "prop-types";
import GlobalModalContext from "../../../../contexts/GlobalModalContext";

const BodyContent = styled.div`
  text-align: left;
`;

const ModalText = styled.div`
  font-family: Helvetica;
  font-size: 14px;
  line-height: 1.71;
  color: ${props => props.theme["dark-grey"]};
`;

const Bold = styled.span`
  font-weight: bold;
`;

const NoEbisMatchFoundModalBody = ({ ebisId }) => (
  <BodyContent>
    <ModalText>
      The EBIS ID <Bold>{ebisId}</Bold> does not exist.
    </ModalText>
  </BodyContent>
);

const NoEbisMatchFoundModalFooter = () => {
  const globalModalContext = useContext(GlobalModalContext);

  return (
    <WBCPrimaryOutlinedButton
      onClick={event => {
        event.stopPropagation();
        globalModalContext.dispatch({ type: "closeModal" });
      }}
    >
      Back
    </WBCPrimaryOutlinedButton>
  );
};

NoEbisMatchFoundModalBody.displayName = "BodyContent";
NoEbisMatchFoundModalFooter.displayName = "FooterContent";

NoEbisMatchFoundModalBody.propTypes = {
  ebisId: PropTypes.string
};

export { NoEbisMatchFoundModalBody, NoEbisMatchFoundModalFooter };
