import React, { useState, useEffect } from "react";
import WBCModal from "../elements/WBCModal";
import { UnsavedChangesModalBodyContent, UnsavedChangesModalFooterContent } from "../elements/UnsavedChangesModal";
import { Prompt } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../history/history";

const UnsavedChangesPrompt = ({ when, onSave, onCancel }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmedNav, setConfirmedNav] = useState(false);
  const [targetLocation, setTargetLocation] = useState("");
  const [saveClicked, setSavedClicked] = useState(false);
  const [cancelClicked, setCancelClicked] = useState(false);

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNav) {
      setTargetLocation(nextLocation.pathname);
      setModalOpen(true);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (confirmedNav) {
      if (saveClicked) {
        const promiseMaybe = onSave();
        if (promiseMaybe instanceof Promise) {
          promiseMaybe.then(() => {
            history.push(targetLocation);
          });
        } else {
          history.push(targetLocation);
        }
      } else if (cancelClicked) {
        onCancel();
        history.push(targetLocation);
      }

      setConfirmedNav(false);
      setModalOpen(false);
    }
  }, [confirmedNav, saveClicked, cancelClicked, onSave, onCancel, targetLocation]);

  return (
    <div>
      <Prompt when={when} message={handleBlockedNavigation} />
      <WBCModal
        isOpen={modalOpen}
        toggleModal={setModalOpen}
        headerText={"You have unsaved changes"}
        onClose={() => {}}
      >
        <UnsavedChangesModalBodyContent />
        <UnsavedChangesModalFooterContent
          onSave={() => {
            setConfirmedNav(true);
            setSavedClicked(true);
          }}
          onCancel={() => {
            setConfirmedNav(true);
            setCancelClicked(true);
          }}
        />
      </WBCModal>
    </div>
  );
};

UnsavedChangesPrompt.propTypes = {
  when: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UnsavedChangesPrompt;
