import React, { useContext, useRef } from "react";
import styled from "styled-components";
import { FormTitleWrapper, Title } from "./PlayerModalStyling";
import { BestLabel, TextArea } from "best-common-react";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import WBCAccessControl from "../../../protected/WBCAccessControl";
import EventReportStatusConstants from "../../../../constants/EventReportStatusConstants";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";

const OffensiveContainerStyle = styled.div.attrs(() => ({
  className: "form-group"
}))`
  text-align: left;
  width: 100%;

  &&&& {
    textarea {
      :disabled {
        color: black;
      }
    }
  }
`;

const DefensiveContainerStyle = styled.div.attrs(() => ({
  className: "form-group"
}))`
  text-align: left;
  width: 100%;

  &&&& {
    textarea {
      :disabled {
        color: black;
      }
    }
  }
`;

const PlayerComments = () => {
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const eventReportContext = useContext(EventReportContext);
  const { eventReport } = eventReportContext.state;
  const playerReportModalDispatch = playerReportModalContext.dispatch;
  const { eventPlayers, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];
  const { comments } = currentEventPlayer;

  const offTextArea = useRef(null);
  const defTextArea = useRef(null);

  const formDirtyFn = () => {
    playerReportModalDispatch({
      type: "setFormDirty",
      formDirty: true
    });
  };

  return (
    <div>
      <Title>
        <FormTitleWrapper>Player Comments</FormTitleWrapper>
      </Title>
      <WBCAccessControl
        bocOnly={
          eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
          eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
        }
        input={isDisabled => (
          <>
            {currentEventPlayer.playerType === "PITCHER" ? (
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <DefensiveContainerStyle>
                    <BestLabel required={!isDisabled}>Results & Comments</BestLabel>
                    <TextArea
                      ref={defTextArea}
                      disabled={isDisabled}
                      onChange={() => {
                        playerReportModalDispatch({
                          type: "setDefComments",
                          defensive: defTextArea.current.value || ""
                        });
                        formDirtyFn();
                      }}
                      value={comments && comments.defensive ? comments.defensive : ""}
                    />
                  </DefensiveContainerStyle>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <OffensiveContainerStyle>
                    <BestLabel required={!isDisabled}>Offensive</BestLabel>
                    <TextArea
                      ref={offTextArea}
                      disabled={isDisabled}
                      onChange={() => {
                        playerReportModalDispatch({
                          type: "setOffComments",
                          offensive: offTextArea.current.value || ""
                        });
                        formDirtyFn();
                      }}
                      value={comments && comments.offensive ? comments.offensive : ""}
                    />
                  </OffensiveContainerStyle>
                </div>
                <div className="col-md-6 col-sm-12">
                  <DefensiveContainerStyle>
                    <BestLabel required={!isDisabled}>Defensive</BestLabel>
                    <TextArea
                      ref={defTextArea}
                      disabled={isDisabled}
                      onChange={() => {
                        playerReportModalDispatch({
                          type: "setDefComments",
                          defensive: defTextArea.current.value || ""
                        });
                        formDirtyFn();
                      }}
                      value={comments && comments.defensive ? comments.defensive : ""}
                    />
                  </DefensiveContainerStyle>
                </div>
              </div>
            )}
          </>
        )}
      />
    </div>
  );
};

export default PlayerComments;
