import styled from "styled-components";

export const EmailNotificationsContainer = styled.div.attrs(() => ({
  className: "p-4"
}))`
  text-align: left;
  height: 80vh;
`;

export const FiltersContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start align-items-center"
}))`
  flex-direction: row;
  width: 80%;
  space-between: 1rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }
  margin-bottom: 1.5rem;
`;
export const SendContainer = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))`
  flex-direction: row;
  space-between: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const SendContents = styled.div.attrs(() => ({
  className: "d-flex justify-content-start"
}))`
  justify-content: center;
  margin-top: 0.1rem;
  gap: 0.5rem;
  font-size: 0.9rem;
  vertical-align: bottom;
`;

export const Filter = styled.div.attrs(() => ({
  className: "col-xs col-md-3 pl-0"
}))`
  @media (max-width: 767px) {
    margin-bottom: 0.75rem;
  }
`;
export const YearFilter = styled.div`
  width: 10rem;
  margin-right: 1rem;
`;
