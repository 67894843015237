import { BestTitle, FormColumn2Wide, FormInput, BestLabel } from "best-common-react";
import styled from "styled-components";
import React, { useContext } from "react";
import CountryContext from "../../contexts/CountryContext";
import ProfileContext from "../../contexts/ProfileContext";
import StateAndProvinceContext from "../../contexts/StateAndProvinceContext";
import WBCAccessControl from "../protected/WBCAccessControl";
import { FormTitleWrapper } from "./shared/ProfileComponents";
import UncontrolledFormInput from "../elements/UncontrolledFormInput";
import UncontrolledInput from "../elements/UncontrolledInput";
import ProfileConstants from "../../constants/ProfileConstants";

const StreetOneFormInput = styled(UncontrolledFormInput)`
  &&& {
    margin-bottom: 0;
  }
`;

const AddressTypeOptions = [
  { label: "Home", value: "Home" },
  { label: "Other", value: "Other" }
];

const ProfileAddressInfoForm = () => {
  // hooks
  const countryContext = useContext(CountryContext);
  const profileContext = useContext(ProfileContext);
  const stateAndProvinceContext = useContext(StateAndProvinceContext);

  // variables
  const { countryIdToName, countryDropdown } = countryContext.state;
  const {
    addressType,
    streetAddress1,
    streetAddress2,
    streetAddress3,
    city,
    stateId,
    provinceId,
    countryId,
    zipCode
  } = profileContext.state;
  const { stateIdToName, stateDropdown, provinceDropdown, provinceIdToName } = stateAndProvinceContext.state;

  // address
  const country = countryIdToName[countryId];
  const zipCodeStatus = ProfileConstants.zipCodeCountries.has(country);

  // functions
  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  return (
    <>
      <BestTitle>
        <FormTitleWrapper>Address</FormTitleWrapper>
      </BestTitle>
      <WBCAccessControl
        input={isDisabled => (
          <div className="row">
            <FormColumn2Wide>
              <FormInput
                editable
                name="addressTypeDropdown"
                label="Address Type"
                type="select"
                isDisabled={isDisabled}
                options={AddressTypeOptions}
                value={{
                  value: addressType || "",
                  label: addressType || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "addressType")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                editable
                name="countryDropdown"
                label="Country"
                type="select"
                isDisabled={isDisabled}
                options={countryDropdown}
                value={{
                  value: countryId,
                  label: countryIdToName[countryId] || ""
                }}
                onChangeFn={value => {
                  profileContext.dispatch({
                    type: "setProfileCountry",
                    countryId: value.value
                  });
                }}
              />
            </FormColumn2Wide>
          </div>
        )}
      />
      <div className="row">
        <FormColumn2Wide>
          <WBCAccessControl
            input={disabled => (
              <div className="form-group">
                {disabled ? (
                  <>
                    <BestLabel>Street Address</BestLabel>
                    <UncontrolledInput
                      id="d-street-address-1"
                      disabled={disabled}
                      value={streetAddress1 || ""}
                      postChange={value => onProfileChange(value, "streetAddress1")}
                    />
                  </>
                ) : (
                  <StreetOneFormInput
                    id="street-address-1"
                    label="Street Address"
                    disabled={disabled}
                    value={streetAddress1 || ""}
                    postChange={value => onProfileChange(value, "streetAddress1")}
                  />
                )}
                <UncontrolledInput
                  disabled={disabled}
                  value={streetAddress2 || ""}
                  postChange={value => onProfileChange(value, "streetAddress2")}
                />
                <UncontrolledInput
                  disabled={disabled}
                  value={streetAddress3 || ""}
                  postChange={value => onProfileChange(value, "streetAddress3")}
                />
              </div>
            )}
          />
        </FormColumn2Wide>
        <FormColumn2Wide>
          <WBCAccessControl
            input={isDisabled => (
              <div>
                <UncontrolledFormInput
                  id="city"
                  label="City"
                  disabled={isDisabled}
                  value={city || ""}
                  postChange={value => onProfileChange(value, "city")}
                />
                <div className="row">
                  <div className="col">
                    {countryId && countryIdToName[countryId] === "United States" ? (
                      <FormInput
                        editable
                        isDisabled={isDisabled}
                        name="state"
                        label="State"
                        type="select"
                        options={stateDropdown}
                        value={{
                          value: stateId,
                          label: stateIdToName[stateId] || ""
                        }}
                        onChangeFn={value => onProfileChange(value.value, "stateId")}
                      />
                    ) : countryIdToName[countryId] === "Canada" ? (
                      <FormInput
                        editable
                        isDisabled={isDisabled}
                        name="province"
                        label="Province"
                        type="select"
                        options={provinceDropdown}
                        value={{
                          value: provinceId,
                          label: provinceIdToName[provinceId] || ""
                        }}
                        onChangeFn={value => onProfileChange(value.value, "provinceId")}
                      />
                    ) : (
                      <FormInput
                        editable
                        isDisabled
                        name="disabledInputWithText"
                        type="select"
                        label="State/Province"
                        value="Disabled Value"
                      />
                    )}
                  </div>
                  <div className="col">
                    {zipCodeStatus ? (
                      <UncontrolledFormInput
                        id="zipCode"
                        label="Zip Code"
                        disabled={isDisabled}
                        value={zipCode}
                        postChange={value => onProfileChange(value, "zipCode")}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          />
        </FormColumn2Wide>
      </div>
    </>
  );
};

export default ProfileAddressInfoForm;
