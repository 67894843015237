import BaseApi from "./BaseApi";

export default class PitchTrackerApi extends BaseApi {
  static getPitchCounts = ({ fedTeamId, tournamentType }) =>
    this.get(`pitch-tracker/team/${fedTeamId}/tournament-type/${tournamentType}/pitch-counts`);

  static savePitchCounts = ({ fedTeamId, tournamentType, pitchCounts }) =>
    this.put(`pitch-tracker/team/${fedTeamId}/tournament-type/${tournamentType}/pitch-counts`, { pitchCounts });

  static previewPitchCounts = ({ profileId, tournamentType, pitchCounts }) =>
    this.put(`pitch-tracker/pitcher/${profileId}/tournament-type/${tournamentType}/pitch-counts/preview`, {
      pitchCounts
    });
}
