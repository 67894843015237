import React, { useState, useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Input } from "best-common-react";
import HorizontalNav from "../navigation/HorizontalNav";
import ButtonHolder from "../elements/ButtonHolder";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import UserManagementTable from "./UserManagementTable";
import UserManagementContext from "../../contexts/UserManagementContext";
import history from "../../history/history";
import UnsavedChangesPrompt from "../protected/UnsavedChangesPrompt";
import UsersApi from "../../httpClients/UsersApi";
import UserManagementFormContext from "../../contexts/UserManagementFormContext";
import { useAlert } from "../hooks/useAlert";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import MobileFullWidth from "../elements/MobileFullWidth";
import { useClearSectionsOpen } from "../hooks/useClearSectionsOpen";
import { useDiscardChanges } from "../hooks/useDiscardChanges";
import EmailNotificationsApi from "../../httpClients/EmailNotificationsApi";

const SearchWrapper = styled(MobileFullWidth)`
  width: 240px;
`;

const UserSearch = () => {
  const userManagementContext = useContext(UserManagementContext);

  return (
    <SearchWrapper>
      <Input
        placeholder="begin typing to search users"
        onChange={e => {
          userManagementContext.dispatch({ type: "setUserFilter", userFilter: e.target.value });
        }}
        type="text"
      />
    </SearchWrapper>
  );
};

const Users = () => {
  // state && context(s)
  const [asterisk, setAsterisk] = useState("");
  const [buttonToggle, toggleButtons] = useState(true);
  const userManagementContext = useContext(UserManagementContext);
  const userManagementFormContext = useContext(UserManagementFormContext);
  const showAlert = useAlert();
  const showDiscardModal = useDiscardChanges();

  // variable(s)
  const { dispatch: userDispatch } = userManagementContext;
  const { dispatch: formDispatch } = userManagementFormContext;
  const {
    countryAssignmentUpdates,
    orgAssignmentUpdates,
    notificationAssignmentUpdates,
    roleAssignmentUpdates
  } = userManagementContext.state;

  // function(s)
  const initializeUsers = useCallback(() => {
    UsersApi.getUsers().then(users => {
      userDispatch({ type: "setUsers", users: users.accounts });

      const countryAssignments = users.accounts.map(user => ({
        accountId: user.accountId,
        countryIds: user.countryAssignments.map(assignment => assignment.countryId)
      }));

      const roleAssignments = users.accounts.map(user => ({
        accountId: user.accountId,
        roleId: user.role ? user.role.roleId : ""
      }));

      const orgAssignments = users.accounts.map(user => ({
        accountId: user.accountId,
        orgAssignmentId: user?.orgAssignment?.orgAssignmentId,
        orgId: user?.orgAssignment?.orgId
      }));

      const notificationAssignments = users.accounts.map(user => ({
        accountId: user.accountId,
        notificationTypeIds: user.accountNotifications.map(notification => notification?.notificationTypeId)
      }));

      userDispatch({
        type: "initializeCountryAssignments",
        assignments: countryAssignments
      });

      userDispatch({
        type: "initializeRoleAssignments",
        roleAssignments
      });

      userDispatch({
        type: "initializeOrgAssignments",
        orgAssignments
      });

      userDispatch({
        type: "initializeNotificationAssignments",
        notificationAssignments
      });
    });

    UsersApi.getUserRoleOptions().then(roleOptions => {
      roleOptions = roleOptions.map(roleOption => ({
        value: roleOption.roleId,
        roleName: roleOption.role,
        label: roleOption.role,
        roleId: roleOption.roleId
      }));

      roleOptions.forEach(roleOption => {
        roleOption.label = roleOption.label.replace(/ROLE_WBC-(.*)/, (match, capture) => {
          return capture
            .split("-")
            .map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase())
            .join(" ")
            .replace(/Boc/, "BOC");
        });
      });

      userDispatch({
        type: "setRoleOptions",
        roleOptions: roleOptions.sort((a, b) => a.label.localeCompare(b.label))
      });
    });

    EmailNotificationsApi.getNotificationTypes().then(notificationTypes => {
      userDispatch({
        type: "setNotificationOptions",
        notificationOptions: notificationTypes.map(notificationType => ({
          value: notificationType.id,
          label: notificationType.name
        }))
      });
    });
  }, [userDispatch]);

  const onFormDirty = useCallback(() => {
    toggleButtons(false);
    setAsterisk("*");
    formDispatch({ type: "setModalStatus", modalStatus: true });
    formDispatch({
      type: "setFormDirty",
      formDirty: true
    });
  }, [formDispatch]);

  const onSave = useCallback(() => {
    toggleButtons(true);
    setAsterisk("");

    // save role assignments
    let assignments = { assignments: [...roleAssignmentUpdates] };
    UsersApi.updateRoleAssignments(assignments).then(() => {
      // save the country assignments
      assignments = { assignments: [...countryAssignmentUpdates] };
      UsersApi.updateCountryAssignments(assignments).then(
        () => {
          assignments = { assignments: [...orgAssignmentUpdates] };
          UsersApi.updateOrgAssignments(assignments).then(
            () => {
              assignments = { assignments: [...notificationAssignmentUpdates] };
              UsersApi.updateNotificationAssignments(assignments).then(
                () => {
                  initializeUsers();
                  showAlert("Saved");
                },
                ({ message }) => {
                  initializeUsers();
                  showAlert(message || "Unexpected error updating notifications.", "danger");
                }
              );
            },
            ({ message }) => {
              initializeUsers();
              showAlert(message || "Unexpected error updating orgs.", "danger");
            }
          );
        },
        ({ message }) => {
          initializeUsers();
          showAlert(message || "Unexpected error updating countries.", "danger");
        }
      );
    });
  }, [
    roleAssignmentUpdates,
    countryAssignmentUpdates,
    orgAssignmentUpdates,
    notificationAssignmentUpdates,
    initializeUsers,
    showAlert
  ]);

  const onCancel = useCallback(() => {
    toggleButtons(true);
    setAsterisk("");
  }, []);

  const resetButtons = () => {
    setAsterisk("");
    toggleButtons(true);
  };

  const onSaveClicked = () => {
    userManagementFormContext.state.onSave();
    cleanForm();
  };

  const onCancelClicked = () => {
    userManagementFormContext.state.onCancel();
    initializeUsers();
    cleanForm();
  };

  const cleanForm = () => {
    resetButtons();
    userManagementFormContext.dispatch({ type: "setFormDirty", formDirty: false });
    userManagementFormContext.dispatch({ type: "setModalStatus", modalStatus: false });
  };

  const syncUsersFromLDAP = () => {
    UsersApi.syncUsersFromLDAP().then(response => {
      if (response.status === 200) {
        showAlert(response.message);
        initializeUsers();
      } else {
        showAlert(response.message, "danger");
      }
    });
  };

  // effect hooks
  useClearSectionsOpen();

  // effect(s)
  useEffect(initializeUsers, [initializeUsers]);

  useEffect(() => {
    formDispatch({
      type: "setFormControls",
      onFormDirty,
      onCancel,
      onSave
    });
  }, [formDispatch, onFormDirty, onCancel, onSave]);

  // component
  return (
    <div>
      <UnsavedChangesPrompt
        when={!buttonToggle}
        onCancel={onCancelClicked}
        onSave={onSaveClicked}
        navigate={path => history.push(path)}
      />
      <HorizontalNav title={`Users${asterisk}`}>
        <WBCAdminAccess>
          <ButtonHolder>
            <div className="mr-2">
              <WBCPrimaryOutlinedButton onClick={syncUsersFromLDAP} disabled={false} className="btn-md">
                Sync Users
              </WBCPrimaryOutlinedButton>
            </div>
            <div className="mr-2">
              <WBCPrimaryButton onClick={onSaveClicked} disabled={buttonToggle} className="btn-md">
                Save
              </WBCPrimaryButton>
            </div>
            <div>
              <WBCPrimaryOutlinedButton
                onClick={() => showDiscardModal(onCancelClicked)}
                disabled={buttonToggle}
                className="btn-md"
              >
                Cancel
              </WBCPrimaryOutlinedButton>
            </div>
          </ButtonHolder>
        </WBCAdminAccess>
      </HorizontalNav>
      <div className="m-4">
        <UserSearch />
        <UserManagementTable />
      </div>
    </div>
  );
};

export default Users;
