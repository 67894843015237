import React, { useContext, useEffect, useState } from "react";
import MLBPlayerSummaryContext from "../../../contexts/MLBPlayerSummaryContext";
import CountryContext from "../../../contexts/CountryContext";
import PlayerCountContainer from "../shared/PlayerCountContainer";
import PlayerCountTile from "../shared/PlayerCountTile";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import { ReactSVG } from "react-svg";

const FLAG_URL = window.MLBBest.envVariables.REACT_APP_FLAG_URL;

const MLBPlayersByFederation = () => {
  const { countryIdToCode } = useContext(CountryContext).state;
  const { filteredPlayerSummaries, fedFilter, sortPlayerCounts, setFedFilter } = useContext(MLBPlayerSummaryContext);
  const { teams } = useContext(SelectedTeamContext).state;

  const [playerCounts, setPlayerCounts] = useState([]);

  const handlePlayerCountClick = playerCount => {
    setFedFilter(playerCount.countryId === fedFilter ? null : playerCount.countryId);
  };

  useEffect(() => {
    if (teams?.length > 0) {
      const playerCountsMap = {};
      teams.forEach(team => {
        playerCountsMap[team.value] = {
          federation: team.label,
          countryId: team.value,
          code: team.code,
          playerCount: 0
        };
      });

      filteredPlayerSummaries.forEach(summary => {
        const fed = summary.federationCountryId;
        const playerCountsForFed = playerCountsMap[fed];
        if (playerCountsForFed && !["BOC", "MSB", "NONE"].includes(summary?.org?.toUpperCase())) {
          playerCountsMap[fed] = { ...playerCountsForFed, playerCount: playerCountsForFed.playerCount + 1 };
        }
      });

      setPlayerCounts(Object.values(playerCountsMap));
    }
  }, [filteredPlayerSummaries, teams]);

  return (
    <PlayerCountContainer title="MLB Players by Federation">
      {sortPlayerCounts(playerCounts, "federation").map(playerCount => {
        return (
          <PlayerCountTile
            key={playerCount.countryId}
            image={
              <ReactSVG
                src={`${FLAG_URL}/${countryIdToCode[playerCount.countryId].toLowerCase()}.svg`}
                alt="org-logo"
                beforeInjection={svg => {
                  svg.setAttribute("style", "height: 30px; width: 40px");
                }}
              />
            }
            label={playerCount.federation}
            playerCount={playerCount.playerCount}
            onClick={e => handlePlayerCountClick(playerCount)}
            selected={playerCount.countryId === fedFilter}
          />
        );
      })}
    </PlayerCountContainer>
  );
};

export default MLBPlayersByFederation;
