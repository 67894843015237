import React, { useReducer } from "react";
import PropTypes from "prop-types";
import LanguageContext from "../contexts/LanguageContext";

const initialState = {
  languages: [],
  languageDropdown: [],
  languageIdToName: {}
};

const LanguageContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "initialize":
        return { ...state, languageDropdown: action.languageDropdown, languageIdToName: action.languageIdToName };
      case "setLanguages":
        return { ...state, languages: action.languages };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <LanguageContext.Provider value={{ state, dispatch }}>{children}</LanguageContext.Provider>;
};

LanguageContextProvider.propTypes = {
  children: PropTypes.object
};

export default LanguageContextProvider;
