import ProfileRequestConstants from "../constants/ProfileRequestConstants";

export const isStatusRequestRequired = profileRequestStatus => {
  return areRequestStatusesEqual(profileRequestStatus, ProfileRequestConstants.STATUS.REQUEST_REQUIRED);
};

export const isStatusSubmittedToBOC = profileRequestStatus => {
  return areRequestStatusesEqual(profileRequestStatus, ProfileRequestConstants.STATUS.SUBMITTED_TO_BOC);
};

export const isStatusSubmittedToClub = profileRequestStatus => {
  return areRequestStatusesEqual(profileRequestStatus, ProfileRequestConstants.STATUS.SUBMITTED_TO_CLUB);
};

export const isStatusCancelled = profileRequestStatus => {
  return areRequestStatusesEqual(profileRequestStatus, ProfileRequestConstants.STATUS.CANCELLED);
};

const areRequestStatusesEqual = (status1, status2) => {
  return status1 === status2;
};
