import React from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import { ListItem } from "./PositionHelpers";

const Placeholder = ({ dragId, index }) => (
  <Draggable isDragDisabled draggableId={dragId} index={index}>
    {provided => {
      const { innerRef, draggableProps, dragHandleProps } = provided;
      return <ListItem ref={innerRef} {...draggableProps} {...dragHandleProps} />;
    }}
  </Draggable>
);

Placeholder.propTypes = {
  dragId: PropTypes.string,
  index: PropTypes.number
};

export default React.memo(Placeholder);
