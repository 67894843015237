import React from "react";
import styled from "styled-components";
import MLBPlayersDetailsTable from "./MLBPlayerDetailsTable";
import MLBPlayerDetailsHeader from "./MLBPlayerDetailsHeader";

const MLBPlayerDetailsContainer = styled.div`
  classname: "d-flex ";
  padding-top: 2rem;
`;

const MLBPlayerDetails = () => {
  return (
    <MLBPlayerDetailsContainer>
      <MLBPlayerDetailsHeader />
      <MLBPlayersDetailsTable />
    </MLBPlayerDetailsContainer>
  );
};

export default MLBPlayerDetails;
