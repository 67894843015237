import React from "react";
import PropTypes from "prop-types";
import GameReportAddPlayersContextProvider from "../../clubcomm/GameReportAddPlayersContextProvider";
import ClubCommDbContextProvider from "../../clubcomm/ClubCommDbContextProvider";
import EventReportContextProvider from "../../clubcomm/EventReportContextProvider";
import PlayerReportModalContextProvider from "../../PlayerReportModalContextProvider";
import WBCPlayerModal from "../../../components/clubcomm/Modals/PlayerModal/WBCPlayerModal";

const ClubCommContextProviderWrapper = ({ children }) => {
  return (
    <ClubCommDbContextProvider>
      <EventReportContextProvider>
        <GameReportAddPlayersContextProvider>
          <PlayerReportModalContextProvider>
            <WBCPlayerModal />
            {children}
          </PlayerReportModalContextProvider>
        </GameReportAddPlayersContextProvider>
      </EventReportContextProvider>
    </ClubCommDbContextProvider>
  );
};

ClubCommContextProviderWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default ClubCommContextProviderWrapper;
