import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { BestLabel } from "best-common-react";
import styled from "styled-components";
import Theme from "../../Theme";
import WBCPrimaryOutlinedButton from "../elements/WBCPrimaryOutlinedButton";
import { WBCPrimaryButton } from "../elements/WBCPrimaryButton";
import _ from "lodash";

const ToggleButtonGroup = styled.div.attrs(() => ({}))`
  display: inline-flex;
  gap: 6px;
  padding: ${props => (props.styles.padding !== undefined ? props.styles.padding : "6px")};
  background: ${props => props.styles.background};
  border: ${props => props.styles.border};
  height: ${props => (!!props.styles.height ? props.styles.height : "auto")};
  border-radius: 0.25rem;
`;

const ToggleButtons = ({
  id,
  label,
  options,
  value,
  onChange,
  className,
  isMulti = false,
  readOnly = false,
  disabled = false,
  gutterBottom,
  styles = {}
}) => {
  const DefaultStyle = {
    background: Theme.white,
    border: `1px solid ${Theme.grey}`
  };
  const CompStyles = { ...DefaultStyle, ...styles };

  const changeValue = index => {
    if (!disabled) {
      onChange(options[index].value);
    }
  };

  const isActive = (option, value) =>
    isMulti && Array.isArray(value) ? value.includes(option.value) : option.value === value;

  return (
    <div id={id}>
      {!!label && <BestLabel htmlFor={id}>{label}</BestLabel>}
      {readOnly ? (
        <div>
          {options
            .filter(opt => isActive(opt, value))
            .map(opt => (
              <div key={opt.value}>{opt.label}</div>
            ))}
        </div>
      ) : (
        <ToggleButtonGroup
          styles={CompStyles}
          className={clsx(`wbc-toggle-btns ${className ? className : ""}`, { "mb-2": gutterBottom })}
        >
          {options.map((option, index) => {
            const btnStyles = { width: "unset", lineHeight: "1.5rem" };
            return _.isEqual(option, value) ? (
              <WBCPrimaryButton key={option.value} styles={btnStyles} onClick={() => changeValue(index)}>
                {option.label}
              </WBCPrimaryButton>
            ) : (
              <WBCPrimaryOutlinedButton key={option.value} styles={btnStyles} onClick={() => changeValue(index)}>
                {option.label}
              </WBCPrimaryOutlinedButton>
            );
          })}
        </ToggleButtonGroup>
      )}
    </div>
  );
};

ToggleButtons.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.object,
  onChange: PropTypes.func,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  styles: PropTypes.object
};

export default ToggleButtons;
