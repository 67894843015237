import React, { useContext } from "react";
import PropTypes from "prop-types";
import CountryContext from "../../contexts/CountryContext";

const BirthCountryFormatter = ({ value }) => {
  const countryContext = useContext(CountryContext);
  return <div>{value ? countryContext.state.countryIdToName[value] : null}</div>;
};

BirthCountryFormatter.propTypes = {
  value: PropTypes.number
};

export default BirthCountryFormatter;
