import React from "react";
import PropTypes from "prop-types";
import PositionList from "./PositionList";
import { PositionContainer } from "./PositionHelpers";

const Outfield = ({ settings }) => (
  <PositionContainer>
    <PositionList
      droppableId="outfield-list"
      header="Outfield"
      maximum={settings.outfield.outfieldMax}
      minimum={settings.outfield.outfieldMin}
    />
  </PositionContainer>
);

Outfield.propTypes = {
  settings: PropTypes.object
};

export default Outfield;
