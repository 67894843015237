import EligibilityStatusConstants from "../../constants/EligibilityStatusConstants";
import PendingButtonFormatter from "./PendingButtonFormatter";
import ApprovedButtonFormatter from "./ApprovedButtonFormatter";
import DeniedButtonFormatter from "./DeniedButtonFormatter";
import React, { useContext, useState } from "react";
import { useAlert } from "../hooks/useAlert";
import EligibilityListApi from "../../httpClients/EligibilityListApi";
import { AlertConstants } from "best-common-react";
import PropTypes from "prop-types";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import PlayerEligibilityContext from "../../contexts/PlayerEligibilityContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";

const EligibilityButtonFormatter = ({ row }) => {
  const { selectedYear } = useContext(WBCYearsContext);
  const playerEligibilityContext = useContext(PlayerEligibilityContext);
  const globalModalContext = useContext(GlobalModalContext);
  const [approving, setApproving] = useState(false);
  const [settingToPending, setSettingToPending] = useState(false);

  const showAlert = useAlert();
  const { teamId } = playerEligibilityContext.state.team;

  const onApproveClick = () => {
    if (!approving) {
      setApproving(true);

      EligibilityListApi.approveProfileEligibility(selectedYear, row.fedTeamId, row.profileId)
        .then(() => {
          showAlert("Approved");
          EligibilityListApi.getPlayerEligibilityList(selectedYear, teamId)
            .then(response => {
              playerEligibilityContext.dispatch({ type: "setPlayers", players: response });
              setApproving(false);
            })
            .catch(() => setApproving(false));
        })
        .catch(({ response }) => {
          setApproving(false);
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const onPendingClick = () => {
    if (!settingToPending) {
      setSettingToPending(true);

      EligibilityListApi.setToPendingProfileEligibility(selectedYear, row.fedTeamId, row.profileId)
        .then(() => {
          showAlert("Pending");
          EligibilityListApi.getPlayerEligibilityList(selectedYear, teamId)
            .then(response => {
              playerEligibilityContext.dispatch({ type: "setPlayers", players: response });
              setSettingToPending(false);
            })
            .catch(() => setSettingToPending(false));
        })
        .catch(({ response }) => {
          setSettingToPending(false);
          showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        });
    }
  };

  const onDenyClick = () => {
    globalModalContext.dispatch({
      type: "openDenyEligibilityModal",
      content: { profileId: row.profileId, teamId: row.fedTeamId },
      headerText: `Player Eligibility for ${row.lastName}, ${row.firstName}`,
      closeCallback: () => {
        playerEligibilityContext.dispatch({ type: "setPlayerProfileToDenyReason", reason: null });
      }
    });
  };

  switch (row.eligibilityStatusId) {
    case EligibilityStatusConstants.STATUS.PENDING:
      return <PendingButtonFormatter onApproveClick={onApproveClick} onDenyClick={onDenyClick} />;
    case EligibilityStatusConstants.STATUS.APPROVED:
      return <ApprovedButtonFormatter onPendingClick={onPendingClick} onDenyClick={onDenyClick} />;
    case EligibilityStatusConstants.STATUS.DENIED:
      return <DeniedButtonFormatter onPendingClick={onPendingClick} onApproveClick={onApproveClick} />;
    default:
      return <></>;
  }
};

EligibilityButtonFormatter.propTypes = {
  row: PropTypes.object
};

export default EligibilityButtonFormatter;
