import styled from "styled-components";

const ListItem = styled.li`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 13px;
  border-bottom: 1px solid ${props => props.theme["light-grey"]};
  counter-increment: item-counter;
  position: relative;

  ::before {
    margin-right: 15px;
    content: counter(item-counter) "";
    font-family: Helvetica;
    position: absolute;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  :last-child {
    border-bottom: none;
  }

  :focus {
    outline: none;
  }
`;

const PositionContainer = styled.div`
  min-width: 232px; /* do we need this? */
  width: 25%;
  margin-right: 10px;

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 1103px) {
    min-width: 0px;
  }
`;

export { ListItem, PositionContainer };
