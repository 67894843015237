import React, { useRef, useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Tooltip from "./Tooltip";

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TruncatedText = ({ children, tooltipPlacement }) => {
  // hooks
  const ref = useRef(null);
  const [isEllipsisVisible, setIsEllipsisVisible] = useState(true);

  const checkForEllipsis = useCallback(() => {
    if (ref.current) {
      const { offsetWidth, scrollWidth } = ref.current;
      const result = offsetWidth < scrollWidth;
      if (result !== isEllipsisVisible) {
        setIsEllipsisVisible(result);
      }
    }
  }, [ref, isEllipsisVisible]);

  // effects
  // initialize
  useEffect(checkForEllipsis, [checkForEllipsis]);

  // ellipsis check
  useEffect(() => {
    window.addEventListener("resize", checkForEllipsis);
    return () => window.removeEventListener("resize", checkForEllipsis);
  }, [checkForEllipsis, setIsEllipsisVisible]);

  return (
    <>
      <Text ref={ref}>{children}</Text>
      {isEllipsisVisible && (
        <Tooltip placement={tooltipPlacement} targetRef={ref}>
          {children}
        </Tooltip>
      )}
    </>
  );
};

TruncatedText.propTypes = {
  children: PropTypes.node,
  tooltipPlacement: PropTypes.oneOf(["top", "bottom", "left", "right"])
};

TruncatedText.defaultProps = {
  tooltipPlacement: "bottom"
};

export default TruncatedText;
