import React, { useReducer } from "react";
import PropTypes from "prop-types";
import SettingsFormContext from "../contexts/SettingsFormContext";

const initialState = {
  onFormDirty: () => {},
  onSave: () => {},
  onCancel: () => {},
  countryOptions: [],
  teamList: [],
  teamListChanged: false,
  settingsChanged: false
};

const SettingsFormContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setFormControls":
        return { ...state, onFormDirty: action.onFormDirty, onSave: action.onSave, onCancel: action.onSave };
      case "setOnSave":
        return { ...state, onSave: action.onSave };
      case "setOnCancel":
        return { ...state, onCancel: action.onCancel };
      case "setOnFormDirty":
        return { ...state, onFormDirty: action.onFormDirty };
      case "setCountryOptions":
        return { ...state, countryOptions: action.countryOptions };
      case "setTeamList":
        return { ...state, teamList: [...action.teamList] };
      case "setSettingsChanged":
        return { ...state, settingsChanged: action.settingsChanged };
      case "setTeamListChanged":
        return { ...state, teamListChanged: action.teamListChanged };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <SettingsFormContext.Provider value={{ state, dispatch }}>{children}</SettingsFormContext.Provider>;
};

SettingsFormContextProvider.propTypes = {
  children: PropTypes.object
};

export default SettingsFormContextProvider;
