export default {
  LABEL_TYPE: {
    PLAYER: 1,
    STAFF: 2
  },
  LABEL: {
    BIRTH_CERTIFICATE: 1,
    PASSPORT: 2,
    OTHER: 3,
    PPA: 4,
    NPPA: 5
  }
};
