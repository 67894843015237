import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import EventReportStatusConstants from "../../constants/EventReportStatusConstants";
import EventReportContext from "../../contexts/clubcomm/EventReportContext";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import PlayerReportModalContext from "../../contexts/PlayerReportModalContext";
import EventReportApi from "../../httpClients/EventReportApi";
import WBCDataTable from "../data-table/WBCDataTable";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import CatcherReport from "./Modals/PlayerModal/CatcherReport";
import DNPReport from "./Modals/PlayerModal/DNPReport";
import PitcherReport from "./Modals/PlayerModal/PitcherReport";
import PositionPlayerReport from "./Modals/PlayerModal/PositionPlayerReport";
import ReportFormatter from "./ReportFormatter";

const PlayerTypeComponents = {
  POSITION: PositionPlayerReport,
  DNP: DNPReport,
  CATCHER: CatcherReport,
  PITCHER: PitcherReport
};

const TrashContainer = styled.div`
  color: ${props => props.theme.wbcBlue};
  cursor: pointer;
  text-align: center;
`;

const LinkContainer = styled.div`
  cursor: ${props => (props.enabled ? "pointer" : "auto")};
  color: ${props => (props.enabled ? props.theme.wbcBlue : props.theme.black)};
  display: ${props => props.display || "block"};
`;

const TrashFormatter = ({ row }) => {
  const eventReportContext = useContext(EventReportContext);
  const globalModalContext = useContext(GlobalModalContext);
  const { eventReport } = eventReportContext.state;
  const { teamId } = eventReportContext.state.eventReport;

  return (
    <WBCAdminAccess
      bocOnly={
        eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
        eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
      }
    >
      <TrashContainer>
        <i
          className="fa fa-trash fa-lg"
          onClick={() => {
            globalModalContext.dispatch({
              type: "openRemovePlayerModal",
              content: { playerInfo: row, teamId: teamId }
            });
          }}
        />
      </TrashContainer>
    </WBCAdminAccess>
  );
};

const EventPlayerLinkFormatter = ({ row }) => {
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const { eventPlayersInfo } = playerReportModalContext.state;
  const { dispatch: playerReportModalDispatch } = playerReportModalContext;

  return (
    <LinkContainer
      enabled={row.orgCode !== null && row.orgCode?.toUpperCase() !== "NO"}
      onClick={() => {
        if (row.orgCode !== null && row.orgCode?.toUpperCase() !== "NO") {
          const ReportComponent = PlayerTypeComponents[row.playerType];

          EventReportApi.getEventReportPlayers(row.fedTeamId, row.eventReportId, true)
            .then(eventPlayers => {
              const eventPlayerInfoWithOrgs = eventPlayersInfo.filter(ep => ep.orgCode);
              const indexes = eventPlayerInfoWithOrgs.map(playerInfo => playerInfo.id);
              eventPlayers = eventPlayers.sort((val1, val2) => {
                if (indexes.indexOf(val1.id) < indexes.indexOf(val2.id)) return -1;
                if (indexes.indexOf(val1.id) > indexes.indexOf(val2.id)) return 1;
                else return 0;
              });

              const currentIndex = eventPlayers.indexOf(
                eventPlayers.find(player => player.id === row.id && player.orgCode !== "NO")
              );
              playerReportModalDispatch({
                type: "setEventPlayers",
                eventPlayers: eventPlayers,
                currentIndex: currentIndex,
                fedTeamId: row.fedTeamId,
                currentEventPlayer: eventPlayers[currentIndex]
              });
              playerReportModalDispatch({
                type: "openPlayerReportModal",
                content: row,
                children: <ReportComponent />
              });
            })
            .catch(response => {
              // error
            });
        }
      }}
    >
      {row ? row.playerName : ""}
    </LinkContainer>
  );
};

const OrgFormatter = ({ row }) => (
  <div>{row ? (row.orgCode && row.orgCode.toLowerCase() === "no" ? "NONE" : row.orgCode) : ""}</div>
);

const PosFormatter = ({ row }) => <div>{row ? row.positionAbbrv : ""}</div>;

const BoxScoreFormatter = ({ row }) => (
  <div style={{ textAlign: "center" }}>{row.hasStats ? <i className="fas fa-check fa-lg"></i> : ""}</div>
);

const CommentsFormatter = ({ row }) => (
  <div style={{ textAlign: "center" }}>{row.hasComments ? <i className="fas fa-check fa-lg"></i> : ""}</div>
);

const SideWorkFormatter = ({ row }) => (
  <div className="ml-4">{row.hasSideWork ? <i className="fas fa-check fa-lg"></i> : ""}</div>
);

const columns = [
  {
    key: "trash",
    name: "",
    width: 35,
    sortable: false,
    formatter: TrashFormatter
  },
  {
    key: "playerName",
    name: "Player",
    width: 200,
    sortable: true,
    formatter: EventPlayerLinkFormatter,
    getRowMetaData: row => row
  },
  {
    key: "completed",
    name: "Report",
    width: 75,
    sortable: true,
    formatter: ReportFormatter
  },
  {
    key: "orgCode",
    name: "MLB Org",
    width: 90,
    sortable: true,
    formatter: OrgFormatter,
    getRowMetaData: row => row
  },
  {
    key: "positionAbbrv",
    name: "Pos",
    sortable: true,
    width: 80,
    formatter: PosFormatter,
    getRowMetaData: row => row
  },
  {
    key: "boxScore",
    name: "Box Score",
    sortable: true,
    width: 100,
    formatter: BoxScoreFormatter
  },
  {
    key: "hasComments",
    name: "Comments",
    sortable: true,
    width: 105,
    formatter: CommentsFormatter
  },
  {
    key: "hasSideWork",
    name: "Side Work",
    sortable: true,
    formatter: SideWorkFormatter
  }
];

const EventReportPlayerTable = () => {
  const eventReportContext = useContext(EventReportContext);
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const { dispatch: playerReportModalDispatch } = playerReportModalContext;
  const [sortFilters, setSortFilters] = useState({ col: "playerName", dir: "ASC" });

  const {
    eventReport: { eventPlayers }
  } = eventReportContext.state;

  useEffect(() => {
    playerReportModalDispatch({
      type: "setEventPlayersInfo",
      eventPlayersInfo: eventPlayers.filter(player => player.orgCode && player.orgCode !== "NO")
    });
  }, [eventPlayers, playerReportModalDispatch]);

  useEffect(() => {
    eventReportContext.dispatch({
      type: "setSortFilters",
      sortFilters: sortFilters
    });
    playerReportModalContext.dispatch({
      type: "setSortFilters",
      sortFilters: sortFilters
    });
  }, [sortFilters]);

  return (
    <WBCDataTable
      useBCR
      height={320}
      columns={columns}
      data={eventPlayers}
      sortFunc={(col, dir) => {
        setSortFilters({ col, dir });
      }}
      sortColumn={sortFilters.col}
      sortDirection={sortFilters.dir}
    />
  );
};

const formatterProps = {
  row: PropTypes.object
};

TrashFormatter.propTypes = formatterProps;
OrgFormatter.propTypes = formatterProps;
PosFormatter.propTypes = formatterProps;
BoxScoreFormatter.propTypes = formatterProps;
CommentsFormatter.propTypes = formatterProps;
SideWorkFormatter.propTypes = formatterProps;

EventPlayerLinkFormatter.propTypes = {
  ...formatterProps,
  history: PropTypes.object
};

export default EventReportPlayerTable;
