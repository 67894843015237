import React, { useContext, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "./WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../contexts/GlobalModalContext";
import MLBPlayerRequestApi from "../../httpClients/MLBPlayerRequestApi";
import AuthContext from "../../contexts/AuthContext";
import { useAlert } from "../hooks/useAlert";

const BodyContent = styled.div`
  text-align: left;
  margin-bottom: 100px;
  width: 100%;
`;

const ConfirmCancelRequestModalContent = ({ body }) => {
  return <>This action cannot be undone.</>;
};

const ConfirmCancelRequestModalFooterContent = ({ onConfirmUpdateFn, updateStatusButtonText, profileId }) => {
  // context(s)
  const authContext = useContext(AuthContext);
  const { isFedAdmin, isTeamCoordinator } = authContext.state;
  const globalModalContext = React.useContext(GlobalModalContext);
  const [confirmationText, setConfirmationText] = useState("");
  const showAlert = useAlert();

  const handleComments = () => {
    MLBPlayerRequestApi.updatePlayerRequestComments(profileId, confirmationText).then(() => {
      showAlert("Cancelled Request");
    });
  };

  // function(s)
  const onClose = () => globalModalContext.dispatch({ type: "closeModal" });

  return (
    <>
      {(isFedAdmin || isTeamCoordinator) && (
        <BodyContent>
          <textarea
            placeholder="Please enter a reason for cancellation."
            value={confirmationText}
            onChange={e => setConfirmationText(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
        </BodyContent>
      )}
      <div className="d-flex">
        <WBCPrimaryButton
          width={142}
          onClick={event => {
            event.stopPropagation();
            if (isFedAdmin || isTeamCoordinator) {
              handleComments();
            }
            onConfirmUpdateFn();

            onClose();
          }}
        >
          {updateStatusButtonText ? updateStatusButtonText : "Update Request"}
        </WBCPrimaryButton>
        <div className="pl-2">
          <WBCPrimaryOutlinedButton
            width={142}
            onClick={event => {
              event.stopPropagation();
              onClose();
            }}
          >
            Back
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </>
  );
};

ConfirmCancelRequestModalContent.propTypes = {
  body: PropTypes.string
};

ConfirmCancelRequestModalFooterContent.propTypes = {
  onDiscardChanges: PropTypes.func,
  discardChangesButtonText: PropTypes.string,
  onBackClicked: PropTypes.func,
  discardChangesFnArg: PropTypes.any,
  onConfirmUpdateFn: PropTypes.func,
  updateStatusButtonText: PropTypes.string,
  profileId: PropTypes.number
};

ConfirmCancelRequestModalContent.defaultProps = {
  body: "You cannot undo this change."
};

ConfirmCancelRequestModalContent.displayName = "BodyContent";
ConfirmCancelRequestModalFooterContent.displayName = "FooterContent";

export { ConfirmCancelRequestModalContent, ConfirmCancelRequestModalFooterContent };
