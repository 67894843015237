import React, { useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import WBCDataTable from "../../data-table/WBCDataTable";
import MLBPlayerRequestContext from "../../../contexts/MLBPlayerRequestContext";
import Moment from "moment";
import { sortPlayers } from "../../roster/RosterSortHelper";
import RequestActionMenuFormatter from "./RequestActionMenuFormatter";
import RequestStatusFormatter from "../../table/RequestStatusFormatter";
import TableSortFilterContext from "../../../contexts/TableSortFilterContext";
import RosterStatusFormatter from "../mlb-player-summary/MLBPlayerRosterStatusFormatter";
import OverflowFormatter from "./OverflowFormatter";
import LinkText from "../../roster/components/LinkText";
import RouteConstants from "../../../constants/RouteConstants";

const MLBPlayerRequestsTableContainer = styled.div`
  display: flex;
  height: calc(100vh - 250px);
  width: 7.5rem;
`;

const MLBPlayersRequestsTable = () => {
  // context variables
  const mlbPlayerRequestContext = useContext(MLBPlayerRequestContext);
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const { filteredPlayerRequests, playerRequests, sortFilters } = useContext(MLBPlayerRequestContext);

  const DateFormatter = row => Moment(row?.value || "").format("MM/DD/YYYY");

  const PersonLinkFormatter = ({ value, row }) => {
    return (
      <LinkText row={row} profileType={row?.profileType?.toLowerCase() || RouteConstants.PLAYER}>
        {value}
      </LinkText>
    );
  };

  PersonLinkFormatter.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object
  };

  let columns = [
    { name: "", key: "profileId", width: 50, formatter: RequestActionMenuFormatter, sortable: false },
    { name: "Last", key: "lastName", width: 100, formatter: PersonLinkFormatter, sortable: true },
    { name: "First", key: "firstName", width: 100, formatter: PersonLinkFormatter, sortable: true },
    { name: "Pos", key: "position", width: 60, sortable: true },
    { name: "Org", key: "org", width: 60, sortable: true },
    { name: "Club", key: "club", width: 120, sortable: true, formatter: row => OverflowFormatter(row, false, false) },
    { name: "Roster Status", key: "mlbRosterStatus", sortable: true, formatter: RosterStatusFormatter },
    { name: "Federation", key: "federation", sortable: true },
    { name: "Request Status", key: "requestStatus", width: 150, formatter: RequestStatusFormatter, sortable: true },
    { name: "Status Last Updated", key: "lastUpdated", formatter: DateFormatter, sortable: true },
    { name: "MLB Club Comments", key: "mlbClubComments", width: 200, sortable: true, formatter: OverflowFormatter },
    {
      name: "Federation Comments",
      key: "fedComments",
      width: 200,
      sortable: true,
      formatter: OverflowFormatter
    }
  ];

  return (
    <MLBPlayerRequestsTableContainer>
      <WBCDataTable
        useBCR
        columns={columns}
        enableRowSelect={null}
        rowScrollTimeout={null}
        headerRowHeight={40}
        minHeight={500}
        rowHeight={45}
        minWidth={1600}
        data={filteredPlayerRequests}
        tableHeights={{ parentHeight: true }}
        sortFunc={(col, dir) => {
          mlbPlayerRequestContext.dispatch({
            col,
            dir,
            type: "sortPlayers",
            playerRequests: sortPlayers(col, dir, [...playerRequests], false)
          });
          tableSortFilterContext.dispatch({
            type: "setSortFilters",
            sortFilters: { col, dir }
          });
        }}
        sortColumn={sortFilters.col}
        sortDirection={sortFilters.dir}
      ></WBCDataTable>
    </MLBPlayerRequestsTableContainer>
  );
};

export default MLBPlayersRequestsTable;
