import React from "react";
import PropTypes from "prop-types";
import PlayerProfileLink from "../navigation/PlayerProfileLink";

const PlayerLinkFormatter = ({ value, row, profileType, displayStyle, className }) => (
  <PlayerProfileLink
    className={className}
    display={displayStyle}
    profileType={profileType}
    profileId={row.profileId}
    fedTeamId={row.fedTeamId ? row.fedTeamId : row.federationCountryId ? row.federationCountryId : null}
  >
    {value}
  </PlayerProfileLink>
);

PlayerLinkFormatter.propTypes = {
  value: PropTypes.string,
  row: PropTypes.object,
  profileType: PropTypes.string,
  displayStyle: PropTypes.string,
  className: PropTypes.string
};

export default PlayerLinkFormatter;
