import InvalidPermissionsContainer from "../../containers/InvalidPermissionsContainer";
import { Redirect } from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import RouteConstants from "../../constants/RouteConstants";

const handleRender = (authContext, history, Component, props, feature) => {
  const { features } = authContext.state;

  if (feature) {
    if (features[feature]) {
      return <Component {...props} />;
    } else {
      history.goBack();
      return <></>;
    }
  } else {
    return <Component {...props} />;
  }
};

const PermissionCheck = withRouter(({ history, authContext, Component, feature, props }) => {
  const hash = window.location.hash;

  if (authContext.state.loggedIn) {
    if (
      history.location.pathname === RouteConstants.USERS ||
      history.location.pathname === RouteConstants.SETTINGS ||
      history.location.pathname === RouteConstants.PLAYER_ELIGIBILITY
    ) {
      if (authContext.state.isBOC) {
        return handleRender(authContext, history, Component, props, feature);
      } else {
        return <InvalidPermissionsContainer />;
      }
    }
    if (
      authContext.state.isBOC ||
      authContext.state.isFedAdmin ||
      authContext.state.isFedUser ||
      authContext.state.isTeamCoordinator
    ) {
      return handleRender(authContext, history, Component, props, feature);
    } else {
      return <InvalidPermissionsContainer />;
    }
  }

  return (
    <Redirect
      to={{
        pathname: hash ? RouteConstants.LOGIN + hash : RouteConstants.LOGIN,
        state: { from: props.location }
      }}
    />
  );
});

PermissionCheck.propTypes = {
  props: PropTypes.object,
  authContext: PropTypes.object,
  Component: PropTypes.func
};

export default PermissionCheck;
