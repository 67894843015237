import React from "react";
import CountryContextProvider from "../CountryContextProvider";
import PropTypes from "prop-types";
import ContextProviderInitializer from "../initializer/ContextProviderInitializer";

// NOTE: Only one Provider in here right now, but still creating a wrapper
// because of potential other needed providers like State
const GeographyContextProviderWrapper = ({ children }) => {
  return (
    <CountryContextProvider>
      <ContextProviderInitializer>{children}</ContextProviderInitializer>
    </CountryContextProvider>
  );
};

GeographyContextProviderWrapper.propTypes = {
  children: PropTypes.object
};

export default GeographyContextProviderWrapper;
