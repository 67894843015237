import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import adaptToBCRTableCell from "./adaptToBCRTableCell";
import DocumentsContext from "../../contexts/DocumentsContext";

const DocumentsTableDeleteButton = ({ documentId }) => {
  const {
    actions: { deleteDocument }
  } = useContext(DocumentsContext);

  const onClick = useCallback(() => deleteDocument({ documentId }), [documentId, deleteDocument]);

  return <Icon style={{ marginTop: "8px" }} onClick={onClick} iconName={"fa-trash-alt"} />;
};

DocumentsTableDeleteButton.displayName = "DocumentsTableDeleteButton";

DocumentsTableDeleteButton.propTypes = {
  documentId: PropTypes.number.isRequired
};

const DocumentsTableDeleteCell = ({ isReadOnly, document: { documentId } }) =>
  !isReadOnly && <DocumentsTableDeleteButton documentId={documentId} />;

DocumentsTableDeleteCell.displayName = "DocumentsTableDeleteCell";

DocumentsTableDeleteCell.propTypes = {
  isReadOnly: PropTypes.bool.isRequired,
  document: PropTypes.shape({
    documentId: PropTypes.number.isRequired
  })
};

export default adaptToBCRTableCell(DocumentsTableDeleteCell);
