import React from "react";
import { ContainerStyle } from "./PlayerModalStyling";
import PlayerComments from "./PlayerComments";
import BoxScore from "./BoxScore";

const PositionPlayerReport = () => {
  return (
    <ContainerStyle>
      <BoxScore />
      <PlayerComments />
    </ContainerStyle>
  );
};

export default PositionPlayerReport;
