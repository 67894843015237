import React, { useReducer } from "react";
import PropTypes from "prop-types";
import AlertContext from "../contexts/AlertContext";

const initialState = {
  alerts: []
};

const AlertContextProvider = ({ children }) => {
  const getAlertId = () =>
    `_${Math.random()
      .toString(36)
      .substr(2, 9)}`;

  const reducer = (state, action) => {
    switch (action.type) {
      case "addAlert":
        return { ...state, alerts: [...state.alerts, { ...action.alert, id: action.alert.id || getAlertId() }] };
      case "removeAlert":
        return { ...state, alerts: state.alerts.filter(a => a.id !== action.id) };
      case "clearAllAlerts":
        return { ...state, alerts: [] };
      default:
        return initialState;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <AlertContext.Provider value={{ state, dispatch }}>{children}</AlertContext.Provider>;
};

AlertContextProvider.propTypes = {
  children: PropTypes.node
};

export default AlertContextProvider;
