import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Icon } from "best-common-react";
import Popper from "./Popper";

const Menu = styled.div`
  padding: 10px 9px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #f2f8fe;
`;

const MenuItem = styled.div.attrs(() => ({
  className: "wbc-context-menu-item"
}))`
  cursor: ${props => (props.disabled ? undefined : "pointer")};
  color: ${props => (props.disabled ? props.theme.grey : props.theme.wbcBlue)};
  margin-bottom: 12px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;

  :last-child {
    margin-bottom: 0;
  }

  i {
    width: 16px;
    height: 18.3px;
    margin-right: 8px;
  }
`;

const MenuButton = styled.button.attrs(() => ({
  type: "button",
  className: "btn btn-sm btn-link"
}))`
  &&& {
    width: 100%;
    cursor: pointer;
    padding-top: 2px;
  }
`;

const MenuIcon = styled(Icon).attrs(() => ({
  className: "fa-lg"
}))`
  &&& {
    font-size: 1.2em;
    -webkit-text-stroke: 1px #ffffff;
  }
`;

/**
 * Context Menus for all sub menus in the project
 */
const ContextMenu = ({ targetRef, toggleMenu, className, children, ...rest }) => (
  <Popper targetRef={targetRef} togglePopper={toggleMenu} {...rest}>
    <Menu className={className}>{children}</Menu>
  </Popper>
);

ContextMenu.propTypes = {
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  toggleMenu: PropTypes.func,
  placement: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any
};

export { ContextMenu, MenuItem, MenuButton, MenuIcon };
