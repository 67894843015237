import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Theme from "../../Theme";

const Container = styled.i.attrs(() => ({
  className: "d-flex"
}))``;

const Checkbox = styled.div.attrs(() => ({}))`
  &&& {
    width: 16px;
    height: 16px;
    border: solid 1px ${Theme["grey-two"]};
    background-color: ${props => (props.disabled ? props.theme["light-grey"] : props.theme["white"])};
    position: relative;
  }
`;

const Check = styled.i.attrs(() => ({
  className: "fas fa-check fa-md"
}))`
  &&& {
    color: ${props => props.theme["dark-grey"]};
    font-size: 1rem;
    position: absolute;
  }
`;

const WBCCheckbox = ({ checked, onClick, isDisabled }) => {
  return (
    <Container>
      <Checkbox onClick={isDisabled ? null : onClick} disabled={isDisabled} />
      {checked ? <Check onClick={isDisabled ? null : onClick} /> : null}
    </Container>
  );
};

WBCCheckbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default WBCCheckbox;
