import React from "react";
import PropTypes from "prop-types";
import NavigationLink from "./NavigationLink";

const ImgNavigationLink = ({ to, imgSrc, tooltip, toggleMobileNav, ...rest }) => {
  return (
    <NavigationLink to={to} tooltip={tooltip} toggleMobileNav={toggleMobileNav} {...rest}>
      <img src={imgSrc} alt="navigation" height="18px" width="auto" />
    </NavigationLink>
  );
};

ImgNavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  toggleMobileNav: PropTypes.bool.isRequired,
  tooltip: PropTypes.string
};

ImgNavigationLink.defaultProps = {
  tooltip: null
};

export default ImgNavigationLink;
