import _ from "lodash";
import React, { useContext, useEffect } from "react";
import styled from "styled-components";

import PitchTrackerContext from "../../contexts/PitchTrackerContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import { formatDate } from "../../utils/DateUtils";
import TruncatedText from "../elements/TruncatedText";
import PlayerProfileLink from "../navigation/PlayerProfileLink";
import PitchTrackerPitcherSelect from "./PitchTrackerPitcherSelect";
import PitchCount from "./PitchCount";
import PitchTrackerTableDeleteRowFormatter from "./PitchTrackerTableDeleteRowFormatter";
import PitchTrackerTableEditRowFormatter from "./PitchTrackerTableEditRowFormatter";

export const formatDateMonthDayOnly = dateStr => formatDate(dateStr, "M/DD");

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
`;

const TableHeader = styled.th`
  white-space: nowrap;
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  background-color: rgb(247, 247, 247);
`;

const TableHeaderPitcherSelect = styled(TableHeader)`
  .pitch-tracker-pitcher-select {
    z-index: 5;
    width: 150px;
    font-size: small;
    margin: 0.25rem;
  }
`;

const TableHeaderAction = styled(TableHeader)`
  max-width: 2rem;
`;

const TableHeaderFixedTop = styled(TableHeader)`
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  text-align: center;
  padding: 4px;
`;

const TableHeaderFixedTopLeft = styled(TableHeaderFixedTop)`
  left: 0;
  z-index: 2;
  text-align: left;
  padding-left: 4px;
`;

const TableHeaderFixedLeft = styled(TableHeader)`
  position: sticky;
  left: 0;
  z-index: 1;
  text-align: left;
  padding: 4px 4px 4px 4px;
  font-size: 14px;
  font-weight: 400;
  max-width: 250px;
`;

const TableData = styled.td`
  white-space: nowrap;
  border-bottom: 1px solid #f2f3f4;
  padding: 2px;
  font-size: 14px;
  width: 72px;
  min-width: 72px;
  vertical-align: "middle";
`;

const PitchTrackerTable = () => {
  // variables
  const {
    state: {
      addPlayerMode,
      editPlayerMode,
      editPlayerProfileId,
      pitchCountsByProfileIdAndDate,
      pitchers,
      provisionalRoster,
      tournamentDates,
      tournamentType
    },
    actions: { requestProvisionalRoster, updatePitchCountAddPitcherOptions }
  } = useContext(PitchTrackerContext);
  const {
    state: {
      team: { teamId: fedTeamId }
    }
  } = useContext(SelectedTeamContext);

  // functions
  const playerToPitcher = player => {
    return {
      profileId: player.profileId,
      firstName: player.firstName,
      lastName: player.lastName,
      orgCode: player.orgId,
      formattedName: `${player.lastName}, ${player.firstName}`
    };
  };

  // effects
  useEffect(() => {
    requestProvisionalRoster({ tournamentType, fedTeamId });
  }, [requestProvisionalRoster, fedTeamId, tournamentType]);

  useEffect(() => {
    const addPlayerOpts = [];
    // Allow user to select non-pitcher provisional players
    if (provisionalRoster?.players?.length) {
      provisionalRoster.players.forEach(player => {
        const playerDetails = playerToPitcher(player);
        if (!pitchers.find(p => p.profileId === playerDetails.profileId)) {
          const playerOpt = { value: playerDetails, label: playerDetails.formattedName };
          addPlayerOpts.push(playerOpt);
        }
      });
    }
    updatePitchCountAddPitcherOptions(_.sortBy(addPlayerOpts, "label"));
  }, [provisionalRoster, pitchers, updatePitchCountAddPitcherOptions]);

  return (
    <Table data-testid="pitch-tracker-table">
      <thead>
        <tr>
          <TableHeaderAction />
          <TableHeaderFixedTopLeft>Player</TableHeaderFixedTopLeft>
          <TableHeaderAction />
          {tournamentDates.map(tournamentDate => (
            <TableHeaderFixedTop key={tournamentDate}>{formatDateMonthDayOnly(tournamentDate)}</TableHeaderFixedTop>
          ))}
        </tr>
      </thead>
      <tbody>
        {addPlayerMode && (
          <tr>
            <PitchTrackerTableDeleteRowFormatter profileId={undefined} isFinalRosterPitcher={false} />
            <TableHeaderPitcherSelect>
              <PitchTrackerPitcherSelect />
            </TableHeaderPitcherSelect>
          </tr>
        )}
        {_.sortBy(pitchers, "formattedName").map(({ profileId, formattedName, isFinalRosterPitcher }) => (
          <tr data-testid={`pitch-tracker-pitcher-${profileId}`} key={profileId}>
            <PitchTrackerTableDeleteRowFormatter profileId={profileId} isFinalRosterPitcher={isFinalRosterPitcher} />
            {editPlayerMode && editPlayerProfileId === profileId ? (
              <TableHeaderPitcherSelect>
                <PitchTrackerPitcherSelect />
              </TableHeaderPitcherSelect>
            ) : (
              <TableHeaderFixedLeft>
                <PlayerProfileLink profileId={profileId} fedTeamId={fedTeamId}>
                  <TruncatedText>{formattedName}</TruncatedText>
                </PlayerProfileLink>
              </TableHeaderFixedLeft>
            )}
            <PitchTrackerTableEditRowFormatter profileId={profileId} isFinalRosterPitcher={isFinalRosterPitcher} />
            {tournamentDates
              .map(tournamentDate => pitchCountsByProfileIdAndDate[profileId][tournamentDate])
              .map(pitchCount => (
                <TableData key={`${pitchCount.profileId}-${pitchCount.date}`}>
                  <PitchCount pitchCount={pitchCount} />
                </TableData>
              ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

PitchTrackerTable.displayName = "PitchTrackerTable";

export default PitchTrackerTable;
