import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon, Modal } from "best-common-react";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import { isEmpty } from "../../../../utils/StringUtils";
import WBCFooter from "../../../navigation/WBCFooter";
import DesktopTabletView from "../../../temp/DesktopTabletView";
import MobileView from "../../../temp/MobileView";
import CatcherReport from "./CatcherReport";
import DNPReport from "./DNPReport";
import PitcherReport from "./PitcherReport";
import PlayerReportModalBody from "./PlayerReportModalBody";
import PositionPlayerReport from "./PositionPlayerReport";
import ReportCheckmark from "./ReportCheckmark";
import SaveAndCancelModalComp from "./SaveAndCancelModalComp";

const WrappedFooter = styled(WBCFooter)`
  left: 0;
  height: 2px;
  z-index: 0;
`;

const NavSpacer = styled.div`
  width: 110px;
`;

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const HeaderTextStyle = styled.div`
  font-size: 26px;
  font-weight: bold;
`;

const StyledPlayerTracker = styled.div`
  position: ${props => (props.absolute ? "absolute" : "initial")};
  right: -20px;
  top: -48px;
  color: ${props => (props.absolute ? "white" : props.theme["dark-grey"])};
  font-style: Helvetica;
  font-size: ${props => `${props.absolute ? 12 : 14}px`};
  margin: ${props => (props.absolute ? "0" : "0 45px")};
`;

const LeftWrapper = styled.div`
  position: ${props => (props.absolute ? "absolute" : "initial")};
  left: -130px;
  top: 55px;
  text-align: right;
`;

const StyledIconLeft = styled(Icon).attrs(() => ({
  className: "fa-2x"
}))`
  &&& {
    color: ${props => (props.absolute ? "white" : props.theme.wbcBlue)};
    cursor: pointer;
  }
`;

const StyledLeftText = styled.div`
  color: ${props => (props.absolute ? "white" : props.theme.wbcBlue)};
  font-size: 12px;
  font-style: Helvetica;
  text-align: right;
`;

const RightWrapper = styled.div`
  position: ${props => (props.absolute ? "absolute" : "initial")};
  right: -20px;
  top: 55px;
  text-align: left;
`;

const StyledIconRight = styled(Icon).attrs(() => ({
  className: "fa-2x"
}))`
  &&& {
    color: ${props => (props.absolute ? "white" : props.theme.wbcBlue)};
    cursor: pointer;
  }
`;

const StyledRightText = styled.div`
  color: ${props => (props.absolute ? "white" : props.theme.wbcBlue)};
  font-size: 12px;
  font-style: Helvetica;
  text-align: left;
`;

const DesktopNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;

const NavLeftMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
`;

const NavRightMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export const PlayerTypeComponents = {
  POSITION: <PositionPlayerReport />,
  DNP: <DNPReport />,
  CATCHER: <CatcherReport />,
  PITCHER: <PitcherReport />
};

// validation functions
const validateDNP = validatingDNP => {
  const { sideWork } = validatingDNP;
  const { comments } = sideWork || {};
  return comments && !isEmpty(comments);
};

const validatePitcher = validatingPitcher => {
  const { comments } = validatingPitcher;
  return comments && !isEmpty(comments.defensive);
};

const validateCatcherOrPositionPlayer = validatingPlayer => {
  const { comments } = validatingPlayer;
  return comments && !isEmpty(comments.offensive) && !isEmpty(comments.defensive);
};

// components
const PlayerTracker = ({ eventPlayers, currentIndex }) => {
  // hooks
  const eventReportContext = useContext(EventReportContext);

  // variables
  const { eventReportStatus } = eventReportContext.state.eventReport;

  // functions
  const trackerText = (fixed = false) => (
    <div style={{ position: fixed ? "fixed" : undefined }}>
      {eventReportStatus === "Preliminary" ? (
        <>
          <div>
            {eventPlayers.filter(player => player.completed).length} of {eventPlayers.length}
          </div>
          <div>complete</div>
        </>
      ) : (
        <>
          {eventPlayers.length > 0 ? (
            <div>
              {currentIndex + 1} of {eventPlayers.length}
            </div>
          ) : (
            <div>0 of 0</div>
          )}
        </>
      )}
    </div>
  );

  return (
    <>
      <DesktopTabletView minWidth={1024}>
        <StyledPlayerTracker absolute>{trackerText(true)}</StyledPlayerTracker>
      </DesktopTabletView>
      <MobileView maxWidth={1023}>
        <StyledPlayerTracker>{trackerText()}</StyledPlayerTracker>
      </MobileView>
    </>
  );
};

const NavLeft = () => {
  // hooks
  const playerReportModalContext = useContext(PlayerReportModalContext);

  // variables
  const { currentIndex, prevIndex, eventPlayers, eventPlayersInfo } = playerReportModalContext.state;

  // find previous player
  let prevPlayerInfo = null;
  let prevPlayer = null;
  if (eventPlayersInfo.length > 1) {
    if (prevIndex === -1) {
      prevPlayerInfo = eventPlayersInfo[eventPlayersInfo.length - 1];
      prevPlayer = eventPlayers[eventPlayers.length - 1];
    } else {
      prevPlayerInfo = eventPlayersInfo[prevIndex];
      prevPlayer = eventPlayers[prevIndex];
    }
  }

  const onPrevClick = () => {
    playerReportModalContext.dispatch({
      type: "setNavPlayers",
      currentPlayer: prevPlayer,
      currentIndex: currentIndex === 0 ? eventPlayersInfo.length - 1 : currentIndex - 1,
      children: PlayerTypeComponents[prevPlayer.playerType]
    });
    document.getElementById("modal-backdrop").scrollTo(0, 0);
  };

  return (
    <div>
      {prevPlayer ? (
        <div>
          <DesktopTabletView minWidth={1024}>
            <LeftWrapper absolute>
              <div style={{ position: "fixed" }}>
                <StyledIconLeft absolute onClick={onPrevClick} iconName="fa-chevron-left" />
                <StyledLeftText absolute>
                  <NavSpacer>
                    <div>{prevPlayerInfo.orgCode}</div>
                    <div>{prevPlayerInfo.playerName}</div>
                  </NavSpacer>
                </StyledLeftText>
              </div>
            </LeftWrapper>
          </DesktopTabletView>

          <MobileView maxWidth={1023}>
            <NavLeftMobileWrapper>
              <LeftWrapper>
                <div>
                  <StyledIconLeft onClick={onPrevClick} iconName="fa-chevron-left" />
                  <StyledLeftText>
                    <NavSpacer>
                      <div>{prevPlayerInfo.orgCode}</div>
                      <div>{prevPlayerInfo.playerName}</div>
                    </NavSpacer>
                  </StyledLeftText>
                </div>
              </LeftWrapper>
            </NavLeftMobileWrapper>
          </MobileView>
        </div>
      ) : (
        <NavSpacer />
      )}
    </div>
  );
};

const NavRight = () => {
  // hooks
  const playerReportModalContext = useContext(PlayerReportModalContext);

  // variables
  const { currentIndex, nextIndex, eventPlayers, eventPlayersInfo } = playerReportModalContext.state;

  // find next player
  let nextPlayerInfo = null;
  let nextPlayer = null;
  if (eventPlayersInfo.length > 1) {
    if (nextIndex === eventPlayersInfo.length) {
      nextPlayerInfo = eventPlayersInfo[0];
      nextPlayer = eventPlayers[0];
    } else {
      nextPlayerInfo = eventPlayersInfo[nextIndex];
      nextPlayer = eventPlayers[nextIndex];
    }
  }

  const onNextClick = () => {
    playerReportModalContext.dispatch({
      type: "setNavPlayers",
      currentPlayer: nextPlayer,
      currentIndex: currentIndex === eventPlayersInfo.length - 1 ? 0 : currentIndex + 1,
      children: PlayerTypeComponents[nextPlayer.playerType]
    });
    document.getElementById("modal-backdrop").scrollTo(0, 0);
  };

  return (
    <div>
      {nextPlayer ? (
        <div>
          <DesktopTabletView minWidth={1024}>
            <RightWrapper absolute>
              <div style={{ position: "fixed" }}>
                <StyledIconRight absolute iconName="fa-chevron-right" onClick={onNextClick} />
                <StyledRightText absolute>
                  <NavSpacer>
                    <div>{nextPlayerInfo.orgCode}</div>
                    <div>{nextPlayerInfo.playerName}</div>
                  </NavSpacer>
                </StyledRightText>
              </div>
            </RightWrapper>
          </DesktopTabletView>
          <MobileView maxWidth={1023}>
            <NavRightMobileWrapper>
              <RightWrapper>
                <div>
                  <StyledIconRight iconName="fa-chevron-right" onClick={onNextClick} />
                  <StyledRightText>
                    <NavSpacer>
                      <div>{nextPlayerInfo.orgCode}</div>
                      <div>{nextPlayerInfo.playerName}</div>
                    </NavSpacer>
                  </StyledRightText>
                </div>
              </RightWrapper>
            </NavRightMobileWrapper>
          </MobileView>
        </div>
      ) : (
        <NavSpacer />
      )}
    </div>
  );
};

const WBCPlayerModal = () => {
  // hooks
  const [currentPlayer, setCurrentPlayer] = useState({});
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const { dispatch: playerReportModalDispatch } = playerReportModalContext;

  // variables
  const { isOpen, content, currentIndex, children, eventPlayers } = playerReportModalContext.state;

  // effects
  useEffect(() => {
    setCurrentPlayer(content);
  }, [content]);

  useEffect(() => {
    const elem = document.getElementById("modal-backdrop");
    if (isOpen && elem) elem.style.zIndex = 1053;
  }, [isOpen]);

  useEffect(() => {
    if (eventPlayers && eventPlayers.length > 0 && currentPlayer) {
      const currentPlayer = eventPlayers[currentIndex];
      let valid = false;
      switch (currentPlayer.playerType) {
        case "DNP":
          valid = validateDNP(currentPlayer);
          break;
        case "PITCHER":
          valid = validatePitcher(currentPlayer);
          break;
        case "CATCHER":
          valid = validateCatcherOrPositionPlayer(currentPlayer);
          break;
        case "POSITION":
          valid = validateCatcherOrPositionPlayer(currentPlayer);
          break;
        default:
          break;
      }
      if (valid !== currentPlayer.completed) {
        playerReportModalDispatch({
          type: "setPlayerCompleted",
          playerCompleted: valid
        });
      }
      setCurrentPlayer(currentPlayer);
    }
  }, [currentPlayer, eventPlayers, currentIndex, playerReportModalDispatch]);

  return (
    <Modal size="lg" show={isOpen}>
      <Modal.Header styles={{ backgroundColor: "white", borderBottomColor: "#1e376d", color: "#1e376d" }}>
        <HeaderStyle>
          <HeaderTextStyle>MLB Player Info</HeaderTextStyle>
          <DesktopTabletView minWidth={1024}>
            <ReportCheckmark player={currentPlayer} />
          </DesktopTabletView>
        </HeaderStyle>
      </Modal.Header>
      <Modal.Body styles={{ backgroundColor: "#f2f3f4" }}>
        <PlayerReportModalBody content={currentPlayer}>{children}</PlayerReportModalBody>
        <DesktopNavWrapper>
          <NavLeft />
          <PlayerTracker eventPlayers={eventPlayers} currentIndex={currentIndex} />
          <NavRight />
        </DesktopNavWrapper>
        <SaveAndCancelModalComp />
      </Modal.Body>
      <Modal.Footer styles={{ backgroundColor: "#f2f3f4", borderTop: "none" }}>
        <WrappedFooter />
      </Modal.Footer>
    </Modal>
  );
};

PlayerTracker.propTypes = {
  eventPlayers: PropTypes.array,
  currentIndex: PropTypes.number
};

export default WBCPlayerModal;
