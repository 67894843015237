import React, { useContext, useState, useEffect, useCallback } from "react";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import GameReportAddPlayersContext from "../../../contexts/clubcomm/GameReportAddPlayersContext";
import EventReportApi from "../../../httpClients/EventReportApi";
import { useAlert } from "../../hooks/useAlert";
import EventReportContext from "../../../contexts/clubcomm/EventReportContext";
import _ from "lodash";

const AddPlayerModalFooter = () => {
  // hook(s)
  const globalModalContext = useContext(GlobalModalContext);
  const gameReportPlayersContext = useContext(GameReportAddPlayersContext);
  const eventReportContext = useContext(EventReportContext);
  const [addingPlayer, setAddingPlayer] = useState(false);
  const showAlert = useAlert();

  // variables
  const { selectedProspectivePlayer, selectedPosition, positionDropdownEnabled } = gameReportPlayersContext.state;
  const { teamId: fedTeamId, eventReportId } = globalModalContext.state.content;

  // dispatches
  const { dispatch: globalDispatch } = globalModalContext;
  const { dispatch: eventReportDispatch } = eventReportContext;
  const { dispatch: playersDispatch } = gameReportPlayersContext;

  // functions

  const resetSelectedPlayer = useCallback(() => {
    playersDispatch({
      type: "setSelectedProspectivePlayer",
      selectedProspectivePlayer: undefined
    });
    playersDispatch({
      type: "setPositionDropdownEnabled",
      positionDropdownEnabled: false
    });
  }, [playersDispatch]);

  const refreshPlayers = () => {
    EventReportApi.getEventReportPlayersInfo(fedTeamId, eventReportId).then(eventPlayers => {
      eventReportDispatch({
        type: "setEventPlayers",
        eventPlayers
      });
    });
  };

  // effect(s)
  useEffect(() => {
    globalDispatch({
      type: "setCloseCallback",
      closeCallback: resetSelectedPlayer
    });
  }, [resetSelectedPlayer, globalDispatch]);

  const addPlayer = () => {
    if (!addingPlayer) {
      setAddingPlayer(true);

      const { profileId } = selectedProspectivePlayer;
      EventReportApi.saveEventReportPlayers(
        fedTeamId,
        eventReportId,
        profileId,
        !_.isEmpty(selectedPosition) ? selectedPosition.value : null
      )
        .then(() => {
          refreshPlayers();
          showAlert("Player added");
          setAddingPlayer(false);

          resetSelectedPlayer();
          globalDispatch({ type: "closeModal" });
        })
        .catch(response => {
          showAlert(`${response.toString()}, while saving players`, "danger");
          setAddingPlayer(false);
        });
    }
  };

  return (
    <div>
      <div className={"d-flex"}>
        <WBCPrimaryButton
          width={151}
          disabled={!selectedProspectivePlayer || (positionDropdownEnabled && !selectedPosition)}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            addPlayer();
          }}
        >
          Add Player
        </WBCPrimaryButton>

        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              resetSelectedPlayer();
              globalDispatch({
                type: "closeModal"
              });
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      </div>
    </div>
  );
};

AddPlayerModalFooter.displayName = "FooterContent";

export default AddPlayerModalFooter;
