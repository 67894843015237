import React, { useContext } from "react";
import { ContainerStyle, FormTitleWrapper, Title } from "./PlayerModalStyling";
import PlayerComments from "./PlayerComments";
import { BestLabel } from "best-common-react";
import styled from "styled-components";
import PlayerReportModalContext from "../../../../contexts/PlayerReportModalContext";
import WBCAccessControl from "../../../protected/WBCAccessControl";
import WBCAdminAccess from "../../../protected/WBCAdminAccess";
import BoxScore from "./BoxScore";
import EventReportStatusConstants from "../../../../constants/EventReportStatusConstants";
import EventReportContext from "../../../../contexts/clubcomm/EventReportContext";

const StolenBasesSection = styled.div.attrs({
  className: "col-md-6 col-sm-12"
})``;

const ThrowsSection = styled.div.attrs({
  className: "col-md-6 col-sm-12"
})``;

const FieldWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;

  input {
    height: 28px;
    width: 160px;
  }

  label {
    font-weight: normal;
  }

  .form-group {
    margin-bottom: 0;
  }
`;

const MarginTopFieldWrapper = styled(FieldWrapper)`
  margin-top: 32px;
`;

const LinkText = styled.div`
  cursor: pointer;
  font-family: Helvetica;
  font-size: 14px;
  color: ${props => props.theme.wbcBlue};
`;

const Input = styled.input.attrs(() => ({
  className: "form-control"
}))`
  &&& {
    color: black;

    :disabled {
      background-color: ${props => props.theme["light-grey"]};
    }

    :focus {
      background-color: #fbf8cb;
    }
  }
`;

const CatcherReport = () => {
  // hooks
  const playerReportModalContext = useContext(PlayerReportModalContext);
  const eventReportContext = useContext(EventReportContext);

  // variables
  const { eventReport } = eventReportContext.state;
  const { eventPlayers, currentIndex } = playerReportModalContext.state;
  const currentEventPlayer = eventPlayers[currentIndex];

  const { throwsToSecond } = currentEventPlayer;
  const stolenBases = currentEventPlayer.stolenBases || {};
  const { inningsCaught, attempts, allowed } = stolenBases;

  // functions
  const formDirtyFn = () => {
    playerReportModalContext.dispatch({
      type: "setFormDirty",
      formDirty: true
    });
  };

  const addThrow = () => {
    playerReportModalContext.dispatch({
      type: "addThrowToSecondBase",
      throwToSecond: {
        number: throwsToSecond.length + 1,
        seconds: null
      }
    });
    formDirtyFn();
  };

  return (
    <ContainerStyle>
      <BoxScore />
      <PlayerComments />
      <Title>
        <FormTitleWrapper>Catcher Breakout</FormTitleWrapper>
      </Title>
      <div className="form-group">
        <WBCAccessControl
          bocOnly={
            eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
            eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
          }
          input={isDisabled => (
            <div className="row" style={{ marginTop: "-24px" }}>
              <StolenBasesSection className="col-md-6 col-sm-12">
                <MarginTopFieldWrapper>
                  <BestLabel>Innings Caught</BestLabel>
                  <Input
                    name="inningsCaught"
                    disabled={isDisabled}
                    value={inningsCaught || ""}
                    onChange={e => {
                      // eslint-disable-next-line no-useless-escape
                      const inningsCaught = e.target.value.replace(/[^0-9\.]/g, "");

                      playerReportModalContext.dispatch({
                        type: "setInningsCaught",
                        inningsCaught
                      });
                      formDirtyFn();
                    }}
                  />
                </MarginTopFieldWrapper>
                <FieldWrapper>
                  <BestLabel>Stolen Base Attempts</BestLabel>
                  <Input
                    name="stolenBaseAttempts"
                    disabled={isDisabled}
                    value={attempts || ""}
                    onChange={e => {
                      const attempts = e.target.value.replace(/[^0-9]/g, "");

                      playerReportModalContext.dispatch({
                        type: "setStolenBaseAttempts",
                        attempts
                      });
                      formDirtyFn();
                    }}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <BestLabel>Stolen Bases Allowed</BestLabel>
                  <Input
                    name="stolenBasesAllowed"
                    disabled={isDisabled}
                    value={allowed || ""}
                    onChange={e => {
                      const allowed = e.target.value.replace(/[^0-9]/g, "");

                      playerReportModalContext.dispatch({
                        type: "setStolenBasesAllowed",
                        allowed
                      });
                      formDirtyFn();
                    }}
                  />
                </FieldWrapper>
              </StolenBasesSection>
              <ThrowsSection className="col-md-6 col-sm-12">
                <MarginTopFieldWrapper>
                  <BestLabel>Throws to Second Base</BestLabel>
                  <BestLabel>Seconds</BestLabel>
                </MarginTopFieldWrapper>
                {throwsToSecond
                  .sort((t1, t2) => t1.number - t2.number)
                  .map((t, index) => (
                    <FieldWrapper key={t.number}>
                      <BestLabel>{`Throw ${t.number}`}</BestLabel>
                      <Input
                        editable
                        name={`throw${index}`}
                        disabled={isDisabled}
                        value={t.seconds}
                        onChange={e => {
                          // eslint-disable-next-line no-useless-escape
                          const seconds = e.target.value.replace(/[^0-9\.]/g, "");

                          playerReportModalContext.dispatch({
                            type: "setThrowsToSecondSeconds",
                            seconds,
                            index
                          });
                          formDirtyFn();
                        }}
                      />
                    </FieldWrapper>
                  ))}
                <FieldWrapper>
                  <span></span>
                  <WBCAdminAccess
                    bocOnly={
                      eventReport.eventReportStatusId === EventReportStatusConstants.Submitted.id ||
                      eventReport.eventReportStatusId === EventReportStatusConstants.Published.id
                    }
                  >
                    <LinkText onClick={addThrow}>Add Throw</LinkText>
                  </WBCAdminAccess>
                </FieldWrapper>
              </ThrowsSection>
            </div>
          )}
        />
      </div>
    </ContainerStyle>
  );
};

export default CatcherReport;
