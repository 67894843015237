import React from "react";
import PitchTrackerContextProvider from "../providers/PitchTrackerContextProvider";
import PitchTracker from "../components/pitch-tracker/PitchTracker";

const PitchTrackerContainer = () => (
  <PitchTrackerContextProvider>
    <PitchTracker />
  </PitchTrackerContextProvider>
);

PitchTrackerContainer.displayName = "PitchTrackerContainer";

export default PitchTrackerContainer;
