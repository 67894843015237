import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

import DocumentsTable from "../../components/documents/DocumentsTable";
import DocumentsContext from "../../contexts/DocumentsContext";
import { getHasDocuments } from "../../selectors/DocumentSelectors";

const LoadingDocumentsTable = ({ isReadOnly }) => {
  const {
    actions: { requestDocuments, requestDocumentLabels },
    state,
    state: {
      documents: { loaded: documentsLoaded, error: documentsError },
      labels: { loaded: labelsLoaded, error: labelsError },
      profile
    }
  } = useContext(DocumentsContext);

  const hasDocuments = getHasDocuments(state);
  const isLoading = (!profile.isNew && !documentsLoaded) || (!isReadOnly && !labelsLoaded);

  useEffect(() => {
    if (!profile.isNew) {
      requestDocuments();
    }
  }, [requestDocuments, profile]);
  useEffect(() => {
    if (!isReadOnly) {
      requestDocumentLabels();
    }
  }, [isReadOnly, requestDocumentLabels]);

  if (documentsError) {
    return <div>{documentsError.message}</div>;
  } else if (labelsError) {
    return <div>{labelsError.message}</div>;
  } else if (isLoading) {
    return <div>Loading...</div>;
  } else if (!hasDocuments) {
    return <div style={{ color: "#666666" }}>No Documents to Display</div>;
  } else {
    return <DocumentsTable isReadOnly={isReadOnly} />;
  }
};

LoadingDocumentsTable.displayName = "LoadingDocumentsTable";

LoadingDocumentsTable.propTypes = {
  isReadOnly: PropTypes.bool.isRequired
};

export default LoadingDocumentsTable;
