import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import ReactToolTip from "react-tooltip";

const NavLink = styled(Link).attrs(() => ({ className: "" }))`
  &&& {
    font-size: 1.125rem;
    color: #041e42;
  }
`.withComponent(({ isActive, ...props }) => <Link {...props} />);

const StyledLi = styled.li`
  padding: 10px 20px 10px 0;
  background-color: ${props => props.isPopoverOpen && props.theme.mlbDarkNavy};
  transition: ${({ transitionDuration }) => transitionDuration && `background-color ${transitionDuration}s`};
`;

const NavigationLink = ({ to, tooltip, toggleMobileNav, children, ...rest }) => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const onClick = () => {
    if (toggleMobileNav) {
      toggleMobileNav(false);
    }
  };

  return (
    <StyledLi className="nav-item" data-place="right" data-tip={tooltip} {...rest}>
      <NavLink to={to} onClick={onClick}>
        {children}
      </NavLink>
      <ReactToolTip disable={isMobile} />
    </StyledLi>
  );
};

NavigationLink.propTypes = {
  to: PropTypes.string.isRequired,
  toggleMobileNav: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  children: PropTypes.node
};

NavigationLink.defaultProps = {
  tooltip: null
};

export default NavigationLink;
