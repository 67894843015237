/* helper functions */
export const removeNode = node => {
  if (node) {
    if (node.remove) {
      node.remove();
    } else {
      node.parentNode.removeChild(node);
    }
  }
};

export const removeNodeById = id => removeNode(document.getElementById(id));

/* coordinate functions */
export const getTopCoord = (y, offsetY, targetHeight, childHeight) => {
  // calculate y
  const yCoord = y - childHeight - offsetY;
  return Math.round(yCoord < 0 ? y + targetHeight + offsetY : yCoord);
};

export const getBottomCoord = (y, offsetY, targetHeight, childHeight) => {
  // calculate y
  const yCoord =
    y + targetHeight + childHeight + offsetY > window.innerHeight
      ? y - childHeight - offsetY
      : y + targetHeight + offsetY;
  return Math.round(yCoord);
};

export const getRightCoord = (x, offsetX, targetWidth, childWidth) => {
  // calculate x
  const xCoord =
    x + targetWidth + childWidth + offsetX > window.innerWidth ? x - childWidth - offsetX : x + targetWidth + offsetX;
  return Math.round(xCoord);
};

export const getLeftCoord = (x, offsetX, targetWidth, childWidth) => {
  const xCoord = x - childWidth - offsetX;
  return Math.round(xCoord < 0 ? x + targetWidth + offsetX : xCoord);
};

export const isTopFlipped = (y, offsetY, childHeight) => y - childHeight - offsetY < 0;
export const isBottomFlipped = (y, offsetY, targetHeight, childHeight) =>
  y + targetHeight + childHeight + offsetY > window.innerHeight;
export const isRightFlipped = (x, offsetX, targetWidth, childWidth) =>
  x + targetWidth + childWidth + offsetX > window.innerWidth;
export const isLeftFlipped = (x, offsetX, childWidth) => x - childWidth - offsetX < 0;
