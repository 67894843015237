import React, { useReducer } from "react";
import PropTypes from "prop-types";
import EventReportContext from "../../contexts/clubcomm/EventReportContext";
import _ from "lodash";
import EventTypeConstants from "../../constants/EventTypeConstants";

const initialState = {
  canUpdateReportStatus: true,
  eventReport: {
    eventReportStatus: "",
    teamName: "",
    teamCode: "",
    opponent: "",
    tournmentType: "",
    eventDate: "",
    eventPlayers: []
  },
  formDirty: false,
  sortFilters: {
    col: "playerName",
    dir: "ASC"
  }
};

const allRequiredInput = eventReport => {
  return eventReport.eventTypeId === EventTypeConstants.EVENT_TYPES.EXHIBITION.ID
    ? eventReport.opponent?.length > 0
    : true;
};

const sortPlayers = (col, dir, rows) => {
  const filters =
    col === "completed"
      ? [r => (r.orgCode === "NO" || r.orgCode === null ? "aa" : _.toLower(r[col]))]
      : [r => _.toLower(r[col])];
  switch (dir) {
    case "ASC":
      return _.sortBy(rows, filters);
    case "DESC":
      return _.sortBy(rows, filters).reverse();
    default:
      return _.sortBy(rows, [r => _.toLower(r["playerName"])]);
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setEventReport":
      return { ...state, eventReport: { ...action.eventReport, eventPlayers: [] } };
    case "setEventPlayers":
      return {
        ...state,
        eventReport: {
          ...state.eventReport,
          eventPlayers: sortPlayers(state.sortFilters.col, state.sortFilters.dir, action.eventPlayers)
        }
      };
    case "initializeEventReportAndPlayers":
      return {
        ...state,
        canUpdateReportStatus: allRequiredInput(action.eventReport),
        sortFilters: initialState.sortFilters,
        eventReport: {
          ...action.eventReport,
          eventPlayers: sortPlayers(initialState.sortFilters.col, initialState.sortFilters.dir, action.eventPlayers)
        }
      };
    case "setFormDirty":
      return { ...state, canUpdateReportStatus: allRequiredInput(state.eventReport), formDirty: action.formDirty };
    case "setSortFilters":
      return {
        ...state,
        sortFilters: action.sortFilters,
        eventReport: {
          ...state.eventReport,
          eventPlayers: sortPlayers(action.sortFilters.col, action.sortFilters.dir, state.eventReport.eventPlayers)
        }
      };
    case "opponentOnChange":
      return {
        ...state,
        formDirty: true,
        canUpdateReportStatus: allRequiredInput({ ...state.eventReport, opponent: action.opponent }),
        eventReport: { ...state.eventReport, opponent: action.opponent }
      };
    case "eventTypeOnChange":
      return {
        ...state,
        canUpdateReportStatus: allRequiredInput({
          ...state.eventReport,
          eventType: action.eventType,
          eventTypeId: action.eventTypeId
        }),
        formDirty: true,
        eventReport: { ...state.eventReport, eventType: action.eventType, eventTypeId: action.eventTypeId }
      };
    case "setEventReportStatus":
      return {
        ...state,
        eventReport: {
          ...state.eventReport,
          eventReportStatus: action.eventReportStatus,
          eventReportStatusId: action.eventReportStatusId
        }
      };
    default:
      return { ...state };
  }
};

const EventReportContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return <EventReportContext.Provider value={{ state, dispatch }}>{children}</EventReportContext.Provider>;
};

EventReportContextProvider.propTypes = {
  children: PropTypes.object
};

export default EventReportContextProvider;

export const EventReportContextConsumer = EventReportContext.Consumer;
