import React, { useReducer } from "react";
import PropTypes from "prop-types";
import TournamentFormContext from "../contexts/TournamentFormContext";

const initialState = {
  onFormDirty: () => {},
  onSave: () => {},
  onCancel: () => {},
  cleanForm: () => {},
  modalStatus: false,
  formDirtyBool: false,
  countryOptions: []
};

const TournamentFormContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case "setFormControls":
        return { ...state, onFormDirty: action.onFormDirty, onSave: action.onSave, onCancel: action.onSave };
      case "setOnSave":
        return { ...state, onSave: action.onSave };
      case "setOnCancel":
        return { ...state, onCancel: action.onCancel };
      case "setCleanForm":
        return { ...state, cleanForm: action.cleanForm };
      case "setOnFormDirty":
        return { ...state, onFormDirty: action.onFormDirty };
      case "setFormDirty":
        return { ...state, formDirtyBool: action.formDirty };
      case "setModalStatus":
        return { ...state, modalStatus: action.modalStatus };
      case "setCountryOptions":
        return { ...state, countryOptions: action.countryOptions };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return <TournamentFormContext.Provider value={{ state, dispatch }}>{children}</TournamentFormContext.Provider>;
};

TournamentFormContextProvider.propTypes = {
  children: PropTypes.node
};

export default TournamentFormContextProvider;
