const sortList = (col, dir, rows) => {
  // NOTE: This is ONLY designed to work for sorting by uploaded column - which is all we need right now
  if (rows.length > 0) {
    const sortedRows = rows.concat().sort((document1, document2) => {
      const value1 = document1[col];
      const value2 = document2[col];

      return value1
        ? value1
            .toString()
            .toLowerCase()
            .localeCompare(value2 ? value2.toString().toLowerCase() : null)
        : 1;
    });

    if (dir !== "ASC") {
      return sortedRows.reverse();
    }

    return sortedRows;
  }
  return [];
};

export { sortList };
