import { datadogRum } from "@datadog/browser-rum";
import { getBranch } from "./utils/GitBranchUtils";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";

// initialize DataDog APM for non-local
if (window.MLBBest.envVariables.REACT_APP_DATADOG_ENABLED === "true") {
  console.log("enabling Datadog RUM");
  datadogRum.init({
    applicationId: window.MLBBest.envVariables.REACT_APP_DATADOG_APP_ID,
    clientToken: window.MLBBest.envVariables.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: "us5.datadoghq.com",
    service: "best_1_-_wbc_portal_application_web",
    env: window.MLBBest.envVariables.REACT_APP_ENV,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: "mask-user-input"
  });

  datadogRum.startSessionReplayRecording();
}

ReactDOM.render(<App />, document.getElementById("root"));
