import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LabelWrapper = styled.div`
  &&& {
    float: left;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: bold;
    color: #000000;
    width: ${props => props.width}px;
  }
`;

const Required = styled.span`
  color: ${props => props.theme["mlb-red"]};
  padding-left: 5px;
`;

const Wrapper = styled.div`
  &&& {
    @media (min-width: 576px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
    @media (min-width: 1087px) {
      flex: 0 0 88%;
      max-width: 88%;
    }
    @media (min-width: 1207px) {
      flex: 0 0 78%;
      max-width: 78%;
    }
    @media (min-width: 1363px) {
      flex: 0 0 68%;
      max-width: 68%;
    }
    @media (min-width: 1572px) {
      flex: 0 0 58%;
      max-width: 58%;
    }
  }
`;

const WBCHorizontalFieldWrapper = ({ label, required, children, width }) => {
  const style = {
    overflow: "auto",
    textAlign: "left",
    lineHeight: "2.9"
  };

  return (
    <Wrapper style={style}>
      <LabelWrapper width={width}>
        {label}
        {required && <Required>*</Required>}
      </LabelWrapper>
      {children}
    </Wrapper>
  );
};

WBCHorizontalFieldWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  children: PropTypes.object,
  width: PropTypes.number
};

WBCHorizontalFieldWrapper.defaultProps = {
  width: 150
};

export default WBCHorizontalFieldWrapper;
