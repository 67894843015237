import React from "react";
import styled from "styled-components";
import Theme from "../../Theme";
import { Link } from "react-router-dom";
import { Icon } from "best-common-react";
import PropTypes from "prop-types";

const NavLink = styled(Link).attrs(() => ({
  className: "d-flex justify-content-between"
}))`
  font-size: 16px;
  font-weight: bold;
  padding: 8px 1rem;
  padding-left: 32px;
  color: white !important;
  background-color: ${Theme.mlbNavy};
  border-style: solid;
  border-color: ${Theme.black};
  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: white !important;
  }
`.withComponent(({ isActive, ...props }) => <Link {...props} />);

const WBCMobileNavBigLink = ({ toggleMobileNav, title, icon, imgSrc, to, isActive, hasTopBorder }) => (
  <NavLink
    style={{ borderWidth: hasTopBorder ? "1px 1px 1px 1px" : "0 1px 1px 1px" }}
    to={to}
    isActive={isActive}
    onClick={() => {
      if (toggleMobileNav) {
        toggleMobileNav(false);
      }
    }}
  >
    <div>
      <>
        {!!icon ? (
          <Icon style={{ marginRight: "10px", color: Theme.wbcBlue }} iconName={icon} />
        ) : (
          <img src={imgSrc} alt="navigation" height="22px" style={{ paddingRight: "0.5rem" }} />
        )}
        {title}
      </>
    </div>
  </NavLink>
);

WBCMobileNavBigLink.propTypes = {
  isActive: PropTypes.bool,
  to: PropTypes.string,
  title: PropTypes.any,
  icon: PropTypes.string,
  imgSrc: PropTypes.string,
  toggleMobileNav: PropTypes.func,
  hasTopBorder: PropTypes.bool
};

export default WBCMobileNavBigLink;
