import BaseApi from "./BaseApi";

export default class MLBPlayerSummaryApi extends BaseApi {
  constructor() {
    if (new.target === MLBPlayerSummaryApi) {
      throw new TypeError("Cannot construct RosterApi instances directly");
    }
  }

  static getMLBPlayerSummariesByYear = year => this.get(`mlbplayersummary?wbcYear=${year}`);
  static getMLBPlayerSummariesByYearAndCountry = (year, fedTeamIds) =>
    this.get(`mlbplayersummary/byCountry?wbcYear=${year}&fedTeamIds=${fedTeamIds}`);
  static getStatuses = year => this.get(`mlbplayersummary/statuses`);
}
