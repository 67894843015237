import React from "react";
import PropTypes from "prop-types";
import { BestTitle } from "best-common-react";
import { FormTitleWrapper } from "../shared/ProfileComponents";
import BaseballLeftForm from "./BaseballLeftForm";
import BaseballRightForm from "./BaseballRightForm";

const ProfileBaseballInfoForm = ({ isStaff }) => (
  <div className="row">
    <div className="col">
      <div className="row">
        <div className="col">
          <BestTitle>
            <FormTitleWrapper>Baseball Information</FormTitleWrapper>
          </BestTitle>
        </div>
      </div>
      <div className="row">
        <BaseballLeftForm isStaff={isStaff} />
        <BaseballRightForm isStaff={isStaff} />
      </div>
    </div>
  </div>
);

ProfileBaseballInfoForm.propTypes = {
  isStaff: PropTypes.bool
};

export default ProfileBaseballInfoForm;
