import { useContext, useEffect } from "react";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import FinalizedStatusContext from "../../contexts/FinalizedStatusContext";

export const useFinalizedStatus = tournamentType => {
  // context(s)
  const selectedTeamContext = useContext(SelectedTeamContext);
  const finalizedStatusContext = useContext(FinalizedStatusContext);

  const { dispatch } = finalizedStatusContext;

  useEffect(() => {
    const { finalizedStatus } = selectedTeamContext.state.team;
    const finalized = finalizedStatus && finalizedStatus[`${tournamentType}RosterFinalizedDate`] !== null;

    dispatch({ type: "setFinalizedStatus", finalized });
  }, [dispatch, tournamentType, selectedTeamContext.state.team]);
};
