import React from "react";
import PropTypes from "prop-types";
import PitchCountRestDay from "./PitchCountRestDay";
import PitchCountInput from "./PitchCountInput";

const PitchCount = ({ pitchCount }) => {
  const { profileId, date, requiredToRest, valid } = pitchCount;

  return requiredToRest && valid ? (
    <PitchCountRestDay data-testid={`pitch-count-rest-day-${profileId}-${date}`} />
  ) : (
    <PitchCountInput data-testid={`pitch-count-input-${profileId}-${date}`} pitchCount={pitchCount} />
  );
};

PitchCount.displayName = "PitchCount";

PitchCount.propTypes = {
  pitchCount: PropTypes.shape({
    profileId: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    requiredToRest: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired
  })
};

export default PitchCount;
