import React from "react";
import ClubsContextProvider from "../ClubsContextProvider";
import PositionsContextProvider from "../PositionsContextProvider";
import LanguageContextProvider from "../LanguageContextProvider";
import BaseballContextInitializer from "../initializer/BaseballContextInitializer";
import OrgsContextProvider from "../OrgsContextProvider";
import PropTypes from "prop-types";

const BaseballContextProviderWrapper = ({ children }) => {
  return (
    <OrgsContextProvider>
      <ClubsContextProvider>
        <PositionsContextProvider>
          <LanguageContextProvider>
            <BaseballContextInitializer>{children}</BaseballContextInitializer>
          </LanguageContextProvider>
        </PositionsContextProvider>
      </ClubsContextProvider>
    </OrgsContextProvider>
  );
};

BaseballContextProviderWrapper.propTypes = {
  children: PropTypes.object
};

export default BaseballContextProviderWrapper;
