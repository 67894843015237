import React from "react";
import { DenyButton, BackToPendingButton } from "./EligibilityButtons.js";
import PropTypes from "prop-types";

function ApprovedButtonFormatter({ onPendingClick, onDenyClick }) {
  return (
    <div className="d-flex">
      <BackToPendingButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onPendingClick();
        }}
        style={{ width: "140px" }}
      >
        Return To Pending
      </BackToPendingButton>
      <DenyButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onDenyClick();
        }}
        style={{ width: "59px" }}
      >
        Deny
      </DenyButton>
    </div>
  );
}
ApprovedButtonFormatter.propTypes = {
  onPendingClick: PropTypes.func.isRequired,
  onDenyClick: PropTypes.func.isRequired
};
export default ApprovedButtonFormatter;
