export const QUALIFYING = "qualifying";
export const MAIN = "main";
export const QUALIFIERS = "qualifiers"; // not added to ALL_TOURNAMENT_TYPES because this is only for display purposes
export const ALL_TOURNAMENT_TYPES = [QUALIFYING, MAIN];

export const QUALIFYING_ID = 1;
export const MAIN_ID = 2;

export const TOURNAMENT_TYPE_BY_ID = {
  [QUALIFYING_ID]: QUALIFYING,
  [MAIN_ID]: MAIN
};
