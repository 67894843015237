import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { randomString } from "../../utils/StringUtils";
import {
  removeNodeById,
  getRightCoord,
  getTopCoord,
  getBottomCoord,
  getLeftCoord,
  isTopFlipped,
  isBottomFlipped,
  isLeftFlipped,
  isRightFlipped
} from "../../utils/PortalUtils";

// styled components
const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
`;

const DefaultTooltip = styled.div`
  border-radius: 5px;
  background-color: ${props => props.theme["dark-grey"]};
  padding: 5px;

  /* transition */
  opacity: 0;
  transition: opacity ${props => `${props.fade / 1000}s`};

  /* text */
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${props => props.theme.white};

  /* arrow */
  ::after {
    content: "";
    position: absolute;
    border: 5px solid;

    /* top arrow */
    bottom: ${props => (props.arrow === "top" ? "100%" : undefined)};
    left: ${props => (props.arrow === "top" ? props.xArrowPos : undefined)};
    border-color: ${props =>
      props.arrow === "top" ? `transparent transparent ${props.theme["dark-grey"]} transparent` : undefined};

    /* bottom arrow */
    top: ${props => (props.arrow === "bottom" ? "100%" : undefined)};
    left: ${props => (props.arrow === "bottom" ? props.xArrowPos : undefined)};
    border-color: ${props =>
      props.arrow === "bottom" ? `${props.theme["dark-grey"]} transparent transparent transparent` : undefined};

    /* left arrow */
    top: ${props => (props.arrow === "left" ? props.yArrowPos : undefined)};
    right: ${props => (props.arrow === "left" ? "100%" : undefined)};
    border-color: ${props =>
      props.arrow === "left" ? `transparent ${props.theme["dark-grey"]} transparent transparent` : undefined};

    /* right arrow */
    top: ${props => (props.arrow === "right" ? props.yArrowPos : undefined)};
    left: ${props => (props.arrow === "right" ? "100%" : undefined)};
    border-color: ${props =>
      props.arrow === "right" ? `transparent transparent transparent ${props.theme["dark-grey"]}` : undefined};
  }
`;

// constants
const upper = new Set(["top", "bottom"]);
const lower = new Set(["left", "right"]);
const arrowMap = {
  top: "bottom",
  left: "right",
  right: "left",
  bottom: "top"
};

// based on placement, get the proper arrow direction
const getArrow = (placement, isFlipped) => {
  if (!isFlipped) return arrowMap[placement];
  switch (placement) {
    case "top":
      return arrowMap["bottom"];
    case "bottom":
      return arrowMap["top"];
    case "left":
      return arrowMap["right"];
    default:
      return arrowMap["left"];
  }
};

// check if the mouse coordinates are inside an element
const mouseInElement = (event, element, placement = "", isOverlay = false) => {
  // isOverlay adds a 2px buffer around the overlay
  const { clientX: x, clientY: y } = event;
  const { top: t, left: l, bottom: b, right: r } = element.getBoundingClientRect();
  const top = isOverlay && upper.has(placement) ? t - 2 : t;
  const left = isOverlay && lower.has(placement) ? l - 2 : l;
  const bottom = isOverlay && upper.has(placement) ? b + 2 : b;
  const right = isOverlay && lower.has(placement) ? r + 2 : r;

  return x >= left && x <= right && y >= top && y <= bottom;
};

/**
 * This is a tooltip that uses portals in the same way that
 * popups do. It's just an uncontrolled popup with certain
 * styling
 */
const Tooltip = ({ targetRef, placement, fade, className, children }) => {
  // hook(s)
  const tooltipRef = useRef(null);
  const containerRef = useRef(null);
  const removeTimeout = useRef(null);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [uniqueId] = useState(`tooltip-${randomString()}`);
  const [position, setPosition] = useState({ x: -1000, y: -1000, flipped: false });
  const [arrowPos, setArrowPos] = useState({ horizontal: "calc(50% - 5px)", vertical: "calc(50% - 5px)" });

  // variable(s)
  const { x, y, flipped } = position;
  const { horizontal, vertical } = arrowPos;
  const style = { transform: `translate3d(${x}px, ${y}px, 0px)` };

  // create the node that the tooltip will be in
  const node = document.getElementById(uniqueId) || document.createElement("div");
  node.setAttribute("id", uniqueId);

  // function(s)
  const closeTooltip = useCallback(() => setIsMouseOver(false), []);
  const onMouseEnter = useCallback(() => {
    setIsMouseOver(true);
    setArrowPos({
      horizontal: `${targetRef.current.offsetWidth / 2 - 4}px`,
      vertical: `${targetRef.current.offsetHeight / 2}px`
    });
  }, [targetRef]);

  const onMouseLeave = useCallback(
    e => {
      // create a div that will overlay the arrow
      const overlay = document.createElement("div");
      overlay.style.height = "20px";
      overlay.style.width = "20px";
      overlay.style.position = "absolute";
      overlay.style.zIndex = -1;

      // update overlay position
      if (upper.has(placement)) {
        overlay.style.left = `${targetRef.current.offsetWidth / 2 - 9}px`;
        if ((placement === "bottom" && !flipped) || (placement === "top" && flipped)) {
          overlay.style.top = "-12.5px";
        } else {
          overlay.style.top = "20px";
        }
      } else {
        overlay.style.top = `${targetRef.current.offsetHeight / 2 - 5}px`;
        if ((placement === "right" && !flipped) || (placement === "left" && flipped)) {
          overlay.style.left = "-10px";
        } else {
          overlay.style.right = "-10px";
        }
      }

      // add overlay listeners
      overlay.onmouseleave = e => {
        // same thing, based on placement, check if you're in the tooltip
        if (!mouseInElement(e, tooltipRef.current) && !mouseInElement(e, targetRef.current)) {
          setIsMouseOver(false);
        }
      };

      // add that overlay to the dom
      containerRef.current.appendChild(overlay);

      // check if the mouse is in the overlay. if not, close this element
      if (!mouseInElement(e, overlay, placement, true)) {
        setIsMouseOver(false);
      }
    },
    [tooltipRef, targetRef, containerRef, placement, flipped]
  );

  // effect(s)
  // add node to DOM/remove node from DOM
  // NOTE: flipped is a dependency because when the component flips, we want this to run
  useEffect(() => {
    if (isMouseOver) {
      // clear any remove timeout
      if (removeTimeout.current) {
        window.clearTimeout(removeTimeout.current);
      }

      // add to DOM
      document.body.appendChild(node);
      setTimeout(() => {
        tooltipRef.current.style.opacity = 1;
      }, 0);
    } else {
      // remove from DOM
      tooltipRef.current.style.opacity = 0;
      removeTimeout.current = setTimeout(() => removeNodeById(uniqueId), fade);
    }
  }, [isMouseOver, node, tooltipRef, uniqueId, fade, flipped, removeTimeout]);

  // calculate position
  useEffect(() => {
    if (isMouseOver && targetRef.current) {
      const { x, y } = targetRef.current.getBoundingClientRect();
      const { offsetWidth: nodeWidth, offsetHeight: nodeHeight } = containerRef.current;
      const { offsetWidth: targetWidth, offsetHeight: targetHeight } = targetRef.current;

      // calculate position & flip arrow if necessary
      if (placement === "top") {
        const topFlipped = isTopFlipped(y, 2, nodeHeight);
        setPosition({
          x: Math.round(x),
          y: getTopCoord(y, 2, targetHeight, nodeHeight) - (topFlipped ? 5 : 0),
          flipped: topFlipped
        });
      } else if (placement === "bottom") {
        const bottomFlipped = isBottomFlipped(y, 2, targetHeight, nodeHeight);
        setPosition({
          x: Math.round(x),
          y: getBottomCoord(y, 2, targetHeight, nodeHeight) - (bottomFlipped ? 5 : 0),
          flipped: bottomFlipped
        });
      } else if (placement === "left") {
        const leftFlipped = isLeftFlipped(x, 7, nodeWidth);
        setPosition({
          x: getLeftCoord(x, 7, targetWidth, nodeWidth) - (leftFlipped ? 5 : 0),
          y: Math.round(y),
          flipped: leftFlipped
        });
      } else {
        // assumes right placement
        const rightFlipped = isRightFlipped(x, 2, targetWidth, nodeWidth);
        setPosition({
          x: getRightCoord(x, 2, targetWidth, nodeWidth) - (rightFlipped ? 5 : 0),
          y: Math.round(y),
          flipped: rightFlipped
        });
      }
    }
  }, [isMouseOver, targetRef, placement]);

  // set on mouse over functions
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.addEventListener("mouseenter", onMouseEnter);
      targetRef.current.addEventListener("mouseleave", onMouseLeave);
    }

    // safeRef is used here to avoid warnings in compiler
    const safeRef = targetRef.current;
    return () => {
      if (safeRef) {
        safeRef.removeEventListener("mouseenter", onMouseEnter);
        safeRef.removeEventListener("mouseleave", onMouseLeave);
      }
      removeNodeById(uniqueId);
    };
  }, [uniqueId, targetRef, onMouseEnter, onMouseLeave]);

  // on scroll, close tooltip
  useEffect(() => {
    window.addEventListener("scroll", closeTooltip, true);
    return () => window.removeEventListener("scroll", closeTooltip, true);
  }, [closeTooltip]);

  return ReactDOM.createPortal(
    <Container ref={containerRef} style={style} onMouseLeave={onMouseLeave}>
      <DefaultTooltip
        ref={tooltipRef}
        className={className}
        fade={fade}
        arrow={getArrow(placement, flipped)}
        xArrowPos={horizontal}
        yArrowPos={vertical}
      >
        {children}
      </DefaultTooltip>
    </Container>,
    node
  );
};

Tooltip.propTypes = {
  targetRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"]),
  className: PropTypes.string,
  fade: PropTypes.number,
  children: PropTypes.any
};

Tooltip.defaultProps = {
  placement: "right",
  fade: 300
};

export default Tooltip;
