import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { AlertConstants, InfoCard } from "best-common-react";
import RouteConstants from "../../../constants/RouteConstants";
import useQuery from "../../hooks/useQuery";
import { useAlert } from "../../hooks/useAlert";
import WBCLoadingButton from "../../elements/WBCLoadingButton";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import BioInfoForm from "./BioInfoForm";
import EbisIdForm from "./EbisIdForm";
import PersonMatchModal from "./PersonMatchModal/PersonMatchModal";
import ProspectivePlayersApi from "../../../httpClients/ProspectivePlayersApi";
import FormHelper from "../../elements/FormHelper";

const Title = styled.h2`
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 300;
  color: ${props => props.theme["dark-grey"]};
`;

const initialPerson = {
  firstName: null,
  lastName: null,
  birthCountry: null,
  birthDate: null,
  ebisId: null
};

const CreateProfileForm = withRouter(({ history }) => {
  const selectedTeamContext = useContext(SelectedTeamContext);
  const globalModalContext = useContext(GlobalModalContext);

  const showAlert = useAlert();
  const query = useQuery();

  const [createPersonBtnEnabled, setCreatePersonBtnEnabled] = useState(false);
  const [matchData, setMatchData] = useState([]);
  const [matchModalOpen, setMatchModalOpen] = useState(false);
  const [person, setPerson] = useState(initialPerson);
  const [searchByBioInfo, setSearchByBioInfo] = useState(true);
  const [searchByEbisId, setSearchByEbisId] = useState(false);

  const profileType = query?.get("tab") === "prospective" ? RouteConstants.PLAYER : RouteConstants.STAFF;
  const profileTypeLabel = profileType === RouteConstants.PLAYER ? "Player" : "Coaching Staff Member";

  const resetSearch = () => {
    setPerson(initialPerson);
  };

  const createNewProfile = person => {
    const { firstName, lastName, birthDate, birthCountry } = person;

    const profile = {
      fullName: {
        firstName,
        lastName
      },
      birthInfo: {
        birthDate,
        birthCountryId: birthCountry?.value
      }
    };

    const fedTeamId = selectedTeamContext.state.team.teamId;
    const route = RouteConstants.PROFILE.replace(":playerId", RouteConstants.NEW_PROFILE_SUFFIX)
      .replace(":fedTeamId", fedTeamId)
      .replace(":profileType", profileType);
    history.push(route, profile);
  };

  const search = () => {
    let searchFn;

    if (searchByBioInfo) {
      searchFn = () =>
        profileType === RouteConstants.PLAYER
          ? ProspectivePlayersApi.checkPlayerMatches(person)
          : ProspectivePlayersApi.checkStaffMatches(person);
    } else {
      searchFn = () => ProspectivePlayersApi.searchByEbisId(profileType, person.ebisId);
    }

    searchFn()
      .then(data => {
        if (data?.matches) {
          if (data?.matches?.length) {
            // Add the search criteria to the beginning of the match data
            data.matches.unshift({
              birthCountryName: person?.birthCountry?.label,
              birthCountryId: person?.birthCountry?.value,
              firstName: person?.firstName,
              lastName: person?.lastName,
              personFullName: searchByEbisId ? "" : `${person.lastName}, ${person.firstName}`,
              birthDate: person.birthDate,
              ebisId: person.ebisId
            });
            setMatchData(data.matches);
            setMatchModalOpen(true);
          } else if (searchByEbisId) {
            globalModalContext.dispatch({
              type: "openNoEbisMatchModal",
              content: person.ebisId
            });
          } else {
            createNewProfile(person);
          }
        }
      })
      .catch(({ response }) => {
        showAlert(response.data.message, AlertConstants.TYPES.DANGER);
        createNewProfile(person);
      })
      .finally(() => {
        resetSearch();
      });
  };

  const setUpSearch = ({ isBioInfoSearch }) => {
    resetSearch();
    setSearchByBioInfo(isBioInfoSearch);
    setSearchByEbisId(!isBioInfoSearch);
  };

  useEffect(() => {
    if (searchByBioInfo) {
      setCreatePersonBtnEnabled(
        FormHelper.areAllPropertiesDefined({
          firstName: person.firstName,
          lastName: person.lastName,
          birthCountry: person.birthCountry,
          birthDate: person.birthDate
        })
      );
    } else if (searchByEbisId) {
      setCreatePersonBtnEnabled(FormHelper.areAllPropertiesDefined({ ebisId: person.ebisId }));
    }
  }, [searchByBioInfo, searchByEbisId, person]);

  return (
    <div>
      <PersonMatchModal
        isOpen={matchModalOpen}
        setIsOpen={setMatchModalOpen}
        matchData={matchData}
        searchByEbisId={searchByEbisId}
        createPersonFn={createNewProfile}
        personType={profileType}
      />
      <InfoCard className="mt-3">
        <div>
          <Title>New {profileTypeLabel}</Title>
          <BioInfoForm
            person={person}
            searchEnabled={searchByBioInfo}
            setPerson={setPerson}
            setUpSearch={setUpSearch}
          />
          <EbisIdForm person={person} searchEnabled={searchByEbisId} setPerson={setPerson} setUpSearch={setUpSearch} />
          <div className="d-flex justify-content-end">
            <WBCLoadingButton
              disabled={!createPersonBtnEnabled}
              text={`Create ${profileTypeLabel}`}
              className={"w-auto px-3"}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                search();
              }}
            />
          </div>
        </div>
      </InfoCard>
    </div>
  );
});

export default CreateProfileForm;
