import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import PitchTrackerContext from "../../contexts/PitchTrackerContext";

const DeleteIcon = styled.i.attrs(() => ({
  className: "fas fa-trash-alt"
}))`
  color: ${props => props.theme.wbcBlue};
  cursor: pointer;
  margin: 0.5rem;
  font-size: 1.16em;
`;

const TableHeaderDelete = styled.th`
  white-space: nowrap;
  border-top: 1px solid #f2f3f4;
  border-bottom: 1px solid #f2f3f4;
  background-color: rgb(247, 247, 247);
  max-width: 2rem;
`;

const PitchTrackerTableDeleteRowFormatter = ({ profileId, isFinalRosterPitcher }) => {
  const {
    actions: { deletePitcherFromPitchCounts, pitchCountsAddPlayerMode }
  } = useContext(PitchTrackerContext);

  const handleDeleteRow = useCallback(
    profileId => {
      if (!profileId) {
        pitchCountsAddPlayerMode(false);
      } else {
        deletePitcherFromPitchCounts(profileId);
      }
    },
    [deletePitcherFromPitchCounts, pitchCountsAddPlayerMode]
  );

  return (
    <TableHeaderDelete>
      {!profileId || !isFinalRosterPitcher ? <DeleteIcon onClick={() => handleDeleteRow(profileId)} /> : <></>}
    </TableHeaderDelete>
  );
};

PitchTrackerTableDeleteRowFormatter.propTypes = {
  profileId: PropTypes.number,
  isFinalRosterPitcher: PropTypes.bool
};

export default PitchTrackerTableDeleteRowFormatter;
