import BaseApi from "./BaseApi";

export default class EventReportApi extends BaseApi {
  // read
  static getEventReports = (wbcYear, teamId, statusId, orgId) =>
    this.get(`eventreport?wbcYear=${wbcYear}&teamId=${teamId}&statusId=${statusId}&orgId=${orgId}`);

  static getEventReportForTeam = (teamId, eventReportId) => this.get(`team/${teamId}/eventReport/${eventReportId}`);

  static getEventReportPlayers = (teamId, eventReportId, withOrg = false) =>
    this.get(`team/${teamId}/eventReport/${eventReportId}/players?withOrg=${withOrg}`);

  static getEventReportPlayer = (teamId, eventReportId, eventPlayerId) =>
    this.get(`team/${teamId}/eventReport/${eventReportId}/players/${eventPlayerId}`);

  static getEventReport = eventReportId => this.get(`eventreport/${eventReportId}`);

  static getEventReportPlayersInfo = (teamId, eventReportId) =>
    this.get(`team/${teamId}/eventReport/${eventReportId}/players/info`);

  // create
  static createEventReport = (eventReport, fedTeamId) => this.post(`team/${fedTeamId}/eventReport`, eventReport);

  static saveEventReportPlayers = (teamId, eventReportId, profileId, positionId) =>
    this.post(`team/${teamId}/eventReport/${eventReportId}/profile`, { profileId, positionId });

  // update
  static updateEventReport = (eventReportId, fedTeamId, requestBody) =>
    this.patch(`team/${fedTeamId}/eventReport/${eventReportId}`, requestBody);

  static updateEventReportPlayerInfo = (teamId, eventReportId, updatePlayersRequest) =>
    this.put(`team/${teamId}/eventReport/${eventReportId}/players`, { updatePlayersRequest });

  // destroy
  static removeEventReportPlayers = (teamId, eventReportId, removePlayersRequest) =>
    this.post(`team/${teamId}/eventReport/${eventReportId}/players/remove`, removePlayersRequest);
}
