import React, { useContext } from "react";
import PropTypes from "prop-types";
import GlobalModalContext from "../../../contexts/GlobalModalContext";
import AuthContext from "../../../contexts/AuthContext";
import WBCPrimaryOutlinedButton from "../../elements/WBCPrimaryOutlinedButton";
import { WBCPrimaryButton } from "../../elements/WBCPrimaryButton";
import EventTypeConstants from "../../../constants/EventTypeConstants";

const AddNonGameDayModalFooter = ({ addEventReportFn }) => {
  const globalModalContext = useContext(GlobalModalContext);
  const authContext = useContext(AuthContext);

  const { eventReport } = globalModalContext.state;
  const globalModalDispatch = globalModalContext.dispatch;
  const { isBOC, isFedAdmin } = authContext.state;

  // Only enable the Add button when all fields are filled out
  let buttonDisabled = !eventReport.eventDate || !eventReport.eventTypeId;
  // Only BOC and Fed Admins see the Federation dropdown
  if (isBOC || isFedAdmin) {
    buttonDisabled = buttonDisabled || !eventReport.teamId;
  }
  // The Exhibition field is only required when it appears on screen
  if (EventTypeConstants.EVENT_TYPES.EXHIBITION.ID === eventReport.eventTypeId) {
    buttonDisabled = buttonDisabled || !eventReport.opponent;
  }

  return (
    <div className="d-flex">
      <WBCPrimaryButton
        width={"150px"}
        disabled={buttonDisabled}
        onClick={event => {
          event.stopPropagation();
          addEventReportFn(eventReport);
          globalModalDispatch({
            type: "closeModal"
          });
        }}
      >
        Add Non-Game Day
      </WBCPrimaryButton>

      <div className={"pl-2"}>
        <WBCPrimaryOutlinedButton
          onClick={event => {
            event.stopPropagation();
            globalModalDispatch({
              type: "closeModal"
            });
          }}
        >
          Cancel
        </WBCPrimaryOutlinedButton>
      </div>
    </div>
  );
};

AddNonGameDayModalFooter.displayName = "FooterContent";

AddNonGameDayModalFooter.propTypes = {
  addEventReportFn: PropTypes.func.isRequired
};

export default AddNonGameDayModalFooter;
