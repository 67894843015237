import React from "react";
import PropTypes from "prop-types";
import OrgsContext from "../../contexts/OrgsContext";
import { useContext } from "react";

const ClubFormatter = ({ row }) => {
  const orgContext = useContext(OrgsContext);
  // adding both checks for case in prospective player and interest list becasue of the different objects
  if (
    (row?.orgId && ["BOC", "MSB", "NONE", null, ""].includes(orgContext.state.orgIdToCode[row?.orgId].toUpperCase())) ||
    ["BOC", "MSB", "NONE", null, ""].includes(row.org?.toUpperCase())
  ) {
    return <div></div>;
  }
  return <div>{row.clubId ? row.club : row.clubString}</div>;
};

ClubFormatter.propTypes = {
  row: PropTypes.object
};

export default ClubFormatter;
