import React, { useContext } from "react";
import { BestSelect } from "best-common-react";
import ClubCommDashboardContext from "../../../contexts/ClubCommDashboardContext";

const ClubCommOppSelect = () => {
  const clubCommDbContext = useContext(ClubCommDashboardContext);

  const { dispatch } = clubCommDbContext;

  const { opponentFilter, opponents } = clubCommDbContext.state;

  return (
    <div className="form-group">
      <BestSelect
        name="eventReportOppDropdown"
        value={opponentFilter}
        options={opponents}
        onChange={selection => {
          dispatch({
            type: "setOpponentFilter",
            label: selection.label,
            value: selection.value
          });
        }}
      />
    </div>
  );
};

export default ClubCommOppSelect;
