import React, { useContext } from "react";
import PropTypes from "prop-types";
import AuthContext from "../../contexts/AuthContext";

const FeatureToggler = ({ feature, children }) => {
  // context(s) && state
  const authContext = useContext(AuthContext);

  // variable(s)
  const { features } = authContext.state;

  return features[feature] ? <>{children}</> : null;
};

FeatureToggler.propTypes = {
  feature: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default FeatureToggler;
