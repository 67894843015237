import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { ErrorMessage } from "best-common-react";
import PitchTrackerTable from "./PitchTrackerTable";
import { ALL_TOURNAMENT_TYPES } from "../../constants/TournamentTypeConstants";
import PitchTrackerContext from "../../contexts/PitchTrackerContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";

const LoadingMessage = styled.div`
  text-align: center;
  max-width: 600px;
  color: #666666;
`;

LoadingMessage.displayName = "LoadingMessage";

const LoadingPitchTrackerTableSection = styled.section`
  position: relative;
`;

LoadingPitchTrackerTableSection.displayName = "LoadingPitchTrackerTableSection";

const LoadingPitchTrackerTable = ({ tournamentType }) => {
  const {
    state: { loadingError, loading, loaded, pitchers },
    actions: { requestPitchCounts }
  } = useContext(PitchTrackerContext);
  const {
    state: {
      team: { teamId: fedTeamId }
    }
  } = useContext(SelectedTeamContext);

  useEffect(() => {
    requestPitchCounts({ fedTeamId, tournamentType });
  }, [requestPitchCounts, fedTeamId, tournamentType]);

  return (
    <LoadingPitchTrackerTableSection>
      {(() => {
        if (loadingError) {
          return <ErrorMessage>Unexpected error loading pitch count data</ErrorMessage>;
        } else if (loading) {
          return <LoadingMessage>Loading...</LoadingMessage>;
        } else if (loaded) {
          return pitchers.length === 0 ? <div>No pitchers on final roster for tournament</div> : <PitchTrackerTable />;
        } else {
          return null;
        }
      })()}
    </LoadingPitchTrackerTableSection>
  );
};

LoadingPitchTrackerTable.displayName = "LoadingPitchTrackerTable";

LoadingPitchTrackerTable.propTypes = {
  tournamentType: PropTypes.oneOf(ALL_TOURNAMENT_TYPES).isRequired
};

export default LoadingPitchTrackerTable;
