import React from "react";
import PropTypes from "prop-types";
import WBCPrimaryOutlinedButton from "../../../elements/WBCPrimaryOutlinedButton";
import RouteConstants from "../../../../constants/RouteConstants";

const PersonMatchModalFooterContent = ({ matchData, setIsOpen, createPlayerFn, searchByEbisId, personType }) => {
  return (
    <div>
      {searchByEbisId ? (
        <div className={"pl-2"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.stopPropagation();
              setIsOpen(false);
            }}
          >
            Cancel
          </WBCPrimaryOutlinedButton>
        </div>
      ) : (
        <div className={"d-flex"}>
          <WBCPrimaryOutlinedButton
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
              createPlayerFn(matchData[0]);
            }}
            styles={{ width: "auto" }}
          >
            Continue Creating New {personType === RouteConstants.PLAYER ? "Player" : "Coaching Staff Member"}
          </WBCPrimaryOutlinedButton>

          <div className={"pl-2"}>
            <WBCPrimaryOutlinedButton
              onClick={event => {
                event.stopPropagation();
                setIsOpen(false);
              }}
            >
              Cancel
            </WBCPrimaryOutlinedButton>
          </div>
        </div>
      )}
    </div>
  );
};

PersonMatchModalFooterContent.propTypes = {
  matchData: PropTypes.array.isRequired,
  setIsOpen: PropTypes.func,
  createPlayerFn: PropTypes.func.isRequired,
  searchByEbisId: PropTypes.bool,
  personType: PropTypes.string.isRequired
};

PersonMatchModalFooterContent.displayName = "FooterContent";

export default PersonMatchModalFooterContent;
