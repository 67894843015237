import PropTypes from "prop-types";
import React from "react";
import { useTeamsByYear } from "../../components/hooks/useTeamsByYear";

const SelectedTeamContextInitilizer = ({ children }) => {
  useTeamsByYear();

  return <>{children}</>;
};

SelectedTeamContextInitilizer.propTypes = {
  children: PropTypes.object
};

export default SelectedTeamContextInitilizer;
