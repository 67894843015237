import React, { useContext } from "react";
import PropTypes from "prop-types";
import Moment from "moment";
import { FormColumn4Wide, FormInput, BestLabel } from "best-common-react";
import WBCRadioButton from "../../elements/WBCRadioButton";
import CountryContext from "../../../contexts/CountryContext";
import { minFormDate } from "../../../utils/DateUtils";
import { Label, Required } from "./CreateProfileFormStyles";

export const BioInfoForm = ({ person, searchEnabled, setPerson, setUpSearch }) => {
  const countryContext = useContext(CountryContext);

  return (
    <div className="d-flex flex-wrap flex-lg-nowrap">
      <div className="d-flex align-items-center" style={{ width: "35px", paddingTop: "7px" }}>
        <WBCRadioButton checked={searchEnabled} onClick={() => setUpSearch({ isBioInfoSearch: true })} />
      </div>
      <div className="row flex-fill">
        <FormColumn4Wide className="pl-lg-0">
          <BestLabel>
            <Label required={searchEnabled}>First Name</Label>
            <Required required={searchEnabled}>*</Required>
          </BestLabel>
          <FormInput
            type="text"
            name="firstName"
            isRequired={searchEnabled}
            isDisabled={!searchEnabled}
            value={person.firstName || ""}
            onChangeFn={value => setPerson({ ...person, firstName: value })}
            label=""
            editable
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel>
            <Label required={searchEnabled}>Last Name</Label>
            <Required required={searchEnabled}>*</Required>
          </BestLabel>
          <FormInput
            type="text"
            name="lastName"
            isRequired={searchEnabled}
            isDisabled={!searchEnabled}
            value={person.lastName || ""}
            onChangeFn={value => setPerson({ ...person, lastName: value })}
            label=""
            editable
          />
        </FormColumn4Wide>
        <FormColumn4Wide>
          <BestLabel>
            <Label required={searchEnabled}>Birth Country</Label>
            <Required required={searchEnabled}>*</Required>
          </BestLabel>
          <FormInput
            name="birthCountry"
            type="select"
            isRequired={searchEnabled}
            isDisabled={!searchEnabled}
            options={countryContext.state.countryDropdown}
            value={person.birthCountry || ""}
            onChangeFn={value => setPerson({ ...person, birthCountry: value })}
            label=""
            editable
          />
        </FormColumn4Wide>
        <FormColumn4Wide className="pl-lg-0 pl-xl-3">
          <BestLabel>
            <Label required={searchEnabled}>Birth Date</Label>
            <Required required={searchEnabled}>*</Required>
          </BestLabel>
          <FormInput
            name="birthDate"
            type="date"
            timeZone="America/New_York"
            minDate={minFormDate}
            value={(person.birthDate ? Moment(person.birthDate, "MM/DD/YYYY") : person.birthDate) || ""}
            onChangeFn={value =>
              setPerson({ ...person, birthDate: value ? Moment(value).format("MM/DD/YYYY") : value })
            }
            isRequired={searchEnabled}
            isDisabled={!searchEnabled}
            label=""
            editable
            autoComplete="off"
            popperPlacement={"bottom-start"}
            popperModifiers={{
              preventOverflow: {
                enabled: true,
                escapeWithReference: false
              }
            }}
          />
        </FormColumn4Wide>
      </div>
    </div>
  );
};

BioInfoForm.propTypes = {
  person: PropTypes.object,
  searchEnabled: PropTypes.bool,
  setPerson: PropTypes.func,
  setUpSearch: PropTypes.func
};

export default BioInfoForm;
