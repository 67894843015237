import React from "react";
import styled from "styled-components";

const EnvironmentCircle = styled.div`
  @media (min-width: 1024px) {
    width: 32.5px;
    height: 32.5px;
    top: 20px;
    left: 20px;
  }
  @media (max-width: 1023px) {
    width: 20.5px;
    height: 20.5px;
    top: 12px;
    left: 12px;
  }
  color: blue;
  border: solid 2px #1e376d;
  border-radius: 50%;
  background-color: ${props =>
    props.envCode === "dev"
      ? "rgba(238, 51, 66)"
      : props.envCode === "stg"
      ? "rgba(254, 209, 5)"
      : props.envCode === "bf"
      ? "rgba(0, 131, 192)"
      : null};
  position: absolute;
`;

const EnvironmentCircleText = styled.div`
  @media (min-width: 1024px) {
    height: 28.5px;
    line-height: 29.5px;
    font-size: 11px;
  }
  @media (max-width: 1023px) {
    height: 20.5px;
    line-height: 18.5px;
    font-size: 7px;
  }
  margin: auto;
  text-align: center;
  font-family: Helvetica;

  font-weight: bold;
  color: ${props => props.theme.white};
`;

const ENV = window.MLBBest.envVariables.REACT_APP_ENV;

const EnvironmentIndicator = () => {
  let showIndicator = ENV === "dev" || ENV === "stg" || ENV === "bf";
  let envText = ENV.toUpperCase();

  return (
    <div>
      {showIndicator ? (
        <EnvironmentCircle envCode={ENV}>
          <EnvironmentCircleText>{envText}</EnvironmentCircleText>
        </EnvironmentCircle>
      ) : null}
    </div>
  );
};

export default EnvironmentIndicator;
