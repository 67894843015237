import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Icon } from "best-common-react";
import WBCDataTable from "../data-table/WBCDataTable";
import PositionsContext from "../../contexts/PositionsContext";
import TableSortFilterContext from "../../contexts/TableSortFilterContext";
import LinkText from "../roster/components/LinkText";
import RouteConstants from "../../constants/RouteConstants";
import { sortPlayers } from "../roster/RosterSortHelper";
import ClubFormatter from "../table/ClubFormatter";
import OrgsContext from "../../contexts/OrgsContext";
import RequestStatusFormatter from "../table/RequestStatusFormatter";
import CoachingStaffActionsFormatter from "./CoachingStaffActionsFormatter";

const ROW_HEIGHT = 35;

const NppaIcon = styled(Icon)`
  &:hover {
    color: ${props => props.theme.wbcBlue};
    cursor: default;
  }
`;

const CoachingStaffTable = ({ isBOC, data, maxRows, staffType }) => {
  const orgsContext = useContext(OrgsContext);
  const { posIdToAbbrev } = useContext(PositionsContext).state;
  const tableSortFilterContext = useContext(TableSortFilterContext);
  const [rows, setRows] = useState([]);
  const [sortedRows, setSortedRows] = useState([]);
  const [sortFilters, setSortFilters] = useState({ col: "lastCommaFirstName", dir: "ASC" });

  const NppaFormatter = ({ value }) => {
    return value ? <NppaIcon iconName="fa-paperclip" /> : null;
  };

  NppaFormatter.propTypes = {
    value: PropTypes.bool
  };

  const StaffLinkFormatter = ({ value, row }) => {
    return (
      <LinkText row={row} profileType={RouteConstants.STAFF}>
        {value}
      </LinkText>
    );
  };

  StaffLinkFormatter.propTypes = {
    value: PropTypes.string,
    row: PropTypes.object
  };

  useEffect(() => {
    const personnelRows = data.map(person => {
      return {
        profileId: person?.profileId,
        fedTeamId: person?.fedTeamId,
        profileType: person?.profileType,
        lastName: person?.fullName?.lastName,
        firstName: person?.fullName?.firstName,
        lastCommaFirstName: `${person?.fullName?.lastName}, ${person?.fullName?.firstName}`,
        position: posIdToAbbrev[person?.stats?.positionId],
        hasNppa: person?.hasNppa,
        orgCode: orgsContext.getOrgCode(person?.affiliation?.orgId),
        clubString: person?.affiliation?.clubString,
        requestStatus: person?.mlbRequestStatus,
        staffType: staffType
      };
    });
    setRows(personnelRows);
  }, [data, posIdToAbbrev]);

  useEffect(() => {
    if (sortFilters.dir === "NONE") {
      setSortedRows(rows);
    } else {
      setSortedRows(sortPlayers(sortFilters.col, sortFilters.dir, rows));
    }
  }, [rows, sortFilters]);

  const columns = [
    { name: "", key: "actions", sortable: false, width: 25, formatter: CoachingStaffActionsFormatter },
    { name: "Name", key: "lastCommaFirstName", width: 250, formatter: StaffLinkFormatter, sortable: true },
    { name: "Pos", key: "position", width: 80, sortable: true },
    { name: "NPPA", key: "hasNppa", width: 80, formatter: NppaFormatter, sortable: true },
    { name: "Org", key: "orgCode", width: 80, sortable: true },
    { name: "Club", key: "clubString", width: 250, formatter: ClubFormatter, sortable: true },
    {
      name: isBOC ? "MLB Request Status" : "MLB Request",
      key: "requestStatus",
      formatter: RequestStatusFormatter,
      sortable: true
    }
  ];

  return (
    <WBCDataTable
      useBCR
      columns={columns}
      headerRowHeight={ROW_HEIGHT - 1}
      rowHeight={ROW_HEIGHT}
      height={Math.min(ROW_HEIGHT * data.length + (ROW_HEIGHT - 1), ROW_HEIGHT * maxRows + (ROW_HEIGHT - 1))}
      data={sortedRows}
      sortFunc={(col, dir) => {
        setSortFilters({ col, dir });
        tableSortFilterContext.dispatch({
          type: "setSortFilters",
          sortFilters: { col, dir }
        });
      }}
      sortColumn={sortFilters.col}
      sortDirection={sortFilters.dir}
    />
  );
};

CoachingStaffTable.propTypes = {
  isBOC: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  maxRows: PropTypes.number.isRequired,
  staffType: PropTypes.string.isRequired
};

export default CoachingStaffTable;
