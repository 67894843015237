import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.div.attrs(() => ({
  className: "d-flex"
}))`
  height: 16px;
  width: 16px;
`;

const Radio = styled.div.attrs(() => ({}))`
  &&& {
    height: 16px;
    width: 16px;
    background-color: ${props => (props.isDisabled ? props.theme["grey-two"] : "#fff")};
    border-radius: 50%;
    border: 1px solid ${props => props.theme["grey-two"]};
    position: relative;
  }
`;

const Check = styled.div.attrs(() => ({}))`
  height: 8px;
  width: 8px;
  background-color: ${props => props.theme["wbcBlue"]};
  border-radius: 50%;
  margin: 4px 0px 0px 4px;
  position: absolute;
`;

const Label = styled.span.attrs(() => ({
  className: "mr-2 ml-1"
}))`
  bottom: 3px;
  position: relative;
  color: ${props => props.theme["black"]};
  ${props => (props.bold ? "font-weight: bold;" : "")}
`;

const WBCRadioButton = ({ label, onClick, checked, bold, isDisabled }) => {
  return (
    <div className="d-flex" onClick={isDisabled ? null : onClick}>
      <Container>
        <Radio isDisabled={isDisabled} />
        {checked ? <Check /> : <></>}
      </Container>
      {label ? <Label bold={bold}>{label}</Label> : <></>}
    </div>
  );
};

WBCRadioButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  bold: PropTypes.bool,
  isDisabled: PropTypes.bool
};

WBCRadioButton.defaultProps = {
  bold: true,
  isDisabled: false
};

export default WBCRadioButton;
