import React from "react";
import PropTypes from "prop-types";
import { WBCPrimaryButton } from "./WBCPrimaryButton";
import styled from "styled-components";

const Button = styled(WBCPrimaryButton)`
  padding-left: 16px;

  span {
    flex-grow: 1;
  }
`;

const WBCPrimaryImageButton = ({ className, onClick, disabled, text, styles, height, width }) => {
  return (
    <Button
      className={`btn-md ${className || ""}`.trim()}
      onClick={event => onClick(event)}
      disabled={disabled}
      styles={styles}
      height={height}
      width={width}
    >
      <i className="fas fa-key fa-md" />
      <span style={{ fontSize: styles.text ? styles.text.fontSize || "13px" : "13px" }}>{text}</span>
    </Button>
  );
};

WBCPrimaryImageButton.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  styles: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number
};

export default WBCPrimaryImageButton;
