import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import StateAndProvinceContext from "../../contexts/StateAndProvinceContext";
import StateAndProvinceApi from "../../httpClients/StateAndProvinceApi";
import PositionsContext from "../../contexts/PositionsContext";
import OrgsContext from "../../contexts/OrgsContext";
import ClubsContext from "../../contexts/ClubsContext";
import { useAlert } from "../../components/hooks/useAlert";

const StateAndProvinceProviderInitializer = ({ children }) => {
  const stateAndProvinceContext = useContext(StateAndProvinceContext);
  const positionsContext = useContext(PositionsContext);
  const orgsContext = useContext(OrgsContext);
  const clubsContext = useContext(ClubsContext);
  const showAlert = useAlert();

  const { dispatch: orgDispatch } = orgsContext;
  const { dispatch: clubDispatch } = clubsContext;
  const { dispatch: stateDispatch } = stateAndProvinceContext;
  const { dispatch: positionDispatch } = positionsContext;

  useEffect(() => {
    const stateIdToName = {};
    const provinceIdToName = {};
    StateAndProvinceApi.getStates()
      .then(states => {
        const stateDropdown = states.map(state => {
          stateIdToName[state.stateId] = state.stateName;
          return { value: state.stateId, label: state.stateName, stateCode: state.stateCode };
        });

        stateDispatch({ type: "setStates", states });
        stateDispatch({
          type: "initializeStates",
          stateDropdown,
          stateIdToName
        });
      })
      .catch(response => showAlert(`${response.toString()}, while loading states`, "danger"));

    StateAndProvinceApi.getProvinces()
      .then(provinces => {
        const provinceDropdown = provinces.map(province => {
          provinceIdToName[province.provinceId] = province.provinceName;
          return { value: province.provinceId, label: province.provinceName };
        });

        stateDispatch({ type: "setProvinces", provinces });
        stateDispatch({
          type: "initializeProvinces",
          provinceDropdown,
          provinceIdToName
        });
      })
      .catch(response => showAlert(`${response.toString()}, while loading provinces`, "danger"));
  }, [orgDispatch, clubDispatch, stateDispatch, positionDispatch, showAlert]);

  return <div>{children}</div>;
};

StateAndProvinceProviderInitializer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default StateAndProvinceProviderInitializer;
