import React, { useCallback, useContext, useEffect } from "react";
import SelectedTeamContext from "../../../contexts/SelectedTeamContext";
import ClubCommDashboardContext from "../../../contexts/ClubCommDashboardContext";
import AuthContext from "../../../contexts/AuthContext";
import { findSelectedTeamByCountry } from "../../../utils/FedTeamUtils";
import { BestSelect } from "best-common-react";

const ClubCommFedSelect = () => {
  const selectedTeamContext = useContext(SelectedTeamContext);
  const clubCommDbContext = useContext(ClubCommDashboardContext);
  const authContext = useContext(AuthContext);

  const { team, teams } = clubCommDbContext.state;
  const { dispatch } = clubCommDbContext;
  const { isBOC } = authContext.state;
  const allTeams = selectedTeamContext.state.teams;

  const setTeam = useCallback(
    ({ value, label, teamId, code }) => {
      dispatch({
        type: "setTeam",
        value,
        label,
        teamId,
        code
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: "setTeams",
      teams: allTeams
    });
  }, [dispatch, allTeams, isBOC]);

  // since fed teams are different for each year, when the year changes, the teams change
  // so we select the team for the previously selected country
  useEffect(() => {
    const teamForCountry = findSelectedTeamByCountry(teams, team);
    if (teamForCountry && teamForCountry.teamId !== team.teamId) {
      setTeam(teamForCountry);
    }
  }, [setTeam, teams, team]);

  return (
    <div className="form-group">
      <BestSelect name="teamDropdown" value={team} options={teams} onChange={setTeam} />
    </div>
  );
};

export default ClubCommFedSelect;
