import React from "react";
import Users from "../components/users/Users";
import UserManagementContextProvider from "../providers/UserManagementContextProvider";
import UserManagementFormContextProvider from "../providers/UserManagementFormContextProvider";

const UsersContainer = () => {
  return (
    <UserManagementFormContextProvider>
      <UserManagementContextProvider>
        <Users />
      </UserManagementContextProvider>
    </UserManagementFormContextProvider>
  );
};

export default UsersContainer;
