import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { EnvIndicator } from "best-common-react";
import MobileView from "./MobileView";

const EnvHolder = styled.div.attrs(() => ({
  className: "fixed-top"
}))`
  width: 100%;
`;

const NavContainer = styled.div`
  text-align: center;
`;

const NavBar = ({ children, displayEnvIndicator, vertical, mobileMax }) => {
  const getComponent = displayName => {
    return children.filter(comp => {
      return comp.props.displayName === displayName;
    });
  };

  return (
    <NavContainer id="best-navbar">
      {displayEnvIndicator ? (
        <MobileView maxWidth={mobileMax}>
          <EnvHolder>
            <EnvIndicator />
          </EnvHolder>
        </MobileView>
      ) : null}
      {getComponent("nav-bar-signout")}
      {vertical ? getComponent("navigation-vertical") : getComponent("navigation")}
    </NavContainer>
  );
};

NavBar.propTypes = {
  displayEnvIndicator: PropTypes.bool,
  vertical: PropTypes.bool,
  mobileMax: PropTypes.number,
  children: PropTypes.any
};

NavBar.defaultProps = {
  displayEnvIndicator: false,
  vertical: false,
  mobileMax: 767
};

export default NavBar;
