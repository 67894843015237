import Moment from "moment";

const calculateMaxDate = () => {
  const dateObj = new Date();
  dateObj.setDate(dateObj.getDate() - 1);
  return Moment(dateObj);
};

export const formatDate = (dateInput, formatStr = "MM/DD/YYYY") => {
  if (!!dateInput) {
    const date = Moment(dateInput)
      .utc()
      .format(formatStr);
    if (date !== "Invalid date") {
      return date;
    }
  }
  return "";
};

export const maxFormDate = calculateMaxDate();
export const minFormDate = Moment(new Date("1/1/1910").valueOf());
export const isSameOrBefore = (dateStr1, dateStr2) => Moment(dateStr1).isSameOrBefore(dateStr2);
