import BaseApi from "./BaseApi";

export default class PlayerDocumentsApi extends BaseApi {
  static getPlayerDocuments = (profileId, teamId, tournamentType) =>
    this.get(
      `playerdocuments?profileId=${profileId}&fedTeamId=${teamId}${
        tournamentType ? `&tournamentType=${tournamentType}` : ""
      }`
    );

  static getPlayerDocumentsByLabel = (profileId, teamId, docLabelIds, tournamentType) =>
    this.post(
      `playerdocuments/label?profileId=${profileId}&fedTeamId=${teamId}${
        tournamentType ? `&tournamentType=${tournamentType}` : ""
      }`,
      docLabelIds
    );

  static uploadDocuments = (profileId, teamId, saveDocumentRequest) =>
    this.post(`playerdocuments/upload?profileId=${profileId}&fedTeamId=${teamId}`, saveDocumentRequest);

  static getDocumentLabels = docLabelTypeId => this.get(`getDocumentLabels/${docLabelTypeId}`);

  static downloadDocument = (teamId, documentId, profileId, transform) =>
    this.download(
      `playerdocuments/download?fedTeamId=${teamId}&documentId=${documentId}&profileId=${profileId}`,
      null,
      null,
      "text/plain",
      transform
    );
}
