import React, { useCallback, useContext, useEffect, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import SelectedTeamContext from "../../contexts/SelectedTeamContext";
import WBCYearsContext from "../../contexts/WBCYearsContext";
import { QUALIFYING, TOURNAMENT_TYPE_BY_ID } from "../../constants/TournamentTypeConstants";
import CoachingStaffSection from "./CoachingStaffSection";
import { withRouter } from "react-router-dom";
import WBCCollapse from "../elements/WBCCollapse";
import CreateProfileForm from "../profile/create-form/CreateProfileForm";
import CoachingStaffContext from "../../contexts/CoachingStaffContext";
import WBCAdminAccess from "../protected/WBCAdminAccess";
import CoachingStaffConstants from "../../constants/CoachingStaffConstants";
import StaffApi from "../../httpClients/StaffApi";

const CoachingStaff = withRouter(({ history }) => {
  const { isBOC } = useContext(AuthContext).state;
  const { teamId: fedTeamId } = useContext(SelectedTeamContext).state.team;
  const { selectedWbcYear } = useContext(WBCYearsContext);
  const coachingStaffContext = useContext(CoachingStaffContext);

  const { dispatch: coachingStaffContextDispatch } = coachingStaffContext;
  const { shouldLoadStaff, staff } = coachingStaffContext.state;

  const [tournamentType, setTournamentType] = useState(null);
  const [tournamentSettings, setTournamentSettings] = useState(null);

  const loadStaff = useCallback(() => {
    if (!fedTeamId || !tournamentType) {
      return;
    }

    coachingStaffContextDispatch({ type: "reset" });
    StaffApi.getTeamStaff(fedTeamId, tournamentType).then(response => {
      coachingStaffContextDispatch({ type: "setStaff", staff: response });
    });
    coachingStaffContextDispatch({ type: "setShouldLoadStaff", shouldLoadStaff: false });
  }, [coachingStaffContextDispatch, fedTeamId, tournamentType]);

  useEffect(() => {
    if (fedTeamId && tournamentType) {
      coachingStaffContextDispatch({ type: "setShouldLoadStaff", shouldLoadStaff: true });
    }
  }, [coachingStaffContextDispatch, fedTeamId, tournamentType]);

  useEffect(() => {
    if (shouldLoadStaff) {
      loadStaff();
    }
  }, [shouldLoadStaff, loadStaff]);

  useEffect(() => {
    if (selectedWbcYear?.generalRosterSettings?.tournamentTypeId) {
      const selectedYearTournamentType = TOURNAMENT_TYPE_BY_ID[selectedWbcYear.generalRosterSettings.tournamentTypeId];
      const selectedYearTournamentSettings =
        selectedYearTournamentType === QUALIFYING
          ? selectedWbcYear.qualifyingTournamentSettings
          : selectedWbcYear.mainTournamentSettings;
      setTournamentType(selectedYearTournamentType);
      setTournamentSettings(selectedYearTournamentSettings);
    }
  }, [selectedWbcYear]);

  return (
    <div className="px-3 py-2">
      <WBCAdminAccess>
        <WBCCollapse title="Create a new staff member" startsOpen={false}>
          <CreateProfileForm />
        </WBCCollapse>
      </WBCAdminAccess>
      <CoachingStaffSection
        staff={{
          type: CoachingStaffConstants.PERSONNEL_TYPE.COACHES,
          min: tournamentSettings?.coaches?.coachesMin,
          max: tournamentSettings?.coaches?.coachesMax
        }}
        isBOC={isBOC}
        personnel={staff?.coaches || []}
      />
      <CoachingStaffSection
        staff={{
          type: CoachingStaffConstants.PERSONNEL_TYPE.MANAGERS,
          min: tournamentSettings?.managers?.managersMin,
          max: tournamentSettings?.managers?.managersMax
        }}
        isBOC={isBOC}
        personnel={staff?.managers || []}
      />
      <CoachingStaffSection
        staff={{
          type: CoachingStaffConstants.PERSONNEL_TYPE.TRAINERS,
          min: tournamentSettings?.trainers?.trainersMin,
          max: tournamentSettings?.trainers?.trainersMax
        }}
        isBOC={isBOC}
        personnel={staff?.trainers || []}
      />
    </div>
  );
});

export default CoachingStaff;
