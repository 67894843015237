import React, { useContext } from "react";
import PropTypes from "prop-types";
import { BestTitle, FormColumn2Wide, FormInput } from "best-common-react";
import WBCAccessControl from "../protected/WBCAccessControl";
import { FormTitleWrapper } from "./shared/ProfileComponents";
import DisabledInputStyle from "../elements/DisabledInputStyle";
import CountryContext from "../../contexts/CountryContext";
import LanguageContext from "../../contexts/LanguageContext";
import ProfileContext from "../../contexts/ProfileContext";
import UncontrolledFormInput from "../elements/UncontrolledFormInput";
import { minFormDate, maxFormDate, formatDate } from "../../utils/DateUtils";
import StateAndProvinceContext from "../../contexts/StateAndProvinceContext";

const ProfileBioInfoForm = ({ isNew, isStaff }) => {
  // hooks
  const countryContext = useContext(CountryContext);
  const languageContext = useContext(LanguageContext);
  const profileContext = useContext(ProfileContext);
  const stateAndProvinceContext = useContext(StateAndProvinceContext);

  // variables
  const {
    firstName,
    lastName,
    middleName,
    extendedLastName,
    birthDate,
    birthCountryId,
    birthStateId,
    birthCity,
    birthProvinceId,
    primaryLanguageId,
    secondaryLanguageId
  } = profileContext.state;
  const { countryIdToName, countryDropdown } = countryContext.state;
  const { languageDropdown, languageIdToName } = languageContext.state;
  const { stateDropdown, stateIdToName, provinceDropdown, provinceIdToName } = stateAndProvinceContext.state;

  // name
  const firstNameDisabled = Boolean(firstName && !isNew);
  const firstNameRequired = !firstNameDisabled;
  const lastNameDisabled = Boolean(lastName && !isNew);
  const lastNameRequired = !lastNameDisabled;

  // functions
  const onProfileChange = (value, key) => {
    profileContext.dispatch({
      key,
      value,
      type: "setProfileInfo"
    });
  };

  const onFirstNameChange = value => onProfileChange(value, "firstName");
  const onLastNameChange = value => onProfileChange(value, "lastName");

  return (
    <WBCAccessControl
      input={isDisabled => (
        <div>
          <BestTitle>
            <FormTitleWrapper>Bio Information</FormTitleWrapper>
          </BestTitle>
          <div className="row">
            <FormColumn2Wide>
              <FormInput
                editable
                isDisabled={firstNameDisabled}
                isRequired={firstNameRequired}
                type="text"
                name="firstName"
                label="First Name"
                value={firstName}
                inputStyle={firstNameDisabled ? DisabledInputStyle : undefined}
                onChangeFn={firstNameRequired ? onFirstNameChange : undefined}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                editable
                isDisabled={firstNameDisabled}
                isRequired={firstNameRequired}
                type="text"
                name="lastName"
                label="Last Name"
                value={lastName}
                inputStyle={lastNameDisabled ? DisabledInputStyle : undefined}
                onChangeFn={lastNameRequired ? onLastNameChange : undefined}
              />
            </FormColumn2Wide>
          </div>
          <div className="row">
            <FormColumn2Wide>
              <UncontrolledFormInput
                id="middleName"
                type="text"
                label="Middle"
                disabled={isDisabled}
                value={middleName}
                postChange={value => onProfileChange(value, "middleName")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <UncontrolledFormInput
                id="extendedLast"
                type="text"
                label="Extended Last"
                disabled={isDisabled}
                value={extendedLastName}
                postChange={value => onProfileChange(value, "extendedLastName")}
              />
            </FormColumn2Wide>
          </div>
          <div className="row">
            <FormColumn2Wide>
              {birthDate && !isNew ? (
                <UncontrolledFormInput disabled id="birthDate" type="text" label="Birth Date" value={birthDate} />
              ) : (
                <FormInput
                  editable
                  isRequired
                  type="date"
                  name="birthDate"
                  autoComplete="off"
                  label="Birth Date"
                  timeZone="America/New_York"
                  minDate={minFormDate}
                  maxDate={maxFormDate}
                  value={birthDate}
                  onChangeFn={value => onProfileChange(formatDate(value) || value, "birthDate")}
                  popperModifiers={{
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false
                    }
                  }}
                />
              )}
            </FormColumn2Wide>
            <FormColumn2Wide>
              {birthCountryId && !isNew && !isStaff ? (
                <UncontrolledFormInput
                  disabled
                  id="birthCountry"
                  type="text"
                  label="Birth Country"
                  value={countryIdToName[birthCountryId]}
                />
              ) : (
                <FormInput
                  editable
                  type="select"
                  name="birthCountry"
                  label="Birth Country"
                  isDisabled={isDisabled}
                  isRequired={!isStaff && !isDisabled}
                  options={countryDropdown}
                  value={{
                    value: birthCountryId || "",
                    label: countryIdToName[birthCountryId] || ""
                  }}
                  onChangeFn={value => onProfileChange(value.value, "birthCountryId")}
                />
              )}
            </FormColumn2Wide>
          </div>
          <div className="row">
            <FormColumn2Wide>
              {birthCountryId && countryIdToName[birthCountryId] === "United States" ? (
                <FormInput
                  editable
                  name="birthState"
                  label="Birth State"
                  type="select"
                  isDisabled={isDisabled}
                  isRequired={!isStaff && !isDisabled}
                  inputStyle={DisabledInputStyle}
                  options={stateDropdown}
                  value={{
                    value: birthStateId || "",
                    label: stateIdToName[birthStateId] || ""
                  }}
                  onChangeFn={value => onProfileChange(value.value, "birthStateId")}
                />
              ) : countryIdToName[birthCountryId] === "Canada" ? (
                <FormInput
                  editable
                  name="birthProvince"
                  label="Birth Province"
                  type="select"
                  inputStyle={DisabledInputStyle}
                  isDisabled={isDisabled}
                  isRequired={!isStaff && !isDisabled}
                  options={provinceDropdown}
                  value={{
                    value: birthProvinceId,
                    label: provinceIdToName[birthProvinceId] || ""
                  }}
                  onChangeFn={value => onProfileChange(value.value, "birthProvinceId")}
                />
              ) : (
                <FormInput
                  editable
                  isDisabled
                  name="disabledInputWithText"
                  type="select"
                  label="Birth State/Province"
                  value="Disabled Value"
                />
              )}
            </FormColumn2Wide>
            <FormColumn2Wide>
              <UncontrolledFormInput
                type="text"
                id="birthCity"
                label="Birth City"
                disabled={isDisabled}
                required={!isStaff && !isDisabled}
                value={birthCity}
                postChange={value => onProfileChange(value, "birthCity")}
              />
            </FormColumn2Wide>
          </div>
          <div className="row">
            <FormColumn2Wide>
              <FormInput
                editable
                isRequired={false}
                type="select"
                name="primaryLanguage"
                label="Primary Language"
                isDisabled={isDisabled}
                options={languageDropdown}
                inputStyle={DisabledInputStyle}
                value={{
                  value: primaryLanguageId || "",
                  label: languageIdToName[primaryLanguageId] || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "primaryLanguageId")}
              />
            </FormColumn2Wide>
            <FormColumn2Wide>
              <FormInput
                editable
                isRequired={false}
                isDisabled={isDisabled}
                type="select"
                name="secondaryLanguage"
                label="Secondary Language"
                inputStyle={DisabledInputStyle}
                options={languageDropdown}
                value={{
                  value: secondaryLanguageId || "",
                  label: languageIdToName[secondaryLanguageId] || ""
                }}
                onChangeFn={value => onProfileChange(value.value, "secondaryLanguageId")}
              />
            </FormColumn2Wide>
          </div>
        </div>
      )}
    />
  );
};

ProfileBioInfoForm.propTypes = {
  isNew: PropTypes.bool,
  isStaff: PropTypes.bool
};

export default ProfileBioInfoForm;
