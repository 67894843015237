import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Popover from "react-tiny-popover";

const PopoverOnHover = ({ children, closeTimeout, content, ...popoverProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  const closePopoverTimeoutRef = useRef(null);

  const clearPopoverTimeout = useCallback(() => {
    if (closePopoverTimeoutRef.current) {
      clearTimeout(closePopoverTimeoutRef.current);
    }
  }, []);

  const onMouseEnter = useCallback(() => {
    clearPopoverTimeout();
    setIsOpen(true);
  }, [clearPopoverTimeout]);

  const onMouseLeave = useCallback(() => {
    closePopoverTimeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, closeTimeout);
  }, [closeTimeout]);

  const onClick = useCallback(() => {
    setIsOpen(false);
  }, []);

  // cleanup async actions to prevent state updates after unmounting
  useEffect(() => clearPopoverTimeout, [clearPopoverTimeout]);

  return (
    <Popover
      content={
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
          {content}
        </div>
      }
      isOpen={isOpen}
      {...popoverProps}
    >
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        {children({ isOpen })}
      </div>
    </Popover>
  );
};

PopoverOnHover.propTypes = {
  ...Popover.propTypes,
  closeTimeout: PropTypes.number,
  content: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired
};

PopoverOnHover.defaultProps = {
  closeTimeout: 300,
  popoverTransitionDuration: 0.35
};

export default PopoverOnHover;
