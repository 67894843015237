import React from "react";
import { ApproveButton, DenyButton } from "./EligibilityButtons.js";
import PropTypes from "prop-types";

function PendingButtonFormatter({ onApproveClick, onDenyClick }) {
  return (
    <div className="d-flex">
      <ApproveButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onApproveClick();
        }}
      >
        Approve
      </ApproveButton>
      <DenyButton
        className={"mr-2"}
        onClick={event => {
          event.stopPropagation();
          onDenyClick();
        }}
        style={{ width: "59px" }}
      >
        Deny
      </DenyButton>
    </div>
  );
}

PendingButtonFormatter.propTypes = {
  onApproveClick: PropTypes.func.isRequired,
  onDenyClick: PropTypes.func.isRequired
};

export default PendingButtonFormatter;
