import React, { useContext, useMemo, useCallback } from "react";
import { BestSelect } from "best-common-react";
import WBCYearsContext from "../../../contexts/WBCYearsContext";
import { isCurrentOrPastTournamentYear } from "../../../utils/WBCYearPredicates";

const ClubCommYearSelect = () => {
  const { wbcYears, selectedWbcYear, changeSelectedYear } = useContext(WBCYearsContext);
  const filteredOptions = useMemo(() => wbcYears.filter(isCurrentOrPastTournamentYear), [wbcYears]);

  return (
    <div className="form-group">
      <BestSelect
        name="yearsDropdown"
        value={selectedWbcYear}
        options={filteredOptions}
        onChange={useCallback(({ year }) => changeSelectedYear(year), [changeSelectedYear])}
      />
    </div>
  );
};

export default ClubCommYearSelect;
