import React, { useReducer } from "react";
import PropTypes from "prop-types";
import CoachingStaffContext from "../contexts/CoachingStaffContext";

export const initialState = {
  shouldLoadStaff: false,
  staff: {
    coaches: [],
    managers: [],
    trainers: []
  }
};

const CoachingStaffContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "setStaff": {
        return {
          ...state,
          staff: action.staff
        };
      }
      case "setShouldLoadStaff": {
        return {
          ...state,
          shouldLoadStaff: action.shouldLoadStaff
        };
      }
      case "reset": {
        return {
          ...initialState
        };
      }
      default:
        return state;
    }
  }, initialState);

  return <CoachingStaffContext.Provider value={{ state, dispatch }}>{children}</CoachingStaffContext.Provider>;
};

CoachingStaffContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default CoachingStaffContextProvider;
