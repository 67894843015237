import styled from "styled-components";

export const Required = styled.span`
  color: ${props => (props.required ? props.theme["mlb-red"] : props.theme["dark-grey"])};
  padding-left: 5px;
`;

export const Label = styled.span`
  color: ${props => (props.required ? props.theme["black"] : props.theme["dark-grey"])};
`;
