const Theme = {
  white: "#FFFFFF",
  "white-two": "#ececec",
  "almost-white": "#f7f7f7",
  "light-grey": "#f2f3f4",
  grey: "#d2d2d2",
  "grey-two": "#c2c2c2",
  "grey-three": "#e6e6e6",
  "dark-grey": "#666666",
  primary: "#0083c0",
  "mlb-blue": "#002e6d",
  "sky-blue": "#2b77eb",
  "mlb-red": "#ed1c34",
  mlbNavy: "#1e376d",
  mlbDarkNavy: "#192E5A",
  black: "#000000",
  green: "#00a651",
  wbcYellow: "#fed105",
  wbcGreen: "#3eb049",
  wbcBlue: "#0083c0",
  wbcRed: "#ee3342",
  // links
  link: {
    color: "#007bff",
    hover: "#002e6d"
  },
  // icons
  icon: {
    color: "#0083c0",
    hover: "#4c8cee",
    size: "1.1rem"
  },
  "focus-color": "#fbf8cb",
  // notifications
  success: {
    background: "#3eb049",
    color: "#FFFFFF",
    border: "#1e376d"
  },
  danger: {
    background: "#ee3342",
    color: "#ffffff",
    border: "#1e376d"
  },
  warning: {
    background: "#fed105",
    color: "#1e376d",
    border: "#856404"
  },
  // hardcoded pixel values
  lineHeight: {
    eventGrid: "21px"
  },
  environment: {
    dev: {
      background: "#bf0d3e"
    },
    stg: {
      background: "#EDB707"
    },
    qa: {
      background: "#1F74BE"
    }
  },
  border: {
    valid: "#80bdff",
    invalid: "#dc3545"
  },
  boxShadow: {
    valid: "rgba(0,123,255,.25)",
    invalid: "rgba(220,53,69,.25)"
  }
};

export default Theme;
